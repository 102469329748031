import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMealItem } from '../interfaces/meal-item.interface';
import { IoService } from '../../services/http/io.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-meal-item',
    templateUrl: './meal-item.component.html',
    styleUrls: ['./meal-item.component.css']
})
export class MealItemComponent implements OnInit {
    @Input() item: IMealItem;
    @Input() markDishImageInSummaryDisabled: boolean;
    @Output() removeItem = new EventEmitter();

    searchText$ = new Subject<string>();

    itemsList: { id: number, name: string, image: string, calories: number, protein: number, carbs: number, fat: number }[] = [];
    dropdownSelectedList: { id: number, name: string }[] = [];

    formUniqueKey: string = `${Date.now().toString()}_${Math.random().toString()}`;

    selectText: string = 'Select dish';

    dropdownSettings: IDropdownSettings = {
        searchPlaceholderText: 'Search',
        singleSelection: true,
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        allowRemoteDataSearch: true,
        closeDropDownOnSelection: true,
    };

    constructor(
        private io: IoService,
    ) { }

    ngOnInit(): void {
        this.searchText$.pipe(
            debounceTime(800),
            distinctUntilChanged(),
        ).subscribe((searchValue) => {
            if (!searchValue || searchValue.trim().length < 2) return;

            this.searchForItem(searchValue.trim());
        });

        if (this.item.id) {
            this.dropdownSelectedList = [{ id: this.item.id, name: this.item.name }];

            this.setSelectText();
        }
    }

    searchForItem(text: string) {
        if (this.item.type === 'order') {
            this.searchForMenuProduct(text);
        } else if (this.item.type === 'cook') {
            this.searchForRecipe(text);
        }
    }

    async searchForMenuProduct(text: string) {
        const res = await this.io.apicall(null, `menu-product/list-by-name?name=${text}`, 'GET');

        this.itemsList = res.menuProducts.map((menuProduct) => ({
            id: menuProduct.id,
            name: `${menuProduct.name} [${menuProduct?.dish?.restaurant?.name}${menuProduct?.dish?.restaurant?.branchName ? ` - ${menuProduct?.dish?.restaurant?.branchName}` : ''}]`,
            image: menuProduct.dish.discImage,
            calories: Math.round(Number(menuProduct.dish.totalCalories) * 100) / 100,
            protein: Math.round(Number(menuProduct.dish.protein) * 100) / 100,
            carbs: Math.round(Number(menuProduct.dish.carbs) * 100) / 100,
            fat: Math.round(Number(menuProduct.dish.fat) * 100) / 100,
        }));
    }

    async searchForRecipe(text: string) {
        const res = await this.io.apicall(null, `recipe/search-by-name?name=${text}`, 'GET');

        this.itemsList = res.recipes.map((recipe) => ({
            id: recipe.id,
            name: `${recipe.recipeName} [${recipe.creator?.name}]`,
            image: recipe.recipeImage,
            calories: Math.round(Number(recipe.totalCalories) * 100) / 100,
            protein: Math.round(Number(recipe.protein) * 100) / 100,
            carbs: Math.round(Number(recipe.carbs) * 100) / 100,
            fat: Math.round(Number(recipe.fat) * 100) / 100,
        }));
    }

    setSelectText() {
        if (this.item.type === 'order') {
            this.selectText = 'Select dish';
        } else if (this.item.type === 'cook') {
            this.selectText = 'Select recipe';
        }
    }

    typeChanged() {
        this.setSelectText();

        this.searchText$.next('');

        this.itemsList = [];
        this.dropdownSelectedList = [];

        Object.assign(this.item, {
            id: undefined,
            name: undefined,
            image: undefined,
            showInSummary: false,
            calories: 0,
            protein: 0,
            carbs: 0,
            fat: 0,
        });
    }

    onSearchTextChanged(event) {
        this.searchText$.next(event);
    }

    listChange() {
        if (!this.dropdownSelectedList.length) return;

        const foundItem = this.itemsList.find((item) => item.id === this.dropdownSelectedList[0].id);

        Object.assign(this.item, {
            id: this.dropdownSelectedList[0].id,
            name: this.dropdownSelectedList[0].name,
            image: foundItem.image,
            calories: foundItem.calories,
            protein: foundItem.protein,
            carbs: foundItem.carbs,
            fat: foundItem.fat,
        });
    }

    removeMealItem() {
        this.removeItem.emit();
    }
}
