import { Component, OnDestroy, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { IoService } from "../../services/http/io.service";
import { DataService } from "../../services/data-management/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { PusherServiceService } from "../../services/http/pusher-service.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { OrderService } from "./../../services/order/order.service";

@Component({
  selector: "app-order-list",
  templateUrl: "./order-list.component.html",
  styleUrls: ["./order-list.component.css"],
})
export class OrderListComponent implements OnInit, OnDestroy {
  totalPages: number;
  currentPage: number = 1;
  itemsPerPage: number = 25;
  orderList: any = [];
  orderStatusList: any;
  // restAutoCompleteList = [];
  data = [];
  restaurantId: string = "";
  status: number[];
  selectedStatus: any = "";
  restaurantName: string = "";
  keyword = "restaurantName";
  newOrder: any;
  newOrderList: any = [];
  tabs: any = [];
  activeTab: number = 0;
  paramObserver: any;
  selectedRestaurant: any = null;

  constructor(
    private ioservice: IoService,
    private dataservice: DataService,
    private router: Router,
    private http: HttpClient,
    private pusherService: PusherServiceService,
    private loader: NgxUiLoaderService,
    private OrderService: OrderService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let paramPage = values.page;
      let paramTab = values.tab;
      let paramStatus = values.status || null;
      let paramSearch = values.search;
      let paramSearchId = values.searchId;
      this.tabs =[
        { url: "", text: "New", status: [1] },
        { url: "", text: "On-going", status: [1, 4, 5, 6] },
        { url: "", text: "Completed", status: [7] },
        { url: "", text: "Canceled", status: [2, 3] },
        { url: "", text: "All", status: [1, 2, 3, 4, 5, 6, 7] },
      ]
      if (paramPage) {
        this.currentPage = +paramPage;
      } else {
        this.currentPage = 1;
      }
      if (paramTab) {
        this.activeTab = +paramTab;
      } else {
        this.activeTab = 0;
      }
      this.tabs[this.activeTab] = {
        ...this.tabs[this.activeTab],
        isActive: true,
      };
      if (paramStatus) {
        this.status = [+paramStatus];
        this.selectedStatus = +paramStatus;
      } else {
        this.status = this.tabs[this.activeTab]?.status;
      }
      if (paramSearch && paramSearchId) {
        this.restaurantId = paramSearchId;
        this.restaurantName = paramSearch;
        this.selectedRestaurant = {
          restaurantName: this.restaurantName,
          id: this.restaurantId,
        };
        this.loadRestaurantOrders(this.restaurantId);
      } else {
        this.handleParams(this.currentPage, this.activeTab);
        this.restaurantOrderList({
          page: this.currentPage,
          limit: this.itemsPerPage,
          restaurantId: this.restaurantId ? this.restaurantId : "",
          status: this.status ? this.status : "",
        });
      }
      this.restaurantOrderStatusList();
    });
    this.pusherService.channel.bind("new-order-initiated", this.handlePusher);
    this.pusherService.channel.bind("order-status-update", this.handlePusher);
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
    this.pusherService.channel.unbind("new-order-initiated", this.handlePusher);
    this.pusherService.channel.unbind("order-status-update", this.handlePusher);
  }

  handleParams(page, tab): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page,
        tab,
        status: this.selectedStatus ? this.selectedStatus : null,
        search: this.restaurantName || null,
        searchId: this.restaurantId || null,
      },
      queryParamsHandling: "merge",
    });
  }

  handleTabChange(index: number): void {
    this.selectedStatus = "";
    delete this.tabs[this.activeTab].isActive;
    this.tabs[index] = { ...this.tabs[index], isActive: true };
    this.activeTab = index;
    this.currentPage = 1;
    this.status = this.tabs[index]?.status;
    this.handleParams(this.currentPage, this.activeTab);
    this.restaurantOrderStatusList();
    this.restaurantOrderList({
      page: this.currentPage,
      limit: this.itemsPerPage,
      restaurantId: this.restaurantId ? this.restaurantId : "",
      status: this.status ? this.status : "",
    });
  }

  handlePusher = (data) => {
    this.restaurantOrderList({
      page: this.currentPage,
      limit: this.itemsPerPage,
      restaurantId: this.restaurantId ? this.restaurantId : "",
      status: this.status ? this.status : "",
    });
  };

  // RESTAURANT ORDER  STATUS LIST //
  restaurantOrderStatusList() {
    this.http.get<any>("./assets/data/orderStatus.json").subscribe((data) => {
      this.orderStatusList = data.orderStatus.filter((item) =>
        this.tabs[this.activeTab]?.status?.includes(item.id)
      );
    });
  }

  // RESTAURANT ORDER LIST //
  restaurantOrderList(payLoad: any): void {
    this.loader.start();
    this.OrderService.loadOrders(payLoad).subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          this.orderList = res["result"].orderList;
          this.totalPages = res["result"].totalRecords;
        }
        this.loader.stop();
      },
      (err) => {
        this.loader.stop();
        throw err;
      }
    );
  }

  // PAGINATION //
  pageChanged(event) {
    this.handleParams(event, this.activeTab);
    if (this.currentPage >= 1 && this.currentPage < this.totalPages) {
      let payLoad = {
        page: this.currentPage,
        limit: this.itemsPerPage,
        restaurantId: this.restaurantId ? this.restaurantId : "",
        status: this.status ? this.status : "",
      };
      this.restaurantOrderList(payLoad);
    }
  }

  // SEARCH BY STATUS //
  searchByStatus(event) {
    this.status = [parseInt(event)];
    this.selectedStatus = event;
    this.orderList = [];
    this.currentPage = 1;
    this.handleParams(1, this.activeTab);
    let payLoad = {
      page: this.currentPage,
      limit: this.itemsPerPage,
      restaurantId: this.restaurantId ? this.restaurantId : "",
      status: this.status.length != 0 ? this.status : "",
    };
    this.restaurantOrderList(payLoad);
  }

  selectEvent(event) {
    this.restaurantId = event.id;
    this.restaurantName = event.restaurantName;
    this.selectedRestaurant = {
      restaurantName: this.restaurantName,
      id: this.restaurantId,
    };
    this.loadRestaurantOrders(this.restaurantId);
  }

  loadRestaurantOrders(id): void {
    this.orderList = [];
    this.currentPage = 1;
    this.handleParams(this.currentPage, this.activeTab);
    let payLoad = {
      page: this.currentPage,
      limit: this.itemsPerPage,
      restaurantId: id ? id : "",
      status: this.status ? this.status : "",
    };
    this.restaurantOrderList(payLoad);
  }

  // RESTAURANT AUTOCOMPLETE LIST //
  restaurantAutocompleteList(restaurantName: string) {
    if (restaurantName.length > 2) {
      let payLoad = {
        restaurantName: restaurantName,
      };
      this.ioservice
        .apicall(payLoad, "restaurant/restaurant-list-autocomplete", "POST")
        .then((res: any) => {
          this.data = res["result"];
        });
    }
  }

  onAutoSearchClear(): void {
    this.restaurantId = "";
    this.restaurantName = "";
    this.loadRestaurantOrders("");
  }

  onFocused(e) {
    // do something when input is focused
  }
}
