import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute } from "@angular/router";
import { IoService } from "../../services/http/io.service";

@Component({
  selector: "app-customer-list",
  templateUrl: "./customer-list.component.html",
  styleUrls: ["./customer-list.component.css"],
})
export class CustomerListComponent implements OnInit, OnDestroy {
  page: number = 1;
  itemsPerPage: number = 50;
  customerList: any = [];
  customerDetails: any = {};
  enqueryList: boolean = false;
  userItem: any = "";
  total: number;
  isSearch: boolean = false;
  paramObserver: any;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private io: IoService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let currentPage = values.page;
      let currentFilter = values.filter;
      if (currentPage) {
        this.page = currentPage;
      } else {
        this.page = 1;
      }
      if (currentFilter) {
        this.isSearch = true;
        this.userItem = currentFilter;
      }
      this.fetchCustomerList();
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  handleParams(page, filter): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page,
        filter: filter ? filter : null,
      },
      queryParamsHandling: "merge",
    });
  }

  fetchCustomerList() {
    this.customerList = [];
    let payLoad = {
      page: this.page,
      limit: this.itemsPerPage,
      name: this.isSearch ? this.userItem : (this.userItem = ""),
      orderName: "name",
      orderType: 1,
    };

    this.io
      .apicall(payLoad, "admin/get-all-usesr", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code == 200) {
          this.customerList = res["results"];
          this.total = res["totalResults"];
        }
      })
      .catch((err) => {
        throw err;
      });
  }
  searchUser() {
    this.isSearch = true;
    this.enqueryList = true;
    this.handleParams(this.page, this.userItem);
    let payload = {
      page: this.page,
      limit: this.itemsPerPage,
      name: this.userItem,
      orderName: "name",
      orderType: 1,
    };
    this.io
      .apicall(payload, "admin/get-all-usesr", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.customerList = res["results"];
          this.total = res["totalResults"];
          if (this.customerList.length === 0) {
            this.fetchCustomerList();
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }
  clearSearchedUser() {
    this.isSearch = false;
    this.enqueryList = false;
    this.userItem = "";
    this.page = 1;
    this.handleParams(this.page, this.userItem);
    this.fetchCustomerList();
  }
  pageChanged(event) {
    this.handleParams(event, this.userItem);
    this.fetchCustomerList();
  }
}
