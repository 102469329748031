import { Component, Input, OnChanges } from "@angular/core";
import { MenuListService } from '../../../services/menu-list.service';
import { Menu } from '../../../interfaces/menu.interface';

@Component({
  selector: "app-restaurant-menus",
  templateUrl: "./restaurant-menus.component.html",
  styleUrls: ["./restaurant-menus.component.css"],
})
export class RestaurantMenusComponent implements OnChanges {
  @Input() restaurantId: number
  @Input() isDeliverect: boolean

  menus: Menu[]

  constructor(
    private menuListService: MenuListService,
  ) { }

  ngOnChanges() {
    this.fetchMenus()
  }

  async fetchMenus() {
    this.menus = await this.menuListService.getMenus(this.restaurantId)
  }
}
