import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CategoryRoutingModule } from './category-routing.module';
import { CategoryComponent } from './category.component';
import { CategoryManagemntComponent } from './category-managemnt/category-managemnt.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CategoryListComponent } from './category-list/category-list.component';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { DataService } from '../services/data-management/data.service';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [CategoryComponent, CategoryManagemntComponent, CategoryListComponent, EditCategoryComponent],
  imports: [
    CommonModule,
    CategoryRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  providers: [
    DataService
  ]
})
export class CategoryModule { }
