import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data-management/data.service';
import { IoService } from '../../services/http/io.service';

@Component({
  selector: 'app-recipe-transaction-payout-list',
  templateUrl: './recipe-transaction-payout-list.component.html',
  styleUrls: ['./recipe-transaction-payout-list.component.css']
})
export class RecipeTransactionPayoutListComponent implements OnInit {
  queryList: boolean = false;
  activeRecipeList: any = [];
  recipeName: any;
  newTotal: number;
  activePage: number = 1;
  activeTotal: number;
  constructor(private io:IoService,
    private data:DataService,private router:Router) { }

  ngOnInit(): void {
    this.getActiveRecipeList();
  }

  viewEditRecipe(obj) {
    let Obj = {
      recipeCreatorId: obj.id,
    };
    this.data._setObj(Obj);
    this.router.navigate(["recipe-transaction/recipe-transaction-payout-view-details"]);
  }

  //ACTIVE RECIPE LIST //
  getActiveRecipeList() {
    this.activeRecipeList = [];
    let payload = {
      page: this.activePage,
      limit: 5,
      name: '',
    }
    this.io.apicall(payload, 'admin/approved-recipe-creators-list', 'POST').then((res: any) => {
      if (res['serverResponse'].code === 200) {
        this.queryList = false;
        this.activeTotal = res['result'].totalRecords;
        this.activeRecipeList = res['result'].creatorList;
      }
    })
      .catch(err => {
        throw err;
      })
  }

   //PAGE CHANGED FOR ACTIVE RECIPE LIST //
   pageChanged(event) {
    if (event >= 1) {
      this.getActiveRecipeList();
    }
  }

}
