import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IoService } from '../../services/http/io.service';
import { DataService } from '../../services/data-management/data.service';
import swal from "sweetalert2";
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { ConditionalExpr } from '@angular/compiler';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { ComponentsModule } from 'src/app/components/components.module';
let closeResult = "";
@Component({
  selector: 'app-update-settings',
  templateUrl: './update-settings.component.html',
  styleUrls: ['./update-settings.component.css']
})
export class UpdateSettingsComponent implements OnInit {
  submitted: boolean = false;
  editAppSettingsFrm: FormGroup;
  addAdminFrm: FormGroup;
  model: any;
  timeModel:any;
  time:any;
  admins:any;
  couponid: any;
  couponDetailsResult: any;
  spinners = false;
  countryCode: any = '+971';
  countries: any;
  selectedCountry: any = 'United Arab Emirates';
  accessControls: any[];
  gantedAccessControls: any[];
  grantAccess:any=[];
  adminId:any;
  menuItems: any[];

  constructor(private fb: FormBuilder, private io: IoService,
    private data: DataService, private router: Router,
    private http: HttpClient,
    private modalService: NgbModal,) { 
    this.editAppSettingsFrm = this.fb.group({
      _id: [null, Validators.compose([Validators.required])],
      privacyPolicy: [null, Validators.compose([Validators.required])],
      termsAndConditions: [null, Validators.compose([Validators.required])],
      maxDeliveryCharge: [null, Validators.compose([Validators.required])],
      refCouponDisAmount: [null, Validators.compose([Validators.required])],
      refCouponDisType: [null, Validators.compose([Validators.required])],
      refCouponExpInDays: [null, Validators.compose([Validators.required])],
      refferalCouponDisAmount: [null, Validators.compose([Validators.required])],
      refferalCouponDisType: [null, Validators.compose([Validators.required])],
      refferalCouponExpInDays: [null, Validators.compose([Validators.required])],
    });



    this.addAdminFrm = this.fb.group({
      email_: [null, Validators.compose([Validators.required])],
      name: [null, Validators.compose([Validators.required])],
      password_: [null, Validators.compose([Validators.required
      ,Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)])],
      country_: [null, Validators.compose([Validators.required])],
      phonenumber_: [null, Validators.compose([Validators.required,
                          Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],

    });
  }

  ngOnInit(): void {
    this.getAppSettings();
    this.getCountry();
    this.fetchAdmins();
    this.discountTypeOnChange();
    // this.gantedAccessControls = JSON.parse(localStorage.getItem('admin')).accessControlls;
  }

  discountTypeOnChange() {

    let reftDiscountType = this.editAppSettingsFrm.value.refCouponDisType ;
  if (reftDiscountType === 'percentage') {
      this.editAppSettingsFrm.controls['refCouponDisAmount'].setValidators([Validators.max(95)])
      this.editAppSettingsFrm.controls['refCouponDisAmount'].updateValueAndValidity()

    }else{
      this.editAppSettingsFrm.controls['refCouponDisAmount'].setValidators([Validators.required])
      this.editAppSettingsFrm.controls['refCouponDisAmount'].updateValueAndValidity()

    }

    let refferaltDiscountType = this.editAppSettingsFrm.value.refferalCouponDisType ;
    if (refferaltDiscountType === 'percentage') {
        this.editAppSettingsFrm.controls['refferalCouponDisAmount'].setValidators([Validators.max(95)])
        this.editAppSettingsFrm.controls['refferalCouponDisAmount'].updateValueAndValidity()
      }else{
        this.editAppSettingsFrm.controls['refferalCouponDisAmount'].setValidators([Validators.required])
        this.editAppSettingsFrm.controls['refferalCouponDisAmount'].updateValueAndValidity()
      }
  }
  checkControls(menuItem){
  
    return   this.gantedAccessControls.some(function(el) {
      return el.feature == menuItem.toUpperCase();
    }) ;
  }
  toggleChkBxEvent(isChecked,accessControl,index) {
    if (isChecked) {
      this.gantedAccessControls.push(accessControl)
 
    } else {
      this.gantedAccessControls = this.gantedAccessControls.filter((item) => item._id !== accessControl._id);
     
    }
  
  }
  openModal(content,ID,grantedAccessControls) {
    grantedAccessControls.forEach( (element) => {
      delete element.resources;
  });
    this.gantedAccessControls = grantedAccessControls
    this.adminId = ID;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }
  getAllAccessControls(){
    this.accessControls = [];
  
    let payLoad = {
    }
  
  this.io
    .apicall(payLoad, 'admin/get-access-controls', "POST")
    .then((res: any) => {
      if (res["serverResponse"].code == 200) {
    
        this.accessControls = res["result"].features
     
   
      }
    })
    .catch((err) => {
      throw err;
    });
  }
  grantAccessControls(){
    this.modalService.dismissAll();
    this.accessControls = [];
  
    let payLoad = {
      userId:this.adminId,
      features:this.gantedAccessControls
    }
  
  this.io
    .apicall(payLoad, 'admin/grant-access', "POST")
    .then((res: any) => {
      if (res["serverResponse"].code == 200) {
    
        this.accessControls = []
        
        this.fetchAdmins();
      }
    })
    .catch((err) => {
      swal.fire({
        title: "Oops...",
        text: this.io.data_.serverResponse.message,
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
    });
  }
  getCountry() {
    this.http.get<any>('./assets/data/country.json')
      .subscribe(data => {
        this.countries = data.countries;
      })
  }

  changeCountry(event) {
    this.selectedCountry = event.target.value;
    let index = this.countries.filter(item => item.name === this.selectedCountry);
    this.countryCode = index[0].code;
  }
  // COUPON VIEW DETAILS API //
  getAppSettings() {
    let payload = {
    
    }
    this.io.apicall(payload, 'admin/get-app-settings', 'POST').then((res: any) => {
      if (res['serverResponse'].code === 200) {
        this.couponDetailsResult = res['result'];
        this.editAppSettingsFrm.get('_id').setValue(res['result']._id);
        this.editAppSettingsFrm.get('privacyPolicy').setValue(res['result'].privacyPolicy);
        this.editAppSettingsFrm.get('termsAndConditions').setValue(res['result'].termsAndConditions);
        this.editAppSettingsFrm.get('maxDeliveryCharge').setValue(res['result'].maxDeliveryCharge);
        this.editAppSettingsFrm.get('refCouponDisAmount').setValue(res['result'].refCouponDisAmount);
        this.editAppSettingsFrm.get('refCouponExpInDays').setValue(res['result'].refCouponExpInDays);
        this.editAppSettingsFrm.get('refferalCouponDisAmount').setValue(res['result'].refferalCouponDisAmount);
        this.editAppSettingsFrm.get('refCouponDisType').setValue(res['result'].refCouponDisType);
        this.editAppSettingsFrm.get('refferalCouponDisType').setValue(res['result'].refferalCouponDisType);
        this.editAppSettingsFrm.get('refferalCouponExpInDays').setValue(res['result'].refferalCouponExpInDays);
        this.discountTypeOnChange();
      }
    })
  }


  updateAppSettings(Obj) {
 
    
    let payLoad = {
      _id: Obj._id,
      privacyPolicy: Obj.privacyPolicy,
      termsAndConditions: Obj.termsAndConditions,
      maxDeliveryCharge: Obj.maxDeliveryCharge,
      refCouponDisAmount: Obj.refCouponDisAmount,
      refCouponDisType: Obj.refCouponDisType,
      refCouponExpInDays: Obj.refCouponExpInDays,
      refferalCouponDisAmount: Obj.refferalCouponDisAmount,
      refferalCouponDisType: Obj.refferalCouponDisType,
      refferalCouponExpInDays: Obj.refferalCouponExpInDays,
    };

    this.io
      .apicall(payLoad, "admin/app-settings-update", "POST")
      .then((res) => {
        if (this.io.data_.serverResponse.code == 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }
  addAdmin(obj) {
    this.submitted = true;
      let payload = {
        email: obj.email_,
        name: obj.name,
        password: obj.password_,
        countryCode: this.countryCode,
        phone:obj.phonenumber_
      }
      this.io.apicall(payload, 'admin/add-new-admin', 'POST',).then(data => {
        if (this.io.data_.serverResponse.code == 200) {
          this.fetchAdmins();
          swal.fire({
            title: "Success",
            text: this.io.data_.serverResponse.message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.addAdminFrm.reset();
          this.submitted = false;
        }
      }).catch(err => {
        if (this.io.data_.serverResponse.code === 400) {
          swal.fire({
            title: "Oops...",
            text: this.io.data_.serverResponse.message,
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      })

  }
  fetchAdmins() {
    this.admins = [];
  
      let payLoad = {
      }
    
    this.io
      .apicall(payLoad, 'admin/get-admins', "POST")
      .then((res: any) => {
        if (res["serverResponse"].code == 200) {
      
          this.admins = res["result"]
        
     
        }
      })
      .catch((err) => {
        throw err;
      });
  }


  showHide() {
    $(".showHide-password").each(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }

  validateEmail(email_) {
    var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return re.test(String(email_).toLowerCase());
  }
}
