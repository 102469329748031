import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IoService } from '../../services/http/io.service';
import { DataService } from '../../services/data-management/data.service';
import swal from "sweetalert2";
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { ConditionalExpr } from '@angular/compiler';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  submitted: boolean = false;
  resetPasswordFrm: FormGroup;
  editAdminFrm: FormGroup;
  model: any;
  timeModel:any;
  time:any;
  admin:any;
  couponid: any;
  couponDetailsResult: any;
  spinners = false;
  countryCode: any = '+971';
  countries: any;
  selectedCountry: any = 'United Arab Emirates';
  email: any='';
  adminId: any='';
  constructor(private fb: FormBuilder, private io: IoService,
    private data: DataService, private router: Router,
    private http: HttpClient) { 
      this.resetPasswordFrm = this.fb.group({
        oldPassword: [null, Validators.compose([Validators.required])],
        password_: [null, Validators.compose([Validators.required])],
        confirmpassword_: [null, Validators.compose([Validators.required])],
      });

    this.editAdminFrm = this.fb.group({
      email_: [null, Validators.compose([Validators.required])],
      name: [null, Validators.compose([Validators.required])],
      // password_: [null, Validators.compose([Validators.required
      // ,Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)])],
      country_: [null, Validators.compose([Validators.required])],
      phonenumber_: [null, Validators.compose([Validators.required,
                          Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],

    });
  }

  ngOnInit(): void {
    let admin = JSON.parse(localStorage.getItem('admin'));
    this.adminId =admin?.id;
    this.email =admin?.email;
    this.getCountry();
    this.fetchAdmin();
  }

  validateEmail(email_) {
    var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return re.test(String(email_).toLowerCase());
  }
  getCountry() {
    this.http.get<any>('./assets/data/country.json')
      .subscribe(data => {
        this.countries = data.countries;
      })
  }

  changeCountry(event) {
    this.selectedCountry = event.target.value;
    let index = this.countries.filter(item => item.name === this.selectedCountry);
    this.countryCode = index[0].code;
  }
  fetchAdmin() {
  
      let payLoad = {
        userId: this.adminId
      }
    
    this.io
      .apicall(payLoad, 'admin/admin-profile-view', "POST")
      .then((res: any) => {
        if (res["serverResponse"].code == 200) {
      
          this.admin = res["result"]
          this.editAdminFrm.get('name').setValue(res["result"].name);
          this.editAdminFrm.get('email_').setValue(res["result"].email);
          // this.editAdminFrm.get('countryCode').setValue(res["result"].countryCode);
          this.editAdminFrm.get('phonenumber_').setValue(res["result"].phone);
    
     
        }
      })
      .catch((err) => {
        throw err;
      });
  }
  get f() { return this.resetPasswordFrm.controls; }

  restPassWord(value) {

    if (!value.oldPassword) {
      swal.fire({
        title: 'Oops...',
        text: 'Please input old password.',
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok'
      });
      return 0;
    }
    if (!value.password_) {
      swal.fire({
        title: 'Oops...',
        text: 'Please input new password.',
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok'
      });
      return 0;
    }

    if (!value.confirmpassword_) {
      swal.fire({
        title: 'Oops...',
        text: 'Please confirm password.',
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok'
      });
      return 0;
    }
    if (value.password_ !== value.confirmpassword_) {
      swal.fire({
        title: 'Oops...',
        text: 'Passwords do not match.',
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok'
      });
      return 0;
    }
    let payload = {
      email: this.email,
      oldPassword: this.resetPasswordFrm.value.oldPassword,
      newPassword: this.resetPasswordFrm.value.password_,
    }
    this.io.apicall(payload, 'admin/change-password', 'POST').then(res => {
      if (this.io.data_.serverResponse.code == 200) {
        swal.fire({
          title: "Success",
          text: res["serverResponse"].message,
          icon: "success",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    }).catch(err => {
      swal.fire({
        title: 'Oops...',
        text: this.io.data_.serverResponse.message,
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok'
      });
    });
  }

  showHide() {
    $(".showHide-password").each(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }
}
