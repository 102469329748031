import { Component, Input, OnInit } from '@angular/core';
import { MealPlanCreatorState } from '../interfaces/creator-state.interface';
import { IoService } from '../../services/http/io.service';

@Component({
    selector: 'app-meal-plan-creator-step-three',
    templateUrl: './meal-plan-creator-step-three.component.html',
    styleUrls: ['./meal-plan-creator-step-three.component.css']
})
export class MealPlanCreatorStepThreeComponent implements OnInit {
    @Input() state: MealPlanCreatorState;

    loadingDishes: boolean = false;
    loadingRecipes: boolean = false;
    allDishes: { id: number; price: number; img: string; name: string; restaurantName: string; calories: number; protein: number; carbs: number; fats: number; selected: boolean; }[] = [];
    allRecipes: { id: number; img: string; name: string; creatorName: string; calories: number; protein: number; carbs: number; fats: number; selected: boolean; }[] = [];

    constructor(
        private io: IoService,
    ) { }

    ngOnInit(): void {
        this.getAllDishes();
        this.getAllRecipes();
    }

    async getAllDishes() {
        if (!this.state.includeDishesOnMEP && !this.state.includeDishesNotOnMEP) return;

        this.loadingDishes = true;

        const selectedRestaurants = new Set<number>(this.state.filterRestaurants.map((res) => res.id));

        this.state.selectedParentRestaurants.forEach((res) => selectedRestaurants.add(res.id));

        const dietaryPreferences = {};

        Object.values(this.state.dietaryPreferences).forEach((dp) => {
            dietaryPreferences[dp.id] = dp.selections.map((s) => s.id);
        });

        const tags = {};

        Object.values(this.state.tags).forEach((tag) => {
            tags[tag.id] = tag.selections.map((s) => s.id);
        });

        const filters = {
            calsMin: +this.state.filterCalsMin,
            calsMax: +this.state.filterCalsMax,
            dietaryPreferences,
            tags,
            restaurants: Array.from(selectedRestaurants),
        };

        const response = (await this.io.postRequest('menu-product/portal/mealplan-creator-list', filters)) as any;

        const selectedProductsIds = new Set<number>(this.state.dishOptions.map((mp) => mp.id));

        this.allDishes = response.map((menuProduct) => ({
            id: menuProduct.id,
            name: menuProduct.name,
            price: +menuProduct.price,
            img: menuProduct.dish.discImage,
            restaurantName: `${menuProduct.dish.restaurant.name}${menuProduct.dish.restaurant.branchName?.trim() ? ` - ${menuProduct.dish.restaurant.branchName.trim()}` : ''}`,
            calories: +menuProduct.dish.totalCalories,
            protein: +menuProduct.dish.protein,
            carbs: +menuProduct.dish.carbs,
            fats: +menuProduct.dish.fat,
            selected: selectedProductsIds.has(menuProduct.id),
        }));

        this.loadingDishes = false;
    }

    async getAllRecipes() {
        if (!this.state.includeRecipes) return;

        this.loadingRecipes = true;

        const selectedRecipeCreators = new Set<number>(this.state.filterRecipeCreators.map((creator) => creator.id));

        const dietaryPreferences = {};

        Object.values(this.state.dietaryPreferences).forEach((dp) => {
            dietaryPreferences[dp.id] = dp.selections.map((s) => s.id);
        });

        const tags = {};

        Object.values(this.state.tags).forEach((tag) => {
            tags[tag.id] = tag.selections.map((s) => s.id);
        });

        const filters = {
            calsMin: +this.state.filterCalsMin,
            calsMax: +this.state.filterCalsMax,
            dietaryPreferences,
            tags,
            recipeCreators: Array.from(selectedRecipeCreators),
        };

        const response = (await this.io.postRequest('recipe/portal/mealplan-creator-list', filters)) as any;

        const selectedRecipeIds = new Set<number>(this.state.recipeOptions.map((rec) => rec.id));

        this.allRecipes = response.map((recipe) => ({
            id: recipe.id,
            img: recipe.recipeImage,
            name: recipe.recipeName,
            creatorName: recipe.creator.name,
            calories: +recipe.totalCalories,
            protein: +recipe.protein,
            carbs: +recipe.carbs,
            fats: +recipe.fat,
            selected: selectedRecipeIds.has(recipe.id),
        }));

        this.loadingRecipes = false;
    }

    addDish(dish) {
        dish.selected = true;

        this.state.dishOptions.push(dish);
    }

    addRecipe(recipe) {
        recipe.selected = true;

        this.state.recipeOptions.push(recipe);
    }

    removeDish(dish) {
        this.state.dishOptions.splice(this.state.dishOptions.findIndex((di) => di.id === dish.id), 1);

        const removeDish = this.allDishes.find((di) => di.id === dish.id);

        if (removeDish) {
            removeDish.selected = false;
        }
    }

    removeRecipe(recipe) {
        this.state.recipeOptions.splice(this.state.recipeOptions.findIndex((rec) => rec.id === recipe.id), 1);

        const removeRecipe = this.allRecipes.find((rec) => rec.id === recipe.id);

        if (removeRecipe) {
            removeRecipe.selected = false;
        }
    }
}
