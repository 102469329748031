import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItemModalComponent } from "./item-modal.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [ItemModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NgbModule,
    ImageCropperModule,
  ],
  exports: [ItemModalComponent],
  providers: [NgbActiveModal],
})
export class ItemModalModule {}
