import { Component, OnInit } from "@angular/core";
import { IoService } from "../../services/http/io.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import swal from "sweetalert2";
import { DataService } from "../../services/data-management/data.service";
let closeResult = "";
@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.css"],
})
export class CategoryComponent implements OnInit {
  categoryForm: FormGroup;
  editcategory: FormGroup;
  categoryList: any;
  tagCategoryName: any;
  tagCategoryId: any;
  constructor(
    private modalService: NgbModal,
    public fb: FormBuilder,
    private io: IoService,
    private ar: ActivatedRoute,
    private dataservice: DataService,
    private router: Router
  ) {
    this.categoryForm = this.fb.group({
      category_: [null, Validators.compose([Validators.required])],
    });
    this.editcategory = this.fb.group({
      editcategory_: [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.fetchCategory();
  }

  viewTagDetails(obj) {
    let Obj = {
      categoryId: obj.id,
    };
    this.dataservice._setObj(Obj);
    this.router.navigate(["/management/tag-management"]);
  }

  open(content, tagcategoryname, tagcategoryid) {
    this.tagCategoryName = tagcategoryname;
    this.tagCategoryId = tagcategoryid;
    this.editcategory.patchValue({
      editcategory_: this.tagCategoryName,
    });
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  deletecat(deleteCategory, tagcategoryid, tagcategoryname) {
    this.tagCategoryId = tagcategoryid;
    this.tagCategoryName = tagcategoryname;
    this.modalService
      .open(deleteCategory, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
      })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  addContent(add) {
    this.modalService
      .open(add, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  createPreDefineHeadAndTag() {
    let payload = {};
    this.io
      .apicall(payload, "tagcategories/create-pre-define-head-and-tag", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.categoryList = res["result"];
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  fetchCategory() {
    let payload = {};
    this.io
      .apicall(payload, "tagCategories/fetch-tag-head", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.categoryList = res["result"];
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  editCategory(value) {
    let payload = {
      tagHeadId: this.tagCategoryId,
      tagHeadName: value.editcategory_,
    };
    this.io
      .apicall(payload, "tagCategories/update-tag-Head", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          //this.categoryId=res['result'].id;
          this.modalService.dismissAll();
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        this.fetchCategory();
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  delete() {
    let payload = {
      tagHeadId: this.tagCategoryId,
    };
    this.io
      .apicall(payload, "tagCategories/delete-tag-head", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          //this.categoryId=res['result'].id;
          this.modalService.dismissAll();
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        this.fetchCategory();
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }
}
