import { NgModule } from "@angular/core";
import { TransactionRoutingModule } from "./transaction-routing.module";
import { TransactionComponent } from "./transaction.component";
import { TransactionListComponent } from "./transaction-list/transaction-list.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [TransactionComponent, TransactionListComponent],
  imports: [
    CommonModule,
    TransactionRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule,
    NgxDaterangepickerMd.forRoot(),
  ],
})
export class TransactionModule {}
