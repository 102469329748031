import { Component, Input, OnInit } from '@angular/core';
import { MealPlanCreatorState } from '../interfaces/creator-state.interface';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
    selector: 'app-meal-plan-creator-step-two',
    templateUrl: './meal-plan-creator-step-two.component.html',
    styleUrls: ['./meal-plan-creator-step-two.component.css']
})
export class MealPlanCreatorStepTwoComponent implements OnInit {
    @Input() state: MealPlanCreatorState;
    @Input() restaurants: { id: number; name: string; children: { id: number; name: string; }[] }[];
    @Input() recipeCreators: { id: number; name: string; }[];
    @Input() dietaryPreferences: {
        id: number;
        name: string;
        preferences: {
            id: number;
            name: string;
        }[];
    }[];
    @Input() tags: {
        id: number;
        name: string;
        tags: {
            id: number;
            name: string;
        }[];
    }[];

    branchesList: { id: number; name: string; }[] = [];

    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: "id",
        textField: "name",
        selectAllText: "Select All",
        unSelectAllText: "UnSelect All",
        allowSearchFilter: true,
        closeDropDownOnSelection: false,
        itemsShowLimit: 3,
    };

    ngOnInit(): void {
        this.calculateBranches();
    }

    calculateBranches() {
        setTimeout(() => {
            if (!this.restaurants?.length) return;

            const restaurantIds = new Set<number>(this.state.selectedParentRestaurants.map((res) => res.id));

            const branches = [];

            this.restaurants.forEach((res) => {
                if (!restaurantIds.has(res.id)) return;

                res.children.forEach((child) => branches.push(child));
            });

            this.branchesList = branches;
        }, 200);
    }
}
