import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IoService } from '../../services/http/io.service';
import { DataService } from '../../services/data-management/data.service';
import swal from 'sweetalert2';
let closeResult = '';
@Component({
  selector: 'app-recipe-transaction-payout-view-details',
  templateUrl: './recipe-transaction-payout-view-details.component.html',
  styleUrls: ['./recipe-transaction-payout-view-details.component.css']
})
export class RecipeTransactionPayoutViewDetailsComponent implements OnInit {
  transactionValue: any;
  createrId: any;
  page: any = 1;
  enqueryList: boolean = false;
  earningsResult: any;
  totalPages: any;
  recipeTransactionPayoutDetailsList: any = [];
  constructor(private modalService: NgbModal,
    private data: DataService, private router: Router,
    private io: IoService) { }

  ngOnInit(): void {
    this.data._currentObj().subscribe((data => {
      this.createrId = data['recipeCreatorId'];
      if (this.createrId) {
        this.allTransactionPayoutViewDetails();
        this.totalEarningsTransaction();
      }
      if (typeof this.createrId === 'undefined') {
        this.router.navigate(['/recipe-transaction/recipe-transaction-payout-list']);
      }
    }))
  }

  open(content) {
    this.transactionValue = '';
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      closeResult = `Closed with: ${result}`;
    }, (reason) => {
      return reason;
    });
  }

  totalEarningsTransaction() {
    let payload = {
      userId: this.createrId,
    }
    this.io.apicall(payload, 'creator/creator-profile-view', 'POST').then((res: any) => {
      if (res['serverResponse'].code === 200) {
        this.earningsResult = res['result'];
      }
    })
      .catch(err => {
        throw err;
      })
  }

  allTransactionPayoutViewDetails() {
    this.recipeTransactionPayoutDetailsList = [];
    let payload = {
      page: this.page,
      limit: 5,
      userId: this.createrId,
    }
    this.io.apicall(payload, 'transaction/recipe-creator-transaction-list', 'POST').then((res: any) => {
      if (res['serverResponse'].code === 200) {
        const result = res['result'].transactionList;
        this.totalPages = res["result"].totalRecords;
        this.recipeTransactionPayoutDetailsList = result;
      }
    })
      .catch(err => {
        throw err;
      })
  }

  pageChanged(event) {
    if (event >= 1) {
      this.allTransactionPayoutViewDetails();
    }
  }

  payToRecipeCreator() {
    let payload = {
      userId: this.createrId,
      transactionAmount: this.transactionValue
    }
    this.io
      .apicall(payload, "transaction/pay-to-recipe-creator-account", "POST")
      .then((res) => {
        if (this.io.data_.serverResponse.code == 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.totalEarningsTransaction();
          this.allTransactionPayoutViewDetails();
          this.modalService.dismissAll();
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.modalService.dismissAll();
      });
  }

}
