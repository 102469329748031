import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { IoService } from "../../services/http/io.service";
import { Restaurant } from '../interfaces/restaurant.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-menu-list",
  templateUrl: "./menu-list.component.html",
  styleUrls: ["./menu-list.component.css"],
})
export class MenuListComponent implements OnInit {
  restaurants: Restaurant[] = []
  selectedRestaurantIndex: number = -1
  forcingMenuRecompilation: boolean = false;

  constructor(
    private io: IoService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.fetchAllRestaurants()
  }

  async fetchAllRestaurants() {
    this.restaurants = ((await this.io.apicall({}, 'restaurant/get-restaurant-list-for-dropdown', 'POST')) as any).result as Restaurant[]
  }

  async forceMenuRecompilation(restaurantId: number) {
    this.forcingMenuRecompilation = true;

    try {
      await this.io.postRequest(`menu/portal/refresh/${restaurantId}`);

      this.toastr.success('Menu Recompiled successfully!');

      const tmpStore = this.selectedRestaurantIndex;

      this.selectedRestaurantIndex = -1;

      this.cdr.detectChanges();

      this.selectedRestaurantIndex = tmpStore;
    }
    catch (err) {
      this.toastr.success('Sorry, something went wrong!');
    }

    this.forcingMenuRecompilation = false;
  }
}
