import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from "../../_constant/constant";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(private http: HttpClient) {}

  loadOrders(payload): Observable<any> {
    return this.http.post(routes.listOrders, payload);
  }
}
