import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RestaurantTransactionPayoutListComponent } from './restaurant-transaction-payout-list/restaurant-transaction-payout-list.component';

import { RestaurantTransactionComponent } from './restaurant-transaction.component';

const routes: Routes = [{
  path: '', component: 
  RestaurantTransactionComponent,
  children: [
    {
      path: 'restaurant-transaction-payout-list', 
      component: RestaurantTransactionPayoutListComponent,
    },
  ] 
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RestaurantTransactionRoutingModule { }
