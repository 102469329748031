import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import swal from "sweetalert2";
import { SupportService } from "./../../services/support/support.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-support-edit",
  templateUrl: "./support-edit.component.html",
  styleUrls: ["./support-edit.component.css"],
})
export class SupportEditComponent implements OnInit {
  private routeSub: Subscription;
  id: String;
  category: Object;
  editForm: FormGroup;
  FORM_KEYS: {
    TEXT:string
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private SupportService: SupportService,
    private FormBuilder: FormBuilder,
    private loader: NgxUiLoaderService,
    private modalService: NgbModal
  ) {
    this.FORM_KEYS = {
      TEXT:"text"
    }
  }

  ngOnInit(): void {
    this.buildForm();
    this.routeSub = this.route.params.subscribe(({id}) => {
      this.id = id;
      this.loadItem(this.id);
    });
  }

  loadItem(id: String): void {
    this.loader.start();
    this.SupportService.getCategoryItem(id).subscribe(
      (res) => {
        this.category = res;
        this.editForm.get(this.FORM_KEYS.TEXT).setValue(res.text)
        this.loader.stop();
      },
      () => {
        this.loader.stop();
        swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.router.navigate(["/support/support-list"]);
      }
    );
  }

  buildForm(): void {
    this.editForm = this.FormBuilder.group({
      [this.FORM_KEYS.TEXT]: ["", Validators.compose([Validators.required])],
    });
  }

  onSubmit(): void {
    if (this.editForm.valid) {
      this.loader.start();
      this.SupportService.editCategoryItem(
        this.id,
        this.editForm.get(this.FORM_KEYS.TEXT).value
      ).subscribe(
        (res) => {
          this.loader.stop();
          swal.fire({
            title: "Success",
            text: "Edited Successfully",
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.router.navigate(["/support/support-list"]);
        },
        () => {
          this.loader.stop();
          swal.fire({
            title: "Oops...",
            text: "Something went wrong!",
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      );
    }
    else{
      swal.fire({
          title: "Oops...",
          text: "Please make sure you filled all required fields correctly",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
      });
      this.editForm.markAllAsTouched();
      return;
    }
  }
  deleteCategoryItem(): void {
    this.loader.start();
    this.SupportService.deleteCategoryItem(this.id).subscribe(
      (res) => {
        this.loader.stop();
        swal.fire({
          title: "Success",
          text: "Deleted Successfully",
          icon: "success",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.modalService.dismissAll();
        this.router.navigate(["/support/support-list"]);
      },
      () => {
        this.loader.stop();
        this.modalService.dismissAll();
        swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }
  open(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
    });
  }
}
