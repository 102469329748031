export const mapBannerTypeToBannerResponse = {
  'dish': 'dish',
  'recipe': 'recipe',
  'restaurant': 'restaurant',
  'recipeCreator': 'creator',
  'category': 'category',
  'mealPlan': 'meal_plan',
  'static': 'static',
  'externalLink': 'external_link',
};
