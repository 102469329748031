export const getSortColumn = (columnObject, nullable = true, fallBack) => {
    return Object.keys(columnObject)?.length > 0
            ? Object.keys(columnObject)[0]
            : nullable
                ? null
                : fallBack
}

export const getSortType = (columnObject, returnType="string", nullable = true, fallBack) => {
    return Object.values(columnObject)?.length > 0
            ? getSortTypeValue(Object.values(columnObject)[0],returnType)
            : nullable 
                ? null
                : fallBack
            
}

const getSortTypeValue = (sortType, returnType) => {
    switch (returnType) {
        case "string":
            return sortType;
        case "number":
            return sortType == "ascending"
                    ? 1
                    : -1;
        default:
            break;
    }
}