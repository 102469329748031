import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { IoService } from "../..//services/http/io.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import swal from "sweetalert2";
import { isObject } from "ngx-chips/core/accessor";
import { DataService } from "../../services/data-management/data.service";
import { RecipeService } from "./../../services/recipe/recipe.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { getSortColumn, getSortType } from "./../../utils/sort";

let closeResult = "";

@Component({
  selector: "app-recipe-provider-list",
  templateUrl: "./recipe-provider-list.component.html",
  styleUrls: ["./recipe-provider-list.component.css"],
})
export class RecipeProviderListComponent implements OnInit, OnDestroy {
  queryList: boolean = false;
  selectedValue: number;
  newRecipeList: any = [];
  activeRecipeList: any = [];
  recipeName: any;
  recipeItem: any = "";
  newPage: number = 1;
  newTotal: number;
  newItemsPerPage: number = 25;
  activePage: number = 1;
  activeTotal: number;
  activeItemsPerPage: number = 25;
  recipeid: any;
  isSearch: boolean = false;
  paramObserver: any;
  tabs: any = [];
  activeTab: number = 0;
  sortedColumn: object = {};
  constructor(
    private modalService: NgbModal,
    private ar: ActivatedRoute,
    private io: IoService,
    private dataservice: DataService,
    private router: Router,
    private RecipeService: RecipeService,
    private loader: NgxUiLoaderService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let paramPublishedPage = values.published;
      let paramUnpublishedPage = values.unpublished;
      let paramSearch = values.search;
      let paramTab = values.tab;
      let sortedField = values.sortBy;
      let sortedDirection = values.sortDirection;
      this.sortedColumn = {};
      this.tabs = [
        { url: "", text: "Published", status: "published" },
        { url: "", text: "Unpublished", status: "unpublished" },
      ]
      if (paramPublishedPage) {
        this.activePage = paramPublishedPage;
      } else {
        this.activePage = 1;
      }
      if (paramUnpublishedPage) {
        this.newPage = paramUnpublishedPage;
      } else {
        this.newPage = 1;
      }
      if (paramSearch) {
        this.isSearch = true;
        this.queryList = true;
        this.recipeItem = paramSearch;
      }
      if (paramTab) {
        this.activeTab = +paramTab;
      } else {
        this.activeTab = 0;
      }
      this.tabs[this.activeTab] = {
        ...this.tabs[this.activeTab],
        isActive: true,
      };
      if (sortedField) {
        this.sortedColumn = { [sortedField]: sortedDirection };
      }
      if (this.tabs[this.activeTab]?.status == "published") {
        this.getActiveRecipeList();
      } else {
        this.getNewRecipeList();
      }
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  handleTabChange(index): void{
    this.sortedColumn = {};
    delete this.tabs[this.activeTab].isActive;
    this.activeTab = index;
    this.tabs[this.activeTab].isActive = true;
    this.handleParams();
  }

  getRecipeList(): void {
    if(this.tabs[this.activeTab]?.status=="published"){
      return this.activeRecipeList;
    }
    else{
      return this.newRecipeList;
    }
  }

  handleSortClick(field): void {
    if (this.sortedColumn[field]) {
      if (this.sortedColumn[field] == "ascending") {
        this.sortedColumn[field] = "descending";
      } else {
        this.sortedColumn[field] = "ascending";
      }
    } else {
      this.sortedColumn = {};
      this.sortedColumn[field] = "ascending";
    }
    this.handleParams();
  }

  handleParams(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        published:this.activePage,
        unpublished:this.newPage,
        search:
          this.recipeItem && this.isSearch ? this.recipeItem : null,
        tab: this.activeTab,
        sortBy: getSortColumn(this.sortedColumn),
        sortDirection: getSortType(this.sortedColumn)
      },
      queryParamsHandling: "merge",
    });
  }

  //NEW RECIPE LIST //
  getNewRecipeList() {
    this.newRecipeList = [];
    let payLoad = {
      page: this.newPage,
      limit: this.newItemsPerPage,
      recipeName: this.isSearch ? this.recipeItem : (this.recipeItem = ""),
      isPublished: false,
    };
    if(getSortColumn(this.sortedColumn)){
      payLoad['orderName'] = getSortColumn(this.sortedColumn);
      payLoad['orderType'] = getSortType(this.sortedColumn, "number", false, 1); 
    }
    this.io
      .apicall(payLoad, "recipe/all-recipe-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.newTotal = res["result"].totalRecords;
          this.newRecipeList = res["result"].recipeList;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  //ACTIVE RECIPE LIST //
  getActiveRecipeList() {
    this.activeRecipeList = [];
    let payload = {
      page: this.activePage,
      limit: this.activeItemsPerPage,
      recipeName: this.isSearch ? this.recipeItem : (this.recipeItem = ""),
      isPublished: true,
    };
    if(getSortColumn(this.sortedColumn)){
      payload['orderName'] = getSortColumn(this.sortedColumn);
      payload['orderType'] = getSortType(this.sortedColumn, "number", false, 1); 
    }
    this.io
      .apicall(payload, "recipe/all-recipe-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.activeTotal = res["result"].totalRecords;
          this.activeRecipeList = res["result"].recipeList;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  //PAGE CHANGED FOR NEW RECIPE LIST //
  pageChanged(event) {
    if(this.tabs[this.activeTab]?.status=="published"){
      this.activePage = event;
    }
    else{
      this.newPage = event;
    }
    this.handleParams();
  }

  deleteRecipe() {
    this.loader.start();
    this.RecipeService.delete(this.recipeid).subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        this.modalService.dismissAll();
        this.newPage = 1;
        this.newRecipeList = [];
        this.getNewRecipeList();
        this.getActiveRecipeList();
        this.loader.stop();
      },
      (err) => {
        this.modalService.dismissAll();
        swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }

  open(content, id) {
    this.recipeid = id;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  countStar(star) {
    this.selectedValue = star;
  }

  fetchRecipe(Obj) {
    this.recipeName = Obj;
  }

  // SEARCH FOR NEW RECIPE //
  search() {
    this.isSearch = true;
    this.queryList = true;
    this.handleParams();
  }
  clearSearch() {
    this.isSearch = false;
    this.queryList = false;
    this.recipeItem = "";
    if(this.tabs[this.activeTab]?.status=="published"){
      this.activePage = 1;
      this.handleParams();
    }
    else{
      this.newPage = 1;
      this.handleParams();
    }
  }
}
