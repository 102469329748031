import { environment } from "./../../environments/environment";
export const constant = {
  // no_profile_img: '../../../assets/images/no_profile_img.png',
  currency_: "AED",
};
export const routes = {
  deleteRecipeUrl: `${environment.baseUrl}recipe/del-recipe`,
  getSupportCategories: `${environment.baseUrl}feedback/categories`,
  getSupportItem: (id: String) =>
    `${environment.baseUrl}feedback/categories/${id}`,
  editSupportItem: (id: String) =>
    `${environment.baseUrl}feedback/categories/${id}`,
  addSupportItem: `${environment.baseUrl}feedback/categories`,
  deleteSupportItem: (id: String) =>
    `${environment.baseUrl}feedback/categories/${id}`,

  dishesSetOrder: (id) =>
    `${environment.baseUrl}restaurant/${id}/dishes/set-order`,
  deleteDish: (id) => `${environment.baseUrl}dish/${id}`,
  deleteRestaurant: (id) => `${environment.baseUrl}restaurant/${id}`,
  deleteRecipeProvider: (id) => `${environment.baseUrl}creator/${id}`,
  listOrders: `${environment.baseUrl}orders/admin-list-of-orders`,
  getDish: `${environment.baseUrl}dish/dish-view`,
  uploadDishImage: `${environment.baseUrl}uploads/dish-img-upload`,
  toggleDishPublishStatus: `${environment.baseUrl}dish/dish-publish-unpublish`,
  toggleDishActiveStatus: `${environment.baseUrl}dish/change-dish-status`,
  addDish: `${environment.baseUrl}dish/add-dish`,
  editDish: `${environment.baseUrl}dish/edit-dish`,
  getRestaurantDishes:() => `${environment.baseUrl}dish/list`,

  changeDishStatus: `${environment.baseUrl}dish/change-dish-status-bulk`,

  getDietaryPreferencesCategories: `${environment.baseUrl}dietary-preference-categories`,
  editDietaryPreferenceCategory: (id) =>
    `${environment.baseUrl}dietary-preference-categories/${id}`,
  getDietaryPreferencesOptions: (categoryId) =>
    `${environment.baseUrl}dietary-preference-categories/${categoryId}/dietary-preferences`,
  addDietaryPreferenceItem: `${environment.baseUrl}dietary-preferences`,
  editDietaryPreferenceItem: (id) =>
    `${environment.baseUrl}dietary-preferences/${id}`,
  deleteDietaryPreferenceItem: (id) =>
    `${environment.baseUrl}dietary-preferences/${id}`,
  getTagCategories: `${environment.baseUrl}tagcategories/fetch-tag-head`,
  getTagOptions: () => `${environment.baseUrl}tagcategories/fetch-tag-by-head`,
  editTagCategory: `${environment.baseUrl}`,
  addTagItem: `${environment.baseUrl}`,
  editTagItem: (id) => `${environment.baseUrl}`,
  deleteTagItem: (id) => `${environment.baseUrl}`,
  listContentManagementPages: `${environment.baseUrl}pages/`,
  listContentManagementCategories: `${environment.baseUrl}categories/`,
  listContentManagementPageSections: (id: string) =>
    `${environment.baseUrl}pages/${id}/page-sections`,
  deleteContentManagementPageSection: (pageId: string, sectionId: string) =>
    `${environment.baseUrl}pages/${pageId}/page-sections/${sectionId}`,
  contentManagementSectionsReoder: (id: string) =>
    `${environment.baseUrl}pages/${id}/page-sections/order/`,
  listAllTagsAndPreferences: `${environment.baseUrl}restaurantowner/flags-and-tags`,

  addCategory: `${environment.baseUrl}categories/`,
  listCategories: `${environment.baseUrl}categories/`,
  deleteCategory: (id: string) => `${environment.baseUrl}categories/${id}`,
  getCategory: (id: string) => `${environment.baseUrl}categories/${id}`,
  editCategory: (id: string) => `${environment.baseUrl}categories/${id}`,
};
