import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BannerSectionData } from '../../../interfaces/banner-section-data.interface';
import { IoService } from '../../../../services/http/io.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { mapBannerTypeToSearchPlaceholderText } from '../../../map/mapBannerTypeToSearchPlaceholderText.map';

@Component({
  selector: 'app-banner-section',
  templateUrl: './banner-section.component.html',
  styleUrls: ['./banner-section.component.css']
})
export class BannerSectionComponent implements OnInit {
  @Input() sectionData: BannerSectionData;
  @Input() canMoveUp: boolean;
  @Input() canMoveDown: boolean;

  @Output() deleteSection = new EventEmitter();
  @Output() moveSectionUp = new EventEmitter();
  @Output() moveSectionDown = new EventEmitter();

  urlLink: string = '';
  urlValid: boolean = true;

  private searchText$ = new Subject<string>();

  listItems: { id: number, name: string }[] = [];
  selectedItems: { id: number, name: string }[] = [];

  dropdownSettings: IDropdownSettings = {
    searchPlaceholderText: 'Search',
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
    closeDropDownOnSelection: true,
  };

  constructor(private io: IoService) { }

  ngOnInit(): void {
    this.searchText$.pipe(
      debounceTime(800),
      distinctUntilChanged(),
    ).subscribe((searchValue) => {
      if (!searchValue || searchValue.trim().length < 2) return;

      this.searchForItem(searchValue.trim());
    });

    this.initializeData();
  }

  initializeData() {
    this.listItems = [];
    this.selectedItems = [];

    this.dropdownSettings.searchPlaceholderText = mapBannerTypeToSearchPlaceholderText[this.sectionData.type];

    this.dropdownSettings = { ...this.dropdownSettings };

    if (!this.sectionData?.destination?.trim()) return;

    this.searchForAlreadySelectedItem(this.sectionData.destination.trim());
  }

  async searchForAlreadySelectedItem(searchId: string) {
    let res;

    switch (this.sectionData.type) {
      case 'dish':
        res = await this.io.apicall(null, `menu-product/name-by-id?id=${this.sectionData.destination}`, 'GET');
        if (res) this.selectedItems = [{ id: res.dish.id, name: res.name }];
        break;

      case 'recipe':
        res = await this.io.apicall(null, `recipe/name-by-id?id=${this.sectionData.destination}`, 'GET');
        if (res) this.selectedItems = [{ id: res.id, name: res.recipeName }];
        break;

      case 'restaurant':
        res = await this.io.apicall(null, `restaurant/name-by-id?id=${this.sectionData.destination}`, 'GET');
        if (res) this.selectedItems = [res];
        break;

      case 'recipeCreator':
        res = await this.io.apicall(null, `creator/name-by-id?id=${this.sectionData.destination}`, 'GET');
        if (res) this.selectedItems = [res];
        break;

      case 'category':
        res = await this.io.apicall(null, `page-sections/name-by-id?id=${this.sectionData.destination}`, 'GET');
        if (res) this.selectedItems = [{ id: res.id, name: res.name }];
        break;

      case 'mealPlan':
        res = await this.io.apicall(null, `meal-plans/name-by-id?id=${this.sectionData.destination}`, 'GET');
        if (res) this.selectedItems = [{ id: res.id, name: res.name }];
        break;

      case 'externalLink':
        this.urlLink = this.sectionData.destination || '';
        break;
    }
  }

  onDeleteClick() {
    this.deleteSection.emit();
  }

  moveUp() {
    this.moveSectionUp.emit();
  }

  moveDown() {
    this.moveSectionDown.emit();
  }

  changeSectionType(newType: BannerSectionData['type']) {
    if (this.sectionData.type === newType) return;

    this.sectionData.type = newType;
    this.sectionData.destination = undefined;

    this.initializeData();
  }

  async uploadImg(event) {
    const imgFile = event?.target?.files?.[0];

    if (imgFile) {
      const formData = new FormData();

      formData.append('file', imgFile);

      const res = (await this.io.apicall(formData, 'uploads/banner-img', 'POST')) as any;

      if (res.serverResponse.code === 200) {
        this.sectionData.imgUrl = res.result[0].fileUrl;
      }
    }
  }

  async uploadSecondaryImg(event) {
    const imgFile = event?.target?.files?.[0];

    if (imgFile) {
      const formData = new FormData();

      formData.append('file', imgFile);

      const res = (await this.io.apicall(formData, 'uploads/banner-img', 'POST')) as any;

      if (res.serverResponse.code === 200) {
        this.sectionData.secondaryImgUrl = res.result[0].fileUrl;
      }
    }
  }

  onItemSelect(event) {
    this.sectionData.destination = String(event.id);
  }

  onSearchTextChanged(event) {
    this.searchText$.next(event);
  }

  searchForItem(text: string) {
    switch (this.sectionData.type) {
      case 'dish':
        this.searchMenuProductChanged(text);
        break;

      case 'recipe':
        this.searchRecipeChanged(text);
        break;

      case 'restaurant':
        this.searchRestaurantChanged(text);
        break;

      case 'recipeCreator':
        this.searchRecipeCreatorChanged(text);
        break;

      case 'category':
        this.searchCategoryChanged(text);
        break;

      case 'mealPlan':
        this.searchMealPlanChanged(text);
        break;
    }
  }

  async searchMenuProductChanged(name: string) {
    const res = (await this.io.apicall(null, `menu-product/list-by-name?name=${name}`, 'GET')) as any;

    this.listItems = res.menuProducts.map((menuProduct) => ({ id: menuProduct.dish.id, name: menuProduct.name }));
  }

  async searchRecipeChanged(name: string) {
    const res = (await this.io.apicall(null, `recipe/search-by-name?name=${name}`, 'GET')) as any;

    this.listItems = res.recipes.map((recipe) => ({ id: recipe.id, name: recipe.recipeName }));
  }

  async searchRestaurantChanged(name: string) {
    const res = (await this.io.apicall(null, `restaurant/search-by-name?name=${name}`, 'GET')) as any;

    this.listItems = res.restaurants;
  }

  async searchRecipeCreatorChanged(name: string) {
    const res = (await this.io.apicall(null, `creator/search-by-name?name=${name}`, 'GET')) as any;

    this.listItems = res.recipeCreators;
  }

  async searchCategoryChanged(name: string) {
    const res = (await this.io.apicall(null, `page-sections/search-by-name?name=${name}`, 'GET')) as any;

    this.listItems = res.pageSections.map((pageSection) => ({ id: pageSection.id, name: pageSection.name }));
  }

  async searchMealPlanChanged(name: string) {
    const res = (await this.io.apicall(null, `meal-plans/search-by-name?name=${name}`, 'GET')) as any;

    this.listItems = res.mealPlans.map((mealPlan) => ({ id: mealPlan.id, name: mealPlan.name }));
  }

  linkChanged(event) {
    this.urlLink = event?.trim();
    this.sectionData.destination = event?.trim();

    this.isValidUrl();
  }

  isValidUrl() {
    if (!this.urlLink.length) return;

    try {
      this.urlValid = Boolean(new URL(this.urlLink));
    } catch {
      this.urlValid = false;
    }
  }
}
