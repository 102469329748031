import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecipeTransactionPayoutListComponent } from './recipe-transaction-payout-list/recipe-transaction-payout-list.component';
import { RecipeTransactionPayoutViewDetailsComponent } from './recipe-transaction-payout-view-details/recipe-transaction-payout-view-details.component';

import { RecipeTransactionComponent } from './recipe-transaction.component';

const routes: Routes = [{ 
  path: '', component: RecipeTransactionComponent,
  children:[
    {
      path: 'recipe-transaction-payout-list', 
      component: RecipeTransactionPayoutListComponent,
    },
    {
      path: 'recipe-transaction-payout-view-details', 
      component: RecipeTransactionPayoutViewDetailsComponent,
    },
  ] 
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecipeTransactionRoutingModule { }
