import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IoService } from "../../services/http/io.service";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import moment from "moment";
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: "app-add-coupon",
  templateUrl: "./add-coupon.component.html",
  styleUrls: ["./add-coupon.component.css"],
})
export class AddCouponComponent implements OnInit {
  addCouponFrm: FormGroup;
  discountArray: any = [];
  currentRestaurantIdsForProductsSet = new Set<number>();
  currentRestaurantIdsForProducts: number[] = [];
  currentShownProducts: { id: number, name: string }[] = [];
  currentSelectedProducts: { id: number, name: string }[] = [];
  startDateModel: any;
  startTimeModel: any;
  model: any;
  timeModel: any;
  time: any;
  spinners = false;
  resturantList: { id: number, restaurantName: string }[];
  restaurantsDropdownSelected: { id: number, restaurantName: string }[] = [];
  restaurantDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'restaurantName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  productsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'Select restaurant to show its products',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  FORM_KEYS: {
    COUPON_CODE: string,
    DISCOUNT_AMOUNT: string,
    DISCOUNT_TYPE: string,
    AMOUNT_CAP: string,
    MAX_USE: string,
    MAX_USE_PER_USER: string,
    START_DATE: string,
    START_TIME: string
    DATE: string,
    TIME: string,
    RESTAURANT_LIST: string,
    PRODUCT_LIST: string,
  }
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private io: IoService
  ) {
    this.FORM_KEYS = {
      COUPON_CODE: "couponCode",
      DISCOUNT_AMOUNT: "discountAmount",
      DISCOUNT_TYPE: "discountType",
      AMOUNT_CAP: "amountCap",
      MAX_USE: "maxUse",
      MAX_USE_PER_USER: "maxUsePerUser",
      START_DATE: "startDate",
      START_TIME: "startTime",
      DATE: "date",
      TIME: "time",
      RESTAURANT_LIST: 'restaurantList',
      PRODUCT_LIST: 'producttList',
    }
    this.addCouponFrm = this.fb.group({
      [this.FORM_KEYS.COUPON_CODE]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.DISCOUNT_AMOUNT]: [null, ''],
      [this.FORM_KEYS.DISCOUNT_TYPE]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.AMOUNT_CAP]: [null, ''],
      [this.FORM_KEYS.MAX_USE]: [null, ''],
      [this.FORM_KEYS.MAX_USE_PER_USER]: [null, ''],
      [this.FORM_KEYS.START_DATE]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.START_TIME]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.DATE]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.TIME]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.RESTAURANT_LIST]: [null, ''],
      [this.FORM_KEYS.PRODUCT_LIST]: [null, ''],
    });
  }

  ngOnInit(): void {
    this.getDiscountType();
    this.getAllRestaurants();

    this.addCouponFrm.get(this.FORM_KEYS.DISCOUNT_TYPE).valueChanges.subscribe((value) => {
      if (value == "percentage") {
        this.addCouponFrm
          .get(this.FORM_KEYS.DISCOUNT_AMOUNT)
          .setValidators(
            Validators.compose([Validators.required, Validators.max(100)])
          );
      } else {
        this.addCouponFrm
          .get(this.FORM_KEYS.DISCOUNT_AMOUNT)
          .setValidators(Validators.compose([Validators.required]));
      }
      this.addCouponFrm.controls[this.FORM_KEYS.DISCOUNT_AMOUNT].updateValueAndValidity();
    });
  }

  async getAllRestaurants() {
    this.resturantList = ((await this.io.apicall({}, 'restaurant/get-restaurant-list-for-dropdown', 'POST')) as any).result;
  }

  // DISCOUNT TYPE //
  getDiscountType() {
    return this.discountArray.push(
      { id: 0, discountType: "flat" },
      { id: 1, discountType: "percentage" }
    );
  }

  // ADD COUPON API //
  addCoupon(Obj) {
    if (!this.addCouponFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.addCouponFrm.markAllAsTouched();
      return;
    }
    let startDate = `${this.startDateModel.year}-${this.startDateModel.month}-${this.startDateModel.day} ${this.startTimeModel.hour}:${this.startTimeModel.minute}`;
    let date = `${this.model.year}-${this.model.month}-${this.model.day} ${this.timeModel.hour}:${this.timeModel.minute}`;

    const restaurantIds = this.restaurantsDropdownSelected.length > 0 ? this.restaurantsDropdownSelected.map((res) => res.id) : null;
    const productIds = this.currentSelectedProducts.length > 0 ? this.currentSelectedProducts.map((prod) => prod.id) : null;

    let payLoad = {
      couponCode: Obj[this.FORM_KEYS.COUPON_CODE].toUpperCase(),
      startDate: moment(startDate, "YYYY-MM-DD HH:mm").utc().toISOString(),
      expDate: moment(date, "YYYY-MM-DD HH:mm").utc().toISOString(),
      discountAmount: Obj[this.FORM_KEYS.DISCOUNT_AMOUNT],
      discountType: Obj[this.FORM_KEYS.DISCOUNT_TYPE],
      amountCap: Obj[this.FORM_KEYS.AMOUNT_CAP],
      maxUsed: Obj[this.FORM_KEYS.MAX_USE],
      maxUsagePerUser: Obj[this.FORM_KEYS.MAX_USE_PER_USER],
      restaurantIds,
      productIds,
      isUserSpecific: false,
    };

    this.io
      .apicall(payLoad, "coupon/add-new-coupon", "POST")
      .then((res) => {
        if (this.io.data_.serverResponse.code == 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.router.navigate(["/coupon/coupon-list"]);
          this.addCouponFrm.reset();
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  async onRestaurantDropdownClose() {
    const selectedRestaurantIdsSet = new Set<number>(this.restaurantsDropdownSelected.map((res) => res.id));

    if (this.areSetsEqual(this.currentRestaurantIdsForProductsSet, selectedRestaurantIdsSet)) return;

    this.currentRestaurantIdsForProductsSet = selectedRestaurantIdsSet;

    this.currentRestaurantIdsForProducts = Array.from(this.currentRestaurantIdsForProductsSet);

    if (!this.currentRestaurantIdsForProducts.length) {
      this.currentShownProducts = [];
      return;
    }

    this.currentShownProducts = ((await this.io.apicall(null, `menu/portal/products?restaurantIds=${JSON.stringify(this.currentRestaurantIdsForProducts)}`, 'GET')) as any);
  }

  areSetsEqual(a: Set<number>, b: Set<number>) {
    return a.size === b.size && Array.from(a).every(b.has.bind(b));
  }

  onItemSelect(event) { }

  onSelectAll(event) { }
}
