import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccordionTabComponent } from "./accordion-tab.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ItemBoxModule } from "../item-box/item-box.module";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ItemModalModule } from "../item-modal/item-modal.module";

@NgModule({
  declarations: [AccordionTabComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ItemBoxModule,
    DragDropModule,
    ItemModalModule,
  ],
  exports: [AccordionTabComponent],
})
export class AccordionTabModule {}
