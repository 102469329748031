import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recipe-transaction',
  templateUrl: './recipe-transaction.component.html',
  styleUrls: ['./recipe-transaction.component.css']
})
export class RecipeTransactionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
