import { Component, OnInit } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { IoService } from "../../services/http/io.service";
import { FilterSection } from "../interfaces/FilterSection.interface";
import swal from "sweetalert2";

@Component({
  selector: "app-filters-sections",
  templateUrl: "./filters-sections.component.html",
  styleUrls: ["./filters-sections.component.css"],
})
export class FiltersSectionsComponent implements OnInit {
  filterSections: FilterSection[] = [];
  orderChanged: boolean = false;

  constructor(private io: IoService) { }

  ngOnInit(): void {
    this.fetchAllFilterSections();
  }

  async fetchAllFilterSections() {
    const filterSections = (await this.io.apicall(
      null,
      "dietary-preferences/filter-sections",
      "GET"
    )) as any;

    this.filterSections = filterSections.filterSections;
  }

  drop(event: CdkDragDrop<string[]>) {
    this.orderChanged = true;

    moveItemInArray(this.filterSections, event.previousIndex, event.currentIndex);
  }

  async saveOrder() {
    const newOrder = this.filterSections.map(section => section.id);

    await this.io.apicall({ ids: newOrder }, 'dietary-preferences/filter-sections/order', 'POST');

    swal.fire({
      title: "Success",
      text: 'Filter sections order saved successfully!',
      icon: "success",
      confirmButtonColor: "#442DFF;",
      confirmButtonText: "ok",
    });

    this.orderChanged = false;
  }
}
