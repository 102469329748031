import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from "./../../_constant/constant";

@Injectable({
  providedIn: "root",
})
export class RecipeService {
  constructor(private http: HttpClient) {}

  delete(id: String): Observable<object> {
    let payload = {
      recipeId: id,
    };

    return this.http.post(routes.deleteRecipeUrl, payload);
  }
}
