import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SupportAddComponent } from "./support-add/support-add.component";
import { SupportEditComponent } from "./support-edit/support-edit.component";
import { SupportListComponent } from "./support-list/support-list.component";

import { SupportComponent } from "./support.component";

const routes: Routes = [
  {
    path: "",
    component: SupportComponent,
    children: [
      {
        path: "support-list",
        component: SupportListComponent,
      },
      {
        path: "edit/:id",
        component: SupportEditComponent,
      },
      {
        path: "add",
        component: SupportAddComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SupportRoutingModule {}
