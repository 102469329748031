import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TransactionComponent } from './transaction.component';
import { TransactionListComponent } from './index';

const routes: Routes = [
  { 
    path: '', 
    component: TransactionComponent,
    children: [
      {
        path: 'transaction-list', 
        component: TransactionListComponent
      }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransactionRoutingModule { }
