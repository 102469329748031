import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MenuFoodScienceRoutingModule } from "./menu-food-science-routing.module";
import { MenuFoodScienceComponent } from "./menu-food-science.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuFoodScienceListComponent } from './menu-food-science-list/menu-food-science-list.component';
import { MenuFoodScienceMenusComponent } from './menu-food-science-list/components/menu-food-science-menus/menu-food-science-menus.component';
import { MenuFoodScienceItemComponent } from './menu-food-science-list/components/menu-item/menu-food-science-item.component';
import { MenuFoodScienceCategoryListComponent } from './menu-food-science-list/components/menu-category-list/menu-food-science-category-list.component';
import { MenuFoodScienceCategoryComponent } from './menu-food-science-list/components/menu-category/menu-food-science-category.component';
import { MenuFoodScienceProductListComponent } from './menu-food-science-list/components/menu-product-list/menu-food-science-product-list.component';
import { MenuFoodScienceProductComponent } from './menu-food-science-list/components/menu-product/menu-food-science-product.component';
import { MenuFoodScienceItemRowComponent } from './menu-food-science-list/components/menu-food-science-item-row/menu-food-science-item-row.component';
import { NumberDirective } from '../validators/numbers-only-menu-food-science.directive';

@NgModule({
  declarations: [
    MenuFoodScienceComponent,
    MenuFoodScienceListComponent,
    MenuFoodScienceMenusComponent,
    MenuFoodScienceItemComponent,
    MenuFoodScienceCategoryListComponent,
    MenuFoodScienceCategoryComponent,
    MenuFoodScienceProductListComponent,
    MenuFoodScienceProductComponent,
    MenuFoodScienceItemRowComponent,
    NumberDirective,
  ],
  imports: [
    CommonModule,
    MenuFoodScienceRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [],
})
export class MenuFoodScienceModule { }
