import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data-management/data.service';
import { IoService } from '../../services/http/io.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-resturant-report',
  templateUrl: './resturant-report.component.html',
  styleUrls: ['./resturant-report.component.css']
})
export class ResturantReportComponent implements OnInit {

  resturantRepostList: any = [];
  activePage: number = 1;
  activeTotal: number;
  queryList: boolean = false;
  // today = new Date();
  fromDate = null;
  toDate = null;
  restaurantName: any;
  resturantListForDropdown: any = [];



  constructor(
    private modalService: NgbModal,
    private ar: ActivatedRoute,
    private io: IoService,
    private dataservice: DataService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {

   }




  ngOnInit(): void {
    this.resturantList();
  //   this.getResturantReportList();
  }


 getResturantReportList() {
  this.resturantRepostList = [];
  // 2021-10-10 01:01:01
  let fromDateFormat = this.fromDate.year+'-'+this.fromDate.month+'-'+this.fromDate.day+' 01:01:01'
  let toDateFormat = this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.day+' 01:01:01'
  let payload = {
    fromdate: fromDateFormat,
    todate: toDateFormat,
    restaurantName:this.restaurantName?this.restaurantName:'',
    page: this.activePage,
    limit: 5,
  }
  this.io.apicall(payload, '/report/trans-restaurant/all', 'POST').then((res: any) => {
    if (res['serverResponse'].code === 200) {
      this.queryList = false;
     this.activeTotal = res['result'][0]['metadata'][0].total;
      this.resturantRepostList = res['result'][0]['data'];
    }
  })
    .catch(err => {
      throw err;
    })
}


  //PAGE CHANGED FOR NEW Report LIST //
  pageChanged(event) {
    if (event >= 1) {
      this.getResturantReportList();
    }
  }
  viewDetails(obj){
    let Obj = {
      resturantId: obj.restaurantId
    };
    this.dataservice._setObj(Obj);
    this.router.navigate(["/report/restaurant-transactions"]);
  }

  resturantList() {

    this.io
      .apicall({}, "restaurant/get-restaurant-list-for-dropdown", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.resturantListForDropdown = res["result"];
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  fetchReports(){

    if(this.fromDate!=null && this.toDate!=null){

      this.getResturantReportList();
    }

  }

  // SEARCH BY RESTURNT NAME //
  chooseResturant(event) {

    this.restaurantName = event.target.value;
    this.fetchReports();

  }

}
