import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-menu',
  templateUrl: './custom-menu.component.html',
  styleUrls: ['./custom-menu.component.css']
})
export class CustomMenuComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
