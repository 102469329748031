import { Component, OnInit } from '@angular/core';

import { RestaurantCoverageResponse, RestaurantCoverageView } from './coverage.model'
import { IoService } from '../../services/http/io.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-coverage-map',
  templateUrl: './coverage-map.component.html',
  styleUrls: ['./coverage-map.component.css'],
})
export class CoverageMapComponent implements OnInit {
  allRestaurants: RestaurantCoverageView[] = [];
  restaurantsCoverages: RestaurantCoverageView[] = [];
  shownRestaurants: RestaurantCoverageView[] = [];
  clickedRestaurantIndex = -1;
  droppingPinMode = false;
  pinDroppedMode = false;
  droppedPin: { lat: number, lng: number } = null;
  initialLatitude = 25.2048;
  initialLongitude = 55.2708;

  loaded = false;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'displayName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  constructor(
    private io: IoService,
  ) { }

  ngOnInit() {
    this.loadRestaurants();
  }

  async loadRestaurants() {
    const response = (await this.io.apicall(null, 'restaurant/coverage', 'GET')) as RestaurantCoverageResponse[];

    this.allRestaurants = response.map((restaurant) => ({
      ...restaurant,
      name: restaurant.name,
      displayName: this.getRestaurantDisplayName(restaurant.name, restaurant.branch_name, restaurant.max_distance_covered_by_restaurant),
      branchName: restaurant.branch_name || '',
      latitude: +restaurant.latitude,
      longitude: +restaurant.longitude,
      max_distance_covered_by_restaurant: +restaurant.max_distance_covered_by_restaurant,
      redComponent: this.colorCodeHash(restaurant.name, 3),
      greenComponent: this.colorCodeHash(restaurant.name, 7),
      blueComponent: this.colorCodeHash(restaurant.name, 13),
      distanceFromDeliveryAddress: 0,
    }));

    this.restaurantsCoverages = this.allRestaurants;

    this.loaded = true;
  }

  getRestaurantDisplayName(name: string, branchName: string | null | undefined, max_distance_covered_by_restaurant: string | number, distanceFromDeliveryAddress?: number) {
    return name +
      (branchName?.trim() ? ` - ${branchName?.trim()}` : '') +
      ` (${(+max_distance_covered_by_restaurant / 1000).toFixed(2)} KM)` +
      (this.droppedPin ? `: ${(distanceFromDeliveryAddress / 1000).toFixed(2)} KM` : '');
  }

  onCircleClick({ coords: { lat, lng } }) {
    if (this.droppingPinMode) {
      this.pinDropped(lat, lng);
      return;
    }

    let minDistance = Number.MAX_VALUE;
    let minRestaurantIndex = -1;

    this.shownRestaurants.forEach((coverage, index) => {
      const currDistance = this.distanceBetween2Points(coverage.latitude, coverage.longitude, lat, lng);

      if (currDistance < minDistance && currDistance < coverage.max_distance_covered_by_restaurant) {
        minDistance = currDistance;
        minRestaurantIndex = index;
      }
    })

    this.clickedRestaurantIndex = minRestaurantIndex;
  }

  selectRestaurant({ coords: { lat, lng } }, index) {
    if (this.droppingPinMode) {
      this.pinDropped(lat, lng);
      return;
    }

    this.clickedRestaurantIndex = index;
  }

  closeInfoWindow() {
    this.clickedRestaurantIndex = -1;
  }

  colorCodeHash(str: string, salt: number) {
    str = str.toLowerCase().replace(/[^a-z0-9]/g, '');
    let hash = 0;

    for (let i = 0, len = str.length; i < len; i++) {
      const chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr + salt;
      hash |= 0;
    }

    return Math.abs(hash % 256);
  }

  distanceBetween2Points(lat1, lng1, lat2, lng2) {
    const R = 6371000;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLng = (lng2 - lng1) * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;

    return d;
  }

  onSingleSelect(event) {
    this.shownRestaurants.push(event);

    this.setShownRestaurants(this.shownRestaurants);
  }

  onDeSelect(event) {
    this.setShownRestaurants(this.shownRestaurants.filter((rest) => rest.id !== event.id));
  }

  setShownRestaurants(event) {
    this.closeInfoWindow();

    const idsSet = new Set<number>();

    event.forEach((rest) => idsSet.add(rest.id));

    this.shownRestaurants = this.restaurantsCoverages.filter((rest) => idsSet.has(rest.id));
  }

  dropPinClicked() {
    if (this.droppedPin) {
      this.removeDroppedPin();
      return;
    }

    this.droppingPinMode = !this.droppingPinMode;
  }

  mapClicked(event) {
    if (this.droppingPinMode) {
      this.pinDropped(event.coords.lat, event.coords.lng);
    } else {
      this.closeInfoWindow();
    }
  }

  pinDropped(lat: number, lng: number) {
    this.shownRestaurants = [];
    this.droppingPinMode = false;
    this.droppedPin = { lat, lng };

    this.filterInRangeRestaurants();
  }

  removeDroppedPin() {
    this.shownRestaurants = [];
    this.droppedPin = null;

    this.filterInRangeRestaurants();
  }

  filterInRangeRestaurants() {
    this.closeInfoWindow();

    if (!this.droppedPin) {
      this.restaurantsCoverages = this.allRestaurants.map((res) => ({
        ...res,
        distanceFromDeliveryAddress: 0,
        displayName: this.getRestaurantDisplayName(res.name, res.branchName, res.max_distance_covered_by_restaurant)
      }));

      return;
    }

    this.restaurantsCoverages = this.allRestaurants.filter((res) => {
      const distance = this.distanceBetween2Points(this.droppedPin.lat, this.droppedPin.lng, res.latitude, res.longitude);

      res.distanceFromDeliveryAddress = distance;

      res.displayName = this.getRestaurantDisplayName(res.name, res.branchName, res.max_distance_covered_by_restaurant, distance);

      return distance <= res.max_distance_covered_by_restaurant;
    });

    this.restaurantsCoverages.sort((resA, resB) =>
      resA.distanceFromDeliveryAddress - resB.distanceFromDeliveryAddress
    );
  }

  locationSelectedFromSearch({ lat, lng }: { lat: number, lng: number }) {
    this.pinDropped(lat, lng);

    this.initialLatitude = lat;
    this.initialLongitude = lng;
  }
}
