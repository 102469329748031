import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";

import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";

import { AppComponent } from "./app.component";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { RecipeProviderModule } from "./recipe-provider/recipe-provider.module";
import { ResturantModule } from "./resturant/resturant.module";
import { CustomerModule } from "./customer/customer.module";
import { GeneralModule } from "./general/general.module";

import { RecipeModule } from "./recipe/recipe.module";
import { ColorPickerModule } from "ngx-color-picker";
import { UserModule } from "./user/user.module";
import { ManagementModule } from "./management/management.module";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { HttpConfigInterceptor } from "./services/intersepters/http-config.intercept";
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from "ngx-ui-loader";
import { CategoryModule } from "./category/category.module";
import { environment } from "../environments/environment";
import { AgmCoreModule } from "@agm/core";
import { DishModule } from "./dish/dish.module";
import { OrderModule } from "./order/order.module";
import { CouponModule } from "./coupon/coupon.module";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { DecimalpointDirective } from "./validators/decimal.directives";
import { SupportModule } from "./support/support.module";
import { RecipeTransactionModule } from "./recipe-transaction/recipe-transaction.module";
import { RestaurantTransactionModule } from "./restaurant-transaction/restaurant-transaction.module";
import { ReportModule } from "./report/report.module";
import { TransactionModule } from "./transaction/transaction.module";
import { SettingsModule } from "./settings/settings.module";
import { ProfileModule } from "./profile/profile.module";
import { DietaryPreferencesModule } from "./dietary-preferences/dietary-preferences.module";
import { ContentManagementModule } from "./content-management/content-management.module";
import { MenuModule } from './menu/menu.module';
import { BranchModule } from './branch/branch.module';
import { NotificationModule } from './notification/notification.module';
import { SupplyCountModule } from './supply-count/supply-count.module';
import { MenuFoodScienceModule } from './menu-food-science/menu-food-science.module';
import { CustomMenuModule } from './custom-menu/custom-menu.module';
import { HeroBannersModule } from './hero-banners/hero-banners.module';
import { ShortcutBannersModule } from './shortcut-banners/shortcut-banners.module';
import { FiltersModule } from "./filters/filters.module";
import { MealPlanModule } from "./meal-plan/meal-plan.module";

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#232526",
  bgsOpacity: 0.9,
  bgsPosition: POSITION.centerCenter,
  bgsSize: 60,
  bgsType: SPINNER.squareLoader,
  blur: 5,
  delay: 0,
  fgsColor: "#B2D571",
  fgsPosition: POSITION.centerCenter,
  fgsSize: 80,
  fgsType: SPINNER.rotatingPlane,
  gap: 24,
  pbColor: "#fdd000",
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 3,
  hasProgressBar: true,
};

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgbModule,
    RecipeProviderModule,
    RecipeModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
      timeOut: 3000,
      preventDuplicates: true,
    }),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    ColorPickerModule,
    ResturantModule,
    BranchModule,
    MenuModule,
    HeroBannersModule,
    ShortcutBannersModule,
    MenuFoodScienceModule,
    NotificationModule,
    CustomerModule,
    DishModule,
    GeneralModule,
    CustomMenuModule,
    UserModule,
    ProfileModule,
    SupplyCountModule,
    ManagementModule,
    ContentManagementModule,
    CategoryModule,
    CouponModule,
    RecipeTransactionModule,
    RestaurantTransactionModule,
    TransactionModule,
    SettingsModule,
    ReportModule,
    MealPlanModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AgmCoreModule.forRoot({
      apiKey: environment?.googleMapKey,
      libraries: ["places"],
    }),
    OrderModule,
    SupportModule,
    DietaryPreferencesModule,
    FiltersModule,
  ],
  declarations: [AppComponent, AdminLayoutComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
