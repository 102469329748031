import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IoService } from "../../services/http/io.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import swal from "sweetalert2";

@Component({
    selector: "app-filters-section-items",
    templateUrl: "./section-items.component.html",
    styleUrls: ["./section-items.component.css"],
})
export class SectionItemsComponent implements OnInit, OnDestroy {
    sectionId: number;
    sectionName: string = '';
    items: { id: number; name: string; }[] = [];
    orderChanged: boolean = false;
    paramObserver: any;

    constructor(
        private route: ActivatedRoute,
        private io: IoService,
    ) { }

    ngOnInit(): void {
        this.paramObserver = this.route.params.subscribe(({ sectionId }) => {
            this.initializePage(Number(sectionId));
        });
    }

    ngOnDestroy(): void {
        this.paramObserver.unsubscribe();
    }

    drop(event: CdkDragDrop<string[]>) {
        this.orderChanged = true;

        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    }

    async initializePage(sectionId: number) {
        const section = (await this.io.apicall(null, `dietary-preferences/filter-section/${sectionId}/items`, 'GET')) as any;

        this.sectionId = sectionId;
        this.sectionName = section.sectionName;

        this.items = section.children;
    }

    async saveOrder() {
        const newOrder = this.items.map(item => item.id);

        await this.io.apicall({ ids: newOrder }, `dietary-preferences/filter-sections/${this.sectionId}/order`, 'POST');

        swal.fire({
            title: "Success",
            text: `${this.sectionName} order saved successfully!`,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
        });

        this.orderChanged = false;
    }
}
