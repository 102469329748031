import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IoService } from '../../../../services/http/io.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ShortcutBannerData } from '../../../interfaces/shortcut-banner.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import swal from 'sweetalert2';

@Component({
  selector: 'app-shortcut-banner',
  templateUrl: './shortcut-banner.component.html',
  styleUrls: ['./shortcut-banner.component.css']
})
export class ShortcutBannerComponent implements OnInit {
  @Input() sectionData: ShortcutBannerData;
  @Input() bannerOptions: { id: number, name: string }[];
  @Input() canMoveUp: boolean;
  @Input() canMoveDown: boolean;

  @Output() deleteSection = new EventEmitter();
  @Output() moveSectionUp = new EventEmitter();
  @Output() moveSectionDown = new EventEmitter();

  listItems: { id: number, name: string }[] = [];
  selectedItems: { id: number, name: string }[] = [];

  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
  };

  image: any = '';
  file: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  finalFile: any = '';
  enableCropper: any = false;
  showCropper: any = false;
  transform: any = {};
  closeResult: any = '';

  constructor(private io: IoService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    this.listItems = this.bannerOptions;
    this.selectedItems = [];

    if (!this.sectionData?.destination?.trim()) return;

    this.searchForAlreadySelectedItem(this.sectionData.destination.trim());
  }

  async searchForAlreadySelectedItem(searchId: string) {
    if (!this.bannerOptions?.length) return;

    this.selectedItems = this.bannerOptions.filter((option) => String(option.id) === searchId);
  }

  onDeleteClick() {
    this.deleteSection.emit();
  }

  moveUp() {
    this.moveSectionUp.emit();
  }

  moveDown() {
    this.moveSectionDown.emit();
  }

  onItemSelect(event) {
    this.sectionData.destination = String(event.id);
  }

  openImageSelectorModal(content) {
    this.imageChangedEvent = '';
    this.croppedImage = '';

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', centered: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  closeModal() {
    this.modalService.dismissAll();
    this.enableCropper = false;
  }

  fileChangeEvent(event: any): void {
    if (!event?.target?.files?.[0]) return;

    const objUrl = URL.createObjectURL(event.target.files[0]);

    const imgEl = new Image();

    imgEl.onload = () => {
      if (imgEl.width >= 700 && imgEl.height >= 700) {
        this.readUploadedImage(event);
      } else {
        event.target.value = '';

        swal.fire({
          title: 'Oops...',
          text: 'Uploaded image must be at least 700x700px',
          icon: 'warning',
          confirmButtonColor: '#442DFF;',
          confirmButtonText: 'ok',
        });
      }

      URL.revokeObjectURL(objUrl);
    };

    imgEl.src = objUrl;
  }

  readUploadedImage(event) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.image = reader.result as string;
    };

    this.file = event.target.files[0];
    reader.readAsDataURL(event.target.files[0]);
    this.imageChangedEvent = event;
    this.image = this.imageChangedEvent.target.value;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }

  imageLoaded(image: HTMLImageElement) {
    this.showCropper = true;
  }

  cropperReady() {
  }

  async saveImage(event) {
    const formData = new FormData();

    formData.append('file', this.finalFile);

    const res = (await this.io.apicall(formData, 'uploads/banner-img', 'POST')) as any;

    if (res.serverResponse.code === 200) {
      this.sectionData.imgUrl = res.result[0].fileUrl;
    }

    this.closeModal();
  }
}
