import { DataService } from "../../services/data-management/data.service";
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { IoService } from "../../services/http/io.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import swal from "sweetalert2";
import { forkJoin, Subscription } from "rxjs";
import { CategoryManagementService } from "./../../services/category-management/category-management.service";
import { DietaryPreferencesService } from "./../../services/dietary-preferences/dietary-preferences.service";
import { TagManagementService } from "./../../services/tag-management/tag-management.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ValidatorsService } from "./../..//services/validators/valdiators.service";

@Component({
  selector: "app-edit-category",
  templateUrl: "./edit-category.component.html",
  styleUrls: ["./edit-category.component.css"],
})
export class EditCategoryComponent implements OnInit, OnDestroy {
  dropdownSettings: IDropdownSettings;
  staticDishesDropdownSettings: IDropdownSettings;
  form: FormGroup;
  foodTypeList: any = [];
  mealtimeList: any = [];
  cuisineList: any = [];
  categoryArray: any = [];
  dietaryPreferences: any = [];
  categoryId: any;
  paramObserver: Subscription;
  isStaticDishStrategy: boolean = false;
  staticSelectedRestaurantId = -1;
  staticDishCategoryName = '';
  staticDishIds = [];
  dishesList: { id: number, dishName: string }[] = [];
  restaurantList: { id: number, restaurantName: string }[] = [];
  FORM_KEYS: {
    CATEGORY_NAME: string;
    PREPARATION_TIME_LTE: string;
    PREPARATION_TIME_GTE: string;
    CATEGORY_TYPE: string;
    CARBS_LTE: string;
    CARBS_GTE: string;
    FAT_LTE: string;
    FAT_GTE: string;
    PROTEIN_LTE: string;
    PROTEIN_GTE: string;
    CALORIES_LTE: string;
    CALORIES_GTE: string;
    PRICE_LTE: string;
    PRICE_GTE: string;
    NEW_RECIPE: string;
    IS_SIMPLE: string;
    BUDGET_FRIENDLY: string;
    POST_WORKOUT: string;
    NEW_DISH: string;
    NEW_RESTAURANT: string;
    ONLY_RESTAURANT: string;
    MEAL_TIME: string;
    CUISINE: string;
    FOOD_TYPE: string;
  };
  constructor(
    private fb: FormBuilder,
    private io: IoService,
    private router: Router,
    private route: ActivatedRoute,
    private categoryManagementService: CategoryManagementService,
    private dietaryPreferencesService: DietaryPreferencesService,
    private tagManagementService: TagManagementService,
    private loader: NgxUiLoaderService,
    private validatorService: ValidatorsService
  ) {
    this.FORM_KEYS = {
      CATEGORY_NAME: "categoryName",
      PREPARATION_TIME_LTE: "preparationTimeLte",
      PREPARATION_TIME_GTE: "preparationTimeGte",
      CATEGORY_TYPE: "categoryType",
      CARBS_LTE: "carbsLte",
      CARBS_GTE: "carbsGte",
      FAT_LTE: "fatLte",
      FAT_GTE: "fatGte",
      PROTEIN_LTE: "proteinLte",
      PROTEIN_GTE: "proteinGte",
      CALORIES_LTE: "caloriesLte",
      CALORIES_GTE: "caloriesGte",
      PRICE_LTE: "priceLte",
      PRICE_GTE: "priceGte",
      NEW_RECIPE: "newRecipe",
      IS_SIMPLE: "isSimple",
      BUDGET_FRIENDLY: "budgetFriendly",
      POST_WORKOUT: "postWorkout",
      NEW_DISH: "newDish",
      NEW_RESTAURANT: "newRestaurant",
      ONLY_RESTAURANT: "onlyRestaurant",
      MEAL_TIME: "mealTime",
      CUISINE: "cuisine",
      FOOD_TYPE: "foodType",
    };
  }

  ngOnInit(): void {
    this.paramObserver = this.route.params.subscribe(({ id }) => {
      if (id) {
        this.categoryId = id;
        this.loadForm();
        this.dropDownIntialized();
        this.loadCategoryType();
        this.fetchAllHead();
      } else {
        this.router.navigate(["/category/category-list"]);
      }
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  loadForm() {
    this.form = this.fb.group({
      [this.FORM_KEYS.CATEGORY_NAME]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.PREPARATION_TIME_LTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange(
            "min",
            () => this.form?.get(this.FORM_KEYS.PREPARATION_TIME_GTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.PREPARATION_TIME_GTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange("min", () => 0),
          this.validatorService.validateRange(
            "max",
            () => this.form?.get(this.FORM_KEYS.PREPARATION_TIME_LTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.CATEGORY_TYPE]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.CARBS_LTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange(
            "min",
            () => this.form?.get(this.FORM_KEYS.CARBS_GTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.CARBS_GTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange("min", () => 0),
          this.validatorService.validateRange(
            "max",
            () => this.form?.get(this.FORM_KEYS.CARBS_LTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.FAT_LTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange(
            "min",
            () => this.form?.get(this.FORM_KEYS.FAT_GTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.FAT_GTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange("min", () => 0),
          this.validatorService.validateRange(
            "max",
            () => this.form?.get(this.FORM_KEYS.FAT_LTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.PROTEIN_LTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange(
            "min",
            () => this.form?.get(this.FORM_KEYS.PROTEIN_GTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.PROTEIN_GTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange("min", () => 0),
          this.validatorService.validateRange(
            "max",
            () => this.form?.get(this.FORM_KEYS.PROTEIN_LTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.CALORIES_LTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange(
            "min",
            () => this.form?.get(this.FORM_KEYS.CALORIES_GTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.CALORIES_GTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange("min", () => 0),
          this.validatorService.validateRange(
            "max",
            () => this.form?.get(this.FORM_KEYS.CALORIES_LTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.PRICE_LTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange(
            "min",
            () => this.form?.get(this.FORM_KEYS.PRICE_GTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.PRICE_GTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange("min", () => 0),
          this.validatorService.validateRange(
            "max",
            () => this.form?.get(this.FORM_KEYS.PRICE_LTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.BUDGET_FRIENDLY]: [null],
      [this.FORM_KEYS.NEW_RECIPE]: [null],
      [this.FORM_KEYS.IS_SIMPLE]: [null],
      [this.FORM_KEYS.POST_WORKOUT]: [null],
      [this.FORM_KEYS.NEW_DISH]: [null],
      [this.FORM_KEYS.NEW_RESTAURANT]: [null],
      [this.FORM_KEYS.ONLY_RESTAURANT]: [null],
      [this.FORM_KEYS.MEAL_TIME]: [[]],
      [this.FORM_KEYS.CUISINE]: [[]],
      [this.FORM_KEYS.FOOD_TYPE]: [[]],
    });
    this.setCustomListeners();
  }

  setCustomListeners(): void {
    const CHANGES_CONTROL_MAPPER = {
      [this.FORM_KEYS.PREPARATION_TIME_LTE]: [
        this.FORM_KEYS.PREPARATION_TIME_GTE,
      ],
      [this.FORM_KEYS.FAT_LTE]: [this.FORM_KEYS.FAT_GTE],
      [this.FORM_KEYS.CARBS_LTE]: [this.FORM_KEYS.CARBS_GTE],
      [this.FORM_KEYS.PRICE_LTE]: [this.FORM_KEYS.PRICE_GTE],
      [this.FORM_KEYS.PROTEIN_LTE]: [this.FORM_KEYS.PROTEIN_GTE],
      [this.FORM_KEYS.CALORIES_LTE]: [this.FORM_KEYS.CALORIES_GTE],
    };
    for (const controlName in CHANGES_CONTROL_MAPPER) {
      this.form.get(controlName).valueChanges.subscribe(() => {
        this.form.get(CHANGES_CONTROL_MAPPER[controlName]).setErrors(null);
      });
      this.form
        .get(CHANGES_CONTROL_MAPPER[controlName])
        .valueChanges.subscribe(() => {
          this.form.get(controlName).setErrors(null);
        });
    }
  }

  loadCategoryType() {
    return this.categoryArray.push(
      { id: 0, categoryType: "recipe" },
      { id: 1, categoryType: "dish" },
      { id: 2, categoryType: "restaurant" }
    );
  }

  fetchCategoryDetails(id: string) {
    this.loader.start();
    this.categoryManagementService.getCategory(id).subscribe(
      (res) => {
        const type = res.type;
        this.form.patchValue({
          [this.FORM_KEYS.CATEGORY_TYPE]: res.type,
          [this.FORM_KEYS.CATEGORY_NAME]: res.name,
        });

        this.isStaticDishStrategy = /static/gi.test(res.strategy);
        this.staticDishCategoryName = res.name;

        if (this.isStaticDishStrategy) {
          this.getDishNamesForDropdown(res?.params?.id);
          this.fetchRestaurantNames();
        }

        if (res?.params) {
          this.form.patchValue({
            [type == "dish"
              ? this.FORM_KEYS.NEW_DISH
              : type == "restaurant"
              ? this.FORM_KEYS.NEW_RESTAURANT
              : this.FORM_KEYS.NEW_RECIPE]: res.params.is_new,
            [this.FORM_KEYS.CUISINE]: res?.params?.cuisine?.map((item) =>
              this.cuisineList.find((searchItem) => searchItem?.tagId == item)
            ),
            [this.FORM_KEYS.FOOD_TYPE]: res?.params?.food_type?.map((item) =>
              this.foodTypeList.find((searchItem) => searchItem?.tagId == item)
            ),
          });
          if (type == "recipe" || type == "dish") {
            this.dietaryPreferences.forEach((item) =>
              this.form
                .get(item?.name)
                .patchValue(
                  res?.params?.dietary_preferences[item?.id]?.map((mapItem) =>
                    item.options.find((searchItem) => searchItem.id == mapItem)
                  )
                )
            );
            this.form.patchValue({
              [this.FORM_KEYS.CARBS_LTE]: res?.params?.carbs_lte,
              [this.FORM_KEYS.CARBS_GTE]: res?.params?.carbs_gte,
              [this.FORM_KEYS.FAT_LTE]: res?.params?.fat_lte,
              [this.FORM_KEYS.FAT_GTE]: res?.params?.fat_gte,
              [this.FORM_KEYS.PROTEIN_LTE]: res?.params?.protein_lte,
              [this.FORM_KEYS.PROTEIN_GTE]: res?.params?.protein_gte,
              [this.FORM_KEYS.CALORIES_LTE]: res?.params?.total_calories_lte,
              [this.FORM_KEYS.CALORIES_GTE]: res?.params?.total_calories_gte,
              [this.FORM_KEYS.MEAL_TIME]: res?.params?.meal_time?.map((item) =>
                this.mealtimeList.find(
                  (searchItem) => searchItem?.tagId == item
                )
              ),
            });
            if (type == "recipe") {
              this.form.patchValue({
                [this.FORM_KEYS.PREPARATION_TIME_LTE]:
                  res?.params?.preparation_time_lte,
                [this.FORM_KEYS.PREPARATION_TIME_GTE]:
                  res?.params?.preparation_time_gte,
                [this.FORM_KEYS.IS_SIMPLE]: res?.params?.is_simple_ingredient,
                [this.FORM_KEYS.BUDGET_FRIENDLY]:
                  res?.params?.is_budget_friendly,
                [this.FORM_KEYS.POST_WORKOUT]: res?.params?.is_post_workout,
              });
            } else {
              this.form.patchValue({
                [this.FORM_KEYS.PRICE_LTE]: res?.params?.price_lte,
                [this.FORM_KEYS.PRICE_GTE]: res?.params?.price_gte,
              });
            }
          } else {
            this.form.patchValue({
              [this.FORM_KEYS.ONLY_RESTAURANT]: res?.params?.is_only,
            });
          }
        }
        this.loader.stop();
      },
      () => {
        this.loader.stop();
      }
    );
  }

  fetchRestaurantNames() {
    this.io
      .apicall({}, "restaurant/get-restaurant-list-for-dropdown", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.restaurantList = res.result;
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: "Couldn't get restaurant names!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  dropDownIntialized() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "tagId",
      textField: "tagName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 2,
      allowSearchFilter: true,
    };

    this.staticDishesDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "dishName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
  }

  editCategory() {
    if (!this.form.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.form.markAllAsTouched();
      return;
    }
    const type = this.form.get(this.FORM_KEYS.CATEGORY_TYPE).value;
    let payLoad: any = {
      name: this.form.get(this.FORM_KEYS.CATEGORY_NAME).value,
      type: type,
      params: {
        is_new:
          type == "dish"
            ? this.form.get(this.FORM_KEYS.NEW_DISH).value
            : type == "recipe"
            ? this.form.get(this.FORM_KEYS.NEW_RECIPE).value
            : this.form.get(this.FORM_KEYS.NEW_RESTAURANT).value,
        cuisine: this.form
          .get(this.FORM_KEYS.CUISINE)
          .value.map((item) => item.id),
        food_type: this.form
          .get(this.FORM_KEYS.FOOD_TYPE)
          .value.map((item) => item.id),
      },
    };
    if (type == "recipe" || type == "dish") {
      let dietary_preferences = {};
      this.dietaryPreferences.forEach((item) => {
        dietary_preferences[item.id] = this.form
          .get(item.name)
          .value.map((preference) => preference.id);
      });
      payLoad = {
        ...payLoad,
        params: {
          ...payLoad.params,
          carbs_lte: this.form.get(this.FORM_KEYS.CARBS_LTE).value || null,
          carbs_gte: this.form.get(this.FORM_KEYS.CARBS_GTE).value || null,
          fat_lte: this.form.get(this.FORM_KEYS.FAT_LTE).value || null,
          fat_gte: this.form.get(this.FORM_KEYS.FAT_GTE).value || null,
          protein_lte: this.form.get(this.FORM_KEYS.PROTEIN_LTE).value || null,
          protein_gte: this.form.get(this.FORM_KEYS.PROTEIN_GTE).value || null,
          total_calories_lte:
            this.form.get(this.FORM_KEYS.CALORIES_LTE).value || null,
          total_calories_gte:
            this.form.get(this.FORM_KEYS.CALORIES_GTE).value || null,
          meal_time: this.form
            .get(this.FORM_KEYS.MEAL_TIME)
            .value.map((item) => item.id),
          dietary_preferences,
        },
      };
      if (type == "recipe") {
        payLoad = {
          ...payLoad,
          params: {
            ...payLoad.params,
            preparation_time_lte:
              this.form.get(this.FORM_KEYS.PREPARATION_TIME_LTE).value || null,
            preparation_time_gte:
              this.form.get(this.FORM_KEYS.PREPARATION_TIME_GTE).value || null,
            is_simple_ingredient: this.form.get(this.FORM_KEYS.IS_SIMPLE).value,
            is_budget_friendly: this.form.get(this.FORM_KEYS.BUDGET_FRIENDLY)
              .value,
            is_post_workout: this.form.get(this.FORM_KEYS.POST_WORKOUT).value,
          },
        };
      } else {
        payLoad = {
          ...payLoad,
          params: {
            ...payLoad.params,
            price_lte: this.form.get(this.FORM_KEYS.PRICE_LTE).value,
            price_gte: this.form.get(this.FORM_KEYS.PRICE_GTE).value,
          },
        };
      }
    } else {
      payLoad = {
        ...payLoad,
        params: {
          ...payLoad.params,
          is_only: this.form.get(this.FORM_KEYS.ONLY_RESTAURANT).value,
        },
      };
    }
    this.categoryManagementService
      .editCategory(payLoad, this.categoryId)
      .subscribe(
        (res) => {
          swal.fire({
            title: "Success",
            text: "Category successfully edited",
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "Ok",
          });
          this.router.navigate(["/category/category-list"]);
        },
        (err) => {
          swal.fire({
            title: "Oops...",
            text: "Something went wrong while editing category",
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      );
  }

  fetchAllHead() {
    const tag_subscriber = this.tagManagementService.listCategories();
    const diet_subscriber = this.dietaryPreferencesService.listCategories();
    forkJoin({
      tagRes: tag_subscriber,
      dietRes: diet_subscriber,
    }).subscribe(async ({ tagRes, dietRes }) => {
      let categories_subscribers: any = {};
      if (tagRes["serverResponse"].code === 200) {
        let allHeadList = tagRes["result"];
        for (var i = 0; i < allHeadList.length; i++) {
          if (allHeadList[i].tagHeadId === "H6") {
            this.headTagMealTime(allHeadList[i].id);
          } else if (allHeadList[i].tagHeadId === "H4") {
            this.headTagFoodType(allHeadList[i].id);
          } else if (allHeadList[i].tagHeadId === "H3") {
            this.headTagCusine(allHeadList[i].id);
          }
        }
      }
      this.dietaryPreferences = dietRes;
      this.dietaryPreferences.forEach((item) => {
        categories_subscribers[item?.id] =
          this.dietaryPreferencesService.getCategoryOptions(item.id);
        item.dropdownSettings = {
          ...this.dropdownSettings,
          singleSelection: !item.multi && !(item.name=='Macronutrient Ratio'),
          textField: "name",
          idField: "id",
        };
        const diet_control = new FormControl([]);
        this.form.addControl(item.name, diet_control);
      });
      forkJoin(categories_subscribers).subscribe((data) => {
        this.handleDietaryPreferencesCategories(data);
      });
    });
  }

  handleDietaryPreferencesCategories(data) {
    this.dietaryPreferences.forEach((item, index) => {
      item.options = data[item.id];
    });
    this.fetchCategoryDetails(this.categoryId);
  }

  async headTagFoodType(Obj) {
    let value = await this.giveTagList(Obj);
    //this.foodTypeList = value;
    let myArr2 = [];
    for (let tags of value) {
      myArr2.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.foodTypeList = myArr2;
  }

  async headTagMealTime(Obj) {
    let value = await this.giveTagList(Obj);
    //this.mealtimeList = value;
    let myArr4 = [];
    for (let tags of value) {
      myArr4.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.mealtimeList = myArr4;
  }

  async headTagCusine(Obj) {
    let value = await this.giveTagList(Obj);
    //this.cuisineList = value;
    let myArr5 = [];
    for (let tags of value) {
      myArr5.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.cuisineList = myArr5;
  }

  async giveTagList(Obj): Promise<any> {
    return new Promise((resolve, reject) => {
      let payLoad = {
        tagHeadId: Obj,
      };
      this.io
        .apicall(payLoad, "tagcategories/fetch-tag-by-head", "POST")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            resolve(res["result"]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  restaurantSelected(id: number) {
    this.staticSelectedRestaurantId = id;

    if (id > -1) {
      this.io
      .apicall({
        isPublished: true,
        restaurantId: id,
      }, "dish/all-dish-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.dishesList = res.result.dishList.map((dish) => ({ id: +dish.id, dishName: dish.dishName }));
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: "Something went wrong while getting dishes",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
    } else {
      this.dishesList = [];
    }
  }

  getDishNamesForDropdown(dishIds: number[]) {
    if (!dishIds?.length) return;

    this.io
      .apicall(null, `dish/dish-names-for-dropdown?dishIds=${JSON.stringify(dishIds)}`, 'GET')
      .then((res: any) => {
        this.staticDishIds = res.dishes;
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: "Something went wrong while getting dishes",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  editStaticStrategy() {
    const payload = {
      name: this.staticDishCategoryName.trim(),
      type: 'dish',
      params: {
        id: this.staticDishIds.map((dish) => +dish.id),
      },
    };

    this.categoryManagementService
      .editCategory(payload, this.categoryId)
      .subscribe(
        (res) => {
          swal.fire({
            title: "Success",
            text: "Category successfully edited",
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "Ok",
          });
          this.router.navigate(["/category/category-list"]);
        },
        (err) => {
          swal.fire({
            title: "Oops...",
            text: "Something went wrong while editing category",
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      );
  }
}
