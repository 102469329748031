import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataService {
  private subject = new BehaviorSubject<Object>({});

  _currentObj() {
    return this.subject.asObservable();
  }

  _setObj(Obj: any) {
    this.subject.next(Obj);
  }
}
