import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RecipeProviderRoutingModule } from "./recipe-provider-routing.module";
import { RecipeProviderComponent } from "./recipe-provider.component";
import { AddOrRemoveRecipeProviderComponent } from "./add-or-remove-recipe-provider/add-or-remove-recipe-provider.component";
import { RecipeProviderListComponent } from "./recipe-provider-list/recipe-provider-list.component";
import { RecipeProviderDetailsComponent } from "./recipe-provider-details/recipe-provider-details.component";
import { HttpClientModule } from "@angular/common/http";
import { IoService } from "../services/http/io.service";
import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ImageCropperModule } from "ngx-image-cropper";
import { TabNavigatorModule } from "../tab-navigator/tab-navigator.module";

@NgModule({
  declarations: [
    RecipeProviderComponent,
    AddOrRemoveRecipeProviderComponent,
    RecipeProviderListComponent,
    RecipeProviderDetailsComponent,
  ],
  imports: [
    CommonModule,
    RecipeProviderRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    ImageCropperModule,
    TabNavigatorModule
  ],
  exports: [],
  providers: [IoService],
})
export class RecipeProviderModule {}
