import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restaurant-transaction',
  templateUrl: './restaurant-transaction.component.html',
  styleUrls: ['./restaurant-transaction.component.css']
})
export class RestaurantTransactionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
