import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from "../../_constant/constant";

@Injectable({
  providedIn: "root",
})
export class RestaurantService {
  constructor(private http: HttpClient) {}

  delete(id: String): Observable<object> {
    return this.http.delete(routes.deleteRestaurant(id));
  }
}
