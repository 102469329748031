import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Filters } from '../interfaces/filters.interface';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-food-science-filters-modal',
  templateUrl: './filters-modal.component.html',
  styleUrls: ['./filters-modal.component.css']
})
export class FiltersModal implements OnInit {
  @Input() activeFilters: Filters;

  @Input() tags: {
    id: number;
    name: string;
    preferences?: { id: number; name: string; }[];
    tags?: { id: number; name: string; }[];
  }[];
  selectedTags: { type: 'preference' | 'tag', selected: { id: number; name: string; }[] }[];
  selectedTagLabels: { tagId: number; tagName: string; }[];
  @Input() tagsLabelsData: { tagId: number; tagName: string; }[];
  tagsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  tagsLabelsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'tagId',
    textField: 'tagName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  @Input() resturantList: { id: number, restaurantName: string }[];

  restaurantDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'restaurantName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  restaurantsDropdownSelected: { id: number, restaurantName: string }[] = [];

  dishName: string = '';

  dishStatusActive: string;
  dishStatusPublish: string;

  caloriesMin: number;
  caloriesMax: number;

  carbsMin: number;
  carbsMax: number;

  proteinMin: number;
  proteinMax: number;

  fatMin: number;
  fatMax: number;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    if (this.activeFilters.restaurantIds) {
      this.restaurantsDropdownSelected = this.resturantList.filter((res) => this.activeFilters.restaurantIds.includes(res.id));
    }

    this.dishName = this.activeFilters.dishName || '';

    this.dishStatusActive = this.activeFilters.active || 'both';
    this.dishStatusPublish = this.activeFilters.publish || 'both';

    this.caloriesMin = this.activeFilters.caloriesMin || null;
    this.caloriesMax = this.activeFilters.caloriesMax || null;

    this.carbsMin = this.activeFilters.carbsMin || null;
    this.carbsMax = this.activeFilters.carbsMax || null;

    this.proteinMin = this.activeFilters.proteinMin || null;
    this.proteinMax = this.activeFilters.proteinMax || null;

    this.fatMin = this.activeFilters.fatMin || null;
    this.fatMax = this.activeFilters.fatMax || null;

    this.selectedTags = this.tags.map((tagCategory) => {
      let type: 'preference' | 'tag';
      let dataArr: { id: number; name: string; }[];

      if (tagCategory?.preferences?.length) {
        type = 'preference';
        dataArr = tagCategory.preferences
      } else {
        type = 'tag';
        dataArr = tagCategory.tags
      }

      return {
        type,
        selected: dataArr.filter((v) => {
          if (type === 'preference') {
            return this.activeFilters?.preferenceIds?.includes(v.id);
          } else {
            return this.activeFilters?.tagIds?.includes(v.id);
          }
        }),
      };
    });

    this.selectedTagLabels = this.tagsLabelsData.filter((tagLabel) => this.activeFilters?.tagLabelIds?.includes(tagLabel.tagId));
  }

  applyFilters() {
    const newFilters: Filters = {};

    if (this.restaurantsDropdownSelected.length < this.resturantList.length) {
      newFilters.restaurantIds = this.restaurantsDropdownSelected.map((val) => val.id);
    } else {
      newFilters.restaurantIds = [];
    }

    newFilters.dishName = this.dishName.trim();

    newFilters.active = this.dishStatusActive;
    newFilters.publish = this.dishStatusPublish;

    newFilters.caloriesMin = this.caloriesMin;
    newFilters.caloriesMax = this.caloriesMax;

    newFilters.carbsMin = this.carbsMin;
    newFilters.carbsMax = this.carbsMax;

    newFilters.proteinMin = this.proteinMin;
    newFilters.proteinMax = this.proteinMax;

    newFilters.fatMin = this.fatMin;
    newFilters.fatMax = this.fatMax;

    newFilters.preferenceIds = [];
    newFilters.tagIds = [];
    this.selectedTags.forEach((tagCategory, i) => {
      if (tagCategory.type === 'preference') {
        if (tagCategory.selected.length < this.tags[i].preferences.length) {
          tagCategory.selected.forEach(e => newFilters.preferenceIds.push(e.id));
        }
      } else {
        if (tagCategory.selected.length < this.tags[i].tags.length) {
          tagCategory.selected.forEach(e => newFilters.tagIds.push(e.id));
        }
      }
    });

    if (this.selectedTagLabels.length < this.tagsLabelsData.length) {
      newFilters.tagLabelIds = this.selectedTagLabels.map((v) => v.tagId);
    } else {
      newFilters.tagLabelIds = [];
    }

    this.activeModal.close(newFilters);
  }

  clearFilters() {
    this.activeModal.close({
      restaurantIds: [],
      dishName: '',
      active: 'both',
      publish: 'both',
      caloriesMin: null,
      caloriesMax: null,
      carbsMin: null,
      carbsMax: null,
      proteinMin: null,
      proteinMax: null,
      fatMin: null,
      fatMax: null,
      preferenceIds: [],
      tagIds: [],
      tagLabelIds: [],
    });
  }

  onItemSelect(event) {}

  onSelectAll(event) {}
}
