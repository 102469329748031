import { Component, OnInit } from '@angular/core';
import { IoService } from '../../services/http/io.service';

@Component({
  selector: 'app-banners-list',
  templateUrl: './banners-list.component.html',
  styleUrls: ['./banners-list.component.css']
})
export class BannersListComponent implements OnInit {
  pageSections: { id: number, name: string }[] = [];
  activePageSection: number = -1;

  constructor(private io: IoService) { }

  ngOnInit(): void {
    this.getBannerSections();
  }

  async getBannerSections() {
    const data = (await this.io.apicall(null, 'page-sections/banner', 'GET')) as any;

    this.pageSections = data.sections;

    if (this.pageSections.length > 0) this.activePageSection = 0;
  }
}
