import { Component, OnInit } from "@angular/core";
import { IoService } from "../../services/http/io.service";
import { Restaurant } from "../interfaces/restaurant.interface";
import { Branch } from "../interfaces/branch.interface";
import { Brand } from "../interfaces/brand.interface";

@Component({
  selector: "app-branch-list",
  templateUrl: "./branch-list.component.html",
  styleUrls: ["./branch-list.component.css"],
})
export class BranchListComponent implements OnInit {
  restaurants: Restaurant[] = [];
  selectedRestaurantIndex: number = -1;
  selectedBrandIndex: number = -1;
  branches: Branch[] = [];
  brands: Brand[] = [];

  constructor(private io: IoService) {}

  ngOnInit(): void {
    this.fetchAllParentRestaurants();
  }

  async fetchAllParentRestaurants() {
    this.restaurants = (await this.io.apicall(
      null,
      "restaurant/parents-dropdown",
      "GET"
    )) as Restaurant[];
  }

  async selectedRestaurantChanged() {
    this.brands = [];
    this.selectedBrandIndex = -1;

    if (this.selectedRestaurantIndex < 0) return;

    const response = (await this.io.apicall(
      null,
      `restaurant/branches/${
        this.restaurants[this.selectedRestaurantIndex].id
      }`,
      "GET"
    )) as any;

    this.branches = response.branches;
    this.brands = response.brands;
  }

  getAddBranchQueryParams() {
    return {
      parentId: this.restaurants[this.selectedRestaurantIndex]?.id,
      parentName: this.restaurants[this.selectedRestaurantIndex]?.name,
      brandId:
        this.selectedBrandIndex == -1
          ? undefined
          : this.brands[this.selectedBrandIndex].id,
      brandName:
        this.selectedBrandIndex == -1
          ? undefined
          : this.brands[this.selectedBrandIndex].name,
    };
  }
}
