import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DietaryPreferencesService } from "../services/dietary-preferences/dietary-preferences.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import swal from "sweetalert2";

@Component({
  selector: "app-dietary-preferences",
  templateUrl: "./dietary-preferences.component.html",
  styleUrls: ["./dietary-preferences.component.css"],
})
export class DietaryPreferencesComponent implements OnInit {
  tabs = [];
  constructor(
    private apiService: DietaryPreferencesService,
    private modalService: NgbModal,
    private loader: NgxUiLoaderService
  ) {}

  handleCollapseChange(isCollapsed, index): void {
    if (!isCollapsed && this.tabs[index]?.items?.length === 0) {
      this.listCategoryOptions(this.tabs[index]?.id, index);
    }
  }

  handleTabEdit(newValues, index): void {
    this.loader.start();
    this.apiService
      .editCategory(this.tabs[index].id, {
        name: newValues.title,
        multi: newValues.multiSelect,
        allowEmpty: newValues.allowEmpty,
        id: this.tabs[index].id, 
        dietaryPreferences: newValues.items.map((item) => {
          return {
            name: item.name,
            id: item.id,
            icon: item.image,
            description: item.description,
          };
        }),
      })
      .subscribe(
        () => {
          this.tabs[index] = {
            ...this.tabs[index],
            title: newValues?.title,
            multiSelect: newValues.multiSelect,
            allowEmpty: newValues.allowEmpty,
            items: newValues.items,
          };
          this.loader.stop();
        },
        () => {
          swal.fire({
            title: "Oops...",
            text: "Something went wrong",
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.loader.stop();
        }
      );
  }

  ngOnInit(): void {
    this.listAllCategories();
  }

  listAllCategories(): void {
    this.loader.start();
    this.apiService.listCategories().subscribe(
      (res) => {
        this.tabs = res.map((item, index) => {
          return {
            title: item.name,
            id: item.id,
            items: [],
            editable: true,
            collapsedByDefault: index == 0 ? false : true,
            allowEmpty: item.allowEmpty,
            multiSelect: item.multi,
          };
        });
        this.loader.stop();
        this.handleCollapseChange(false, 0);
      },
      () => {
        swal.fire({
          title: "Oops...",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }

  listCategoryOptions(id, index): void {
    this.loader.start();
    this.apiService.getCategoryOptions(id).subscribe(
      (res) => {
        this.tabs[index].items = res.map((item) => {
          return {
            name: item.name,
            image: item.icon,
            description: item.description,
            id: item.id,
          };
        });
        this.loader.stop();
      },
      () => {
        this.loader.stop();
      }
    );
  }

  handleSaveNewItem(values, index): void {
    this.loader.start();
    this.apiService
      .addNewItem({
        name: values.title,
        description: values.description,
        dietaryPreferenceCategory: this.tabs[index].id,
        icon: values.image,
      })
      .subscribe(
        () => {
          swal.fire({
            title: "Success",
            text: `${values?.title} has been successfully added`,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.loader.stop();
          this.modalService.dismissAll();
          this.listCategoryOptions(this.tabs[index].id, index);
        },
        (err) => {
          swal.fire({
            title: "Oops...",
            text: err?.error?.serverResponse?.message,
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.loader.stop();
        }
      );
  }

  handleItemEdit(values, index): void {
    this.loader.start();
    this.apiService
      .editItem(values.id, {
        name: values.title,
        description: values.description,
        id: this.tabs[index].id,
        icon: values.image,
      })
      .subscribe(
        () => {
          swal.fire({
            title: "Success",
            text: `${values?.title} has been successfully updated`,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.loader.stop();
          this.modalService.dismissAll();
          this.listCategoryOptions(this.tabs[index].id, index);
        },
        (err) => {
          swal.fire({
            title: "Oops...",
            text: err?.error?.serverResponse?.message,
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.loader.stop();
        }
      );
  }

  handleItemDelete(values, index): void {
    this.loader.start();
    this.apiService.deleteItem(values.id).subscribe(
      (res) => {
        swal.fire({
          title: "Success",
          text: `${values.title} has been successfully deleted`,
          icon: "success",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
        this.modalService.dismissAll();
        this.listCategoryOptions(this.tabs[index].id, index);
      },
      (err) => {
        swal.fire({
          title: "Oops...",
          text: err?.error?.serverResponse?.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }
}
