import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IoService } from '../../services/http/io.service';
import swal from "sweetalert2";
import { IMealDay } from '../interfaces/meal-day.interface';

@Component({
    selector: 'app-meal-plan-form',
    templateUrl: './meal-plan-form.component.html',
    styleUrls: ['./meal-plan-form.component.css']
})
export class MealPlanFormComponent implements OnInit, OnDestroy {
    paramObserver: Subscription;

    editPlanId: number | null;

    imageChangedEvent: any = '';
    croppedImage: any = '';
    closeResult: any = '';
    enableCropper: any = false;
    finalFile: any = '';
    file: any;
    transform: ImageTransform = {};

    image: string = '';
    planName: string = '';
    planDuration: number = 1;
    expiryDate: any = '';
    planTarget: string = '';
    planPrice: string = '';
    planDescription: string = '';
    isActive: boolean = false;
    isPublished: boolean = false;

    mealDays: IMealDay[] = [];

    constructor(
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private io: IoService,
        private toastr: ToastrService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.paramObserver = this.route.params.subscribe((values) => {
            this.editPlanId = values.planId ? +values.planId : null;

            if (this.editPlanId) {
                this.prefillForm();
            }
        });
    }

    ngOnDestroy(): void {
        this.paramObserver.unsubscribe();
    }

    async prefillForm() {
        try {
            const result = await this.io.apicall(null, `meal-plans/portal/${this.editPlanId}`, 'GET');

            const plan = result.mealPlan as {
                id: number;
                name: string;
                image: string;
                duration: string;
                target: string;
                description: string;
                isActive: boolean;
                isPublished: boolean;
                expiryDate: string;
                price: string;
                days: {
                    name: string;
                    meals: {
                        name: string;
                        items: {
                            id: number;
                            name: string;
                            image: string;
                            type: 'order' | 'cook';
                            showInSummary: boolean;
                            totalCalories: number | string;
                            protein: number | string;
                            carbs: number | string;
                            fat: number | string;
                        }[];
                    }[];
                }[];
            };

            this.planName = plan.name;
            this.image = plan.image;
            this.planDuration = +plan.duration.split(' ')[0];
            this.planTarget = plan.target;
            this.planDescription = plan.description;
            this.isActive = plan.isActive;
            this.isPublished = plan.isPublished;
            this.expiryDate = this.parseExpiryDate(plan.expiryDate);
            this.planPrice = plan.price;

            this.mealDays = plan.days.map((day) => ({
                name: day.name,
                meals: day.meals.map((meal) => ({
                    name: meal.name,
                    items: meal.items.map((item) => ({
                        id: item.id,
                        name: item.name,
                        image: item.image,
                        type: item.type,
                        showInSummary: item.showInSummary,
                        calories: Math.round(Number(item.totalCalories) * 100) / 100,
                        protein: Math.round(Number(item.protein) * 100) / 100,
                        carbs: Math.round(Number(item.carbs) * 100) / 100,
                        fat: Math.round(Number(item.fat) * 100) / 100,
                    })),
                })),
            }));
        } catch {
            swal.fire({
                title: 'Oops...',
                text: 'Failed to fetch plan details, please try again later!',
                icon: 'warning',
                confirmButtonColor: '#442DFF;',
                confirmButtonText: 'ok',
            });
        }
    }

    nutritionInfo() {
        let calories = 0;
        let carbs = 0;
        let fat = 0;
        let protein = 0;

        this.mealDays.forEach((day) => {
            day.meals.forEach((meal) => {
                meal.items.forEach((item) => {
                    calories += item.calories;
                    carbs += item.carbs;
                    fat += item.fat;
                    protein += item.protein;
                });
            });
        });

        return `${calories} Kcal (C ${carbs}g, F ${fat}g, P ${protein}g)`;
    }

    parseExpiryDate(expiryDate: string) {
        if (!expiryDate) return '';


        const dateParts = expiryDate.split('-');

        return {
            year: +dateParts[0],
            month: +dateParts[1],
            day: +dateParts[2].split('T')[0],
        };
    }

    openModal(content) {
        this.imageChangedEvent = '';
        this.croppedImage = '';

        this.modalService
            .open(content, { ariaLabelledBy: 'modal-basic-title' })
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    return reason;
                }
            );
    }

    closeModal() {
        this.modalService.dismissAll();
        this.enableCropper = false;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.finalFile = base64ToFile(this.croppedImage);
        this.enableCropper = true;
    }

    cropperReady(sourceImageDimensions: Dimensions) {
    }

    imageLoaded(image: HTMLImageElement) {
    }

    fileChangeEvent(event: any) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.image = reader.result as string;
            };
            this.file = event.target.files[0];
            reader.readAsDataURL(event.target.files[0]);
            this.imageChangedEvent = event;
        }
    }

    async saveImage() {
        const fd = new FormData();

        fd.append('file', this.finalFile);

        try {
            const res = await this.io.apicall(fd, 'uploads/meal-plan-img', 'POST');

            if (res['serverResponse'].code === 200) {
                this.image = res['result'][0].fileUrl;

                this.toastr.success('Image uploaded!');
                this.modalService.dismissAll();

                return res['result'][0].fileUrl;
            }
        } catch (err) {
            swal.fire({
                title: 'Oops...',
                text:
                    err?.error?.serverResponse?.message ||
                    'Something went wrong while uploading image',
                icon: 'warning',
                confirmButtonColor: '#442DFF;',
                confirmButtonText: 'ok',
            });
        }

        return null;
    }

    savePlanBtnDisabled() {
        if (!this.planName.trim().length) return true;

        if (+this.planDuration < 1) return true;

        if (!this.planTarget.trim().length) return true;

        if (!this.planPrice.trim().length) return true;

        if (!this.image.trim().length) return true;

        let numberOfMeals = 0;
        let numberOfMenuProductsShowInSummary = 0;
        let validationFailed = false;

        this.mealDays.forEach((day) => {
            if (!day.meals.length) validationFailed = true;

            numberOfMeals += day.meals.length;

            day.meals.forEach((meal) => {
                if (!meal.name.trim().length || !meal.items.length) validationFailed = true;

                meal.items.forEach((item) => {
                    if (!item.id) validationFailed = true;

                    if (item.showInSummary) numberOfMenuProductsShowInSummary++;
                });
            });
        });

        if (validationFailed) return true;

        if (numberOfMeals < 4) return true;

        if (numberOfMenuProductsShowInSummary < 4) return true;

        return false;
    }

    async savePlan() {
        if (this.savePlanBtnDisabled()) return;

        let parsedExpiryDate = null;

        if (this.expiryDate) {
            parsedExpiryDate = `${this.expiryDate.year}-${this.expiryDate.month}-${this.expiryDate.day}`;
        }

        let description = null;

        if (this.planDescription.trim()) {
            description = this.planDescription.trim();
        }

        const summaryImages: string[] = [];

        const days = this.mealDays.map((day, index) => ({
            name: `Day ${index + 1}`,
            meals: day.meals.map((meal) => ({
                name: meal.name,
                items: meal.items.map((item) => {
                    if (item.showInSummary) summaryImages.push(item.image);

                    return {
                        id: item.id,
                        type: item.type,
                    };
                }),
            })),
        }));

        const payload = {
            name: this.planName.trim(),
            duration: +this.planDuration,
            expiryDate: parsedExpiryDate,
            target: this.planTarget.trim(),
            price: this.planPrice.trim(),
            description,
            image: this.image.trim(),
            days,
            summaryImages,
        };

        const route = `meal-plans/portal/${this.editPlanId ? `update/${this.editPlanId}` : 'create'}`;

        try {
            await this.io.apicall(payload, route, 'POST');

            this.toastr.success('Plan saved!');

            this.router.navigate(['/meal-plan/list']);
        } catch {
            swal.fire({
                title: 'Oops...',
                text: 'Failed to save plan, please try again later!',
                icon: 'warning',
                confirmButtonColor: '#442DFF;',
                confirmButtonText: 'ok',
            });
        }

    }

    async openSwitchIsActiveModal() {
        const result = await swal.fire({
            title: `Are you sure you want to ${this.isActive ? 'deactivate' : 'activate'} this meal plan?`,
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
        });

        if (result.isConfirmed) {
            this.switchIsActive();
        }
    }

    async switchIsActive() {
        const route = `meal-plans/portal/${this.isActive ? 'deactivate' : 'activate'}/${this.editPlanId}`;
        const successToasterMsg = `Plan ${this.isActive ? 'deactivated' : 'activated'} successfully!`;

        try {
            await this.io.apicall(null, route, 'POST');

            this.prefillForm();

            this.toastr.success(successToasterMsg);
        } catch {
            swal.fire({
                title: 'Oops...',
                text: `Failed to ${this.isActive ? 'deactivate' : 'activate'} plan, please try again later!`,
                icon: 'warning',
                confirmButtonColor: '#442DFF;',
                confirmButtonText: 'ok',
            });
        }
    }

    async openSwitchIsPublishedModal() {
        const result = await swal.fire({
            title: `Are you sure you want to ${this.isPublished ? 'unpublish' : 'publish'} this meal plan?`,
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
        });

        if (result.isConfirmed) {
            this.switchIsPublished();
        }
    }

    async switchIsPublished() {
        const route = `meal-plans/portal/${this.isPublished ? 'unpublish' : 'publish'}/${this.editPlanId}`;
        const successToasterMsg = `Plan ${this.isPublished ? 'unpublished' : 'published'} successfully!`;

        try {
            await this.io.apicall(null, route, 'POST');

            this.prefillForm();

            this.toastr.success(successToasterMsg);
        } catch {
            swal.fire({
                title: 'Oops...',
                text: `Failed to ${this.isPublished ? 'unpublish' : 'publish'} plan, please try again later!`,
                icon: 'warning',
                confirmButtonColor: '#442DFF;',
                confirmButtonText: 'ok',
            });
        }
    }

    addDay() {
        this.mealDays.push({
            meals: [],
        });
    }

    removeDay(index: number) {
        this.mealDays.splice(index, 1);
    }

    isAddDaysDisabled() {
        return false;
    }
}
