import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthguardService } from "./guards/authguard.service";
import { MessagesComponent } from "./components/messages/messages.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren:
          "./layouts/admin-layout/admin-layout.module#AdminLayoutModule",
        canActivate: [AuthguardService],
      },
    ],
  },
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "unauthorize-access",
    component: MessagesComponent,
  },
  {
    path: "recipe-provider",
    loadChildren: () =>
      import("./recipe-provider/recipe-provider.module").then(
        (m) => m.RecipeProviderModule
      ),
    canActivate: [AuthguardService],
  },
  {
    path: "recipe",
    loadChildren: () =>
      import("./recipe/recipe.module").then((m) => m.RecipeModule),
    canActivate: [AuthguardService],
  },
  {
    path: "",
    loadChildren: () =>
      import("./general/general.module").then((m) => m.GeneralModule),
    canActivate: [AuthguardService],
  },
  {
    path: "resturant",
    loadChildren: () =>
      import("./resturant/resturant.module").then((m) => m.ResturantModule),
    canActivate: [AuthguardService],
  },
  {
    path: "menu",
    loadChildren: () =>
      import("./menu/menu.module").then((m) => m.MenuModule),
    canActivate: [AuthguardService],
  },
  {
    path: "hero-banners",
    loadChildren: () =>
      import("./hero-banners/hero-banners.module").then((m) => m.HeroBannersModule),
    canActivate: [AuthguardService],
  },
  {
    path: "shortcut-banners",
    loadChildren: () =>
      import("./shortcut-banners/shortcut-banners.module").then((m) => m.ShortcutBannersModule),
    canActivate: [AuthguardService],
  },
  {
    path: "filters",
    loadChildren: () =>
      import("./filters/filters.module").then((m) => m.FiltersModule),
    canActivate: [AuthguardService],
  },
  {
    path: "meal-plan",
    loadChildren: () =>
      import("./meal-plan/meal-plan.module").then((m) => m.MealPlanModule),
    canActivate: [AuthguardService],
  },
  {
    path: "custom-menu",
    loadChildren: () =>
      import("./custom-menu/custom-menu.module").then((m) => m.CustomMenuModule),
    canActivate: [AuthguardService],
  },
  {
    path: "menu-food-science",
    loadChildren: () =>
      import("./menu-food-science/menu-food-science.module").then((m) => m.MenuFoodScienceModule),
    canActivate: [AuthguardService],
  },
  {
    path: "supply",
    loadChildren: () =>
      import("./supply-count/supply-count.module").then((m) => m.SupplyCountModule),
    canActivate: [AuthguardService],
  },
  {
    path: "notification",
    loadChildren: () =>
      import("./notification/notification.module").then((m) => m.NotificationModule),
    canActivate: [AuthguardService],
  },
  {
    path: "dish",
    loadChildren: () => import("./dish/dish.module").then((m) => m.DishModule),
    canActivate: [AuthguardService],
  },
  {
    path: "customer",
    loadChildren: () =>
      import("./customer/customer.module").then((m) => m.CustomerModule),
    canActivate: [AuthguardService],
  },
  {
    path: "marketing",
    loadChildren: () =>
      import("./marketing/marketing.module").then((m) => m.MarketingModule),
    canActivate: [AuthguardService],
  },
  {
    path: "order",
    loadChildren: () =>
      import("./order/order.module").then((m) => m.OrderModule),
    canActivate: [AuthguardService],
  },
  {
    path: "transaction",
    loadChildren: () =>
      import("./transaction/transaction.module").then(
        (m) => m.TransactionModule
      ),
    canActivate: [AuthguardService],
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./settings/settings.module").then((m) => m.SettingsModule),
    canActivate: [AuthguardService],
  },
  {
    path: "content-management",
    loadChildren: () =>
      import("./content-management/content-management.module").then(
        (m) => m.ContentManagementModule
      ),
    canActivate: [AuthguardService],
  },
  {
    path: "support",
    loadChildren: () =>
      import("./support/support.module").then((m) => m.SupportModule),
    canActivate: [AuthguardService],
  },
  {
    path: "user",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
    canActivate: [AuthguardService],
  },
  {
    path: "management",
    loadChildren: () =>
      import("./management/management.module").then((m) => m.ManagementModule),
    canActivate: [AuthguardService],
  },
  {
    path: "report",
    loadChildren: () =>
      import("./report/report.module").then((m) => m.ReportModule),
    canActivate: [AuthguardService],
  },
  {
    path: "category",
    loadChildren: () =>
      import("./category/category.module").then((m) => m.CategoryModule),
    canActivate: [AuthguardService],
  },
  {
    path: "dietary-preferences",
    loadChildren: () =>
      import("./dietary-preferences/dietary-preferences.module").then(
        (m) => m.DietaryPreferencesModule
      ),
    canActivate: [AuthguardService],
  },
  {
    path: "coupon",
    loadChildren: () =>
      import("./coupon/coupon.module").then((m) => m.CouponModule),
    canActivate: [AuthguardService],
  },
  {
    path: "restaurant-transaction",
    loadChildren: () =>
      import("./restaurant-transaction/restaurant-transaction.module").then(
        (m) => m.RestaurantTransactionModule
      ),
    canActivate: [AuthguardService],
  },
  {
    path: "recipe-transaction",
    loadChildren: () =>
      import("./recipe-transaction/recipe-transaction.module").then(
        (m) => m.RecipeTransactionModule
      ),
    canActivate: [AuthguardService],
  },
  {
    path: "**",
    redirectTo: "dashboard",
    canActivate: [AuthguardService],
  },
];

@NgModule({
  imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes)],
  exports: [],
})
export class AppRoutingModule {}
