import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FiltersComponent } from "./filters.component";
import { FiltersRoutingModule } from "./filters-routing.module";
import { FiltersSectionsComponent } from "./filters-sections/filters-sections.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SectionItemsComponent } from "./section-items/section-items.component";

@NgModule({
  declarations: [
    FiltersComponent,
    FiltersSectionsComponent,
    SectionItemsComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FiltersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
  ],
  providers: [],
})
export class FiltersModule {}
