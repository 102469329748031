import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerComponent } from './customer.component';
import { CustomerListComponent } from './index';

const routes: Routes = [
  { 
    path: '', 
    component: CustomerComponent,
    children: [

      {
        path: 'customer-list', 
        component: CustomerListComponent,
      }
    ] 
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule { }
