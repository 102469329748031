import { Injectable } from "@angular/core";
import { IoService } from '../../services/http/io.service';
import { SingleMenuProductResponse } from '../interfaces/single-menu-product-response.interface';

@Injectable({
  providedIn: "root",
})
export class MenuFetchProductService {
  constructor(private io: IoService) { }

  private productsCache: { [productId: number]: SingleMenuProductResponse } = {}

  async getProduct(productId: number) {
    const product = (await this.io.apicall(null, `menu/portal/product/${productId}`, 'GET')) as SingleMenuProductResponse;

    return product;
  }
}
