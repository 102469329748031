export const mapBannerResponseToBannerType = {
  'dish': 'dish',
  'recipe': 'recipe',
  'restaurant': 'restaurant',
  'creator': 'recipeCreator',
  'category': 'category',
  'meal_plan': 'mealPlan',
  'static': 'static',
  'external_link': 'externalLink',
};
