import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../services/data-management/data.service';
import { IoService } from '../../services/http/io.service';
let closeResult = '';
import swal from 'sweetalert2';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-restaurant-transaction-payout-view-details',
  templateUrl: './restaurant-transaction-payout-view-details.component.html',
  styleUrls: ['./restaurant-transaction-payout-view-details.component.css']
})
export class RestaurantTransactionPayoutViewDetailsComponent implements OnInit, OnDestroy {
  transactionValue: any;
  restaurantPayoutId: any;
  page: any = 1;
  totalPages:any;
  enqueryList: boolean = false;
  earningsResult: any;
  output:any='dayOfYear';
  from: any;
  to: any;
  restaurantTransactionPayoutDetailsList: any = [];
  selected: {startDate: Moment, endDate: Moment};
  filteredTransactions: any = [];
  paramObserver: Subscription;
  constructor(private modalService: NgbModal,
    private router: Router,
    private io: IoService,
    private route: ActivatedRoute
    )
    {}

  ngOnInit(): void {
    this.paramObserver = this.route.params.subscribe(({id})=>{
      if(id) {
        this.restaurantPayoutId = id;
        this.allTransactionPayoutViewDetails();
        this.totalEarningsTransaction();
      }
      else {
        this.router.navigate(['/restaurant-transaction/restaurant-transaction-payout-list']);
      }
    })
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  open(content) {
    this.transactionValue = '';
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      closeResult = `Closed with: ${result}`;
    }, (reason) => {
      return reason;
    });
  }
  change(e) {
    this.to = {"day":this.selected?.endDate.format('DD'),"month":parseInt(this.selected?.endDate.format('MM'))-1,"year":this.selected?.endDate.format('YYYY')};
    this.from = {"day":this.selected?.startDate.format('DD'),"month":parseInt(this.selected?.startDate.format('MM'))-1,"year":this.selected?.startDate.format('YYYY')}
    this.filterTransactionPayout()
  }
  changeFilterType(event) {
    this.output = event.target?.value;
    this.filterTransactionPayout()
  }
  totalEarningsTransaction() {
    let payload = {
      restaurantId: this.restaurantPayoutId,
    }
    this.io.apicall(payload, 'restaurant/restaurant-view', 'POST').then((res: any) => {
      if (res['serverResponse'].code === 200) {
        this.earningsResult = res['result'][0];
      }
    })
      .catch(err => {
        throw err;
      })
  }

  allTransactionPayoutViewDetails() {
    this.restaurantTransactionPayoutDetailsList = [];
    let payload = {
      page: this.page,
      limit: 5,
      restaurantId: this.restaurantPayoutId,
    }
    this.io.apicall(payload, 'transaction/restaurant-payout-transaction-list', 'POST').then((res: any) => {
      if (res['serverResponse'].code === 200) {
        const result = res['result'].transactionList;
        //this.enqueryList = false;
        this.totalPages = res['result'].totalRecords;
        this.restaurantTransactionPayoutDetailsList = result;
      }
    })
      .catch(err => {
        throw err;
      })
  }

  
  pageChanged(event) {
    if (event >= 1) {
      this.allTransactionPayoutViewDetails();
    }
  }


  payToRestaurantCreator() {
    let payload = {
      restaurantId: this.restaurantPayoutId,
      transactionAmount: this.transactionValue
    }
    this.io
      .apicall(payload, "transaction/pay-to-restaurant-account", "POST")
      .then((res) => {
        if (this.io.data_.serverResponse.code == 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.totalEarningsTransaction();
          this.allTransactionPayoutViewDetails();
          this.modalService.dismissAll();
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.modalService.dismissAll();
      });
  }
  filterTransactionPayout() {
     let payload =  {
          "restaurantId":this.restaurantPayoutId,
          "from":this.from,
          "to":this.to,
          "output":this.output // ['year', 'month', 'week', 'dayOfYear']
          }
     this.io
       .apicall(payload, "transaction/restaurant-transaction-list", "POST")
       .then((res: any) => {
         if (res['serverResponse'].code === 200) {
           const result = res['result'];
          
           this.filteredTransactions = result;
         }
       })
       .catch((err) => {
         throw err;
       });
   }
}
