import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-item-modal",
  templateUrl: "./item-modal.component.html",
  styleUrls: ["./item-modal.component.css"],
})
export class ItemModalComponent implements OnInit {
  FORM_KEYS: {
    TITLE: string;
    IMAGE: string;
    DESCRIPTION: string;
  };
  form: FormGroup;
  @Input() title: string;
  @Input() description: string;
  @Input() image: string;
  @Input() formType: string;
  @Output() onModalSave = new EventEmitter();
  imageChangedEvent: any = "";

  constructor(
    private FormBuilder: FormBuilder,
    public activeModal: NgbActiveModal
  ) {
    this.FORM_KEYS = {
      TITLE: "title",
      IMAGE: "image",
      DESCRIPTION: "description",
    };
  }

  ngOnInit(): void {
    this.buildForm(
      this.formType == "edit" ? this.title : "",
      this.image || "",
      this.description || ""
    );
  }

  buildForm(title, image, description): void {
    this.form = this.FormBuilder.group({
      [this.FORM_KEYS.TITLE]: [
        title,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.IMAGE]: [image],
      [this.FORM_KEYS.DESCRIPTION]: [description],
    });
  }
  handleImageUpload(event): void {
    this.imageChangedEvent = event;
  }
  handleSave(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.onModalSave.emit({
      title: this.form.get(this.FORM_KEYS.TITLE).value,
      image: this.form.get(this.FORM_KEYS.IMAGE).value,
      description: this.form.get(this.FORM_KEYS.DESCRIPTION).value,
    });
  }

  handleImageCropped(event: ImageCroppedEvent): void {
    this.form.get(this.FORM_KEYS.IMAGE).patchValue(event.base64);
  }
}
