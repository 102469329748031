import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ManagementComponent } from "./management.component";
import { TagManagementComponent } from "./tag-management/tag-management.component";
import { CategoryComponent } from "./category/category.component";

const routes: Routes = [
  {
    path: "",
    component: ManagementComponent,
    children: [
      {
        path: "category",
        component: CategoryComponent,
      },
      {
        path: "tag-management",
        component: TagManagementComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagementRoutingModule {}
