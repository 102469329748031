import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { RecipeProviderComponent } from "./recipe-provider.component";
import {
  AddOrRemoveRecipeProviderComponent,
  RecipeProviderListComponent,
  RecipeProviderDetailsComponent,
} from "./";

const routes: Routes = [
  {
    path: "",
    component: RecipeProviderComponent,
    children: [
      {
        path: "recipe-provider-action",
        component: AddOrRemoveRecipeProviderComponent,
      },
      {
        path: "recipe-provider-list",
        component: RecipeProviderListComponent,
      },
      {
        path: "recipe-provider-details",
        component: RecipeProviderDetailsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RecipeProviderRoutingModule {}
