import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomMenuComponent } from './custom-menu.component';
import { CustomMenuSubmitComponent } from './custom-menu-submit/custom-menu-submit.component';

const routes: Routes = [
  {
    path: '',
    component: CustomMenuComponent,
    children: [
      {
        path: 'submit',
        component: CustomMenuSubmitComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomMenuRoutingModule { }
