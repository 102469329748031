import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShortcutBannersComponent } from './shortcut-banners.component';
import { ShortcutBannersListComponent } from './shortcut-banners-list/shortcut-banners-list.component';

const routes: Routes = [
  {
    path: '',
    component: ShortcutBannersComponent,
    children: [
      {
        path: 'list',
        component: ShortcutBannersListComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShortcutBannersRoutingModule { }
