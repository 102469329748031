import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Moment } from "moment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "../../services/data-management/data.service";
import { IoService } from "../../services/http/io.service";
import swal from "sweetalert2";

@Component({
  selector: "app-transaction-list",
  templateUrl: "./transaction-list.component.html",
  styleUrls: ["./transaction-list.component.css"],
})
export class TransactionListComponent implements OnInit, OnDestroy {
  transactionValue: any;

  page: any = 1;
  totalPages: any;
  enqueryList: boolean = false;
  earningsResult: any;
  output: any = "dayOfYear";
  from: any;
  to: any;
  resturantListForDropdown: any = [];
  resturantName: any = "";
  restaurantTransactionPayoutDetailsList: any = [];
  selected: { startDate: Moment; endDate: Moment };
  filteredTransactions: any = [];
  restId: any = "";
  paramObserver: any;
  constructor(
    private modalService: NgbModal,
    private data: DataService,
    private router: Router,
    private io: IoService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let paramPage = values.page;
      let paramRestaurant = values.restaurant;
      let paramFilter = values.filter;
      if (paramPage) {
        this.page = paramPage;
      } else {
        this.page = 1;
      }
      if (paramRestaurant) {
        this.restId = paramRestaurant;
      }
      if (paramFilter) {
        this.output = paramFilter;
      }
      this.resturantList();
      this.filterTransactionPayout();
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  handleParams(page): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page,
        restaurant: this.restId ? this.restId : null,
        filter: this.output ? this.output : null,
      },
      queryParamsHandling: "merge",
    });
  }

  change(e) {
    this.to = {
      day: this.selected?.endDate.format("DD"),
      month: parseInt(this.selected?.endDate.format("MM")) - 1,
      year: this.selected?.endDate.format("YYYY"),
    };
    this.from = {
      day: this.selected?.startDate.format("DD"),
      month: parseInt(this.selected?.startDate.format("MM")) - 1,
      year: this.selected?.startDate.format("YYYY"),
    };
    this.filterTransactionPayout();
  }
  changeFilterType(event) {
    this.output = event.target?.value;
    this.handleParams(this.page);
    this.filterTransactionPayout();
  }

  pageChanged(event) {
    this.handleParams(this.page);
    if (event >= 1) {
      this.filterTransactionPayout();
    }
  }

  filterTransactionPayout() {
    let payload = {
      from: this.from ? this.from : "",
      to: this.to ? this.to : "",
      output: this.output ? this.output : "", // ['year', 'month', 'week', 'dayOfYear']
    };
    if (this.restId) {
      payload["restaurantId"] = this.restId;
    }

    this.io
      .apicall(payload, "transaction/restaurant-transaction-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];

          this.filteredTransactions = result;
        }
      })
      .catch((err) => {
        throw err;
      });
  }
  resturantList() {
    this.io
      .apicall({}, "restaurant/get-restaurant-list-for-dropdown", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.resturantListForDropdown = res["result"];
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  chooseResturant(event) {
    this.page = 1;
    this.resturantName = event.target.options[event.target.selectedIndex].text;
    this.restId = event.target.value;
    this.handleParams(this.page);
    this.filteredTransactions = [];
    this.filterTransactionPayout();
  }
}
