import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";

import { CouponRoutingModule } from "./coupon-routing.module";
import { CouponComponent } from "./coupon.component";
import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CouponListComponent } from "./coupon-list/coupon-list.component";
import { CouponDetailsComponent } from "./coupon-details/coupon-details.component";
import { AddCouponComponent } from "./add-coupon/add-coupon.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

@NgModule({
  declarations: [
    CouponComponent,
    CouponListComponent,
    CouponDetailsComponent,
    AddCouponComponent,
  ],
  imports: [
    CommonModule,
    CouponRoutingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [DatePipe],
})
export class CouponModule {}
