import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecipeTransactionRoutingModule } from './recipe-transaction-routing.module';
import { RecipeTransactionComponent } from './recipe-transaction.component';
import { RecipeTransactionPayoutListComponent } from './recipe-transaction-payout-list/recipe-transaction-payout-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { RecipeTransactionPayoutViewDetailsComponent } from './recipe-transaction-payout-view-details/recipe-transaction-payout-view-details.component';


@NgModule({
  declarations: [RecipeTransactionComponent, RecipeTransactionPayoutListComponent, RecipeTransactionPayoutViewDetailsComponent],
  imports: [
    CommonModule,
    RecipeTransactionRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
  ]
})
export class RecipeTransactionModule { }
