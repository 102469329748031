import { Injectable } from '@angular/core';
 declare const Pusher: any;
//import Pusher from 'pusher-js';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class PusherServiceService {
  pusher: any;
  channel: any;
  channelName:string = environment.pusher.channel;
  constructor(private http:HttpClient) {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true
    });
    this.channel = this.pusher.subscribe(environment.pusher.channel);
  }

}
