import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { IoService } from "../../services/http/io.service";
import { GetAppMessagesResponse } from './interfaces/get-app-messages-response.interface';
import { AppMessage } from './interfaces/app-message.interface';

@Component({
  selector: 'app-app-messages',
  templateUrl: './app-messages.component.html',
  styleUrls: ['./app-messages.component.css']
})
export class AppMessagesComponent implements OnInit {
  loading: boolean = true;
  error: boolean = false;
  appMessages: AppMessage[];
  editId: number = -1;
  editMessageText: string = '';
  submittingEditedMessage: boolean = false;

  constructor(
    private io: IoService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAppMessages();
  }

  async getAppMessages() {
    this.loading = true;
    this.error = false;

    try {
      const response = await this.io.getRequest('app-settings/app-messages') as GetAppMessagesResponse;

      this.appMessages = response.appMessages;
    }
    catch (err) {
      this.error = true;
    }

    this.loading = false;
  }

  openEditMessage(appMessage: AppMessage) {
    this.editId = appMessage.id;
    this.editMessageText = appMessage.message;
  }

  cancelEdit() {
    this.editId = -1;
    this.editMessageText = '';
  }

  async submitEditMessage(appMessage: AppMessage) {
    this.submittingEditedMessage = true;

    const editedMessage = this.editMessageText.trim();

    try {
      await this.io.patchRequest(`app-settings/app-messages/${appMessage.id}`, { message: editedMessage });

      appMessage.message = editedMessage;

      this.editId = -1;
      this.editMessageText = '';
    }
    catch(err) {
      this.toastr.error('Sorry, something went wrong!');
    }

    this.submittingEditedMessage = false;
  }
}
