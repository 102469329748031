import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from "../../services/data-management/data.service";
import { IoService } from "../../services/http/io.service";

@Component({
  selector: "app-restaurant-transaction-payout-list",
  templateUrl: "./restaurant-transaction-payout-list.component.html",
  styleUrls: ["./restaurant-transaction-payout-list.component.css"],
})
export class RestaurantTransactionPayoutListComponent
  implements OnInit, OnDestroy
{
  queryList: boolean = false;
  activePage: number = 1;
  itemsPerPage: number = 50;
  activeTotal: number;
  activeRestaurantList: any = [];
  isSearch: boolean = false;
  searchResult: boolean = false;
  resturantName: any;
  paramObserver: any;
  constructor(
    private io: IoService,
    private router: Router,
    private data: DataService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let paramPage = values.page;
      let paramSearch = values.search;
      if (paramPage) {
        this.activePage = paramPage;
      } else {
        this.activePage = 1;
      }
      if (paramSearch) {
        this.searchResult = true;
        this.resturantName = paramSearch;
      }
      this.getActiveRestaurantList();
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  handleParams(page): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page,
        search: this.resturantName ? this.resturantName : null,
      },
      queryParamsHandling: "merge",
    });
  }

  //ACTIVE RECIPE LIST //
  getActiveRestaurantList() {
    this.activeRestaurantList = [];
    let payload = {
      page: this.activePage,
      limit: this.itemsPerPage,
      searchKey: this.resturantName,
      orderName: "restaurantName",
      orderType: 1,
    };
    this.io
      .apicall(payload, "restaurant/approved-restaurant-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.queryList = false;
          this.activeTotal = res["result"].totalRecords;
          this.activeRestaurantList = res["result"].restaurantList;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  searchApprovedResurants() {
    this.searchResult = true;
    this.activePage = 1;
    this.handleParams(this.activePage);
    let payload = {
      page: this.activePage,
      limit: this.itemsPerPage,
      searchKey: this.resturantName,
      orderName: "restaurantName",
      orderType: 1,
    };
    this.io
      .apicall(payload, "restaurant/approved-restaurant-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.queryList = false;
          this.activeTotal = res["result"].totalRecords;
          this.activeRestaurantList = res["result"].restaurantList;
          if (this.activeRestaurantList.length === 0) {
            this.getActiveRestaurantList();
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }
  clearApprovedResturantList() {
    // this.isSearch = false;
    this.searchResult = false;
    this.resturantName = "";
    this.activePage = 1;
    this.handleParams(this.activePage);
    this.getActiveRestaurantList();
  }
  //PAGE CHANGED FOR ACTIVE RECIPE LIST //
  pageChanged(event) {
    this.handleParams(this.activePage);
    if (event >= 1) {
      this.getActiveRestaurantList();
    }
  }
}
