import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgmCoreModule } from '@agm/core';
import { NgxPaginationModule } from "ngx-pagination";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ImageCropperModule } from "ngx-image-cropper";
import { TimeRangeModule } from "./../time-range/time-range.module";
import { NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { TabNavigatorModule } from "./../tab-navigator/tab-navigator.module";
import { CustomMenuRoutingModule } from './custom-menu-routing.module';
import { CustomMenuComponent } from './custom-menu.component';
import { CustomMenuSubmitComponent } from './custom-menu-submit/custom-menu-submit.component';


@NgModule({
  declarations: [
    CustomMenuComponent,
    CustomMenuSubmitComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMenuRoutingModule,
    NgxPaginationModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule,
    TimeRangeModule,
    NgbTimepickerModule,
    TabNavigatorModule,
    AgmCoreModule,
  ],
  providers: [],
})
export class CustomMenuModule { }
