import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GeneralComponent } from './general.component';
import { CommingSoonComponent } from './';

const routes: Routes = [
  { 
    path: '', 
    component: GeneralComponent,
    children: [
      {
        path:'coming-soon',
        component: CommingSoonComponent
      }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeneralRoutingModule { }
