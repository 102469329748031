import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from "../../_constant/constant";

@Injectable({
  providedIn: "root",
})
export class CategoryManagementService {
  constructor(private http: HttpClient) {}

  listCategories(queryParams): Observable<any> {
    return this.http.get(routes.listCategories,{params: {...queryParams}});
  }

  addCategory(payload): Observable<any> {
    return this.http.post(routes.addCategory, { ...payload });
  }

  editCategory(payload, id): Observable<any> {
    return this.http.put(routes.editCategory(id), { ...payload });
  }

  deleteCategory(id: string): Observable<any> {
    return this.http.delete(routes.deleteCategory(id));
  }

  getCategory(id: string): Observable<any> {
    return this.http.get(routes.getCategory(id));
  }
}
