import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from "../../_constant/constant";

@Injectable({
  providedIn: "root",
})
export class TagManagementService {
  constructor(private http: HttpClient) {}

  listCategories(): Observable<any> {
    return this.http.post(routes.getTagCategories, {});
  }

  listTagsAndDietPreferences(): Observable<any> {
    return this.http.get(routes.listAllTagsAndPreferences)
  }

  getCategoryOptions(id): Observable<any> {
    return this.http.post(routes.getTagOptions(), { tagHeadId: id });
  }

  editCategory(payload): Observable<any> {
    return this.http.patch(routes.editTagCategory, payload);
  }

  addNewItem(payload): Observable<any> {
    return this.http.post(routes.addTagItem, payload);
  }

  editItem(categoryId, payload): Observable<any> {
    return this.http.patch(routes.editTagItem(categoryId), payload);
  }

  deleteItem(id): Observable<any> {
    return this.http.delete(routes.deleteTagItem(id));
  }
}
