import { Component, Input } from '@angular/core';
import { MealPlanCreatorState } from '../interfaces/creator-state.interface';

@Component({
    selector: 'app-meal-plan-creator-step-one',
    templateUrl: './meal-plan-creator-step-one.component.html',
    styleUrls: ['./meal-plan-creator-step-one.component.css']
})
export class MealPlanCreatorStepOneComponent {
    @Input() state: MealPlanCreatorState;
}
