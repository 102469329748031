import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { IoService } from '../../../../services/http/io.service';
import swal from 'sweetalert2';
import { ShortcutBannerData } from '../../../interfaces/shortcut-banner.interface';

@Component({
  selector: 'app-shortcut-section',
  templateUrl: './shortcut-section.component.html',
  styleUrls: ['./shortcut-section.component.css']
})
export class ShortcutSectionComponent implements OnInit {
  @Input() pageSectionId: number;
  @Input() maxBanners: number;
  @Input() bannerOptions: { id: number, name: string }[];

  bannerSections: ShortcutBannerData[] = [];

  constructor(private io: IoService) { }

  ngOnInit(): void {
    this.getPageSectionBanners();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageSectionId && !changes.pageSectionId.firstChange) {
      this.getPageSectionBanners();
    }
  }

  async getPageSectionBanners() {
    this.bannerSections = [];

    const res = (await this.io.apicall(null, `banner/list/${this.pageSectionId}`, 'GET')) as any;

    this.bannerSections = res.banners.map((banner) => ({
      id: banner.id,
      imgUrl: banner.imgUrl,
      destination: banner.destination,
    }));
  }

  addNewSection() {
    this.bannerSections.push({ imgUrl: null });
  }

  deleteSection(index: number) {
    this.bannerSections.splice(index, 1);
  }

  moveSectionUp(index: number) {
    const temp = this.bannerSections[index];
    this.bannerSections[index] = this.bannerSections[index - 1];
    this.bannerSections[index - 1] = temp;
  }

  moveSectionDown(index: number) {
    const temp = this.bannerSections[index];
    this.bannerSections[index] = this.bannerSections[index + 1];
    this.bannerSections[index + 1] = temp;
  }

  saveDisabled() {
    return this.bannerSections.some((section) => !section.imgUrl || !section.destination?.trim()) ||
           (![0, 4, 8].includes(this.bannerSections.length));
  }

  async saveBanners() {
    if (this.saveDisabled()) return;

    const body = {
      banners: this.bannerSections.map((banner) => ({
        id: banner.id,
        type: 'category',
        style: 'shortcut',
        imgUrl: banner.imgUrl,
        destination: banner.destination,
      })),
    };

    try {
      await this.io.apicall(body, `banner/save/${this.pageSectionId}`, 'POST');

      swal.fire({
        title: 'Success',
        text: 'Shortcut Banners saved!',
        icon: 'success',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    } catch {
      swal.fire({
        title: 'Oops...',
        text: 'Something went wrong!',
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    }
  }
}
