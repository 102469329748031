import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ShortcutBannersRoutingModule } from './shortcut-banners-routing.module';
import { ShortcutBannersComponent } from './shortcut-banners.component';
import { ShortcutBannersListComponent } from './shortcut-banners-list/shortcut-banners-list.component';
import { ShortcutSectionComponent } from './shortcut-banners-list/components/shortcut-section/shortcut-section.component';
import { ShortcutBannerComponent } from './shortcut-banners-list/components/shortcut-banner/shortcut-banner.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    ShortcutBannersComponent,
    ShortcutBannersListComponent,
    ShortcutSectionComponent,
    ShortcutBannerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShortcutBannersRoutingModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [],
})
export class ShortcutBannersModule { }
