import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BannerSectionData } from '../../../interfaces/banner-section-data.interface';
import { IoService } from '../../../../services/http/io.service';
import { mapBannerResponseToBannerType } from '../../../map/mapBannerResponseToBannerType.map';
import { mapBannerTypeToBannerResponse } from '../../../map/mapBannerTypeToBannerResponse.map';
import swal from 'sweetalert2';

@Component({
  selector: 'app-page-section-banners',
  templateUrl: './page-section-banners.component.html',
  styleUrls: ['./page-section-banners.component.css']
})
export class PageSectionBannersComponent implements OnInit, OnChanges {
  @Input() pageSectionId: number;

  bannerSections: BannerSectionData[] = [];

  constructor(private io: IoService) { }

  ngOnInit(): void {
    this.getPageSectionBanners();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageSectionId && !changes.pageSectionId.firstChange) {
      this.getPageSectionBanners();
    }
  }

  async getPageSectionBanners() {
    this.bannerSections = [];

    const res = (await this.io.apicall(null, `banner/list/${this.pageSectionId}`, 'GET')) as any;

    this.bannerSections = res.banners.map((banner) => ({
      id: banner.id,
      randomId: `${banner.id.toString()}_${Date.now().toString()}`,
      type: mapBannerResponseToBannerType[banner.type],
      imgUrl: banner.imgUrl,
      destination: banner.destination,
      secondaryImgUrl: banner.secondaryImgUrl,
    }));
  }

  addNewSection() {
    this.bannerSections.push({ type: 'dish', imgUrl: null, randomId: Date.now().toString() });
  }

  deleteSection(index: number) {
    this.bannerSections.splice(index, 1);
  }

  moveSectionUp(index: number) {
    const temp = this.bannerSections[index];
    this.bannerSections[index] = this.bannerSections[index - 1];
    this.bannerSections[index - 1] = temp;
  }

  moveSectionDown(index: number) {
    const temp = this.bannerSections[index];
    this.bannerSections[index] = this.bannerSections[index + 1];
    this.bannerSections[index + 1] = temp;
  }

  isValidUrl(urlString: string) {
    try {
      return Boolean(new URL(urlString));
    } catch {
      return false;
    }
  }

  saveDisabled() {
    return this.bannerSections.some((section) =>
      (!section.imgUrl) ||
      (section.type === 'mealPlan' && !section.secondaryImgUrl) ||
      (section.type !== 'static' && !section.destination?.trim()) ||
      (section.type === 'externalLink' && !this.isValidUrl(section.destination))
    );
  }

  async saveBanners() {
    if (this.saveDisabled()) return;

    const body = {
      banners: this.bannerSections.map((banner) => ({
        id: banner.id,
        type: mapBannerTypeToBannerResponse[banner.type],
        style: 'hero',
        imgUrl: banner.imgUrl,
        destination: banner.destination,
        secondaryImgUrl: banner.type === 'mealPlan' ? banner.secondaryImgUrl : null,
      })),
    };

    try {
      await this.io.apicall(body, `banner/save/${this.pageSectionId}`, 'POST');

      swal.fire({
        title: 'Success',
        text: 'Banners saved!',
        icon: 'success',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    } catch {
      swal.fire({
        title: 'Oops...',
        text: 'Something went wrong!',
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    }
  }
}
