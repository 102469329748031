import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-food-science',
  templateUrl: './menu-food-science.component.html',
  styleUrls: ['./menu-food-science.component.css']
})
export class MenuFoodScienceComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
