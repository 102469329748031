import { DataService } from "../../services/data-management/data.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IoService } from "../../services/http/io.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import swal from "sweetalert2";
import { CategoryManagementService } from "./../../services/category-management/category-management.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
let closeResult = "";

@Component({
  selector: "app-category-list",
  templateUrl: "./category-list.component.html",
  styleUrls: ["./category-list.component.css"],
})
export class CategoryListComponent implements OnInit, OnDestroy {
  page: number = 1;
  categoryList: any = [];
  categoryDetails: any = {};
  categoryArray: any = [];
  total: number;
  categoryDropdown: any = null;
  itemsPerPage: number = 50;
  paramObserver: any;
  constructor(
    private modalService: NgbModal,
    private setCategoryId: DataService,
    private router: Router,
    private io: IoService,
    private route: ActivatedRoute,
    private categoryManagementService: CategoryManagementService,
    private loader: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let currentPage = values.page;
      let currentFilter = values.filter;
      if (currentPage) {
        this.page = currentPage;
      } else {
        this.page = 1;
      }
      if (currentFilter) {
        this.categoryDropdown = currentFilter;
      }
      this.loadCategoryType();
      this.fetchCategoryList();
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  handleParams(page, filter): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page,
        filter: filter ? filter : null,
      },
      queryParamsHandling: "merge",
    });
  }

  openModal(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  loadCategoryType() {
    this.categoryArray = [
      { id: 0, categoryType: "recipe" },
      { id: 1, categoryType: "dish" },
      { id: 2, categoryType: "restaurant" },
    ];
  }

  fetchCategoryList() {
    this.loader.start();
    let params: any = {
      page: this.page,
      limit: this.itemsPerPage,
    };
    if (this.categoryDropdown) {
      params = { ...params, filter: this.categoryDropdown };
    }
    this.categoryManagementService.listCategories(params).subscribe(
      (res) => {
        this.total = res.totalResults;
        this.categoryList = res.results;
      },
      () => {},
      () => {
        this.loader.stop();
      }
    );
  }

  pageChanged(event) {
    this.handleParams(event, this.categoryDropdown);
  }

  catchRecipe(Obj) {
    this.categoryDetails = Obj;
  }

  deleteCategory(id: string) {
    this.categoryManagementService.deleteCategory(id).subscribe(
      () => {
        swal.fire({
          title: "Success",
          text: "Category deleted successfully",
          icon: "success",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "Ok",
        });
        this.modalService.dismissAll();
        this.page = 1;
        this.categoryList = [];
        this.fetchCategoryList();
      },
      (err) => {
        swal.fire({
          title: "Oops...",
          text: err?.error["serverResponse"]?.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }

  categoryType(event) {
    this.categoryDropdown = event.target.value;
    this.categoryList = [];
    this.page = 1;
    this.handleParams(this.page, event.target.value);
  }
}
