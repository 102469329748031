import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgmCoreModule } from '@agm/core';
import { NgxPaginationModule } from "ngx-pagination";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ImageCropperModule } from "ngx-image-cropper";
import { TimeRangeModule } from "./../time-range/time-range.module";
import { TimeRangeComponent } from "./../time-range/time-range.component";
import { NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { TabNavigatorModule } from "./../tab-navigator/tab-navigator.module";
import { BranchComponent } from './branch.component';
import { BranchListComponent } from './branch-list/branch-list.component';
import { BranchRoutingModule } from './branch-routing.module';
import { RestaurantBranchesComponent } from './branch-list/components/restaurant-branches/restaurant-branches.component';

@NgModule({
  declarations: [
    BranchComponent,
    BranchListComponent,
    RestaurantBranchesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BranchRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule,
    TimeRangeModule,
    NgbTimepickerModule,
    TabNavigatorModule,
    AgmCoreModule,
  ],
  providers: [],
})
export class BranchModule { }
