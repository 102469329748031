import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SupportRoutingModule } from "./support-routing.module";
import { SupportComponent } from "./support.component";
import { SupportListComponent } from "./support-list/support-list.component";
import { SupportEditComponent } from "./support-edit/support-edit.component";
import { ReactiveFormsModule } from "@angular/forms";
import { SupportAddComponent } from "./support-add/support-add.component";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    SupportComponent,
    SupportListComponent,
    SupportEditComponent,
    SupportAddComponent,
  ],
  imports: [
    CommonModule,
    SupportRoutingModule,
    ReactiveFormsModule,
    NgbModalModule,
  ],
})
export class SupportModule {}
