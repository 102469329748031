import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { constant } from "../../_constant/constant";
import { DataService } from "../../services/data-management/data.service";
import { IoService } from "../../services/http/io.service";
import moment from "moment";

@Component({
  selector: "app-coupon-list",
  templateUrl: "./coupon-list.component.html",
  styleUrls: ["./coupon-list.component.css"],
})
export class CouponListComponent implements OnInit, OnDestroy {
  couponList: any = [];
  currency: any;
  totalPages: any;
  currentPage: number = 1;
  paramObserver: any;
  itemsPerPage: number = 50;
  constructor(
    private ioservice: IoService,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.currency = constant.currency_;
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let paramPage = values.page;
      if (paramPage) {
        this.currentPage = paramPage;
      } else {
        this.currentPage = 1;
      }
      this.restaurantCouponList({
        page: this.currentPage,
        limit: this.itemsPerPage,
        orderName: "couponCode",
        orderType: 1,
      });
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  handleParams(page): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page,
      },
      queryParamsHandling: "merge",
    });
  }

  // Coupons //
  restaurantCouponList(payLoad: any): void {
    this.ioservice
      .apicall(payLoad, "coupon/all-coupon-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.couponList = res["result"].couponList.results;
          this.totalPages = res["result"].couponList.totalResults;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  // PAGINATION //
  pageChanged(event) {
    this.handleParams(this.currentPage);
    if (this.currentPage >= 1 && this.currentPage < this.totalPages) {
      let payLoad = {
        page: this.currentPage,
        limit: this.itemsPerPage,
        orderName: "couponCode",
        orderType: 1,
      };
      this.restaurantCouponList(payLoad);
    }
  }

  isInFuture(startDate) {
    const currentDate = new Date();

    return moment(startDate).isAfter(moment(currentDate));
  }

  isExpirationExpired(experationDate) {
    let currentDate = new Date();

    return moment(experationDate).isBefore(moment(currentDate));
  }

  usageExceded(max, total) {
    if (total >= max) {
      return true;
    } else {
      return false;
    }
  }
}
