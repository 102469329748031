import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccordionTabModule } from "./../components/accordion-tab/accordion-tab.module";
import { DietaryPreferencesComponent } from "./dietary-preferences.component";

@NgModule({
  declarations: [DietaryPreferencesComponent],
  imports: [CommonModule, AccordionTabModule],
})
export class DietaryPreferencesModule {}
