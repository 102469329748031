import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supply-count',
  templateUrl: './supply-count.component.html',
  styleUrls: ['./supply-count.component.css']
})
export class SupplyCountComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
