import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OrderComponent } from './order.component';
import { OrderListComponent, OrderDetailsComponent } from './index';

const routes: Routes = [
  { 
    path: '', 
    component: OrderComponent,
    children: [
      {
        path: 'order-list', 
        component: OrderListComponent,
      },
      {
        path: 'order-details', 
        component: OrderDetailsComponent,
      }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderRoutingModule { }
