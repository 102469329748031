import { Component, OnChanges, Input } from '@angular/core';
import { IoService } from '../../../../services/http/io.service';
import { Menu } from '../../../interfaces/menu.interface';

@Component({
  selector: 'app-menu-food-science-menus',
  templateUrl: './menu-food-science-menus.component.html',
  styleUrls: ['./menu-food-science-menus.component.css'],
})
export class MenuFoodScienceMenusComponent implements OnChanges {
  @Input() restaurantId: number
  @Input() isDeliverect: boolean

  @Input() tags
  @Input() avoidancesId

  menus: Menu[]

  constructor(
    private io: IoService,
  ) { }

  ngOnChanges() {
    this.fetchMenus()
  }

  async fetchMenus() {
    this.menus = (await this.io.apicall(null, `menu/portal/restaurant/${this.restaurantId}`, 'GET')) as Menu[];
  }
}
