import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ItemModalComponent } from "./../item-modal/item-modal.component";

@Component({
  selector: "app-item-box",
  templateUrl: "./item-box.component.html",
  styleUrls: ["./item-box.component.css"],
})
export class ItemBoxComponent implements OnInit {
  @Input() title = "";
  @Input() id = "";
  @Input() index = "";
  @Input() description = "";
  @Input() image = "";
  @Input() editable = false;
  @Output() onItemEdit = new EventEmitter();
  @Output() onItemDelete = new EventEmitter();
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  openModal(): void {
    const modalRef = this.modalService.open(ItemModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.title = this.title;
    modalRef.componentInstance.description = this.description;
    modalRef.componentInstance.image = this.image;
    modalRef.componentInstance.formType = "edit";
    modalRef.componentInstance.onModalSave.subscribe((values) =>
      this.onItemEdit.emit({ ...values, id: this.id })
    );
  }

  openDeleteModal(content) {
    this.modalService.open(content, { centered: true });
  }

  handleItemDelete(): void {
    this.onItemDelete.emit(this.id);
  }
}
