import { Component, OnInit } from '@angular/core';
import { IoService } from '../../services/http/io.service';

@Component({
  selector: 'app-shortcut-banners-list',
  templateUrl: './shortcut-banners-list.component.html',
  styleUrls: ['./shortcut-banners-list.component.css']
})
export class ShortcutBannersListComponent implements OnInit {
  pageSections: { id: number, name: string, maxBanners: number }[] = [];
  bannerOptions: { id: number, name: string }[] = [];
  activePageSection: number = -1;

  constructor(private io: IoService) { }

  ngOnInit(): void {
    Promise.all([
      this.getBannerSections(),
      this.getBannerOptions(),
    ]).then(() => {
      if (this.pageSections.length > 0) this.activePageSection = 0;
    });
  }

  async getBannerSections() {
    const data = (await this.io.apicall(null, 'page-sections/shortcut-banner', 'GET')) as any;

    this.pageSections = data.sections.map((section) => ({
      id: section.id,
      name: section.name,
      maxBanners: section.displayType === '2x4' ? 8 : 4,
    }));
  }

  async getBannerOptions() {
    const data = (await this.io.apicall(null, 'page-sections/dishes-or-recipes', 'GET')) as any;

    this.bannerOptions = data.sections;
  }
}
