import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MealPlanRoutingModule } from "./meal-plan-routing.module";
import { MealPlanComponent } from "./meal-plan.component";
import { MealPlanListComponent } from "./meal-plan-list/meal-plan-list.component";
import { TabNavigatorModule } from "../tab-navigator/tab-navigator.module";
import { MealPlanFormComponent } from "./meal-plan-form/meal-plan-form.component";
import { doubleDecimalMealPlanDirective } from "../validators/double-decimal-point-meal-plan.directive";
import { NumberDirective } from "../validators/numbers-only-meal-plan.directive";
import { MealDaysGroupComponent } from "./meal-days-group/meal-days-group.component";
import { MealDayComponent } from "./meal-day/meal-day.component";
import { MealsGroupComponent } from "./meals-group/meals-group.component";
import { SingleMealComponent } from "./single-meal/single-meal.component";
import { MealItemComponent } from "./meal-item/meal-item.component";
import { MealPlanCreatorComponent } from "./meal-plan-creator/meal-plan-creator.component";
import { MealPlanCreatorStepOneComponent } from "./meal-plan-creator-step-one/meal-plan-creator-step-one.component";
import { MealPlanCreatorStepTwoComponent } from "./meal-plan-creator-step-two/meal-plan-creator-step-two.component";
import { MealPlanCreatorStepThreeComponent } from "./meal-plan-creator-step-three/meal-plan-creator-step-three.component";
import { MealPlanCreatorStepFourComponent } from "./meal-plan-creator-step-four/meal-plan-creator-step-four.component";
import { MealPlanCreatorStepFiveComponent } from "./meal-plan-creator-step-five/meal-plan-creator-step-five.component";
import { LocalCssModule } from "../utils/LocalCss/LocalCss.module";

@NgModule({
    declarations: [
        MealPlanComponent,
        MealPlanListComponent,
        MealPlanFormComponent,
        doubleDecimalMealPlanDirective,
        NumberDirective,
        MealDaysGroupComponent,
        MealDayComponent,
        MealsGroupComponent,
        SingleMealComponent,
        MealItemComponent,
        MealPlanCreatorComponent,
        MealPlanCreatorStepOneComponent,
        MealPlanCreatorStepTwoComponent,
        MealPlanCreatorStepThreeComponent,
        MealPlanCreatorStepFourComponent,
        MealPlanCreatorStepFiveComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ImageCropperModule,
        NgMultiSelectDropDownModule,
        MealPlanRoutingModule,
        TabNavigatorModule,
        NgbModule,
        LocalCssModule,
    ],
    providers: [],
})
export class MealPlanModule { }
