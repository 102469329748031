import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.css'],
})
export class LocationSearchComponent implements OnInit, OnDestroy {
  @Output() locationSelectedEvent = new EventEmitter();

  @ViewChild('mapContainer') mapContainer: ElementRef;
  placesService: any;

  searchText: string = '';
  loading: boolean = false;

  searchSubject = new Subject<string>();

  searchResults: { name: string, address: string, lat: number, lng: number }[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.initMap();

    this.searchSubject
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
      )
      .subscribe((searchValue) => this.searchForLocations(searchValue?.trim()));
  }

  ngOnDestroy() {
    this.searchSubject.complete();
  }

  initMap() {
    const script = document.getElementById('agmGoogleMapsApiScript');

    script.addEventListener('load', () => {
      const mapOptions = {
        center: { lat: 25.2048, lng: 55.2708 },
        zoom: 10,
      };

      const googleLib: any = (window as any).google;

      const map = new googleLib.maps.Map(this.mapContainer.nativeElement, mapOptions);
      this.placesService = new googleLib.maps.places.PlacesService(map);
    });
  }

  onSearch() {
    this.searchSubject.next(this.searchText);
  }

  async searchForLocations(searchValue: string) {
    if (!searchValue || searchValue.length < 3) {
      this.closeResultsDropdown();
      return;
    }

    const request = {
      query: searchValue,
      location: {
        lat: 25.2048,
        lng: 55.2708,
      },
    };

    this.loading = true;

    this.placesService.textSearch(request, (results, status) => this.updateData(results, status));
  }

  updateData(results, status) {
    const googleLib: any = (window as any).google;

    this.loading = false;

    if (status !== googleLib.maps.places.PlacesServiceStatus.OK) return;

    this.searchResults = results.map((entry) => ({
      name: entry.name,
      address: entry.formatted_address,
      lat: entry.geometry.location.lat(),
      lng: entry.geometry.location.lng(),
    }));

    this.changeDetectorRef.detectChanges();
  }

  closeResultsDropdown() {
    this.loading = false;
    this.searchResults = [];
  }

  locationSelected(loc) {
    this.closeResultsDropdown();
    this.searchText = loc.name;
    this.searchSubject.next('');

    this.locationSelectedEvent.emit({ lat: loc.lat, lng: loc.lng });
  }
}
