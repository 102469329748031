import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Component, OnInit, OnDestroy, ChangeDetectorRef, HostListener } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IoService } from "../../services/http/io.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  base64ToFile,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import swal from "sweetalert2";
import { DataService } from "../../services/data-management/data.service";
import { ToastrService } from "ngx-toastr";
import { DishService } from "./../../services/dish/dish.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { forkJoin, Observable, Subscription } from "rxjs";
import { DietaryPreferencesService } from "./../../services/dietary-preferences/dietary-preferences.service";
import { TagManagementService } from "./../../services/tag-management/tag-management.service";
import { ComponentCanDeactivate } from '../../guards/pending-changes.guard';
import Swal from "sweetalert2";

@Component({
  selector: "app-edit-dish",
  templateUrl: "./edit-dish.component.html",
  styleUrls: ["./edit-dish.component.css"],
})
export class EditDishComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  unsavedChanges = false;
  selectedItems2 = [];
  selectedItems3 = [];
  selectedItems4 = [];
  dropdownSettings: IDropdownSettings;
  editDishFrm: FormGroup;
  croppedImage: any = "";
  image: any = "";
  file: any;
  closeResult = "";
  imageChangedEvent: any = "";
  finalFile: any = "";
  enableCropper = false;
  showCropper = false;
  transform: ImageTransform = {};
  allHeadList: any = [];
  foodTypeHeadId: any = "";
  foodTypeList: any = [];
  mealTimeHeadId: any = "";
  mealTimeList: any = [];
  cuisineHeadId: any = "";
  cuisineList: any = [];
  mealTimeSelectUnselect = [];
  cuisineSelectUnselect = [];
  foodTypeSelectUnselect = [];
  allListedItem = [];
  isNewDishCheck: any;
  dishDetails: any;
  dishId: string;
  isDeliverect: boolean;
  showExternalLink: boolean = false;
  externalLink: string = null;
  discountArray: any = [];
  discountType_: any;
  selectedDiscountType: any = "flat";
  discount_: number = 0;
  dietaryPreferences: any = [];
  paramObserver: Subscription;
  finalAddTagArr: any = [];
  FORM_KEYS: {
    DISH_NAME: string;
    CARBS: string;
    PROTEIN: string;
    FAT: string;
    TOTAL_CALORIES: string;
    PRICE: string;
    NET_PRICE: string;
    NEW_DISH: string;
    DISH_DETAILS: string;
    DISCOUNT: string;
    DISCOUNT_TYPE: string;
    IS_SIDE_DISH: string;
  };
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private io: IoService,
    private router: Router,
    private route: ActivatedRoute,
    private data: DataService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private DishService: DishService,
    private loader: NgxUiLoaderService,
    private dietaryPreferencesService: DietaryPreferencesService,
    private tagManagementService: TagManagementService
  ) {
    this.FORM_KEYS = {
      DISH_NAME: "dishName",
      CARBS: "carbs",
      PROTEIN: "protein",
      FAT: "fat",
      TOTAL_CALORIES: "totalCalories",
      PRICE: "price",
      NET_PRICE: "netPrice",
      NEW_DISH: "newDish",
      DISH_DETAILS: "dishDetails",
      DISCOUNT: "discount",
      DISCOUNT_TYPE: "discountType",
      IS_SIDE_DISH: 'isSideDish',
    };
  }

  ngOnInit(): void {
    this.paramObserver = this.route.params.subscribe(({ id }) => {
      if (id) {
        this.dishId = id;
        this.loadDropDown();
        this.readyeditDishFrm();
        this.loadDiscountType();
        this.fetchAllHead();
      } else {
        this.router.navigate(["/dish/dish-list"]);
      }
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.unsavedChanges;
  }

  deleteDish(): void {
    this.loader.start();
    this.DishService.delete(this.dishId).subscribe(
      (res) => {
        swal.fire({
          title: "Success",
          text: "Deleted successfully",
          icon: "success",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.modalService.dismissAll();
        this.loader.stop();
        this.router.navigate(["/dish/dish-list"]);
      },
      (err) => {
        this.modalService.dismissAll();
        swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }

  loadDiscountType() {
    return this.discountArray.push(
      { id: 0, discountType: "flat" },
      { id: 1, discountType: "percentage" }
    );
  }

  discountTypeOnChange() {
    this.discount_ = this.editDishFrm.get(this.FORM_KEYS.DISCOUNT).value;
    let price = this.editDishFrm.get(this.FORM_KEYS.PRICE).value;
    let discount = this.editDishFrm.get(this.FORM_KEYS.DISCOUNT).value;
    let netPrice: any = 0;
    let currentDiscountType = this.editDishFrm.get(this.FORM_KEYS.DISCOUNT_TYPE)
      .value
      ? this.editDishFrm.get(this.FORM_KEYS.DISCOUNT_TYPE).value
      : this.selectedDiscountType;
    if (currentDiscountType === "flat") {
      netPrice = (price - discount).toFixed(2);
    } else if (currentDiscountType === "percentage") {
      netPrice = (price - (price * discount) / 100).toFixed(2);
    } else {
      netPrice = price;
    }
    this.editDishFrm.get(this.FORM_KEYS.NET_PRICE).setValue(netPrice);
  }

  open(content) {
    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  loadDropDown() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "tagId",
      textField: "tagName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 2,
      allowSearchFilter: true,
    };
  }

  readyeditDishFrm() {
    this.editDishFrm = this.fb.group({
      [this.FORM_KEYS.DISH_NAME]: ["", Validators.required],
      [this.FORM_KEYS.CARBS]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.PROTEIN]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.FAT]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.TOTAL_CALORIES]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.PRICE]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.NEW_DISH]: [
        false,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.DISH_DETAILS]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.DISCOUNT]: [""],
      [this.FORM_KEYS.DISCOUNT_TYPE]: [""],
      [this.FORM_KEYS.NET_PRICE]: [
        "",
        Validators.compose([Validators.required, Validators.min(2)]),
      ],
      [this.FORM_KEYS.IS_SIDE_DISH]: [false],
    });

    this.editDishFrm.valueChanges.subscribe(() => {
      this.unsavedChanges = true;
    })
  }

  loadDishDetails() {
    let payload = {
      dishId: this.dishId,
    };
    this.io
      .apicall(payload, "dish/dish-view", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          let storeResult = res["result"][0];
          this.isDeliverect = storeResult.channel === 'deliverect';
          this.showExternalLink = storeResult.restaurantNotOnMyeatpal;
          this.externalLink = storeResult.externalLink;
          this.discountType_ = storeResult.discountType;
          this.dishDetails = res["result"][0];
          // this.cd.detectChanges();
          this.isNewDishCheck = res["result"][0].IsNew;
          this.image = res["result"][0].discImage;
          this.editDishFrm
            .get(this.FORM_KEYS.DISH_NAME)
            .setValue(storeResult.dishName);
          //this.recipeName =  storeResult.recipeName;
          this.editDishFrm
            .get(this.FORM_KEYS.CARBS)
            .setValue(storeResult.carbs);
          this.editDishFrm
            .get(this.FORM_KEYS.PROTEIN)
            .setValue(storeResult.protein);
          this.editDishFrm.get(this.FORM_KEYS.FAT).setValue(storeResult.fat);
          this.editDishFrm
            .get(this.FORM_KEYS.TOTAL_CALORIES)
            .setValue(storeResult.totalCalories);
          this.editDishFrm
            .get(this.FORM_KEYS.PRICE)
            .setValue(storeResult.price);
          this.editDishFrm
            .get(this.FORM_KEYS.NEW_DISH)
            .setValue(storeResult.IsNew);
          this.editDishFrm
            .get(this.FORM_KEYS.DISCOUNT)
            .setValue(storeResult.discount);
          this.editDishFrm
            .get(this.FORM_KEYS.NET_PRICE)
            .setValue(
              storeResult.netPrice
                ? Number(storeResult.netPrice).toFixed(2)
                : storeResult.netPrice
            );
          this.editDishFrm
            .get(this.FORM_KEYS.DISCOUNT_TYPE)
            .setValue(storeResult.discountType);
          this.editDishFrm
            .get(this.FORM_KEYS.DISH_DETAILS)
            .setValue(storeResult.dishdetails);

          this.editDishFrm
            .get(this.FORM_KEYS.IS_SIDE_DISH)
            .setValue(storeResult.isSideDish);

          this.selectedItems2 = storeResult.headTagInfo[2].tags;
          this.selectedItems3 = storeResult.headTagInfo[3].tags;
          this.selectedItems4 = storeResult.headTagInfo[4].tags;

          for (const tags of storeResult.headTagInfo[2].tags) {
            this.mealTimeSelectUnselect.push({
              tagId: tags.tagId,
              tagName: tags.tagName,
            });
          }
          for (const tags of storeResult.headTagInfo[3].tags) {
            this.cuisineSelectUnselect.push({
              tagId: tags.tagId,
              tagName: tags.tagName,
            });
          }

          for (const tags of storeResult.headTagInfo[4].tags) {
            this.foodTypeSelectUnselect.push({
              tagId: tags.tagId,
              tagName: tags.tagName,
            });
          }

          for (const tags of storeResult.nutritionalInfo) {
            this.allListedItem.push({
              tagId: tags.tagId,
              tagName: tags.tagName,
            });
          }

          this.dietaryPreferences.forEach((item) => {
            if (storeResult?.dietaryPreferences[item?.id]) {
              let selection = storeResult?.dietaryPreferences[item?.id].map(
                (mapItem) => {
                  let searchRes = item.options?.find(
                    (searchItem) => searchItem.id == mapItem
                  );
                  if (searchRes) {
                    return { id: searchRes?.id, name: searchRes.name };
                  }
                }
              );
              this.editDishFrm.patchValue({ [item.name]: selection });
            }
          });

          this.unsavedChanges = false;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  //================================== 'section for dropdown' ======================================================================//

  fetchAllHead() {
    const tag_subscriber = this.tagManagementService.listCategories();
    const diet_subscriber = this.dietaryPreferencesService.listCategories();
    forkJoin({
      tagRes: tag_subscriber,
      dietRes: diet_subscriber,
    }).subscribe(async ({ tagRes, dietRes }) => {
      let categories_subscribers: any = {};
      if (tagRes["serverResponse"].code === 200) {
        this.allHeadList = tagRes["result"];
        for (var i = 0; i < this.allHeadList.length; i++) {
          if (this.allHeadList[i].tagHeadId === "H6") {
            this.headTagMealTime(this.allHeadList[i].id);
          } else if (this.allHeadList[i].tagHeadId === "H4") {
            this.headTagFoodType(this.allHeadList[i].id);
          } else if (this.allHeadList[i].tagHeadId === "H3") {
            this.headTagCusine(this.allHeadList[i].id);
          }
        }
      }
      this.dietaryPreferences = dietRes;
      this.dietaryPreferences.forEach((item) => {
        categories_subscribers[item?.id] =
          this.dietaryPreferencesService.getCategoryOptions(item.id);
        item.dropdownSettings = {
          ...this.dropdownSettings,
          singleSelection: !item.multi && !(item.name == 'Macronutrient Ratio'),
          textField: "name",
          idField: "id",
        };
        const diet_control = new FormControl([]);
        this.editDishFrm.addControl(item.name, diet_control);
      });
      forkJoin(categories_subscribers).subscribe((data) => {
        this.handleDietaryPreferencesCategories(data);
      });
    });
  }

  handleDietaryPreferencesCategories(data) {
    this.dietaryPreferences.forEach((item, index) => {
      item.options = data[item.id];
      if (item.selectionType == "exclude") {
        this.finalAddTagArr = this.finalAddTagArr.concat(
          item.options.map((mapItem) => {
            return {
              ...mapItem,
              tagName: mapItem.name + " Free",
              tagId: mapItem.id,
            };
          })
        );
        this.handleExcludePreference(item.name, index);
      }
    });

    this.loadDishDetails();
  }

  handleExcludePreference(controlName: string, index: number): void {
    const field_control = this.editDishFrm.get(controlName);
    field_control.valueChanges.subscribe((value) => {
      this.allListedItem = this.allListedItem.filter(
        (filteredItem) =>
          !value.find((searchItem) => searchItem?.tagid == filteredItem.tagId)
      );
      let options = [...this.dietaryPreferences[index].options];

      this.finalAddTagArr = options
        .filter(
          (filteredItem) =>
            !value.find((searchItem) => searchItem.id == filteredItem.id)
        )
        .map((item) => {
          return { ...item, tagName: item.name + " Free", tagId: item.id };
        });
    });
  }

  async headTagFoodType(Obj) {
    this.foodTypeHeadId = Obj;
    let value = await this.giveTagList(Obj);
    let myArr1 = [];
    for (let tags of value) {
      myArr1.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.foodTypeList = myArr1;
  }

  async headTagMealTime(Obj) {
    this.mealTimeHeadId = Obj;
    let value = await this.giveTagList(Obj);
    let myArr3 = [];
    for (let tags of value) {
      myArr3.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.mealTimeList = myArr3;
  }

  async headTagCusine(Obj) {
    this.cuisineHeadId = Obj;
    let value = await this.giveTagList(Obj);
    let myArr4 = [];
    for (let tags of value) {
      myArr4.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.cuisineList = myArr4;
  }

  async giveTagList(Obj): Promise<any> {
    return new Promise((resolve, reject) => {
      let payLoad = {
        tagHeadId: Obj,
      };
      this.io
        .apicall(payLoad, "tagcategories/fetch-tag-by-head", "POST")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            resolve(res["result"]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //=============================================' diet select dropdown ' ================================================================//

  //=============================================' start of meal time '==================================================================//
  onItemSelect2(item: any) {
    this.unsavedChanges = true;
    //meal time single item Select
    this.mealTimeSelectUnselect.push({
      tagId: item.tagId,
      tagName: item.tagName,
    });
  }

  onSelectAll2(item: any) {
    this.unsavedChanges = true;
    //meal time all item Select
    this.mealTimeSelectUnselect = [];
    for (let i = 0; i < item.length; i++) {
      this.mealTimeSelectUnselect.push({
        tagId: item[i].tagId,
        tagName: item[i].tagName,
      });
    }
  }

  onItemDeSelect2(event: any) {
    this.unsavedChanges = true;
    //meal time single item deSelect
    this.mealTimeSelectUnselect.splice(
      this.mealTimeSelectUnselect.findIndex(
        (tier) => tier.tagId === event.tagId
      ),
      1
    );
  }

  onDeSelectAll2(item: any) {
    this.unsavedChanges = true;
    //meal time all item deSelect
    this.mealTimeSelectUnselect = [];
  }
  //=============================================' end of meal time '====================================================================//

  //=============================================' start of cusine ' =====================================================================//
  onItemSelect3(item: any) {
    this.unsavedChanges = true;
    //cuisine single item Select
    this.cuisineSelectUnselect.push({
      tagId: item.tagId,
      tagName: item.tagName,
    });
  }

  onSelectAll3(item: any) {
    this.unsavedChanges = true;
    //cuisine all item Select
    this.cuisineSelectUnselect = [];
    for (let i = 0; i < item.length; i++) {
      this.cuisineSelectUnselect.push({
        tagId: item[i].tagId,
        tagName: item[i].tagName,
      });
    }
  }

  onItemDeSelect3(event: any) {
    this.unsavedChanges = true;
    //cuisine single item deSelect
    this.cuisineSelectUnselect.splice(
      this.cuisineSelectUnselect.findIndex(
        (tier) => tier.tagId === event.tagId
      ),
      1
    );
  }

  onDeSelectAll3(item: any) {
    this.unsavedChanges = true;
    //cuisine all item deSelect
    this.cuisineSelectUnselect = [];
  }
  //==============================================' end of cusine '========================================================================//

  //==============================================' start of food type ' ================================================================//
  onItemSelect4(item: any) {
    this.unsavedChanges = true;
    //food type single item Select
    this.foodTypeSelectUnselect.push({
      tagId: item.tagId,
      tagName: item.tagName,
    });
  }

  onSelectAll4(item: any) {
    this.unsavedChanges = true;
    //food type all item Select
    this.foodTypeSelectUnselect = [];
    for (let i = 0; i < item.length; i++) {
      this.foodTypeSelectUnselect.push({
        tagId: item[i].tagId,
        tagName: item[i].tagName,
      });
    }
  }

  onItemDeSelect4(event: any) {
    this.unsavedChanges = true;
    //food type single item deSelect
    this.foodTypeSelectUnselect.splice(
      this.foodTypeSelectUnselect.findIndex(
        (tier) => tier.tagId === event.tagId
      ),
      1
    );
  }

  onDeSelectAll4(item: any) {
    this.unsavedChanges = true;
    //food type all item deSelect
    this.foodTypeSelectUnselect = [];
  }
  //==============================================' end of food type '===================================================================//

  //==============================================' start of tags ' ================================================================//
  onItemSelect5(item: any) {
    this.unsavedChanges = true;
  }

  onSelectAll5(item: any) {
    this.unsavedChanges = true;
  }

  onItemDeSelect5(event: any) {
    this.unsavedChanges = true;
  }

  onDeSelectAll5(item: any) {
    this.unsavedChanges = true;
  }
  //==============================================' end of food type '===================================================================//

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
      this.image = this.imageChangedEvent.target.value;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }
  imageLoaded(image: HTMLImageElement) {
    this.showCropper = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  editDish(Obj) {
    if (!this.editDishFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.editDishFrm.markAllAsTouched();
      return;
    }
    let dietary_preferences = {};
    this.dietaryPreferences.forEach((item) => {
      dietary_preferences[item.id] = this.editDishFrm
        .get(item.name)
        .value.map((preference) => preference.id);
    });
    let payload = {
      dishId: this.dishId,
      restaurantId: this.dishDetails.restaurantId,
      dishName: this.editDishFrm.get(this.FORM_KEYS.DISH_NAME).value,
      discImage: this.image,
      carbs: this.editDishFrm.get(this.FORM_KEYS.CARBS).value,
      fat: this.editDishFrm.get(this.FORM_KEYS.FAT).value,
      protein: this.editDishFrm.get(this.FORM_KEYS.PROTEIN).value,
      totalCalories: this.editDishFrm.get(this.FORM_KEYS.TOTAL_CALORIES).value,
      nutritionalInfo: this.allListedItem,
      IsNew: this.isNewDishCheck,
      dishdetails: this.editDishFrm.get(this.FORM_KEYS.DISH_DETAILS).value,
      price: Obj.price,
      isSideDish: this.editDishFrm.get(this.FORM_KEYS.IS_SIDE_DISH).value,
      externalLink: this.externalLink?.trim() || null,
      discountType: this.editDishFrm.get(this.FORM_KEYS.DISCOUNT_TYPE).value
        ? this.editDishFrm.get(this.FORM_KEYS.DISCOUNT_TYPE).value
        : "flat",
      discount: this.editDishFrm.get(this.FORM_KEYS.DISCOUNT).value
        ? this.editDishFrm.get(this.FORM_KEYS.DISCOUNT).value
        : 0,
      netPrice: this.editDishFrm.get(this.FORM_KEYS.NET_PRICE).value,
      headTagInfo: [
        {
          headId: this.mealTimeHeadId,
          tags: this.mealTimeSelectUnselect,
        },
        {
          headId: this.cuisineHeadId,
          tags: this.cuisineSelectUnselect,
        },
        {
          headId: this.foodTypeHeadId,
          tags: this.foodTypeSelectUnselect,
        },
      ],
      dietaryPreferences: dietary_preferences,
    };
    this.io
      .apicall(payload, "dish/edit-dish", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          // this.router.navigate(["/dish/dish-list"]);
          this.loadDishDetails();
          this.unsavedChanges = false;
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  async savePublishAndActivate(Obj) {
    if (!this.editDishFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.editDishFrm.markAllAsTouched();
      return;
    }

    try {
      await this.io.apicall({ dishId: this.dishId }, 'dish/dish-publish-activate', 'POST');

      this.editDish(Obj);
    } catch {
      Swal.fire({
        title: 'Oops...',
        text: 'Something went wrong, please try again!',
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    }
  }

  saveImage() {
    const fd = new FormData();
    fd.append("file", this.finalFile);
    this.io.apicall(fd, "uploads/dish-img-upload", "POST").then((res: any) => {
      if (res["serverResponse"].code === 200) {
        this.image = res["result"][0].fileUrl;
        this.modalService.dismissAll();

        this.unsavedChanges = true;
      }
    });
  }

  publish() {
    let payload = {
      dishId: this.dishId,
    };
    this.io
      .apicall(payload, "dish/dish-publish-unpublish", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.dishDetails.isPublished = true;
          //this.loadDishDetails();
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  toggleActive(obj) {
    let payload;
    if (obj.isActive == true) {
      payload = {
        dishId: this.dishId,
        isActive: false,
      };
    } else {
      payload = {
        dishId: this.dishId,
        isActive: true,
      };
    }

    this.io
      .apicall(payload, "dish/change-dish-status", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.dishDetails.isActive = payload.isActive;
          //this.loadDishDetails();
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  closeModal() {
    this.modalService.dismissAll();
    // this.image = "";
    this.enableCropper = false;
  }
  save() {
    this.modalService.dismissAll();
  }

  checkedDish(event) {
    this.isNewDishCheck = event.target.checked;
  }
}
