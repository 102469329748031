import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IoService } from "../../services/http/io.service";
import { ToastrService } from 'ngx-toastr';
import { TagManagementService } from "./../../services/tag-management/tag-management.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-menu-food-science-list',
  templateUrl: './menu-food-science-list.component.html',
  styleUrls: ['./menu-food-science-list.component.css'],
})
export class MenuFoodScienceListComponent implements OnInit {
  restaurants: { id: number, restaurantName: string, channelLinkId: string, menuType: 'flat' | 'deliverect' | 'custom' }[] = []
  selectedRestaurantIndex: number = -1
  forcingMenuRecompilation: boolean = false;

  tags;
  avoidancesId;

  constructor(
    private io: IoService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private tagService: TagManagementService,
  ) { }

  ngOnInit(): void {
    this.fetchAllRestaurants()
    this.getTags();
  }

  async fetchAllRestaurants() {
    this.restaurants = ((await this.io.apicall({}, 'restaurant/get-restaurant-list-for-dropdown', 'POST')) as any).result;
  }

  async forceMenuRecompilation(restaurantId: number) {
    this.forcingMenuRecompilation = true;

    try {
      await this.io.postRequest(`menu/portal/refresh/${restaurantId}`);

      this.toastr.success('Menu Recompiled successfully!');

      const tmpStore = this.selectedRestaurantIndex;

      this.selectedRestaurantIndex = -1;

      this.cdr.detectChanges();

      this.selectedRestaurantIndex = tmpStore;
    }
    catch (err) {
      this.toastr.success('Sorry, something went wrong!');
    }

    this.forcingMenuRecompilation = false;
  }

  getTags(): void {
    this.tagService.listTagsAndDietPreferences().subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          this.tags = res.result;

          this.avoidancesId = res.result.find(
            (item) => item.name == "Avoidances"
          )?.id;
        }
      },
      () => {
        Swal.fire({
          title: "Oops...",
          text: "Something went wrong while getting tags!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }
}
