import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FoodScienceComponent } from "./food-science.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NumberDirective } from "./../../validators/numbers-only-food-science.directive";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FiltersModal } from './filters-modal/filters-modal.component';

@NgModule({
  declarations: [
    FoodScienceComponent,
    NumberDirective,
    FiltersModal,
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [FoodScienceComponent],
})
export class FoodScienceModule {};
