import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ItemModalComponent } from "../item-modal/item-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-accordion-tab",
  templateUrl: "./accordion-tab.component.html",
  styleUrls: ["./accordion-tab.component.css"],
})
export class AccordionTabComponent implements OnInit, OnChanges {
  @Input() collapsed: boolean;
  @Input() title: string = "";
  @Input() items;
  @Input() editable: boolean;
  @Input() multiSelect: boolean;
  @Input() canAddItem: boolean;
  @Input() allowEmpty: boolean;
  @Output() onCollapseChange = new EventEmitter();
  @Output() onTabEdit = new EventEmitter();
  @Output() onNewItemSave = new EventEmitter();
  @Output() onItemEdit = new EventEmitter();
  @Output() onItemDelete = new EventEmitter();
  editForm: FormGroup;
  FORM_KEYS: {
    TITLE: string;
    MULTI_SELECT: string;
    ALLOW_EMPTY: string;
    ITEMS: string;
  };

  constructor(
    private FormBuilder: FormBuilder,
    private modalService: NgbModal
  ) {
    this.FORM_KEYS = {
      TITLE: "title",
      MULTI_SELECT: "multiSelect",
      ALLOW_EMPTY: "allowEmpty",
      ITEMS: "items",
    };
  }

  ngOnInit(): void {
    this.buildForm();
    /* this.editForm
      .get(this.FORM_KEYS.TITLE)
      .valueChanges.subscribe((newTitle) => (this.title = newTitle)); */
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.editForm) {
      if (changes?.items != undefined) {
        this.editForm.patchValue({
          [this.FORM_KEYS.ITEMS]: changes?.items?.currentValue,
        });
      }
      if (changes?.title != undefined) {
        this.editForm.patchValue({
          [this.FORM_KEYS.TITLE]: changes?.title?.currentValue,
        });
      }
      if (changes?.allowEmpty != undefined) {
        this.editForm.patchValue({
          [this.FORM_KEYS.ALLOW_EMPTY]: changes?.allowEmpty?.currentValue,
        });
      }
      if (changes?.multiSelect != undefined) {
        this.editForm.patchValue({
          [this.FORM_KEYS.MULTI_SELECT]: changes?.multiSelect?.currentValue,
        });
      }
    }
  }

  buildForm(): void {
    this.editForm = this.FormBuilder.group({
      [this.FORM_KEYS.TITLE]: [
        this.title,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.MULTI_SELECT]: [
        this.multiSelect != undefined ? this.multiSelect : null,
      ],
      [this.FORM_KEYS.ALLOW_EMPTY]: [
        this.allowEmpty != undefined ? this.allowEmpty : null,
      ],
      [this.FORM_KEYS.ITEMS]: [this.items],
    });
  }

  onSubmit(): void {
    if (!this.editForm.valid) {
      return;
    }
    let newValues = {
      title: this.editForm.get(this.FORM_KEYS.TITLE).value,
      multiSelect: this.editForm.get(this.FORM_KEYS.MULTI_SELECT).value,
      allowEmpty: this.editForm.get(this.FORM_KEYS.ALLOW_EMPTY).value,
      items: this.editForm.get(this.FORM_KEYS.ITEMS).value,
    };
    this.onTabEdit.emit(newValues);
  }

  handleCollapse(): void {
    this.collapsed = !this.collapsed;
    this.onCollapseChange.emit(this.collapsed);
  }

  handleDragDrop(event: CdkDragDrop<number>) {
    let oldList = [...this.editForm.get(this.FORM_KEYS.ITEMS).value];
    moveItemInArray(
      oldList,
      event.previousContainer.data,
      event.container.data
    );
    this.editForm.get(this.FORM_KEYS.ITEMS).patchValue(oldList);
    this.editForm.markAsDirty();
  }

  openModal(): void {
    const modalRef = this.modalService.open(ItemModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.title = "Add item";
    modalRef.componentInstance.description = "";
    modalRef.componentInstance.image = "";
    modalRef.componentInstance.onModalSave.subscribe((values) =>
      this.onNewItemSave.emit(values)
    );
  }

  handleItemEdit(values): void {
    this.onItemEdit.emit(values);
  }

  handleItemDelete(id, title): void {
    this.onItemDelete.emit({ id, title });
  }
}
