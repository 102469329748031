import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IoService } from "../../../../services/http/io.service";
import { Branch } from "../../../interfaces/branch.interface";
import { Brand } from "../../../interfaces/brand.interface";
import swal from "sweetalert2";

@Component({
  selector: "app-restaurant-branches",
  templateUrl: "./restaurant-branches.component.html",
  styleUrls: ["./restaurant-branches.component.css"],
})
export class RestaurantBranchesComponent implements OnChanges {
  @Input() branches: Branch[];
  @Input() brands: Brand[];
  @Input() selectedBrandIndex: number;

  shownBranches: Branch[] = [];
  selectedBrandsIndex: { [branchId: number]: number } = {};

  constructor(private io: IoService) {}

  ngOnChanges(): void {
    this.selectedBrandsIndex = {};

    this.branches.forEach((branch) => {
      this.selectedBrandsIndex[branch.id] = this.brands.findIndex(
        (brand) => brand.id === branch.parentBrandId
      );
    });

    this.shownBranches = Number(this.selectedBrandIndex) === -1 ? this.branches.map((branch) => branch) : this.branches.filter((branch) => branch.parentBrandId === this.brands[this.selectedBrandIndex].id);
  }

  selectedBrandChanged(branchId: number) {
    const brandIndex = this.selectedBrandsIndex[branchId];

    if (brandIndex < 0) return;

    this.io
      .apicall(
        { brandId: this.brands[brandIndex].id },
        `restaurant/link-to-brand/${branchId}`,
        "POST"
      )
      .then(() => {
        swal.fire({
          title: "Success",
          text: "The branch has been successfully linked to the chosen brand.",
          icon: "success",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });

        this.branches.find((branch) => branch.id === branchId).parentBrandId =
          this.brands[brandIndex].id;

        this.ngOnChanges();
      })
      .catch(() => {
        this.selectedBrandsIndex[branchId] = -1;

        swal.fire({
          title: "Oops...",
          text: "Something went wrong! Please try again.",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }
}
