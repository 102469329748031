import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { base64ToFile } from "ngx-image-cropper";
import swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../../services/data-management/data.service";
import { ToastrService } from "ngx-toastr";
import { DishService } from "./../../services/dish/dish.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { forkJoin, Subscription } from "rxjs";
import { DietaryPreferencesService } from "./../../services/dietary-preferences/dietary-preferences.service";
import { TagManagementService } from "./../../services/tag-management/tag-management.service";

@Component({
  selector: "app-add-dish",
  templateUrl: "./add-dish.component.html",
  styleUrls: ["./add-dish.component.css"],
})
export class AddDishComponent implements OnInit, OnDestroy {
  dropdownSettings: IDropdownSettings;
  addDishFrm: FormGroup;
  croppedImage: any = "";
  image: any = "";
  file: any;
  closeResult = "";
  imageChangedEvent = "";
  finalFile: any = "";
  enableCropper = false;
  transform: ImageTransform = {};
  allHeadList: any = [];
  foodTypeHeadId: any = "";
  foodTypeList: any = [];
  mealTimeHeadId: any = "";
  mealTimeList: any = [];
  cuisineHeadId: any = "";
  cuisineList: any = [];
  mealTimeSelectUnselect = [];
  cuisineSelectUnselect = [];
  foodTypeSelectUnselect = [];
  allListedItem = [];
  restaurantName: any;
  restaurantId: any;
  showExternalLink: boolean = false;
  externalLink: string = null;
  discountArray: any = [];
  finalAddTagArr: any = [];
  urls = [];
  myFiles: string[] = [];
  hdnBrowseButton = 0;
  selectedDiscountType: any = "flat";
  discount_: number = 0;
  duplicateId: any = null;
  paramObserver: Subscription;
  dietaryPreferences: any = [];
  FORM_KEYS: {
    DISH_NAME: string;
    CARBS: string;
    PROTEIN: string;
    FAT: string;
    TOTAL_CALORIES: string;
    PRICE: string;
    NET_PRICE: string;
    NEW_DISH: string;
    DISH_DETAILS: string;
    DISCOUNT: string;
    DISCOUNT_TYPE: string;
    IS_SIDE_DISH: string,
  };
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private dataservice: DataService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private DishService: DishService,
    private loader: NgxUiLoaderService,
    private dietaryPreferencesService: DietaryPreferencesService,
    private tagManagementService: TagManagementService
  ) {
    this.FORM_KEYS = {
      DISH_NAME: "dishName",
      CARBS: "carbs",
      PROTEIN: "protein",
      FAT: "fat",
      TOTAL_CALORIES: "totalCalories",
      PRICE: "price",
      NET_PRICE: "netPrice",
      NEW_DISH: "newDish",
      DISH_DETAILS: "dishDetails",
      DISCOUNT: "discount",
      DISCOUNT_TYPE: "discountType",
      IS_SIDE_DISH: 'isSideDish',
    };
  }

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe(
      async ({ duplicate, restaurantId, restaurantName, showExternalLink }) => {
        this.duplicateId = duplicate || null;
        this.restaurantId = restaurantId || null;
        this.restaurantName = restaurantName || null;
        this.showExternalLink = showExternalLink === 'true';
        this.loadDropDown();
        this.readyAddDishFrm();
        this.loadDiscountType();
        this.fetchAllHead();
      }
    );
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  loadDiscountType() {
    return this.discountArray.push(
      { id: 0, discountType: "flat" },
      { id: 1, discountType: "percentage" }
    );
  }

  discountTypeOnChange() {
    this.discount_ = this.addDishFrm.get(this.FORM_KEYS.DISCOUNT).value;
    let price = this.addDishFrm.get(this.FORM_KEYS.PRICE).value;
    let discount = this.addDishFrm.get(this.FORM_KEYS.DISCOUNT).value;
    let netPrice: any = 0;

    let currentDiscountType = this.addDishFrm.get(this.FORM_KEYS.DISCOUNT_TYPE)
      .value
      ? this.addDishFrm.get(this.FORM_KEYS.DISCOUNT_TYPE).value
      : this.selectedDiscountType;

    if (currentDiscountType === "flat") {
      netPrice = (price - discount).toFixed(2);
    } else if (currentDiscountType === "percentage") {
      netPrice = (price - (price * discount) / 100).toFixed(2);
    } else {
      netPrice = price;
    }
    this.addDishFrm.get(this.FORM_KEYS.NET_PRICE).setValue(netPrice);
  }

  getRestaurantAndDishData() {
    if (this.duplicateId) {
      this.loader.start();
      this.DishService.getDish(this.duplicateId).subscribe(
        (res) => {
          if (res?.serverResponse?.code == 200) {
            let data = res["result"][0];
            this.restaurantName = data.restaurantName;
            this.restaurantId = data.restaurantId;
            this.prefillForm(data);
          }
          this.loader.stop();
        },
        () => {
          this.loader.stop();
          this.router.navigate(["/dish/dish-list"]);
        }
      );
    }
  }

  prefillForm(data): void {
    this.addDishFrm.patchValue({
      [this.FORM_KEYS.DISH_NAME]: data.dishName,
      [this.FORM_KEYS.CARBS]: data.carbs,
      [this.FORM_KEYS.PROTEIN]: data.protein,
      [this.FORM_KEYS.FAT]: data.fat,
      [this.FORM_KEYS.TOTAL_CALORIES]: data.totalCalories,
      [this.FORM_KEYS.PRICE]: data.price,
      [this.FORM_KEYS.NEW_DISH]: data.IsNew,
      [this.FORM_KEYS.DISH_DETAILS]: data.dishdetails,
      [this.FORM_KEYS.DISCOUNT]: data.discount,
      [this.FORM_KEYS.DISCOUNT_TYPE]: data.discountType,
      [this.FORM_KEYS.NET_PRICE]: data.netPrice,
    });
    this.image = data.discImage;
    this.allListedItem = [...data.nutritionalInfo];
    this.foodTypeSelectUnselect = [...data.headTagInfo[4].tags];
    this.cuisineSelectUnselect = [...data.headTagInfo[3].tags];
    this.mealTimeSelectUnselect = [...data.headTagInfo[2].tags];
    this.dietaryPreferences.forEach((item) =>
      this.addDishFrm
        .get(item?.name)
        .patchValue(
          data?.dietaryPreferences[item?.id]?.map((mapItem) =>
            item.options.find((searchItem) => searchItem.id == mapItem)
          )
        )
    );
  }

  loadDropDown() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "tagName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
  }

  readyAddDishFrm() {
    this.addDishFrm = this.fb.group({
      [this.FORM_KEYS.DISH_NAME]: ["", Validators.required],
      [this.FORM_KEYS.CARBS]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.PROTEIN]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.FAT]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.TOTAL_CALORIES]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.PRICE]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.NEW_DISH]: [
        false,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.DISH_DETAILS]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.DISCOUNT]: [""],
      [this.FORM_KEYS.DISCOUNT_TYPE]: [""],
      [this.FORM_KEYS.NET_PRICE]: [
        "",
        Validators.compose([Validators.required, Validators.min(2)]),
      ],
      [this.FORM_KEYS.IS_SIDE_DISH]: [false],
    });
  }

  openModal(content) {
    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  closeModal() {
    this.modalService.dismissAll();
    this.enableCropper = false;
  }

  //================================ 'image upload for dish '===================================================================//

  fileChangeEvent(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
      this.image = this.imageChangedEvent;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
  }

  imageLoaded(image: HTMLImageElement) {
    // show cropper
  }

  // PREVIEW ON SELECT FILES //
  onSelectFile(event: { target: { files: string | any[] } }) {
    this.myFiles = [];
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const file = event.target.files[i];
        const type = file.type.split("/")[0];
        const size = file.size;
        const maxImgSize = 5242880; //10485760; //5242880; // Byte
        if (type === "image") {
          if (size > maxImgSize) {
            swal.fire({
              title: "Warning",
              text: "Maximum allowed image size is 5 MB.",
              icon: "warning",
              confirmButtonColor: "#442DFF;",
              confirmButtonText: "ok",
            });
          }
        }
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.urls.push({ link: event.target.result, fileType: type });
        };
        this.myFiles.push(file);
        reader.readAsDataURL(event.target.files[i]);
        this.hdnBrowseButton = 1;
      }
    }
  }

  async saveImage(): Promise<any> {
    return new Promise((resolve, reject) => {
      const fd = new FormData();
      fd.append("file", this.finalFile);
      this.loader.start();
      this.DishService.uploadImage(fd).subscribe(
        (res) => {
          if (res["serverResponse"].code === 200) {
            this.image = res["result"][0].fileUrl;
            this.toastr.success("Image uploaded.");
            resolve(res["result"][0].fileUrl);
            this.modalService.dismissAll();
          }
        },
        (err) => {
          reject(err);
          swal.fire({
            title: "Oops...",
            text:
              err?.error?.serverResponse?.message ||
              "Something went wrong while uploading image",
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        },
        () => {
          this.loader.stop();
        }
      );
    });
  }

  //================================= 'end of dish upload'  ========================================================================//

  //================================== 'section for dropdown' ======================================================================//

  fetchAllHead() {
    const tag_subscriber = this.tagManagementService.listCategories();
    const diet_subscriber = this.dietaryPreferencesService.listCategories();
    forkJoin({
      tagRes: tag_subscriber,
      dietRes: diet_subscriber,
    }).subscribe(async ({ tagRes, dietRes }) => {
      let categories_subscribers: any = {};
      if (tagRes["serverResponse"].code === 200) {
        this.allHeadList = tagRes["result"];
        for (var i = 0; i < this.allHeadList.length; i++) {
          if (this.allHeadList[i].tagHeadId === "H6") {
            this.headTagMealTime(this.allHeadList[i].id);
          } else if (this.allHeadList[i].tagHeadId === "H4") {
            this.headTagFoodType(this.allHeadList[i].id);
          } else if (this.allHeadList[i].tagHeadId === "H3") {
            this.headTagCusine(this.allHeadList[i].id);
          }
        }
      }
      this.dietaryPreferences = dietRes;
      this.dietaryPreferences.forEach((item) => {
        categories_subscribers[item?.id] =
          this.dietaryPreferencesService.getCategoryOptions(item.id);
        item.dropdownSettings = {
          ...this.dropdownSettings,
          singleSelection: !item.multi && !(item.name == "Macronutrient Ratio"),
          textField: "name",
          idField: "id",
        };
        const diet_control = new FormControl([]);
        this.addDishFrm.addControl(item.name, diet_control);
      });
      forkJoin(categories_subscribers).subscribe((data) => {
        this.handleDietaryPreferencesCategories(data);
      });
    });
  }

  handleDietaryPreferencesCategories(data) {
    this.dietaryPreferences.forEach((item, index) => {
      item.options = data[item.id];
      if (item.selectionType == "exclude") {
        this.finalAddTagArr = this.finalAddTagArr.concat(
          item.options.map((mapItem) => {
            return {
              ...mapItem,
              tagName: mapItem.name + " Free",
              id: mapItem.id,
            };
          })
        );
        this.handleExcludePreference(item.name, index);
      }
    });
    this.getRestaurantAndDishData();
  }

  handleExcludePreference(controlName: string, index: number): void {
    const field_control = this.addDishFrm.get(controlName);
    field_control.valueChanges.subscribe((value) => {
      if (!value) return;

      this.allListedItem = this.allListedItem.filter(
        (filteredItem) =>
          !value.find((searchItem) => searchItem?.tagid == filteredItem.tagId)
      );
      let options = [...this.dietaryPreferences[index].options];

      this.finalAddTagArr = options
        .filter(
          (filteredItem) =>
            !value.find((searchItem) => searchItem.id == filteredItem.id)
        )
        .map((item) => {
          return { ...item, tagName: item.name + " Free", id: item.id };
        });
    });
  }

  async headTagFoodType(Obj) {
    this.foodTypeHeadId = Obj;
    let value = await this.giveTagList(Obj);
    // let myArr1 = [];
    // for (let tags of value) {
    //   myArr1.push({ tagId: tags.id, tagName: tags.tagName });
    // }
    this.foodTypeList = value;
  }

  async headTagMealTime(Obj) {
    this.mealTimeHeadId = Obj;
    let value = await this.giveTagList(Obj);
    this.mealTimeList = value;
  }

  async headTagCusine(Obj) {
    this.cuisineHeadId = Obj;
    let value = await this.giveTagList(Obj);
    this.cuisineList = value;
  }

  async giveTagList(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.tagManagementService.getCategoryOptions(id).subscribe(
        (res) => {
          if (res["serverResponse"].code === 200) {
            resolve(res["result"]);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  save() {
    this.modalService.dismissAll();
  }

  async addDish() {
    if (!this.addDishFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.addDishFrm.markAllAsTouched();
      return;
    }
    if (!this.image) {
      swal.fire({
        title: "Oops...",
        text: "Please add dish image.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    if (this.finalFile !== "") {
      await this.saveImage();
    }

    this.loader.start();

    let dietary_preferences = {};
    this.dietaryPreferences.forEach((item) => {
      if (!this.addDishFrm.get(item.name).value) return;

      dietary_preferences[item.id] = this.addDishFrm
        .get(item.name)
        .value?.map((preference) => preference.id);
    });
    var payLoad = {
      dishName: this.addDishFrm.get(this.FORM_KEYS.DISH_NAME).value,
      restaurantId: this.restaurantId,
      carbs: this.addDishFrm.get(this.FORM_KEYS.CARBS).value,
      fat: this.addDishFrm.get(this.FORM_KEYS.FAT).value,
      protein: this.addDishFrm.get(this.FORM_KEYS.PROTEIN).value,
      totalCalories: this.addDishFrm.get(this.FORM_KEYS.TOTAL_CALORIES).value,
      IsNew: this.addDishFrm.get([this.FORM_KEYS.NEW_DISH]).value,
      price: this.addDishFrm.get(this.FORM_KEYS.PRICE).value,
      nutritionalInfo: this.allListedItem,
      discImage: this.image,
      isSideDish: this.addDishFrm.get(this.FORM_KEYS.IS_SIDE_DISH).value,
      dishdetails: this.addDishFrm.get(this.FORM_KEYS.DISH_DETAILS).value,
      externalLink: this.externalLink?.trim() || null,
      discountType: this.addDishFrm.get(this.FORM_KEYS.DISCOUNT_TYPE).value
        ? this.addDishFrm.get(this.FORM_KEYS.DISCOUNT_TYPE).value
        : "flat",
      discount: this.addDishFrm.get(this.FORM_KEYS.DISCOUNT).value
        ? this.addDishFrm.get(this.FORM_KEYS.DISCOUNT).value
        : 0,
      netPrice: this.addDishFrm.get(this.FORM_KEYS.NET_PRICE).value,
      headTagInfo: [
        {
          headId: this.mealTimeHeadId,
          tags: this.mealTimeSelectUnselect,
        },
        {
          headId: this.cuisineHeadId,
          tags: this.cuisineSelectUnselect,
        },
        {
          headId: this.foodTypeHeadId,
          tags: this.foodTypeSelectUnselect,
        },
      ],
      dietaryPreferences: dietary_preferences,
    };

    let convToText = JSON.stringify(payLoad.headTagInfo);
    let srchAndReplace = convToText.split("id").join("tagId");
    let convToJson = JSON.parse(srchAndReplace);
    payLoad.headTagInfo = convToJson;

    let convToText1 = JSON.stringify(payLoad.nutritionalInfo);
    let srchAndReplace1 = convToText1.split("id").join("tagId");
    let convToJson1 = JSON.parse(srchAndReplace1);
    payLoad.nutritionalInfo = convToJson1;

    this.DishService.addDish(payLoad).subscribe(
      (res) => {
        if (res["serverResponse"].code == 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          let Obj = {
            resturantName: this.restaurantName,
            restaurantId: this.restaurantId,
          };
          this.dataservice._setObj(Obj);
          this.router.navigate(["/dish/dish-list"]);
          this.addDishFrm.reset();
        }
      },
      (err) => {
        swal.fire({
          title: "Oops...",
          text: err?.error?.serverResponse?.message || "Something went wrong",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      },
      () => {
        this.loader.stop();
      }
    );
  }
}
