import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from "../../_constant/constant";
import { Filters } from '../../components/food-science/interfaces/filters.interface';

@Injectable({
  providedIn: "root",
})
export class DishService {
  constructor(private http: HttpClient) {}

  delete(id: String): Observable<object> {
    return this.http.delete(routes.deleteDish(id));
  }

  changeDishesOrder(dishes, restId): Observable<any> {
    return this.http.patch(routes.dishesSetOrder(restId), {
      dishes,
    });
  }

  getDish(id): Observable<any> {
    return this.http.post(routes.getDish, { dishId: id });
  }

  addDish(payload): Observable<any> {
    return this.http.post(routes.addDish, { ...payload });
  }

  editDish(payload): Observable<any> {
    return this.http.post(routes.editDish, { ...payload });
  }

  toggleDishPublishStatus(id): Observable<any> {
    return this.http.post(routes.toggleDishPublishStatus, { dishId: id });
  }

  toggleDishActiveStatus(payload): Observable<any> {
    return this.http.post(routes.toggleDishActiveStatus, { ...payload });
  }

  uploadImage(formData): Observable<any> {
    return this.http.post(routes.uploadDishImage, formData);
  }

  changeDishStatus(payload): Observable<Object> {
    return this.http.post(routes.changeDishStatus, payload);
  }

  getRestaurantDishes(query: { page: number, limit: number, orderBy: string, orderDir: string, filters: Filters }): Observable<any> {
    let route = `${routes.getRestaurantDishes()}?page=${query.page}&limit=${query.limit}&orderBy=${query.orderBy}&orderDir=${query.orderDir}`;

    if (query.filters?.restaurantIds?.length) {
      route += `&restaurantIds=${JSON.stringify(query.filters.restaurantIds)}`;
    }

    if (query.filters?.dishName?.length) {
      route += `&dishName=${query.filters.dishName}`;
    }

    if (query.filters?.active === 'active') {
      route += '&active=true';
    } else if (query.filters?.active === 'inactive') {
      route += '&active=false';
    }

    if (query.filters?.publish === 'published') {
      route += '&published=true';
    } else if (query.filters?.publish === 'unpublished') {
      route += '&published=false';
    }

    if (query.filters?.caloriesMin) {
      route += `&caloriesMin=${query.filters.caloriesMin}`;
    }

    if (query.filters?.caloriesMax) {
      route += `&caloriesMax=${query.filters.caloriesMax}`;
    }

    if (query.filters?.carbsMin) {
      route += `&carbsMin=${query.filters.carbsMin}`;
    }

    if (query.filters?.carbsMax) {
      route += `&carbsMax=${query.filters.carbsMax}`;
    }

    if (query.filters?.proteinMin) {
      route += `&proteinMin=${query.filters.proteinMin}`;
    }

    if (query.filters?.proteinMax) {
      route += `&proteinMax=${query.filters.proteinMax}`;
    }

    if (query.filters?.fatMin) {
      route += `&fatMin=${query.filters.fatMin}`;
    }

    if (query.filters?.fatMax) {
      route += `&fatMax=${query.filters.fatMax}`;
    }

    if (query.filters?.preferenceIds?.length) {
      route += `&preferenceIds=${JSON.stringify(query.filters.preferenceIds)}`;
    }

    if (query.filters?.tagIds?.length) {
      route += `&tagIds=${JSON.stringify(query.filters.tagIds)}`;
    }

    if (query.filters?.tagLabelIds?.length) {
      route += `&tagLabelIds=${JSON.stringify(query.filters.tagLabelIds)}`;
    }

    return this.http.get(route);
  }
}
