import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from './settings.component';
import { UpdateSettingsComponent } from './update-settings/update-settings.component';
import { AppMessagesComponent } from './app-messages/app-messages.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    children: [
      {
        path: 'update-settings',
        component: UpdateSettingsComponent
      },
      {
        path: 'app-messages',
        component: AppMessagesComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingRoutingModule { }
