import { Component, Input, OnInit } from '@angular/core';
import { MealPlanCreatorState } from '../interfaces/creator-state.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-meal-plan-creator-step-four',
    templateUrl: './meal-plan-creator-step-four.component.html',
    styleUrls: ['./meal-plan-creator-step-four.component.css']
})
export class MealPlanCreatorStepFourComponent implements OnInit {
    @Input() state: MealPlanCreatorState;
    @Input() planDuration: number;
    @Input() restaurants: { id: number; name: string; children: { id: number; name: string; }[] }[];
    @Input() recipeCreators: { id: number; name: string; }[];
    @Input() dietaryPreferences: {
        id: number;
        name: string;
        preferences: {
            id: number;
            name: string;
        }[];
    }[];
    @Input() tags: {
        id: number;
        name: string;
        tags: {
            id: number;
            name: string;
        }[];
    }[];

    numberOfDays: number;

    selectedMeal: {}[] = [];

    addToClick: number = -1;
    showSelect: boolean = true;

    dayNutrition: { calories: number; protein: number; carbs: number; fats: number; price: number; highlightCalories: boolean; highlightProtein: boolean; highlightCarbs: boolean; highlightFats: boolean; highlightPrice: boolean; };

    filterModalDayIndex: number = 1;

    constructor(
        private modalService: NgbModal,
    ) { }

    ngOnInit(): void {
        this.numberOfDays = +this.planDuration || 0;

        if (this.state.days.length < this.numberOfDays) {
            const increaseDays = this.numberOfDays - this.state.days.length;

            for (let i = 0; i < increaseDays; i++) {
                this.state.days.push(this.getDefaultDay());
            }
        } else if (this.state.days.length > this.numberOfDays) {
            const removeDays = this.state.days.length - this.numberOfDays;

            for (let i = 0; i < removeDays; i++) {
                this.state.days.pop();
            }
        }

        if (this.state.currentDayIndex >= this.state.days.length) {
            this.state.currentDayIndex = this.state.days.length - 1;
        }

        if (this.state.currentDayIndex < 0) {
            this.state.currentDayIndex = 0;
        }
    }

    getDefaultDay(): { meals: { name: string; items: any[] }[] } {
        const meals = [];

        if (this.state.prefillBreakfast) {
            meals.push({ name: 'Breakfast', items: [] });
        }

        if (this.state.prefillLunch) {
            meals.push({ name: 'Lunch', items: [] });
        }

        if (this.state.prefillSnack) {
            meals.push({ name: 'Snack', items: [] });
        }

        if (this.state.prefillDinner) {
            meals.push({ name: 'Dinner', items: [] });
        }

        return { meals };
    }

    markDishImageInSummaryDisabled() {
        const markedDishes = new Set<string>();

        this.state.days.forEach((day) => {
            day.meals.forEach((meal) => {
                meal.items.forEach((item) => {
                    if (item.showInSummary) {
                        markedDishes.add(`${item.restaurantName ? 'order' : 'cook'}${item.id}`)
                    };
                });
            });
        });

        return markedDishes.size >= 4;
    }

    addToMeal(dish) {
        this.showSelect = false;

        this.state.days[this.state.currentDayIndex].meals[+this.addToClick].items.push(dish);

        setTimeout(() => {
            this.addToClick = -1;
            this.showSelect = true;
        });
    }

    removeItem(meal, index) {
        let itemCount = 0;
        const item = meal.items[index];
        const itemId = `${item.restaurantName ? 'order' : 'cook'}${item.id}`;

        this.state.days[this.state.currentDayIndex].meals.forEach((meal) => {
            meal.items.forEach((it) => {
                const itId = `${it.restaurantName ? 'order' : 'cook'}${it.id}`;

                if (itId === itemId) itemCount++;
            });
        });

        if (itemCount < 2) {
            item.showInSummary = false;
        }

        meal.items.splice(index, 1);
    }

    calculateDayNutrition() {
        const day = this.state.days[this.state.currentDayIndex];

        let cals = 0;
        let protein = 0;
        let carbs = 0;
        let fats = 0;
        let price = 0;

        day.meals.forEach((meal) => {
            meal.items.forEach((item) => {
                cals += item.calories;
                protein += item.protein;
                carbs += item.carbs;
                fats += item.fats;
                price += item.price || 0;
            });
        });

        this.dayNutrition = {
            calories: cals,
            highlightCalories: cals < +this.state.minCals || cals > +this.state.maxCals,
            protein: protein,
            highlightProtein: protein < +this.state.minProtein || protein > +this.state.maxProtein,
            carbs: carbs,
            highlightCarbs: carbs < +this.state.minCarbs || carbs > +this.state.maxCarbs,
            fats: fats,
            highlightFats: fats < +this.state.minFat || fats > +this.state.maxFat,
            price: price,
            highlightPrice: price < +this.state.minCost || price > +this.state.maxCost,
        };
    }

    calculateNutrition(meal) {
        let cals = 0;
        let protein = 0;
        let carbs = 0;
        let fats = 0;
        let price = 0;

        meal.items.forEach((item) => {
            cals += item.calories;
            protein += item.protein;
            carbs += item.carbs;
            fats += item.fats;
            price += item.price || 0;
        });

        return `Cals: ${cals}kCal P: ${protein}g C: ${carbs}g F: ${fats}g | AED ${price}`;
    }

    addMeal() {
        this.state.days[this.state.currentDayIndex].meals.push({ name: `Meal ${this.state.days[this.state.currentDayIndex].meals.length + 1}`, items: [] });
    }

    removeMeal(dayIndex: number, mealIndex: number) {
        this.state.days[dayIndex].meals.splice(mealIndex, 1);
    }

    previousNavButtonDisabled() {
        return this.state.currentDayIndex < 1;
    }

    nextNavButtonDisabled() {
        return this.state.currentDayIndex >= this.state.days.length - 1;
    }

    previousNavHandler() {
        this.state.currentDayIndex--;
    }

    nextNavHandler() {
        this.state.currentDayIndex++;
    }

    openFiltersModal(content) {
        this.modalService
            .open(content, { ariaLabelledBy: 'modal-basic-title', centered: true })
            .result.then(
                (result) => {
                    return result;
                },
                (reason) => {
                    return reason;
                }
            );
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    previousFilterModalPageDisabled() {
        return this.filterModalDayIndex < 2;
    }

    nextFilterModalPageDisabled() {
        return this.filterModalDayIndex > 1;
    }

    previousFilterModalHandler() {
        this.filterModalDayIndex--;
    }

    nextFilterModalHandler() {
        this.filterModalDayIndex++;
    }
}
