import { Component, OnInit } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SupportService } from "../../services/support/support.service";
import swal from "sweetalert2";

@Component({
  selector: "app-support-list",
  templateUrl: "./support-list.component.html",
  styleUrls: ["./support-list.component.css"],
})
export class SupportListComponent implements OnInit {
  categories: [];

  constructor(
    private SupportService: SupportService,
    private loader: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.fetchCategories();
  }

  fetchCategories(): void {
    this.loader.start();
    this.SupportService.getCategories().subscribe(
      (res) => {
        this.categories = res;
        this.loader.stop();
      },
      () => {
        swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }
}
