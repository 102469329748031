import { Component, OnInit } from '@angular/core';
import { IoService } from '../../services/http/io.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-all-signup-user',
  templateUrl: './all-signup-user.component.html',
  styleUrls: ['./all-signup-user.component.css']
})
export class AllSignupUserComponent implements OnInit {
  page: any;
  alluserSignupList:any = [];
  constructor(private io:IoService) { }

  ngOnInit(): void {
    this.allSignupUserEmail();
  }

  allSignupUserEmail() {
    let payload = {
      "page": this.page,
      "limit": 10,
    }
    this.io.apicall(payload, 'subscribers/subscribers-mail-list', 'POST').then((res:any) => {
      if(res['serverResponse'].code === 200) {
        const result = res['result'];
        if(result.totalRecords > this.alluserSignupList.length) {
          this.alluserSignupList= [...this.alluserSignupList, ...result.subscriMailList];
        }
      }
    })
    .catch(err => {
      throw err;
    })
  }

  pageChanged(event) {
    if(event > 1) {
      this.allSignupUserEmail();
    }
  }

  allUserMailSend(){
    let payload = {
    }

    this.io.apicall(payload, 'subscribers/mail-send-to-subscribers', 'POST').then((res:any) => {
      if(res['serverResponse'].code === 200) {
        swal.fire({
          title: 'Success',
          text: res['serverResponse'].message,
          icon: 'success',
          confirmButtonColor: '#442DFF;',
          confirmButtonText: 'ok'
        });
      }
    })
    .catch(err => {
      throw err;
    })
  }

}
