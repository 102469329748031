import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IoService } from "../..//services/http/io.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import swal from "sweetalert2";
import { DataService } from "../../services/data-management/data.service";
import { Router, ActivatedRoute } from "@angular/router";
let closeResult = "";

@Component({
  selector: "app-add-or-remove-recipe-provider",
  templateUrl: "./add-or-remove-recipe-provider.component.html",
  styleUrls: ["./add-or-remove-recipe-provider.component.css"],
})
export class AddOrRemoveRecipeProviderComponent implements OnInit, OnDestroy {
  pendingRecipeProvider: any = [];
  approvedRecipeProvider: any = [];
  recipeProvider: any = {};
  searchResult: boolean = false;
  newMessage: any;
  page: number;
  total: number;
  itemsPerPage: number = 25;
  rate: any;
  name: any;
  isSearch: boolean = false;
  paramObserver: any;
  constructor(
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private io: IoService,
    private dataservice: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let currentPage = values.page;
      let currentSearch = values.search;
      if (currentPage) {
        this.page = currentPage;
      } else {
        this.page = 1;
      }
      if (currentSearch) {
        this.name = currentSearch;
        this.searchApprovedProviders();
      } else {
        this.pendingRecipeProviderList();
        this.approvedRecipeProviderList();
      }
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  handleParams(page, search = null): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page, search },
      queryParamsHandling: "merge",
    });
  }

  pendingRecipeProviderList() {
    let payload = {
      page: this.page,
      limit: this.itemsPerPage,
    };
    this.io
      .apicall(payload, "admin/pending-recipe-creators-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];
          if (result.totalRecords > this.pendingRecipeProvider.length) {
            this.pendingRecipeProvider = [
              ...this.pendingRecipeProvider,
              ...result.creatorList,
            ];
          }
        }
      });
  }

  approvedRecipeProviderList() {
    let payload = {
      page: this.page,
      limit: this.itemsPerPage,
      name: this.isSearch ? this.name : (this.name = ""),
      orderName: "name",
      orderType: 1,
    };
    this.io
      .apicall(payload, "admin/approved-recipe-creators-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];
          // this.searchResult = false;
          this.total = result.totalRecords;
          this.approvedRecipeProvider = result.creatorList;
          // this.name = '';
          // this.newMessage = '';
        }
      });
  }
  clearApprovedRecipeProviderList() {
    this.isSearch = false;
    this.searchResult = false;
    this.name = "";
    this.page = 1;
    this.approvedRecipeProvider = [];
    this.handleParams(1);
  }
  /*   approve(Obj: any) {
    let payload = {
      userId: Obj.id,
      isAccept: true,
      recipeCreatorRateAmount: this.rate,
    };
    this.io
      .apicall(payload, "admin/recipe-creator-request-approved", "POST")
      .then((res: any) => {
        if (this.io.data_.serverResponse.code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          // this.approvedRecipeProvider.push(Obj);
          this.pendingRecipeProvider = [];
          this.page = 1;
          this.pendingRecipeProviderList();
          this.approvedRecipeProvider = [];
          this.approvedRecipeProviderList();
          this.modalService.dismissAll();
        }
      })
      .catch((err) => {
        if (this.io.data_.serverResponse.code === 401) {
          swal.fire({
            title: "Oops...",
            text: this.io.data_.serverResponse.message,
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      });
  } */

  /*   reject(id: any) {
    let payload = {
      userId: id,
      isRejected: true,
    };
    this.io
      .apicall(payload, "admin/recipe-creator-request-reject", "POST")
      .then((res: any) => {
        if (this.io.data_.serverResponse.code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.pendingRecipeProvider = [];
          this.page = 1;
          this.pendingRecipeProviderList();
          this.approvedRecipeProvider = [];
          this.approvedRecipeProviderList();
          this.modalService.dismissAll();
        }
      })
      .catch((err) => {
        if (this.io.data_.serverResponse.code === 401) {
          swal.fire({
            title: "Oops...",
            text: this.io.data_.serverResponse.message,
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      });
  } */

  pageChanged(event) {
    this.handleParams(event);
  }

  trackByRecipeId(index: number, approvedRecipeProvider: any): string {
    return approvedRecipeProvider.id;
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  catchRecipeProvider(Obj) {
    this.recipeProvider = Obj;
  }

  searchApprovedProviders() {
    this.isSearch = true;
    this.page = 1;
    let payload = {
      page: this.page,
      limit: this.itemsPerPage,
      name: this.name,
      orderName: "name",
      orderType: 1,
    };
    this.io
      .apicall(payload, "admin/approved-recipe-creators-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];
          this.searchResult = true;
          this.approvedRecipeProvider = result.creatorList;
          if (this.approvedRecipeProvider.length === 0) {
            // swal.fire({
            //   title: 'Oops...',
            //   text: 'Sorry! no member found .....',
            //   icon: 'warning',
            //   confirmButtonColor: '#442DFF;',
            //   confirmButtonText: 'ok'
            // });
            this.approvedRecipeProviderList();
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  transform(url) {
    if (!url) {
      return null;
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  // [href]="recipe?.referralWebLink"

  routerNavigate(obj: any) {
    let payload;
    let abc = obj.referralWebLink;
    if (abc.split("/")[0] == "https:") {
      payload = abc;
    } else {
      payload = `https://${abc}`;
    }
    window.open(payload, "_blank");
  }
}
