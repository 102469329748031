import { IoService } from "../../services/http/io.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "../../services/data-management/data.service";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: "/dashboard", title: "Dashboard", icon: "design_app", class: "" },
  { path: "/profile", title: "Profile", icon: "users_single-02", class: "" },
  // { path: '/icons', title: 'Icons',  icon:'education_atom', class: '' },
  {
    path: "/recipe-provider/recipe-provider-action",
    title: "Recipe Publisher",
    icon: "business_bulb-63",
    class: "",
  },
  {
    path: "/recipe-provider/recipe-provider-list",
    title: "Recipe Management",
    icon: "now-ui-icons business_chart-bar-32",
    class: "",
  },
  // { path: '/user/all-signup-user', title: 'All Signup User',  icon:'users_single-02', class: '' },
  {
    path: "/resturant/restaurant-list",
    title: "Restaurant Management",
    icon: "location_map-big",
    class: "",
  },
  {
    path: "/branch/branch-list",
    title: "Branch Management",
    icon: "location_map-big",
    class: "",
  },
  {
    path: '/menu/menu-list',
    title: 'Menu Management',
    icon: 'location_map-big',
    class: '',
  },
  {
    path: '/custom-menu/submit',
    title: 'Custom Menu',
    icon: 'location_map-big',
    class: '',
  },
  {
    path: '/menu-food-science/menu-list',
    title: 'Menu Food Science',
    icon: 'location_map-big',
    class: '',
  },
  {
    path: "/dish/dish-list",
    title: "Dish Management",
    icon: "ui-1_bell-53",
    class: "",
  },
  {
    path: "/management/category",
    title: "Tag Management",
    icon: "ui-1_bell-53",
    class: "",
  },
  {
    path: "/category/category-list",
    title: "Category Management",
    icon: "arrows-1_cloud-upload-94",
    class: "",
  },
  {
    path: "/customer/customer-list",
    title: "Customer Management",
    icon: "users_single-02",
    class: "",
  },
  // { path: '/marketing', title: 'Marketing',  icon:'business_globe', class: '' },
  {
    path: "/coupon/coupon-list",
    title: "Coupon Management",
    icon: "files_box",
    class: "",
  },
  {
    path: "/order/order-list",
    title: "Order Management",
    icon: "files_box",
    class: "",
  },
  // { path: '/recipe-transaction/recipe-transaction-payout-list', title: 'Recipe Transaction Payout',  icon:'now-ui-icons shopping_box', class: '' },
  {
    path: "/restaurant-transaction/restaurant-transaction-payout-list",
    title: "Restaurant Transaction Payout",
    icon: "now-ui-icons shopping_box",
    class: "",
  },
  {
    path: "/transaction/transaction-list",
    title: "Transaction Report",
    icon: "now-ui-icons shopping_box",
    class: "",
  },
  {
    path: "/content-management",
    title: "Content Management",
    icon: "ui-2_chat-round",
    class: "",
  },
  {
    path: "/filters/sections",
    title: "Filters",
    icon: "files_box",
    class: "",
  },
  {
    path: "/meal-plan/list",
    title: "Meal Plans",
    icon: "ui-2_chat-round",
    class: "",
  },
  {
    path: "/hero-banners/list",
    title: "Hero Banners",
    icon: "ui-2_chat-round",
    class: "",
  },
  {
    path: "/shortcut-banners/list",
    title: "Shortcut Banners",
    icon: "ui-2_chat-round",
    class: "",
  },
  {
    path: "/supply/count",
    title: "Supply Count",
    icon: "now-ui-icons shopping_box",
    class: "",
  },
  {
    path: "/dietary-preferences",
    title: "User Preferences",
    icon: "now-ui-icons tech_mobile",
    class: "",
  },
  {
    path: "/notification/send",
    title: "Notifications",
    icon: "now-ui-icons tech_mobile",
    class: "",
  },
  {
    path: "/settings/app-messages",
    title: "App Messages",
    icon: "ui-1_settings-gear-63",
    class: "",
  },
  {
    path: "/settings/update-settings",
    title: "App Settings",
    icon: "ui-1_settings-gear-63",
    class: "",
  },
  {
    path: "/support/support-list",
    title: "Support",
    icon: "tech_headphones",
    class: "",
  },
  {
    path: "/login",
    title: "Logout",
    icon: "now-ui-icons media-1_button-power",
    class: "",
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  accessControls: any[];
  constructor(
    private io: IoService,
    private router: Router,
    private data: DataService
  ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.accessControls = JSON.parse(
      localStorage.getItem("admin")
    )?.accessControlls;
  }
  clearData() {
    let Obj = { restaurantId: "", resturantName: "" };
    this.data._setObj(Obj);
  }
  checkControls(menuItem) {
    return this.accessControls.some(function (el) {
      if (menuItem === 'Branch Management' && el.feature === 'RESTAURANT MANAGEMENT') return true;

      return el.feature == menuItem.toUpperCase();
    });
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }

  logout() {
    let token = JSON.parse(localStorage.getItem("refreashToken"));
    let payload = {
      refreshToken: token,
    };
    this.io
      .apicall(payload, "admin/logout", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          localStorage.clear();
          this.router.navigate(["/login"]);
        }
      })
      .catch((err) => {
        localStorage.clear();
        this.router.navigate(["/login"]);
      });
  }
}
