import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import swal from "sweetalert2";
import { SupportService } from "./../../services/support/support.service";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-support-add",
  templateUrl: "./support-add.component.html",
  styleUrls: ["./support-add.component.css"],
})
export class SupportAddComponent implements OnInit {
  addForm: FormGroup;

  FORM_KEYS: {
    TEXT:string
  }
  constructor(
    private router: Router,
    private SupportService: SupportService,
    private FormBuilder: FormBuilder,
    private loader: NgxUiLoaderService
  ) {
    this.FORM_KEYS = {
      TEXT:"text"
    }
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.addForm = this.FormBuilder.group({
      [this.FORM_KEYS.TEXT]: ["", Validators.compose([Validators.required])],
    });
  }

  onSubmit(): void {
    if (this.addForm.valid) {
      this.loader.start();
      this.SupportService.addCategoryItem(this.addForm.get(this.FORM_KEYS.TEXT).value).subscribe(
        (res) => {
          this.loader.stop();
          swal.fire({
            title: "Success",
            text: "Added Successfully",
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.router.navigate(["/support/support-list"]);
        },
        () => {
          this.loader.stop();
          swal.fire({
            title: "Oops...",
            text: "Something went wrong!",
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      );
    }
    else{
      swal.fire({
          title: "Oops...",
          text: "Please make sure you filled all required fields correctly",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
      });
      this.addForm.markAllAsTouched();
      return;
    }
  }
}
