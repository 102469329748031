import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UiModalComponent } from "../../modal/ui-modal/ui-modal.component";
import { DataService } from "../../services/data-management/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IoService } from "../../services/http/io.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { constant } from "../../_constant/constant";
import swal from "sweetalert2";
import { PusherServiceService } from "../../services/http/pusher-service.service";
import { Subscription } from "rxjs";
import { OrderProduct } from '../interfaces/order-product.interface';
@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.css"],
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  @ViewChild(UiModalComponent) uimoadal: UiModalComponent;
  public orderStatusId: any;
  orderid: any;
  orderDetailsResult: any;
  restaurantDetailsForm: FormGroup;
  customerDetailsForm: FormGroup;
  orderDetailsList: any;
  restaurantOrderDetails: any;
  orderStatusDetails: any;
  paymentOrderDetails: any;
  isDeliverect: boolean;
  currency: any;
  orderStatusindex: any;
  netValue: number = 0;
  paramObserver: Subscription;
  orderProducts: OrderProduct[];
  constructor(
    private data: DataService,
    private pusherService: PusherServiceService,
    private router: Router,
    private io: IoService,
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.restaurantDetailsForm = this.fb.group({
      restaurantName_: [null, Validators.compose([Validators.required])],
      restaurantContact_: [null, Validators.compose([Validators.required])],
      restaurantAddress_: [null, Validators.compose([Validators.required])],
    });
    this.customerDetailsForm = this.fb.group({
      customerName_: [null, Validators.compose([Validators.required])],
      customerContact_: [null, Validators.compose([Validators.required])],
      customerAddress_: [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.currency = constant.currency_;
    this.restaurantOrderDetailsList();
    this.paramObserver = this.route.params.subscribe(({ id }) => {
      if (id) {
        this.orderid = id;
        this.loadOrderDetails();
        this.restaurantPaymentHistory();
        this.pusherService.channel.bind(
          `order-status-update-${this.orderid}`,
          (data) => {
            this.loadOrderDetails();
          }
        );
      } else {
        this.router.navigate(["/order/order-list"]);
      }
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  loadOrderDetails() {
    this.io
      .apicall(null, `orders/admin-order-view/${this.orderid}`, "GET")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.orderDetailsResult = res["result"].orderDtls;
          this.isDeliverect = this.orderDetailsResult.channel === 'deliverect';
          this.orderProducts = this.orderDetailsResult.products;
          this.restaurantDetailsForm
            .get("restaurantName_")
            .setValue(this.orderDetailsResult.restaurantName);
          this.restaurantDetailsForm
            .get("restaurantContact_")
            .setValue(this.orderDetailsResult.restaurantContact);
          this.restaurantDetailsForm
            .get("restaurantAddress_")
            .setValue(this.orderDetailsResult.restaurantAddress);
          this.customerDetailsForm
            .get("customerName_")
            .setValue(this.orderDetailsResult.userNmae);
          this.customerDetailsForm
            .get("customerContact_")
            .setValue(this.orderDetailsResult.address.contactNumber);
          var concatedAddress;
          if(this.orderDetailsResult.address.type){
            var addressType = this.orderDetailsResult.address.type;
            if(addressType=="Villa/House"){
              concatedAddress = `Location Type: ${this.orderDetailsResult.address.type}`;
              concatedAddress = concatedAddress + `, House: ${this.orderDetailsResult.address.houseNo}`;
              concatedAddress = concatedAddress + `, Street: ${this.orderDetailsResult.address.street}`;
              concatedAddress = concatedAddress + `\nContact Number: ${this.orderDetailsResult.address.contactNumber}`;
              concatedAddress = concatedAddress + `, Additional Directions: ${this.orderDetailsResult.address.additionalDirections}`;
            } else if (addressType=="Office") {
              concatedAddress = `Location Type: ${this.orderDetailsResult.address.type}`;
              concatedAddress = concatedAddress + `, Building: ${this.orderDetailsResult.address.houseNo}`;
              concatedAddress = concatedAddress + `, Street: ${this.orderDetailsResult.address.street}`;
              concatedAddress = concatedAddress + `, Floor: ${this.orderDetailsResult.address.floor}`;
              concatedAddress = concatedAddress + `, Office: ${this.orderDetailsResult.address.placeName}`;
              concatedAddress = concatedAddress + `\nContact Number: ${this.orderDetailsResult.address.contactNumber}`;
              concatedAddress = concatedAddress + `, Additional Directions: ${this.orderDetailsResult.address.additionalDirections}`;
            }
            else if (addressType=="Apartment") {
              concatedAddress = `Location Type: ${this.orderDetailsResult.address.type}`;
              concatedAddress = concatedAddress + `, Building: ${this.orderDetailsResult.address.houseNo}`;
              concatedAddress = concatedAddress + `, Street: ${this.orderDetailsResult.address.street}`;
              concatedAddress = concatedAddress + `, Floor: ${this.orderDetailsResult.address.floor}`;
              concatedAddress = concatedAddress + `, Apartment: ${this.orderDetailsResult.address.apartmentNo}`;
              concatedAddress = concatedAddress + `\nContact Number: ${this.orderDetailsResult.address.contactNumber}`;
              concatedAddress = concatedAddress + `, Additional Directions: ${this.orderDetailsResult.address.additionalDirections}`;
            }
            else if (addressType=="Other") {
              concatedAddress = `Location Type: ${this.orderDetailsResult.address.type}`;
              concatedAddress = concatedAddress + `, Place: ${this.orderDetailsResult.address.placeName}`;
              concatedAddress = concatedAddress + `, Street: ${this.orderDetailsResult.address.street}`;
              concatedAddress = concatedAddress + `, Landmark: ${this.orderDetailsResult.address.landmark}`;
              concatedAddress = concatedAddress + `\nContact Number: ${this.orderDetailsResult.address.contactNumber}`;
              concatedAddress = concatedAddress + `, Additional Directions: ${this.orderDetailsResult.address.additionalDirections}`;
            }
          } else {
            concatedAddress = `${this.orderDetailsResult.address.addressNickname}: ${this.orderDetailsResult.address.houseNo}, ${this.orderDetailsResult.address.addressLine}`;
          }
          concatedAddress = concatedAddress + `\nCustomer pin: ${this.orderDetailsResult.address.latitude}` + `, ${this.orderDetailsResult.address.longitude}`;

          this.customerDetailsForm
            .get("customerAddress_")
            .setValue(concatedAddress);
        }
      });
  }

  orderStatusModal(event) {
    this.orderStatusindex = this.orderDetailsList.findIndex((arr) => {
      return arr.id == event;
    });
    this.orderStatusId = this.orderDetailsList[this.orderStatusindex];
    this.uimoadal.show();
  }

  restaurantOrderDetailsList() {
    this.http.get<any>("./assets/data/orderStatus.json").subscribe((data) => {
      this.orderDetailsList = data.orderStatus;
    });
  }

  orderStatusUpdate() {
    let payload = {
      orderId: this.orderid,
      status: parseInt(this.orderStatusId.id),
    };
    this.io
      .apicall(payload, "orders/order-status-update", "POST")
      .then((res: any) => {
        this.orderStatusDetails = res["result"];
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        //throw err;
        swal.fire({
          title: "Oops...",
          text: err.error.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  restaurantPaymentHistory() {
    let payload = {
      orderId: this.orderid,
    };
    this.io
      .apicall(payload, "payment/get-payment-details-by-order", "POST")
      .then((res: any) => {
        this.paymentOrderDetails = res["result"].paymentDetails;
      });
  }

  orderChangeStatus(val: any) {
    return val.name;
  }
}
