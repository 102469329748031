import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMealPlan } from '../interfaces/meal-plan.interface';
import { IoService } from "../../services/http/io.service";
import swal from "sweetalert2";
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-meal-plan-list',
    templateUrl: './meal-plan-list.component.html',
    styleUrls: ['./meal-plan-list.component.css']
})
export class MealPlanListComponent implements OnInit, OnDestroy {
    paramObserver: Subscription;

    tabs: { text: string, status: string, isActive: boolean }[] = [];
    activeTab: number = 0;

    loadingMealPlans = false;
    mealPlans: IMealPlan[] = [
        { id: 1, name: 'first mp', description: 'some kind of des', isActive: true },
        { id: 2, name: 'second mp', description: 'some kind of des2', isActive: false },
        { id: 3, name: 'third mp', description: 'some kind of des3', isActive: false },
        { id: 4, name: 'fourth mp', description: 'some kind of des4', isActive: true },
        { id: 5, name: 'fifth mp', description: 'some kind of des5', isActive: true },
    ];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private io: IoService,
    ) { }

    ngOnInit(): void {
        this.paramObserver = this.route.queryParams.subscribe((values) => {
            this.tabs = [
                { text: 'Unpublished', status: 'unpublished', isActive: false },
                { text: 'Published', status: 'published', isActive: false },
            ];

            this.activeTab = values.tab ? +values.tab : 0;

            this.tabs[this.activeTab] = {
                ...this.tabs[this.activeTab],
                isActive: true,
            };

            this.getMealPlansList();
        });
    }

    ngOnDestroy(): void {
        this.paramObserver.unsubscribe();
    }

    async getMealPlansList() {
        const status = this.tabs[this.activeTab].status;

        this.loadingMealPlans = true;

        try {
            const result = await this.io.apicall(null, `meal-plans/portal/list/${status}`, 'GET');

            this.mealPlans = result.mealPlans;
        } catch {
            swal.fire({
                title: 'Oops...',
                text: 'Failed to load meal plans, Please try again later!',
                icon: 'warning',
                confirmButtonColor: '#442DFF;',
                confirmButtonText: 'ok',
            });
        }

        this.loadingMealPlans = false;
    }

    handleTabChange(index): void {
        delete this.tabs[this.activeTab].isActive;

        this.activeTab = index;

        this.tabs[this.activeTab].isActive = true;

        this.handleParams();
    }

    handleParams(): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                tab: this.activeTab,
            },
            queryParamsHandling: "merge",
        });
    }

    async openConfirmDuplicatePlanModal(planId: number) {
        if (!planId) return;

        const result = await swal.fire({
            title: "Are you sure you want to duplicate this meal plan?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
        });

        if (result.isConfirmed) {
            this.duplicatePlan(planId);
        }
    }

    async duplicatePlan(planId: number) {
        try {
            await this.io.apicall(null, `meal-plans/portal/duplicate/${planId}`, 'POST');

            swal.fire({
                title: 'Success',
                text: 'Plan duplicated successfully.',
                icon: 'success',
                confirmButtonColor: '#442DFF;',
                confirmButtonText: 'ok',
            });

            this.getMealPlansList();
        } catch {
            swal.fire({
                title: 'Oops...',
                text: 'Failed to duplicate meal plan, Please try again later!',
                icon: 'warning',
                confirmButtonColor: '#442DFF;',
                confirmButtonText: 'ok',
            });
        }
    }

    async openConfirmDeletePlanModal(planId: number) {
        if (!planId) return;

        const result = await swal.fire({
            title: "Are you sure you want to permanently delete this meal plan?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
        });

        if (result.isConfirmed) {
            this.deletePlan(planId);
        }
    }

    async deletePlan(planId: number) {
        try {
            await this.io.apicall(null, `meal-plans/portal/${planId}`, 'DELETE');

            swal.fire({
                title: 'Success',
                text: 'Plan deleted successfully.',
                icon: 'success',
                confirmButtonColor: '#442DFF;',
                confirmButtonText: 'ok',
            });

            this.getMealPlansList();
        } catch (err) {
            swal.fire({
                title: 'Oops...',
                text: 'Failed to delete meal plan, Please try again later!',
                icon: 'warning',
                confirmButtonColor: '#442DFF;',
                confirmButtonText: 'ok',
            });
        }
    }
}
