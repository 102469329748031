import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import * as $ from "jquery";
import { TagInputModule } from "ngx-chips";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import swal from "sweetalert2";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import {
  base64ToFile,
  ImageCroppedEvent,
  ImageCropperModule,
  ImageTransform,
} from "ngx-image-cropper";
import { IoService } from "../../services/http/io.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { DataService } from "../../services/data-management/data.service";
import { DietaryPreferencesService } from "./../../services/dietary-preferences/dietary-preferences.service";

TagInputModule.withDefaults({
  tagInput: {
    placeholder: "Enter New Nutrition List",
    secondaryPlaceholder: "Enter New Nutrition List",
    // add here other default values for tag-input
  },
});

@Component({
  selector: "app-add-recipe",
  templateUrl: "./add-recipe.component.html",
  styleUrls: ["./add-recipe.component.css"],
})
export class AddRecipeComponent implements OnInit, OnDestroy {
  imageChangedEvent: any = "";
  croppedImage: any = "";
  allListedItem: any = [];
  backgroundcolor: any = "#5998be";
  color: any = "#5998be";
  addtier = [];
  image: any;
  canvasRotation = 0;
  scale = 1;
  transform: ImageTransform = {};
  showCropper = false;
  rotation = 0;
  closeResult = "";
  enableCropper = false;
  creatorid: any;
  file: any = "";
  finalFile: any;
  ingreDients: any;
  @ViewChild("ingredient") ingredient: ElementRef;
  @ViewChild("imageLoad") imageLoad: ElementRef;
  addRecipeFrm: FormGroup;
  dropdownSettings: IDropdownSettings;
  tagsDropdownSettings: IDropdownSettings;
  mealTimeSelectUnselect = [];
  cuisineList: any = [];
  cuisineSelectUnselect = [];
  foodTypeList: any = [];
  foodTypeSelectUnselect = [];
  allHeadList: any = [];
  finalAddTagArr: any = [];
  mealtimeList: any = [];
  newIngridients: any;
  budgetFriendly: any;
  postWorkout: any;
  dontKnow: any;
  ingredientUnitTypeArray: any = [];
  dontknowValue: boolean = false;
  choosedColor: any = "#61C5BB";
  ingredientUnitType_: any = "";
  ingredientCount_: any = "";
  addprocess: any = [];
  prepProcess: any = "";
  ingridientunit: any;
  otherIngriDients_: any = "";
  prepProcessIndex: boolean = false;
  editProcess: FormGroup;
  editIngredientProcess: FormGroup;
  processIndex: any;
  ingredientIndex: any;
  ingridientunit1: any;
  paramObserver: any;
  dietaryPreferences: any = [];
  FORM_KEYS: {
    RECIPE_NAME: string;
    PREPARATION_TIME: string;
    CARBS: string;
    FAT: string;
    PROTEIN: string;
    CALORIES: string;
    SERVING: string;
  };
  constructor(
    private fb: FormBuilder,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private io: IoService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private data: DataService,
    private route: ActivatedRoute,
    private dietaryPreferencesService: DietaryPreferencesService
  ) {
    config.backdrop = "static";
    config.keyboard = false;
    this.FORM_KEYS = {
      RECIPE_NAME: "recipeName",
      PREPARATION_TIME: "preparationTime",
      CARBS: "carbs",
      FAT: "fat",
      PROTEIN: "protein",
      CALORIES: "calories",
      SERVING: "serving",
    };
    this.addRecipeFrm = this.fb.group({
      [this.FORM_KEYS.RECIPE_NAME]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.PREPARATION_TIME]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.CARBS]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.FAT]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.PROTEIN]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.CALORIES]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.SERVING]: ["", Validators.compose([Validators.required])],
    });

    this.editProcess = this.fb.group({
      editprocess: ["", Validators.compose([Validators.required])],
    });

    this.editIngredientProcess = this.fb.group({
      editIngredientText: ["", Validators.compose([Validators.required])],
      editIngredientQuantity: ["", Validators.compose([Validators.required])],
      editIngredientUnitType: ["", Validators.compose([Validators.required])],
      editIngredientOthers: ["", Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe(({ id }) => {
      if (id) {
        this.creatorid = id;
      } else {
        this.router.navigate(["/recipe-provider/recipe-provider-action"]);
      }
    });
    this.fetchAllHead();

    this.loadIngridientUnitType();
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "tagName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  get f() {
    return this.addRecipeFrm.controls;
  }

  numbersCheck(element) {
    element.target.value = element.target.value.replace(/[^(\d|\.|\/|\s)]/, "");
  }

  loadIngridientUnitType() {
    this.ingredientUnitTypeArray.push(
      { id: 0, ingredientUnitType: "tbsp" },
      { id: 1, ingredientUnitType: "tsp" },
      { id: 2, ingredientUnitType: "ml" },
      { id: 3, ingredientUnitType: "g" },
      { id: 4, ingredientUnitType: "cup" },
      { id: 5, ingredientUnitType: "L" },
      { id: 6, ingredientUnitType: "handful" },
      { id: 7, ingredientUnitType: "sprinkle" },
      { id: 8, ingredientUnitType: "splash" },
      { id: 9, ingredientUnitType: "kg" },
      { id: 10, ingredientUnitType: "whole" },
      { id: 11, ingredientUnitType: "floret" },
      { id: 12, ingredientUnitType: "small" },
      { id: 13, ingredientUnitType: "medium" },
      { id: 14, ingredientUnitType: "large" },
      { id: 15, ingredientUnitType: "bulb" },
      { id: 16, ingredientUnitType: "clove" },
      { id: 17, ingredientUnitType: "bunch" },
      { id: 18, ingredientUnitType: "head" },
      { id: 19, ingredientUnitType: "other" }
    );
  }

  ingridientUnit(event) {
    this.ingridientunit = event.target.value;
  }

  ingridientUnit1(event) {
    this.ingridientunit1 = event.target.value;
    if (this.ingridientunit1 == "other") {
      this.editIngredientProcess.patchValue({
        editIngredientOthers: "",
      });
    }
  }

  toggleCheckSimpleIngidients(event) {
    this.newIngridients = event.target.checked;
  }

  toggleBudgetFriendly(event) {
    this.budgetFriendly = event.target.checked;
  }
  togglePostWorkout(event) {
    this.postWorkout = event.target.checked;
  }

  toggleCheckDontKnow(event) {
    this.dontKnow = event.target.checked;
    if (this.dontKnow == true) {
      this.addRecipeFrm.patchValue({
        [this.FORM_KEYS.CARBS]: 0,
        [this.FORM_KEYS.FAT]: 0,
        [this.FORM_KEYS.PROTEIN]: 0,
        [this.FORM_KEYS.CALORIES]: 0,
      });
      this.dontknowValue = true;
    } else {
      this.addRecipeFrm.patchValue({
        [this.FORM_KEYS.CARBS]: "",
        [this.FORM_KEYS.FAT]: "",
        [this.FORM_KEYS.PROTEIN]: "",
        [this.FORM_KEYS.CALORIES]: "",
      });
      this.dontknowValue = false;
    }
  }

  fetchAllHead() {
    this.io
      .apicall({}, "tagcategories/fetch-tag-head", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.allHeadList = res["result"];
          for (var i = 0; i < this.allHeadList.length; i++) {
            if (this.allHeadList[i].tagHeadId === "H6") {
              this.headTagMealTime(this.allHeadList[i].id);
            } else if (this.allHeadList[i].tagHeadId === "H4") {
              this.headTagFoodType(this.allHeadList[i].id);
            } else if (this.allHeadList[i].tagHeadId === "H3") {
              this.headTagCusine(this.allHeadList[i].id);
            }
          }
        }
      })
      .catch((err) => {
        throw err;
      });
    this.dietaryPreferencesService.listCategories().subscribe((res) => {
      this.dietaryPreferences = res;
      this.dietaryPreferences.forEach((item, index) => {
        item.dropdownSettings = {
          ...this.dropdownSettings,
          singleSelection: !item.multi && !(item.name=='Macronutrient Ratio'),
          textField: "name",
        };
        const diet_control = new FormControl([]);
        this.addRecipeFrm.addControl(item.name, diet_control);
        this.fetchDietaryPreferencesForCategory(item.id, index);
      });
    });
  }

  fetchDietaryPreferencesForCategory(id: string, index: number) {
    this.dietaryPreferencesService.getCategoryOptions(id).subscribe((res) => {
      this.dietaryPreferences[index] = {
        ...this.dietaryPreferences[index],
        options: res,
      };
      if (this.dietaryPreferences[index]?.selectionType == "exclude") {
        this.finalAddTagArr = this.finalAddTagArr.concat(
          this.dietaryPreferences[index].options.map((item) => {
            return { ...item, tagName: item.name + " Free" };
          })
        );
        this.handleExcludePreference(
          this.dietaryPreferences[index].name,
          index
        );
      }
    });
  }

  handleExcludePreference(controlName: string, index: number): void {
    const field_control = this.addRecipeFrm.get(controlName);
    field_control.valueChanges.subscribe((value) => {
      this.allListedItem = this.allListedItem.filter(
        (filteredItem) =>
          !value.find((searchItem) => searchItem?.id == filteredItem.id)
      );
      let options = [...this.dietaryPreferences[index].options];

      this.finalAddTagArr = options
        .filter(
          (filteredItem) =>
            !value.find((searchItem) => searchItem.id == filteredItem.id)
        )
        .map((item) => {
          return { ...item, tagName: item.name + " Free" };
        });
    });
  }

  async headTagFoodType(Obj) {
    let value = await this.giveTagList(Obj);
    this.foodTypeList = value;
  }

  async headTagMealTime(Obj) {
    let value = await this.giveTagList(Obj);
    this.mealtimeList = value;
  }

  async headTagCusine(Obj) {
    let value = await this.giveTagList(Obj);
    this.cuisineList = value;
  }

  async giveTagList(Obj): Promise<any> {
    return new Promise((resolve, reject) => {
      let payLoad = {
        tagHeadId: Obj,
      };
      this.io
        .apicall(payLoad, "tagcategories/fetch-tag-by-head", "POST")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            resolve(res["result"]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //=============================================' start of meal time '==================================================================//
  onItemSelect2(item: any) {
    //meal time single item Select
    this.mealTimeSelectUnselect.push({
      id: item.id,
      tagName: item.tagName,
    });
  }

  onSelectAll2(item: any) {
    //meal time all item Select
    this.mealTimeSelectUnselect = [];
    for (let i = 0; i < item.length; i++) {
      this.mealTimeSelectUnselect.push({
        id: item[i].id,
        tagName: item[i].tagName,
      });
    }
  }

  onItemDeSelect2(event: any) {
    //meal time single item deSelect
    this.mealTimeSelectUnselect.splice(
      this.mealTimeSelectUnselect.findIndex((tier) => tier.id === event.id),
      1
    );
  }

  onDeSelectAll2(item: any) {
    this.mealTimeSelectUnselect = [];
  }
  //=============================================' end of meal time '====================================================================//

  //=============================================' start of cusine ' =====================================================================//
  onItemSelect3(item: any) {
    //cuisine single item Select
    this.cuisineSelectUnselect.push({
      id: item.id,
      tagName: item.tagName,
    });
  }

  onSelectAll3(item: any) {
    //cuisine all item Select
    this.cuisineSelectUnselect = [];
    for (let i = 0; i < item.length; i++) {
      this.cuisineSelectUnselect.push({
        id: item[i].id,
        tagName: item[i].tagName,
      });
    }
  }

  onItemDeSelect3(event: any) {
    //cuisine single item deSelect
    this.cuisineSelectUnselect.splice(
      this.cuisineSelectUnselect.findIndex((tier) => tier.id === event.id),
      1
    );
  }

  onDeSelectAll3(item: any) {
    //cuisine all item deSelect
    this.cuisineSelectUnselect = [];
  }
  //==============================================' end of cusine '========================================================================//

  //==============================================' start of food type ' ================================================================//
  onItemSelect4(item: any) {
    //food type single item Select
    this.foodTypeSelectUnselect.push({
      id: item.id,
      tagName: item.tagName,
    });
  }

  onSelectAll4(item: any) {
    //food type all item Select
    this.foodTypeSelectUnselect = [];
    for (let i = 0; i < item.length; i++) {
      this.foodTypeSelectUnselect.push({
        id: item[i].id,
        tagName: item[i].tagName,
      });
    }
  }

  onItemDeSelect4(event: any) {
    //food type single item deSelect
    this.foodTypeSelectUnselect.splice(
      this.foodTypeSelectUnselect.findIndex((tier) => tier.id === event.id),
      1
    );
  }

  onDeSelectAll4(item: any) {
    //food type all item deSelect
    this.foodTypeSelectUnselect = [];
  }
  //==============================================' end of food type '===================================================================//

  onItemSelect6(item: any) {
    this.allListedItem.push({
      id: item.id,
      tagName: item.tagName,
    });
  }

  onSelectAll6(items: any) {
    //add tag all item Select
    this.allListedItem = [];
    for (let i = 0; i < items.length; i++) {
      this.allListedItem.push({
        id: items[i].id,
        tagName: items[i].tagName,
      });
    }
  }

  onItemDeSelect6(event) {
    //add tag single item deSelect
    this.allListedItem.splice(
      this.allListedItem.findIndex((tier) => tier.id === event.id),
      1
    );
  }
  onDeSelectAll6(event) {
    //add tag all item deSelect
    this.allListedItem = [];
  }

  open(content) {
    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  colorPicker(e) {
    this.backgroundcolor = e;
    this.color = e;
  }

  addTier() {
    let validNumber = new RegExp(/^(\d+\.?\/?\d+|\d+(\s\d+\/\d+)?)$/);
    if (!validNumber.test(this.ingredientCount_)) {
      swal.fire({
        title: "Oops...",
        text: "Quantity is invalid",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return;
    }
    if (
      !this.ingreDients ||
      !this.ingredientUnitType_ ||
      !this.ingredientCount_ ||
      (this.ingridientunit == "other" && !this.otherIngriDients_)
    ) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all fields",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return;
    }
    if (this.ingridientunit != "other") {
      this.addtier.push({
        ingredientText: this.ingredient.nativeElement.value,
        ingredientCount: this.ingredientCount_,
        ingredientUnit: this.ingredientUnitType_,
        unit: this.ingridientunit,
      });
    } else {
      this.addtier.push({
        ingredientText: this.ingredient.nativeElement.value,
        ingredientCount: this.ingredientCount_,
        ingredientUnit: this.otherIngriDients_,
        unit: this.ingridientunit,
      });
    }

    this.ingredient.nativeElement.value = "";
    (<HTMLInputElement>document.getElementById("ingredient-btn")).disabled =
      true;
    this.ingreDients = "";
    this.ingredientCount_ = "";
    this.ingredientUnitType_ = "";
    this.otherIngriDients_ = "";
    this.ingridientunit = "";
    //this.ingredientValue = this.ingredient.nativeElement.value;
  }
  deleteTier(index: number) {
    this.addtier.splice(index, 1);
  }

  addProcess() {
    this.prepProcessIndex = false;
    let mystring = this.prepProcess.split(".");
    this.addprocess.push({
      prepprocess: this.prepProcess.substring(mystring[0].length),
    });
    this.prepProcess = "";
  }

  abc() {
    if (this.prepProcess.length > 0) {
      this.prepProcessIndex = true;
      this.prepProcess = this.addprocess.length + 1 + ". " + this.prepProcess;
    }
  }

  abcd() {
    if (this.prepProcess.length == 0) {
      this.prepProcessIndex = false;
      //this.prepProcess = (this.addprocess.length + 1) +  ' ' + this.prepProcess;
    }
  }

  deleteProcess(index: number) {
    this.addprocess.splice(index, 1);
  }

  open1(content, item, index) {
    this.processIndex = index;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
    this.editProcess.patchValue({
      editprocess: item?.prepprocess.replace(/\./g, "").trim(),
    });
  }

  processEdit() {
    this.addprocess[this.processIndex].prepprocess =
      ". " + this.editProcess.value.editprocess;
    this.modalService.dismissAll();
  }

  open2(content, item, index) {
    this.ingredientIndex = index;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
    if (item.unit != "other") {
      this.editIngredientProcess.patchValue({
        editIngredientText: item?.ingredientText,
        editIngredientQuantity: item?.ingredientCount,
        editIngredientUnitType: item?.ingredientUnit,
        // editIngredientOthers: item?.otherIngriDients_,
      });
    } else {
      this.editIngredientProcess.patchValue({
        editIngredientText: item?.ingredientText,
        editIngredientQuantity: item?.ingredientCount,
        editIngredientUnitType: item?.unit,
        editIngredientOthers: item?.ingredientUnit,
      });
      this.editIngredientProcess.updateValueAndValidity();
    }
  }

  editTier() {
    let validNumber = new RegExp(/^(\d+\.?\/?\d+|\d+(\s\d+\/\d+)?)$/);
    if (
      !validNumber.test(
        this.editIngredientProcess.get("editIngredientQuantity").value
      )
    ) {
      swal.fire({
        title: "Oops...",
        text: "Quantity is invalid",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return;
    }
    if (
      !this.editIngredientProcess.get("editIngredientText").value ||
      !this.editIngredientProcess.get("editIngredientQuantity").value ||
      !this.editIngredientProcess.get("editIngredientUnitType").value ||
      (this.editIngredientProcess.get("editIngredientUnitType").value ==
        "other" &&
        !this.editIngredientProcess.get("editIngredientOthers").value)
    ) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all fields",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return;
    }
    if (this.editIngredientProcess.value.editIngredientUnitType != "other") {
      this.addtier[this.ingredientIndex].ingredientText =
        this.editIngredientProcess.value.editIngredientText;
      this.addtier[this.ingredientIndex].ingredientCount =
        this.editIngredientProcess.value.editIngredientQuantity;
      this.addtier[this.ingredientIndex].ingredientUnit =
        this.editIngredientProcess.value.editIngredientUnitType;
      this.addtier[this.ingredientIndex].unit =
        this.editIngredientProcess.value.editIngredientUnitType;
    } else {
      this.addtier[this.ingredientIndex].ingredientText =
        this.editIngredientProcess.value.editIngredientText;
      this.addtier[this.ingredientIndex].ingredientCount =
        this.editIngredientProcess.value.editIngredientQuantity;
      this.addtier[this.ingredientIndex].ingredientUnit =
        this.editIngredientProcess.value.editIngredientOthers;
      this.addtier[this.ingredientIndex].unit =
        this.editIngredientProcess.value.editIngredientUnitType;
    }
    this.editIngredientProcess.value.editIngredientText = "";
    this.editIngredientProcess.value.editIngredientQuantity = "";
    this.editIngredientProcess.value.editIngredientOthers = "";
    this.editIngredientProcess.value.editIngredientUnitType = "";
    this.modalService.dismissAll();
  }

  async addRecipe() {
    if (!this.addRecipeFrm.valid || this.addtier.length === 0) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.addRecipeFrm.markAllAsTouched();
      return;
    }

    if (!this.image) {
      swal.fire({
        title: "Oops...",
        text: "Please add recipe image.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }
    await this.saveImage();
    let dietary_preferences = {};
    this.dietaryPreferences.forEach((item) => {
      dietary_preferences[item.id] = this.addRecipeFrm
        .get(item.name)
        .value?.map((preference) => preference.id);
    });
    let payload = {
      recipeName: this.addRecipeFrm.get(this.FORM_KEYS.RECIPE_NAME).value,
      creatorId: this.creatorid,
      prepTime: parseInt(
        this.addRecipeFrm.get(this.FORM_KEYS.PREPARATION_TIME).value
      ),
      carbs: this.addRecipeFrm.get(this.FORM_KEYS.CARBS).value,
      fat: this.addRecipeFrm.get(this.FORM_KEYS.FAT).value,
      protein: this.addRecipeFrm.get(this.FORM_KEYS.PROTEIN).value,
      serving: this.addRecipeFrm.get(this.FORM_KEYS.SERVING).value,
      totalCalories: this.addRecipeFrm.get(this.FORM_KEYS.CALORIES).value,
      IsNew: true,
      isSimpleIngredient: this.newIngridients,
      isPostWorkOut: this.postWorkout,
      isBudgetFriendly: this.budgetFriendly,
      cardColorCode: this.color,
      prepProcess: this.addprocess.map((item) =>
        item.prepprocess.replace(/[\r\n]+/gm, "")
      ),
      nutritionalInfo: this.allListedItem,
      ingredients: this.addtier
        ? this.addtier.map(
            (item) =>
              item.ingredientCount +
              " " +
              item.ingredientUnit +
              " " +
              item.ingredientText
          )
        : "",
      recipeImage: this.image,
      IDontKnowCalories: this.dontknowValue,
      IngredientList: this.addtier,
      headTagInfo: [
        {
          headId: this.allHeadList[0].id,
          tags: this.mealTimeSelectUnselect,
        },
        {
          headId: this.allHeadList[3].id,
          tags: this.cuisineSelectUnselect,
        },
        {
          headId: this.allHeadList[2].id,
          tags: this.foodTypeSelectUnselect,
        },
      ],
      dietaryPreferences: dietary_preferences,
    };

    let convToText = JSON.stringify(payload.headTagInfo);
    let srchAndReplace = convToText.split("id").join("tagId");
    let convToJson = JSON.parse(srchAndReplace);
    payload.headTagInfo = convToJson;

    let convToText1 = JSON.stringify(payload.nutritionalInfo);
    let srchAndReplace1 = convToText1.split("id").join("tagId");
    let convToJson1 = JSON.parse(srchAndReplace1);
    payload.nutritionalInfo = convToJson1;

    this.io
      .apicall(payload, "recipe/add-recipe", "POST")
      .then((res) => {
        if (this.io.data_.serverResponse.code == 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.router.navigate(["/recipe/recipe-list"]);
          this.addRecipeFrm.reset();
          this.addtier = [];
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  addIngredients() {
    if (this.ingredient.nativeElement.value.length > 0) {
      (<HTMLInputElement>document.getElementById("ingredient-btn")).disabled =
        false;
    } else {
      (<HTMLInputElement>document.getElementById("ingredient-btn")).disabled =
        true;
    }
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
      this.image = this.imageChangedEvent;
    }
    //this.fileToUpload = event.target.files[0];
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }
  imageLoaded(image: HTMLImageElement) {
    this.showCropper = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  async saveImage(): Promise<any> {
    return new Promise((resolve, reject) => {
      const fd = new FormData();
      fd.append("file", this.finalFile);
      this.io
        .apicall(fd, "uploads/recipe-img-upload", "POST")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            this.image = res["result"][0].fileUrl;
            resolve(res["result"][0].fileUrl);
            this.modalService.dismissAll();
          }
        })
        .catch((err) => {
          if (err) {
            reject(err);
          }
        });
    });
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  closeModal() {
    this.modalService.dismissAll();
    this.image = "";
    this.enableCropper = false;
  }
  save() {
    this.modalService.dismissAll();
  }

  selectColor(event) {
    this.choosedColor = event.target.value;
  }
}
