import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ManagementRoutingModule } from './management-routing.module';
import { ManagementComponent } from './management.component';
import { TagManagementComponent } from './tag-management/tag-management.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CategoryComponent } from './category/category.component';
import { ImageCropperModule } from 'ngx-image-cropper';


@NgModule({
  declarations: [ManagementComponent, TagManagementComponent, CategoryComponent],
  imports: [
    CommonModule,
    ManagementRoutingModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    BsDropdownModule,
    ImageCropperModule
  ]
})
export class ManagementModule { }
