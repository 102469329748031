import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { IoService } from "../../services/http/io.service";
import swal from "sweetalert2";

@Component({
  selector: 'app-custom-menu-submit',
  templateUrl: './custom-menu-submit.component.html',
  styleUrls: ['./custom-menu-submit.component.css']
})
export class CustomMenuSubmitComponent implements OnInit {
  resturantList: { id: number, restaurantName: string }[] = [];
  restaurantsDropdownSelected: { id: number, restaurantName: string }[] = [];

  restaurantDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'restaurantName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  fileInput: File = null;
  displayFileName: string = null;

  uploading: boolean = false;
  downloading: boolean = false;

  constructor(
    private io: IoService,
  ) { }

  ngOnInit(): void {
    this.getAllRestaurants();
  }

  async getAllRestaurants() {
    try {
      this.resturantList = ((await this.io.apicall({}, 'restaurant/get-restaurant-list-for-dropdown', 'POST')) as any).result;
    } catch (err) {
      swal.fire({
        title: "Oops...",
        text: 'Failed to load restaurants list!',
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
    }
  }

  onFileChange(event) {
    if (!event?.target?.files?.[0]) return;

    this.fileInput = event.target.files[0];
    this.displayFileName = this.fileInput.name;
  }

  loading() {
    return this.uploading || this.downloading;
  }

  submitDisabled() {
    return !(this.fileInput && this.restaurantsDropdownSelected.length) || this.loading();
  }

  downloadDisabled() {
    return !this.restaurantsDropdownSelected.length || this.loading();
  }

  async submit() {
    if (this.submitDisabled()) return;

    this.uploading = true;

    const formData = new FormData();

    formData.append('menu', this.fileInput);

    try {
      await this.io.postRequest(`menu/portal/custom-menu/${this.restaurantsDropdownSelected[0].id}`, formData);

      swal.fire({
        title: "Success",
        text: 'Custom menu uploaded successfully!',
        icon: "success",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
    } catch (err) {
      swal.fire({
        title: "Oops...",
        text: err?.error?.serverResponse?.message || 'Invalid Csv!',
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
    }

    this.uploading = false;
  }

  async downloadMenu() {
    if (this.downloadDisabled()) return;

    this.downloading = true;

    try {
      const fileBlob = await this.io.downloadFile(`menu/portal/custom-menu/${this.restaurantsDropdownSelected[0].id}`);

      this.downloadBlob(fileBlob, `${this.restaurantsDropdownSelected[0].restaurantName} Menu`)
    } catch (err) {
      swal.fire({
        title: "Oops...",
        text: 'Something went wrong!',
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
    }

    this.downloading = false;
  }

  downloadBlob(blob, fileName) {
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');

    link.href = blobUrl;
    link.download = fileName;

    document.body.appendChild(link);

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    URL.revokeObjectURL(blobUrl);

    document.body.removeChild(link);
  }

  onItemSelect(event) { }

  onSelectAll(event) { }
}
