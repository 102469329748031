export const mapBannerTypeToSearchPlaceholderText = {
  'dish': 'Search for dishes',
  'recipe': 'Search for recipes',
  'restaurant': 'Search for restaurants',
  'recipeCreator': 'Search for recipe creators',
  'category': 'Search for categories',
  'mealPlan': 'Search for meal plans',
  'static': '',
  'externalLink': '',
};
