import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  OnDestroy,
} from "@angular/core";
import { IoService } from "../../services/http/io.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MapsAPILoader } from "@agm/core";
import { Location } from "@angular/common";
import {
  base64ToFile,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import { DataService } from "../../services/data-management/data.service";
import { HttpClient } from "@angular/common/http";
import { RestaurantService } from "./../../services/restaurant/restaurant.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ValidatorsService } from "./../../services/validators/valdiators.service";
import { sortDaysArray } from "../../utils/sortDaysArray";
import { Subscription } from "rxjs";
declare var google: any;
@Component({
  selector: "app-edit-restaurant",
  templateUrl: "./edit-restaurant.component.html",
  styleUrls: ["./edit-restaurant.component.css"],
})
export class EditRestaurantComponent implements OnInit, OnDestroy {
  CHANNELS = [
    { key: 'mep', name: 'MyEatPal' },
    { key: 'deliverect', name: 'Deliverect' },
  ];
  DELIVERY_METHODS = [
    { key: 'restaurant', name: 'Restaurant' },
    { key: 'careem', name: 'Careem' },
  ];
  imageChangedEvent: any = "";
  imageChangedEvent1: any = "";
  croppedImage: any = "";
  croppedImage1: any = "";
  image: any;
  logoimage: any;
  fileToUpload: any;
  canvasRotation = 0;
  scale = 1;
  transform: ImageTransform = {};
  showCropper = false;
  rotation = 0;
  containWithinAspectRatio = false;
  closeResult = "";
  enableCropper = false;
  recipeViewResult: any;
  creatorid: any;
  file: any;
  finalFile: any;
  logoFinalFile: any;
  editRestaurantFrm: FormGroup;
  restaurantId: any;
  restaurantViewResult: any;
  locationName: any;
  lat: any;
  lng: any;
  @ViewChild("search") search: ElementRef;
  onlyCheck: any = "";
  restaurantCheck: any = "";
  countries: any;
  setTitle: any;
  logo: boolean = false;
  backgroundlogo: boolean = false;
  deliveryToggleBtn: boolean = false;
  pickupToggleBtn: boolean = false;
  maxAmount_: any;
  slavMaxDistance_: any;
  slavRangeForm_: any = 0;
  slavRangeTo_: any;
  slavDeliveryAmount_: any;
  deliverySlav: any = [];
  endDeliverySlavIndex = 0;
  workingHours: any[] = [];
  selectedCountry: any = "";
  countryCode: any = "";
  paramObserver: Subscription;
  emailPattern = "^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$";
  FORM_KEYS: {
    RESTAURANT_NAME: string;
    CONTACT_NUMBER: string;
    EMAIL: string;
    CITY: string;
    ADDRESS: string;
    LANDMARK: string;
    LATITUDE: string;
    LONGITUDE: string;
    STATUS: string;
    DISH_COUNT: string;
    RESTAURANT_DETAILS: string;
    COMMISSION_RATE: string;
    MINIMUM_ORDER_AMOUNT: string;
    COUNTRY: string;
    WORKING_HOURS: string;
    SMS_NOTIFICATION: string;
    SMS_PHONE: string;
    CHANNEL: string;
    CHANNEL_LINK_ID: string;
    DELIVERY_METHOD: string;
    BRANCH_NAME: string;
    NOT_ON_MYEATPAL: string;
  };
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private io: IoService,
    private router: Router,
    private ngZone: NgZone,
    public location: Location,
    private mapsAPILoader: MapsAPILoader,
    private dataservice: DataService,
    private http: HttpClient,
    private RestaurantService: RestaurantService,
    private loader: NgxUiLoaderService,
    private ValidatorsService: ValidatorsService
  ) {
    this.FORM_KEYS = {
      RESTAURANT_NAME: "restaurantName",
      CONTACT_NUMBER: "contactNumber",
      EMAIL: "email",
      CITY: "city",
      ADDRESS: "address",
      LANDMARK: "landmark",
      LATITUDE: "latitude",
      LONGITUDE: "longitude",
      STATUS: "status",
      DISH_COUNT: "dishCount",
      RESTAURANT_DETAILS: "restaurantDetails",
      COMMISSION_RATE: "commissionRate",
      MINIMUM_ORDER_AMOUNT: "minimumOrderAmount",
      COUNTRY: "country",
      WORKING_HOURS: "workingHours",
      SMS_NOTIFICATION: "smsNotification",
      SMS_PHONE: "smsPhone",
      CHANNEL: "channel", 
      CHANNEL_LINK_ID: "channelLinkId",
      DELIVERY_METHOD: 'deliveryMethod',
      BRANCH_NAME: "branchName",
      NOT_ON_MYEATPAL: "notOnMyeatpal",
    };
    this.editRestaurantFrm = this.fb.group({
      [this.FORM_KEYS.RESTAURANT_NAME]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.BRANCH_NAME]: [null],
      [this.FORM_KEYS.CONTACT_NUMBER]: [
        null,
        Validators.compose([
          Validators.required,
          this.ValidatorsService.validatePhoneNumber(
            () => this.countryCode,
            "phone"
          ),
        ]),
      ],
      [this.FORM_KEYS.EMAIL]: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      [this.FORM_KEYS.CITY]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.ADDRESS]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.LANDMARK]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.STATUS]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.DISH_COUNT]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.RESTAURANT_DETAILS]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.LATITUDE]: [""],
      [this.FORM_KEYS.LONGITUDE]: [""],
      [this.FORM_KEYS.COMMISSION_RATE]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.MINIMUM_ORDER_AMOUNT]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.COUNTRY]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.WORKING_HOURS]: [
        null,
        [ValidatorsService.validateWorkingHours],
      ],
      [this.FORM_KEYS.SMS_NOTIFICATION]: [false],
      [this.FORM_KEYS.NOT_ON_MYEATPAL]: [false],
      [this.FORM_KEYS.SMS_PHONE]: [null],
      [this.FORM_KEYS.CHANNEL]: [
        null,
        Validators.compose([Validators.required])
      ],
      [this.FORM_KEYS.CHANNEL_LINK_ID]: [null],
      [this.FORM_KEYS.DELIVERY_METHOD]: [
        null,
        Validators.compose([Validators.required])
      ],
    });
    this.setCustomValidators();
  }

  setCustomValidators(): void {
    const smsNotificationControl = this.editRestaurantFrm.get(
      this.FORM_KEYS.SMS_NOTIFICATION
    );
    const smsPhoneControl = this.editRestaurantFrm.get(
      this.FORM_KEYS.SMS_PHONE
    );
    smsNotificationControl.valueChanges.subscribe((value) => {
      if (value) {
        smsPhoneControl.setValidators([
          Validators.required,
          this.ValidatorsService.validatePhoneNumber(
            () => this.countryCode,
            "mobile"
          ),
          ,
        ]);
      } else {
        smsPhoneControl.setValidators(null);
      }
      smsPhoneControl.updateValueAndValidity();
    });

    this.editRestaurantFrm.get(this.FORM_KEYS.CHANNEL).valueChanges.subscribe((value: string) => {
      if (value !== this.CHANNELS[0].key) {
        this.editRestaurantFrm.get(this.FORM_KEYS.CHANNEL_LINK_ID).setValidators(Validators.required);
      } else {
        this.editRestaurantFrm.get(this.FORM_KEYS.CHANNEL_LINK_ID).clearValidators();
      }

      this.editRestaurantFrm.get(this.FORM_KEYS.CHANNEL_LINK_ID).updateValueAndValidity();
    });

    this.editRestaurantFrm.get(this.FORM_KEYS.NOT_ON_MYEATPAL).valueChanges.subscribe((value: boolean) => {
      const contactNo = this.editRestaurantFrm.get(this.FORM_KEYS.CONTACT_NUMBER);
      const smsPhoneControl = this.editRestaurantFrm.get(this.FORM_KEYS.SMS_PHONE);
      const email = this.editRestaurantFrm.get(this.FORM_KEYS.EMAIL);
      const address = this.editRestaurantFrm.get(this.FORM_KEYS.ADDRESS);
      const city = this.editRestaurantFrm.get(this.FORM_KEYS.CITY);
      const landmark = this.editRestaurantFrm.get(this.FORM_KEYS.LANDMARK);
      const commission = this.editRestaurantFrm.get(this.FORM_KEYS.COMMISSION_RATE);
      const minimumOrderAmount = this.editRestaurantFrm.get(this.FORM_KEYS.MINIMUM_ORDER_AMOUNT);
      const deliveryMethod = this.editRestaurantFrm.get(this.FORM_KEYS.DELIVERY_METHOD);
      const channelLinkId = this.editRestaurantFrm.get(this.FORM_KEYS.CHANNEL_LINK_ID);
      const workingHours = this.editRestaurantFrm.get(this.FORM_KEYS.WORKING_HOURS);
      const restaurantDetails = this.editRestaurantFrm.get(this.FORM_KEYS.RESTAURANT_DETAILS);

      if (value) {
        contactNo.clearValidators();
        smsPhoneControl.clearValidators();
        email.clearValidators();
        address.clearValidators();
        city.clearValidators();
        landmark.clearValidators();
        commission.clearValidators();
        minimumOrderAmount.clearValidators();
        deliveryMethod.clearValidators();
        channelLinkId.clearValidators();
        workingHours.clearValidators();
        restaurantDetails.clearValidators();
      } else {
        contactNo.setValidators(
          Validators.compose([
            Validators.required,
            this.ValidatorsService.validatePhoneNumber(
              () => this.countryCode,
              "phone"
            ),
          ])
        );

        if (this.editRestaurantFrm.get(this.FORM_KEYS.SMS_NOTIFICATION).value) {
          smsPhoneControl.setValidators([
            Validators.required,
            this.ValidatorsService.validatePhoneNumber(
              () => this.countryCode,
              "mobile"
            ),
            ,
          ]);
        } else {
          smsPhoneControl.setValidators(null);
        }

        email.setValidators(
          Validators.compose([
            Validators.required,
            Validators.pattern(this.emailPattern),
          ])
        );
        address.setValidators(
          Validators.compose([Validators.required])
        );
        city.setValidators(Validators.compose([Validators.required]));
        landmark.setValidators(Validators.compose([Validators.required]));
        commission.setValidators(Validators.compose([Validators.required]));
        minimumOrderAmount.setValidators(Validators.compose([Validators.required]));
        deliveryMethod.setValidators(Validators.compose([Validators.required]));
        if (this.editRestaurantFrm.get(this.FORM_KEYS.CHANNEL).value !== this.CHANNELS[0].key) {
          channelLinkId.setValidators(Validators.required);
        } else {
          channelLinkId.clearValidators();
        }
        workingHours.setValidators(
          Validators.compose([
            this.ValidatorsService.validateWorkingHours
          ])
        );
        restaurantDetails.setValidators(Validators.compose([Validators.required]));
      }

      contactNo.updateValueAndValidity();
      smsPhoneControl.updateValueAndValidity();
      email.updateValueAndValidity();
      address.updateValueAndValidity();
      city.updateValueAndValidity();
      landmark.updateValueAndValidity();
      commission.updateValueAndValidity();
      minimumOrderAmount.updateValueAndValidity();
      deliveryMethod.updateValueAndValidity();
      channelLinkId.updateValueAndValidity();
      workingHours.updateValueAndValidity();
      restaurantDetails.updateValueAndValidity();
    });
  }

  ngOnInit(): void {
    this.getLoc();
    this.paramObserver = this.route.params.subscribe(({ id }) => {
      if (id) {
        this.restaurantId = id;
        this.restaurantView();
      } else {
        this.router.navigate(["/resturant/restaurant-list"]);
      }
    });
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.search.nativeElement,
        { types: [] }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // get the place result //
          const place: typeof google.maps.places.PlaceResult =
            autocomplete.getPlace();
          if (!place.place_id) {
            window.alert("Please select an option from the dropdown list.");
            return;
          }
          this.locationName = place.name;
          // verify result //
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // set latitude, longitude and zoom //
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.editRestaurantFrm.patchValue({
            [this.FORM_KEYS.ADDRESS]: this.locationName,
            [this.FORM_KEYS.LATITUDE]: this.lat,
            [this.FORM_KEYS.LONGITUDE]: this.lng,
          });
          //this.zoom = 12;
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  updateFormWorkingHours(newHours) {
    this.editRestaurantFrm.patchValue({
      [this.FORM_KEYS.WORKING_HOURS]: newHours,
    });
  }

  handleHoursChange(value, index, field): void {
    let current = { ...this.workingHours[index] };
    current[Object.keys(current)[0]] = {
      ...current[Object.keys(current)[0]],
      [field]: value,
    };
    this.workingHours[index] = current;
    this.updateFormWorkingHours(this.workingHours);
  }

  getDayFieldValue(item, field): string {
    if (field === "day") {
      return Object.keys(item.value)[0];
    } else {
      return null;
    }
  }

  isDayOpen(item) {
    return item.value[Object.keys(item.value)[0]] !== null;
  }

  handleDayOpenClose(index) {
    let current = { ...this.workingHours[index] };
    if (current[Object.keys(current)[0]] == null) {
      current[Object.keys(current)[0]] = { from: "09:00", to: "22:00" };
    } else {
      current[Object.keys(current)[0]] = null;
    }
    this.workingHours[index] = current;
    this.updateFormWorkingHours(this.workingHours);
  }

  deleteRestaurant(): void {
    this.loader.start();
    this.RestaurantService.delete(this.restaurantId).subscribe(
      (res) => {
        swal.fire({
          title: "Success",
          text: "Deleted Successfully",
          icon: "success",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.modalService.dismissAll();
        this.loader.stop();
        this.router.navigate(["/resturant/restaurant-list"]);
      },
      (err) => {
        this.modalService.dismissAll();
        swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }

  getLoc() {
    /* this.http.get<any>("./assets/data/country.json").subscribe((data) => {
      this.countries = data.countries;
      if (this.countries.length == 1) {
        this.selectedCountry = this.countries[0].name;
        this.countryCode = this.countries[0].code;
        this.editRestaurantFrm.patchValue({
          countryType: this.selectedCountry,
        });
      }
    }); */
    this.countries = [
      {
        code: "+971",
        name: "United Arab Emirates",
      },
    ];
    this.selectedCountry = this.countries[0].name;
    this.countryCode = this.countries[0].code;
    this.editRestaurantFrm.patchValue({
      country: this.selectedCountry,
    });
  }

  changeCountry(event) {
    this.selectedCountry = event.target.value;
    let index = this.countries.filter(
      (item) => item.name === this.selectedCountry
    );
    this.countryCode = index[0].code;
  }

  open(content, status = null) {
    if (status == "background") {
      this.setTitle = "Restaurant Background image";
      this.logo = false;
      this.backgroundlogo = true;
    }
    if (status == "logo") {
      this.setTitle = "Restaurant Logo image";
      this.backgroundlogo = false;
      this.logo = true;
    }
    this.imageChangedEvent = "";
    this.imageChangedEvent1 = "";
    this.croppedImage = "";
    this.croppedImage1 = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  restaurantView() {
    let payload = {
      restaurantId: this.restaurantId,
    };
    this.io
      .apicall(payload, "restaurant/restaurant-view", "POST")
      .then((res) => {
        if (res["serverResponse"].code === 200) {
          this.restaurantViewResult = res["result"][0];
          let storeResult = res["result"][0];
          let selectedCountry = this.restaurantViewResult.country;
          let index = this.countries.filter(
            (item) => item.name === selectedCountry
          );
          if (index.length !== 0) {
            this.countryCode = index[0].code;
          }
          //this.deliverySlav =  storeResult.deliverySlav;
          this.slavMaxDistance_ = storeResult.maxDistCoveredByRestaurant;
          this.deliveryToggleBtn = storeResult.isDeliveryAvaliable
            ? storeResult.isDeliveryAvaliable
            : false;
          this.pickupToggleBtn = storeResult.isPickupAvaliable
            ? storeResult.isPickupAvaliable
            : false;
          this.restaurantCheck = this.restaurantViewResult?.IsNew;
          this.onlyCheck = this.restaurantViewResult?.isOnly;
          this.image = this.restaurantViewResult.imageUrl;
          this.logoimage = this.restaurantViewResult.restaurantLogo;
          if (
            !this.restaurantViewResult?.workingHours ||
            Object.keys(this.restaurantViewResult.workingHours)?.length == 0
          ) {
            this.workingHours = [
              { monday: null },
              { tuesday: null },
              { wednesday: null },
              { thursday: null },
              { friday: null },
              { saturday: null },
              { sunday: null },
            ];
          } else {
            this.workingHours = Object.entries(
              this.restaurantViewResult.workingHours
            ).map((item) => {
              let arr: Array<any> = [];
              let obj = {};
              arr = arr.concat(item[1]);
              if (arr.length > 0) {
                obj = { ...arr[0] };
              }
              return { [item[0]]: arr.length == 0 ? null : obj };
            });
          }
          this.workingHours = sortDaysArray(this.workingHours);
          this.editRestaurantFrm.patchValue({
            [this.FORM_KEYS.RESTAURANT_NAME]:
              this.restaurantViewResult.restaurantName,
            [this.FORM_KEYS.CONTACT_NUMBER]:
              this.restaurantViewResult.contactNo,
            [this.FORM_KEYS.EMAIL]: this.restaurantViewResult.email,
            [this.FORM_KEYS.CITY]: this.restaurantViewResult.city,
            [this.FORM_KEYS.STATUS]: this.restaurantViewResult?.isActive
              ? "Active"
              : "Inactive",
            [this.FORM_KEYS.ADDRESS]: this.restaurantViewResult.address,
            [this.FORM_KEYS.LANDMARK]: this.restaurantViewResult.landmark,
            [this.FORM_KEYS.DISH_COUNT]: this.restaurantViewResult.numberOfDish,
            [this.FORM_KEYS.RESTAURANT_DETAILS]:
              this.restaurantViewResult.restaurantDetails,
            [this.FORM_KEYS.LATITUDE]: this.restaurantViewResult.latitude,
            [this.FORM_KEYS.LONGITUDE]: this.restaurantViewResult.longitude,
            [this.FORM_KEYS.COMMISSION_RATE]:
              this.restaurantViewResult.commissionRate,
            [this.FORM_KEYS.MINIMUM_ORDER_AMOUNT]:
              this.restaurantViewResult.minimumOrderAmount,
            [this.FORM_KEYS.COUNTRY]:
              this.countries?.length == 1
                ? this.selectedCountry
                : this.restaurantViewResult.country,
            [this.FORM_KEYS.WORKING_HOURS]: this.workingHours,
            [this.FORM_KEYS.SMS_NOTIFICATION]:
              this.restaurantViewResult?.notifyOnNewOrder,
            [this.FORM_KEYS.SMS_PHONE]:
              this.restaurantViewResult?.notificationNumber || null,
            [this.FORM_KEYS.CHANNEL]: this.restaurantViewResult?.channel,
            [this.FORM_KEYS.CHANNEL_LINK_ID]: this.restaurantViewResult?.channelLinkId,
            [this.FORM_KEYS.DELIVERY_METHOD]: this.restaurantViewResult?.deliveryMethod,
            [this.FORM_KEYS.BRANCH_NAME]: this.restaurantViewResult?.branchName,
            [this.FORM_KEYS.NOT_ON_MYEATPAL]: this.restaurantViewResult?.notOnMyeatpal,
          });
          this.editRestaurantFrm.markAllAsTouched();
          this.deliverySlav = [];
          for (let slav of storeResult.deliverySlav) {
            this.deliverySlav.push({
              rangeFrom: slav.rangeFrom,
              rangeTo: slav.rangeTo,
              deliveryAmount: slav.deliveryAmount,
            });
          }
          this.endDeliverySlavIndex = this.deliverySlav.length - 1;

          if (this.deliverySlav.length > 0) {
            this.slavRangeForm_ =
              this.deliverySlav[this.deliverySlav.length - 1].rangeTo + 1;
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  toggleCheckIsOnly(event) {
    this.onlyCheck = event.target.checked;
  }
  toggleCheckRestaurant(event) {
    this.restaurantCheck = event.target.checked;
  }

  editRestaurant(value) {
    if (!this.editRestaurantFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.editRestaurantFrm.markAllAsTouched();
      return;
    }

    const notOnMyeatpal = value[this.FORM_KEYS.NOT_ON_MYEATPAL];

    let hoursParsed = {};
    this.workingHours.forEach((item) => {
      hoursParsed[Object.keys(item)[0]] =
        item[Object.keys(item)[0]] == null ? [] : [item[Object.keys(item)[0]]];
    });
    let payload = {
      restaurantId: this.restaurantId,
      restaurantName: value[this.FORM_KEYS.RESTAURANT_NAME],
      branchName: notOnMyeatpal ? null : value[this.FORM_KEYS.BRANCH_NAME],
      contactNo: notOnMyeatpal ? null : value[this.FORM_KEYS.CONTACT_NUMBER].toString(),
      email: notOnMyeatpal ? null : value[this.FORM_KEYS.EMAIL],
      city: notOnMyeatpal ? null : value[this.FORM_KEYS.CITY],
      country: value[this.FORM_KEYS.COUNTRY],
      address: notOnMyeatpal ? null : value[this.FORM_KEYS.ADDRESS],
      landmark: notOnMyeatpal ? null : value[this.FORM_KEYS.LANDMARK],
      latitude: value[this.FORM_KEYS.LATITUDE],
      longitude: value[this.FORM_KEYS.LONGITUDE],
      imageUrl: notOnMyeatpal ? null : this.image,
      restaurantLogo: this.logoimage,
      IsNew: this.restaurantCheck ? this.restaurantCheck : false,
      isOnly: notOnMyeatpal ? false : this.onlyCheck ? this.onlyCheck : false,
      restaurantDetails: notOnMyeatpal ? null : value[this.FORM_KEYS.RESTAURANT_DETAILS],
      commissionRate: notOnMyeatpal ? null : value[this.FORM_KEYS.COMMISSION_RATE],
      minimumOrderAmount: notOnMyeatpal ? 0 : value[this.FORM_KEYS.MINIMUM_ORDER_AMOUNT],
      workingHours: notOnMyeatpal ? null : hoursParsed,
      channel: notOnMyeatpal ? this.CHANNELS[0].key : value[this.FORM_KEYS.CHANNEL],
      channelLinkId: notOnMyeatpal ? null : value[this.FORM_KEYS.CHANNEL_LINK_ID],
      deliveryMethod: notOnMyeatpal ? this.DELIVERY_METHODS[0].key : value[this.FORM_KEYS.DELIVERY_METHOD],
      notifyOnNewOrder: notOnMyeatpal ? false : value[this.FORM_KEYS.SMS_NOTIFICATION],
      notOnMyeatpal: notOnMyeatpal,
      notificationNumber: notOnMyeatpal ? null :
        value[this.FORM_KEYS.SMS_NOTIFICATION]
          ? value[this.FORM_KEYS.SMS_PHONE]
          : null,
    };
    this.io
      .apicall(payload, "restaurant/edit-restaurant", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.router.navigate(["/resturant/restaurant-list"]);
          //this.editRestaurantFrm.reset();
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
      this.image = this.imageChangedEvent.target.value;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }
  imageLoaded(image: HTMLImageElement) {
    this.showCropper = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  fileChangeEvent1(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.logoimage = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent1 = event;
      this.logoimage = this.imageChangedEvent1;
    }
    //this.fileToUpload = event.target.files[0];
  }
  imageCropped1(event: ImageCroppedEvent) {
    this.croppedImage1 = event.base64;
    this.logoFinalFile = base64ToFile(this.croppedImage1);
    this.enableCropper = true;
  }
  imageLoaded1(image: HTMLImageElement) {
    this.showCropper = true;
  }
  cropperReady1() {
    // cropper ready
  }
  loadImageFailed1() {
    // show message
  }

  saveImage() {
    const fd = new FormData();
    fd.append("file", this.finalFile);
    this.io
      .apicall(fd, "uploads/restaurant-img-upload", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.image = res["result"][0].fileUrl;
          this.modalService.dismissAll();
        }
      });
  }

  logoImage() {
    const fd = new FormData();
    fd.append("file", this.logoFinalFile);
    this.io
      .apicall(fd, "uploads/restaurant-img-upload", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.logoimage = res["result"][0].fileUrl;
          this.modalService.dismissAll();
        }
      });
  }

  closeModal() {
    this.modalService.dismissAll();
    // this.image = "";
    this.enableCropper = false;
  }
  save() {
    this.modalService.dismissAll();
  }

  activeInactive(obj) {
    swal.fire({
      title: "Are you sure you want to change the restaurant status?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.saveActiveInActive(obj);
      }
    });
  }

  saveActiveInActive(obj) {
    let payLoad;
    if (obj.isActive == true) {
      payLoad = {
        restaurantId: obj._id,
        isActive: false,
      };
    } else {
      payLoad = {
        restaurantId: obj._id,
        isActive: true,
      };
    }

    this.io
      .apicall(payLoad, "restaurant/change-restaurant-status", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: "Restaurant status updated successfully.",
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.restaurantView();
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  //SETTINGS REALTED WORK //

  // TOGGLE ON OFF FOR DELIVERY //
  toggleForDelivery(event) {
    this.deliveryToggleBtn = event.target.checked;
    let payload = {
      restaurantId: this.restaurantId,
      isDeliveryAvaliable: this.deliveryToggleBtn,
      isPickupAvaliable: this.pickupToggleBtn,
    };
    this.io
      .apicall(payload, "restaurant/set-delivery-or-pickup-restaurant", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  // TOGGLE ON OFF FOR PICKUP //
  toggleForPickup(event) {
    this.pickupToggleBtn = event.target.checked;
    let payload = {
      restaurantId: this.restaurantId,
      isDeliveryAvaliable: this.deliveryToggleBtn,
      isPickupAvaliable: this.pickupToggleBtn,
    };
    this.io
      .apicall(payload, "restaurant/set-delivery-or-pickup-restaurant", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  // ADD SLAV RANGE //
  addRangeSlav() {
    if (this.slavRangeForm_ > this.slavRangeTo_) {
      alert("RangeTo must be greater than RangeFrom");
      return false;
    }
    if (this.slavMaxDistance_ < this.slavRangeTo_) {
      alert("Your given delivery range is exceeded");
      return false;
    }
    this.deliverySlav.push({
      rangeFrom: this.slavRangeForm_,
      rangeTo: this.slavRangeTo_,
      deliveryAmount: this.slavDeliveryAmount_,
    });
    if (this.deliverySlav.length > 0) {
      this.slavRangeForm_ =
        this.deliverySlav[this.deliverySlav.length - 1].rangeTo + 1;
    }
    this.endDeliverySlavIndex = this.deliverySlav.length - 1;
    // this.slavRangeForm_ = "";
    this.slavRangeTo_ = "";
    this.slavDeliveryAmount_ = "";
  }

  // DELETE SLAV ITEM //
  deleteSlavRange(id: number) {
    this.deliverySlav.splice(
      this.deliverySlav.findIndex((abc) => abc.id === id),
      1
    );
    this.endDeliverySlavIndex = this.deliverySlav.length - 1;
    if (this.deliverySlav.length > 0) {
      this.slavRangeForm_ =
        this.deliverySlav[this.deliverySlav.length - 1].rangeTo + 1;
    } else if (this.deliverySlav.length == 0) {
      this.slavRangeForm_ = 0;
    }
  }

  // SLAV MAX DISTANCE //
  maxDistanceSave() {
    let payload = {
      restaurantId: this.restaurantId,
      maxDistCoveredByRestaurant: this.slavMaxDistance_,
    };
    this.io
      .apicall(payload, "restaurant/set-max-dist-covered-by-restaurant", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          // swal.fire({
          //   title: "Success",
          //   text: res["serverResponse"].message,
          //   icon: "success",
          //   confirmButtonColor: "#442DFF;",
          //   confirmButtonText: "ok",
          // });
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  // ADD SLAV LIST //
  addSlavList() {
    if (
      this.slavMaxDistance_ !=
      this.deliverySlav[this.deliverySlav.length - 1].rangeTo
    ) {
      alert(
        "You have to complete delivery slav list as per max distance and then save"
      );
      return false;
    } else {
      this.maxDistanceSave();
      let payload = {
        restaurantId: this.restaurantId,
        deliverySlav: this.deliverySlav,
      };
      this.io
        .apicall(payload, "restaurant/add-delivery-slav", "POST")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            swal.fire({
              title: "Success",
              text: res["serverResponse"].message,
              icon: "success",
              confirmButtonColor: "#442DFF;",
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          swal.fire({
            title: "Oops...",
            text: this.io.data_.serverResponse.message,
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        });
    }
  }

  toggleShowOnApp() {
    swal.fire({
      title: "Are you sure you want to change the restaurant visibility on app?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.saveToggleShowOnApp();
      }
    });
  }

  saveToggleShowOnApp() {
    this.io
      .apicall({ restaurantId: Number(this.restaurantId), showOnApp: !this.restaurantViewResult?.showOnApp }, 'restaurant/set-show-on-app', 'POST')
      .then((res: any) => {
        swal.fire({
          title: 'Success',
          text: `${this.restaurantViewResult?.restaurantName} is now ${this.restaurantViewResult?.showOnApp ? 'hidden' : 'shown'} on app`,
          icon: 'success',
          confirmButtonColor: '#442DFF;',
          confirmButtonText: 'ok',
        });

        this.restaurantViewResult.showOnApp = !this.restaurantViewResult?.showOnApp;
      })
      .catch((err) => {
        swal.fire({
          title: 'Oops...',
          text: this.io.data_.serverResponse.message,
          icon: 'warning',
          confirmButtonColor: '#442DFF;',
          confirmButtonText: 'ok',
        });
      });
  }
}
