import { Component, OnDestroy, OnInit } from "@angular/core";
import { IoService } from "../../services/http/io.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { DishService } from "../../services/dish/dish.service";
import swal from "sweetalert2";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { getSortColumn, getSortType } from "./../../utils/sort";

let closeResult = "";
@Component({
  selector: "app-dish-list",
  templateUrl: "./dish-list.component.html",
  styleUrls: ["./dish-list.component.css"],
})
export class DishListComponent implements OnInit, OnDestroy {
  dishAllList: any = [];
  resturantListForDropdown: any = [];
  restId: any = "";
  editMode: boolean = false;
  page: number = 1;
  publishedItemsPerPage: number = 50;
  unpublishedItemsPerPage: number = 50;
  total: number;
  unpublishedPage: number = 1;
  unpublishedTotal: number;
  unpublishedDishList: any = [];
  sub: Subscription;
  paramObserver: Subscription;
  selectedDishes: Array<string> = [];
  tabs: any = [];
  activeTab: number = 0;
  sortedColumn: object = {};

  constructor(
    private ioservice: IoService,
    private router: Router,
    private DishService: DishService,
    private loader: NgxUiLoaderService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.resturantList();
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let currentPublishedPage = values.published;
      let currentUnpublishedPage = values.unpublished;
      let search = values.restaurant;
      let paramTab = values.tab;
      let sortedField = values.sortBy;
      let sortedDirection = values.sortDirection;
      this.sortedColumn = {};
      this.tabs = [
        { url: "", text: "Published", status: "published" },
        { url: "", text: "Unpublished", status: "unpublished" },
        { url: "", text: "Food Science", status: "all" },
      ];
      if (currentPublishedPage) {
        this.page = currentPublishedPage;
      } else {
        this.page = 1;
      }
      if (currentUnpublishedPage) {
        this.unpublishedPage = currentUnpublishedPage;
      } else {
        this.unpublishedPage = 1;
      }
      if (search) {
        this.restId = search;
      } else {
        this.restId = null;
      }

      if (paramTab) {
        this.activeTab = +paramTab;
      } else {
        this.activeTab = 0;
      }
      this.tabs[this.activeTab] = {
        ...this.tabs[this.activeTab],
        isActive: true,
      };
      if (sortedField) {
        this.sortedColumn = { [sortedField]: sortedDirection };
      }
      if (this.tabs[this.activeTab]?.status == "published") {
        this.loadPublishedDishList();
      } else if (this.tabs[this.activeTab]?.status == "unpublished") {
        this.loadunPublishedDishList();
      }
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  handleTabChange(index: number): void {
    this.sortedColumn = {};
    delete this.tabs[this.activeTab].isActive;
    this.activeTab = index;
    this.tabs[this.activeTab].isActive = true;
    this.handleParams();
  }

  handleSelectAll(event): void {
    if (event.target.checked) {
      this.selectedDishes = this.dishAllList.map((item) => item.id);
    } else {
      this.selectedDishes = [];
    }
  }

  handleSortClick(field): void {
    if (this.sortedColumn[field]) {
      if (this.sortedColumn[field] == "ascending") {
        this.sortedColumn[field] = "descending";
      } else {
        this.sortedColumn[field] = "ascending";
      }
    } else {
      this.sortedColumn = {};
      this.sortedColumn[field] = "ascending";
    }
    this.handleParams();
  }

  handleDishSelect(event): void {
    if (event.target.checked) {
      this.selectedDishes.push(event.target.value);
    } else {
      this.selectedDishes = this.selectedDishes.filter(
        (item) => item != event.target.value
      );
    }
  }

  getDishList(): void {
    if (this.tabs[this.activeTab].status == "published") {
      return this.dishAllList;
    } else {
      return this.unpublishedDishList;
    }
  }

  handleDishStatus(isActive): void {
    let payload = {};
    payload = {
      dishIds: this.selectedDishes,
      isActive: isActive,
    };
    this.loader.start();
    this.DishService.changeDishStatus(payload).subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          this.loader.stop();
          this.selectedDishes = [];
          this.loadPublishedDishList();
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      },
      () => {
        this.loader.stop();
        swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }

  handleParams(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        published: this.page,
        unpublished: this.unpublishedPage,
        restaurant: this.restId,
        tab: this.activeTab,
        sortBy: getSortColumn(this.sortedColumn),
        sortDirection: getSortType(this.sortedColumn),
      },
      queryParamsHandling: "",
    });
  }

  onDrop(event: CdkDragDrop<any>) {
    moveItemInArray(this.dishAllList, event.previousIndex, event.currentIndex);
  }

  loadunPublishedDishList() {
    this.unpublishedDishList = [];
    let payLoad = {};
    if (this.restId) {
      payLoad = {
        //page: this.unpublishedPage,
        //limit: this.unpublishedItemsPerPage,
        restaurantId: this.restId,
        orderName: getSortColumn(this.sortedColumn, false, "rank"),
        orderType: getSortType(this.sortedColumn, "number", false, 1),
        isPublished: false,
      };
    } else {
      payLoad = {
        page: this.unpublishedPage,
        limit: this.unpublishedItemsPerPage,
        orderName: getSortColumn(this.sortedColumn, false, "rank"),
        orderType: getSortType(this.sortedColumn, "number", false, 1),
        isPublished: false,
      };
    }
    this.ioservice
      .apicall(payLoad, "dish/all-dish-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];
          this.unpublishedTotal = result.totalRecords;
          this.unpublishedDishList = res["result"].dishList;
        }
      })
      .catch((err) => {
        throw err;
      });
  }
  loadPublishedDishList() {
    let payLoad = {};
    if (this.restId) {
      payLoad = {
        restaurantId: this.restId,
        orderName: getSortColumn(this.sortedColumn, false, "rank"),
        orderType: getSortType(this.sortedColumn, "number", false, 1),
        isPublished: true,
      };
    } else {
      payLoad = {
        page: this.page,
        limit: this.publishedItemsPerPage,
        orderName: getSortColumn(this.sortedColumn, false, "rank"),
        orderType: getSortType(this.sortedColumn, "number", false, 1),
        isPublished: true,
      };
    }
    this.ioservice
      .apicall(payLoad, "dish/all-dish-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];
          this.total = result.totalRecords;
          this.dishAllList = res["result"].dishList;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  handlePageChange(index): void {
    if (this.tabs[this.activeTab].status == "published") {
      this.page = index;
      this.selectedDishes = [];
    } else {
      this.unpublishedPage = index;
    }
    this.handleParams();
  }
  resturantList() {
    this.ioservice
      .apicall({}, "restaurant/get-restaurant-list-for-dropdown", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.resturantListForDropdown = res["result"];
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  chooseResturant(id) {
    this.editMode = false;
    if (this.tabs[this.activeTab].status == "published") {
      this.page = 1;
      this.dishAllList = [];
      this.selectedDishes = [];
    } else {
      this.unpublishedPage = 1;
      this.unpublishedDishList = [];
    }
    this.handleParams();
  }

  onEditClick(): void {
    this.editMode = true;
  }

  onEditSave(): void {
    this.loader.start();
    this.editMode = false;
    let order = this.dishAllList.map((item) => item.id);
    this.DishService.changeDishesOrder(order, this.restId).subscribe(
      () => {
        this.loader.stop();
        swal.fire({
          title: "Success",
          text: "Order changed successfully",
          icon: "success",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      },
      () => {
        swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }
}
