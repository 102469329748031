import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { FiltersComponent } from "./filters.component";
import { FiltersSectionsComponent } from "./filters-sections/filters-sections.component";
import { SectionItemsComponent } from "./section-items/section-items.component";

const routes: Routes = [
  {
    path: "",
    component: FiltersComponent,
    children: [
      {
        path: "sections",
        component: FiltersSectionsComponent,
      },
      {
        path: "section-details/:sectionId",
        component: SectionItemsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FiltersRoutingModule {}
