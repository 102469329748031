import { Component, Input, OnInit } from "@angular/core";
import { MenuProduct } from '../../../interfaces/menu-product.interface';
import { IoService } from '../../../../services/http/io.service';
import { FoodScienceProduct } from '../../../interfaces/food-science-product.interface';

@Component({
  selector: "app-menu-food-science-product",
  templateUrl: "./menu-food-science-product.component.html",
  styleUrls: ["./menu-food-science-product.component.css"],
})
export class MenuFoodScienceProductComponent implements OnInit {
  @Input() product: MenuProduct;

  @Input() tags
  @Input() avoidancesId

  productFoodScienceView: FoodScienceProduct;
  productLoaded = false;

  showFoodScienceView = false;

  constructor(
    private io: IoService,
  ) { }

  ngOnInit(): void {
  }

  async toggleFoodScienceView() {
    if (!this.productLoaded) await this.fetchFoodScienceView();

    this.showFoodScienceView = !this.showFoodScienceView;
  }

  async fetchFoodScienceView() {
    const product = await this.io.apicall(null, `menu/portal/product/${this.product.mepProductId}`, 'GET');

    const dishIds = this.getProductDishIds(product);

    const foodScienceData = (await this.io.apicall(null, `dish/list?dishIds=${JSON.stringify(dishIds)}&limit=100`, 'GET')) as any;

    this.formatProductsWithDishes(product, foodScienceData.dishes);

    this.productLoaded = true;
  }

  getProductDishIds(product) {
    const dishIdsSet = new Set<number>();

    this._getProductDishIds(product, dishIdsSet);

    return Array.from(dishIdsSet);
  }

  _getProductDishIds(product, dishIdsSet: Set<number>) {
    if (product?.dish?.id) dishIdsSet.add(product.dish.id);

    if (product?.subProducts?.length) {
      product.subProducts.forEach((subProduct) => this._getProductDishIds(subProduct, dishIdsSet));
    }
  }

  formatProductsWithDishes(product, foodScienceData) {
    const menuProduct = this._formatProductsWithDishes(product, foodScienceData);

    this.productFoodScienceView = menuProduct;
  }

  _formatProductsWithDishes(product, foodScienceData) {
    const dishData = foodScienceData.find((dish) => dish.id === product?.dish?.id);

    const children = product?.subProducts?.map((subProduct) => this._formatProductsWithDishes(subProduct, foodScienceData));

    return {
      id: product.id,
      name: product.name,
      plu: product.plu,
      price: product.price,
      min: product.min,
      dish: dishData || null,
      subProducts: children,
    };
  }
}
