import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import swal from "sweetalert2";
import { IoService } from "../../services/http/io.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../../services/data-management/data.service";
import {
  base64ToFile,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import { ToastrService } from "ngx-toastr";
let closeResult = "";

@Component({
  selector: "app-tag-management",
  templateUrl: "./tag-management.component.html",
  styleUrls: ["./tag-management.component.css"],
})
export class TagManagementComponent implements OnInit {
  tagName: any;
  tagId: any;
  tagid: any;
  tagForm: FormGroup;
  edittag: FormGroup;
  tagList: any;
  image: any;
  iconImage: any;
  showCropper = false;
  enableCropper = false;
  file: any = "";
  finalFile: any;
  logoFinalFile: any;
  imageChangedEvent: any = "";
  imageChangedEvent1: any = "";
  croppedImage1: any = "";
  croppedImage: any = "";
  transform: ImageTransform = {};
  setTitle: any;
  logo: boolean = false;
  backgroundlogo: boolean = false;
  editimage: number;
  tagImage: any;
  info: any;
  uploadImgObj: any = {};

  constructor(
    private io: IoService,
    private modalService: NgbModal,
    public fb: FormBuilder,
    private ar: ActivatedRoute,
    private data: DataService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.tagForm = this.fb.group({
      tag_: [null, Validators.compose([Validators.required])],
      info_: [null, Validators.compose([Validators.required])],
    });
    this.edittag = this.fb.group({
      edittag_: [null, Validators.compose([Validators.required])],
      editinfo_: [null, Validators.compose([Validators.required])],
      editimg_: [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.loadTagDetails();
  }

  loadTagDetails() {
    this.data._currentObj().subscribe((data) => {
      this.tagid = data["categoryId"];
      if (this.tagid) {
        this.fetchTag();
      }
      if (typeof this.tagid == "undefined") {
        this.router.navigate(["/management/category"]);
      }
    });
  }

  open(content, tagname, tagid, taginfo, tagimage) {
    this.croppedImage = "";
    this.tagName = tagname;
    this.tagId = tagid;
    this.info = taginfo;
    this.iconImage = tagimage;
    if (this.iconImage) {
      this.uploadImgObj = {
        image: this.iconImage,
        uploadedFile: false,
        oldImage: true,
      };
    } else {
      this.uploadImgObj = {
        image: this.iconImage,
        uploadedFile: false,
        oldImage: true,
      };
    }
    this.edittag.patchValue({
      edittag_: this.tagName,
      editinfo_: this.info,
      editimg_: this.tagImage,
    });
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  editTagImage(content, status, i) {
    this.editimage = i;
    if (status == "background") {
      this.setTitle = "Add Tag image";
      this.logo = false;
      this.backgroundlogo = true;
    }
    if (status == "logo") {
      this.setTitle = "Edit Tag image";
      this.backgroundlogo = false;
      this.logo = true;
    }
    this.imageChangedEvent = "";
    this.imageChangedEvent1 = "";
    this.croppedImage = "";
    this.croppedImage1 = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  delete(deletetag, tagcategoryid, tagname) {
    this.tagId = tagcategoryid;
    this.tagName = tagname;
    this.modalService
      .open(deletetag, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  addContent(addtag) {
    this.croppedImage = "";
    this.image = "";
    this.modalService
      .open(addtag, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  fetchTag() {
    let payload = {
      tagHeadId: this.tagid,
    };
    this.io
      .apicall(payload, "/tagcategories/fetch-tag", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.tagList = res["result"].recipeList;
          this.uploadImgObj = {
            image: this.image,
            uploadedFile: false,
            oldImage: true,
          };
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  addTag(value) {
    if (!value.tag_) {
      swal.fire({
        title: "Oops...",
        text: "Please input tag name.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    let payload = {
      tagName: value.tag_ == null ? "" : value.tag_,
      tagHeadId: this.tagid,
      icon: this.image == null ? "" : this.image,
      info: value.info_ == null ? "" : value.info_,
    };
    this.io
      .apicall(payload, "tagCategories/add-tag", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.tagId = res["result"].id;
          this.modalService.dismissAll();
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        this.tagForm.reset();
        this.fetchTag();
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  editTag(value) {
    if (!value.edittag_) {
      swal.fire({
        title: "Oops...",
        text: "Please input tag name.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }
    if (this.uploadImgObj.image) {
      if (!this.uploadImgObj.uploadedFile && !this.uploadImgObj.oldImage) {
        this.toastr.error("Please Click On Upload Image");
        return 0;
      }
    }
    let payLoad = {
      tagId: this.tagId,
      tagHeadId: this.tagid,
      tagName: value.edittag_ == null ? "" : value.edittag_,
      icon: this.iconImage == null ? "" : this.iconImage,
      info: value.editinfo_ == null ? "" : value.editinfo_,
    };

    this.io
      .apicall(payLoad, "tagCategories/update-tag", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.modalService.dismissAll();
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        this.fetchTag();
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  deleteTag() {
    let payload = {
      tagId: this.tagId,
    };
    this.io
      .apicall(payload, "tagCategories/delete-tag", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.modalService.dismissAll();
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        this.fetchTag();
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
      this.image = this.imageChangedEvent;
      this.uploadImgObj = {
        image: this.image,
        uploadedFile: false,
        oldImage: false,
      };
    }
    //this.fileToUpload = event.target.files[0];
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }
  imageLoaded(image: HTMLImageElement) {
    this.showCropper = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  fileChangeEvent1(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.iconImage = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent1 = event;
      this.iconImage = this.imageChangedEvent1;
      this.uploadImgObj = {
        image: this.iconImage,
        uploadedFile: false,
        oldImage: false,
      };
    }
  }
  imageCropped1(event: ImageCroppedEvent) {
    this.croppedImage1 = event.base64;
    this.logoFinalFile = base64ToFile(this.croppedImage1);
    this.enableCropper = true;
  }
  imageLoaded1(image: HTMLImageElement) {
    this.showCropper = true;
  }
  cropperReady1() {
    // cropper ready
  }
  loadImageFailed1() {
    // show message
  }

  saveImage(): Promise<any> {
    return new Promise((resolve, reject) => {
      let fd = new FormData();
      fd.append("file", this.finalFile);
      this.io
        .apicall(fd, "uploads/icon-img-upload", "POST")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            this.image = res["result"][0].fileUrl;
            this.uploadImgObj = {
              image: this.image,
              uploadedFile: true,
              oldImage: false,
            };
            this.toastr.success("Image uploaded.");
            resolve(res["result"][0].fileUrl);
            //this.modalService.dismissAll();
          }
        })
        .catch((err) => {
          if (err) {
            reject(err);
          }
        });
    });
  }

  editImage() {
    let fd = new FormData();
    fd.append("file", this.logoFinalFile);
    this.io
      .apicall(fd, "uploads/icon-img-upload", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.toastr.success("Image uploaded.");
          this.iconImage = res["result"][0].fileUrl;
          this.uploadImgObj = {
            image: this.iconImage,
            uploadedFile: true,
            oldImage: false,
          };
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  }

  closeModal() {
    this.modalService.dismissAll();
    this.image = "";
    this.enableCropper = false;
  }
  save() {
    // this.modalService.closed();
  }
}
