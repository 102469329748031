import { Component, OnInit } from '@angular/core';

import Swal from "sweetalert2";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { TagManagementService } from "./../../services/tag-management/tag-management.service";
import { IoService } from '../../services/http/io.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-supply-count-count',
  templateUrl: './count.component.html',
  styleUrls: ['./count.component.css']
})
export class CountComponent implements OnInit {
  loadingUserAddress: boolean = false;
  locationType: string = 'userList';
  longitude: string = '';
  latitude: string = '';

  unit: string = 'grams';

  caloriesMore: string = '';
  caloriesLess: string = '';

  carbsMore: string = '';
  carbsLess: string = '';

  proteinMore: string = '';
  proteinLess: string = '';

  fatMore: string = '';
  fatLess: string = '';

  preferenceCategories: { id: number, name: string, preferences: { id: number, name: string }[] }[] = [];
  tagCategories: { id: number, name: string, tags: { id: number, name: string }[] }[] = [];

  selectedPreferences: { [categoryId: number]: { id: number, name: string }[] } = {};
  selectedTags: { [categoryId: number]: { id: number, name: string }[] } = {};

  result: {
    dishesCount: number;
    recipeCreatorsCount: number;
    recipesCount: number;
    restaurantsCount: number;
    restaurants: { restaurantId: number; restaurantName: string; dishesCount: string; }[];
    recipeCreators: { id: number; name: string; recipesCount: string; }[];
  } = null;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    allowSearchFilter: true,
    itemsShowLimit: 5,
  };

  usersListDropdownSettings: IDropdownSettings = {
    searchPlaceholderText: 'Enter user name or email to start searching...',
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
    closeDropDownOnSelection: true,
  };

  addressListDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
  };

  usersList: { id: number; name: string; }[] = [];
  selectedUser: { id: number; name: string; }[] = [];
  private userListSearchText = new Subject<string>();

  addressesList: { id: number; name: string; longitude: string; latitude: string }[] = [];
  selectedAddress: { id: number; name: string; }[] = [];

  constructor(
    private tagService: TagManagementService,
    private loader: NgxUiLoaderService,
    private io: IoService,
  ) { }

  ngOnInit(): void {
    this.userListSearchText.pipe(
      debounceTime(800),
      distinctUntilChanged(),
    ).subscribe((searchValue) => {
      if (!searchValue || searchValue.trim().length < 2) return;

      this.searchForUser(searchValue.trim());
    });

    this.loader.start();

    this.tagService.listTagsAndDietPreferences().subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          res.result.forEach((el) => {
            if (el.preferences) {
              this.preferenceCategories.push(el);
              this.selectedPreferences[el.id] = [];
            } else {
              this.tagCategories.push(el);
              this.selectedTags[el.id] = [];
            }
          });
        }

        this.loader.stop();
      },
      () => {
        this.loader.stop();

        Swal.fire({
          title: "Oops...",
          text: "Something went wrong while getting tags!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }

  onUsersListSearchTextChanged(event) {
    this.userListSearchText.next(event);
  }

  async searchForUser(searchText) {
    const res = (await this.io.apicall(null, `users/portal/admin-search?searchText=${searchText}`, 'GET')) as any;

    this.usersList = res.users.map((user) => ({ id: user.id, name: `${user.name} (${user.email})` }));
  }

  async onUserSelect(event) {
    this.selectedAddress = [];

    this.loadingUserAddress = true;

    const res = (await this.io.apicall(null, `users/portal/admin-user-addresses/${event.id}`, 'GET')) as any;

    this.addressesList = res.addresses.map((address) => ({
      id: address.id,
      longitude: String(address.location.longitude),
      latitude: String(address.location.latitude),
      name: `
             ${address.addressLine?.trim() && address.addressLine?.trim() !== 'undefined' ? `Address Line: ${address.addressLine.trim()} | ` : ''}
             ${address.address?.area?.name?.trim() && address.address?.area?.name?.trim() !== 'undefined' ? `Area: ${address.address.area.name.trim()} | ` : ''}
             ${address.address?.addressNickname?.trim() && address.address?.addressNickname?.trim() !== 'undefined' ? `Address Nickname: ${address.address.addressNickname.trim()} | ` : ''}
             ${address.address?.country?.trim() && address.address?.country?.trim() !== 'undefined' ? `Country: ${address.address.country.trim()} | ` : ''}
             ${address.address?.state?.trim() && address.address?.state?.trim() !== 'undefined' ? `State: ${address.address.state.trim()} | ` : ''}
             ${address.address?.city?.trim() && address.address?.city?.trim() !== 'undefined' ? `City: ${address.address.city.trim()} | ` : ''}
             ${address.type?.trim() && address.type?.trim() !== 'undefined' ? `Type: ${address.type.trim()} | ` : ''}
             ${address.placeName?.trim() && address.placeName?.trim() !== 'undefined' ? `Place Name: ${address.placeName.trim()} | ` : ''}
             ${address.street?.trim() && address.street?.trim() !== 'undefined' ? `Street: ${address.street.trim()} | ` : ''}
             ${address.landmark?.trim() && address.landmark?.trim() !== 'undefined' ? `Landmark: ${address.landmark.trim()} | ` : ''}
             ${address.additionalDirections?.trim() && address.additionalDirections?.trim() !== 'undefined' ? `Additional Directions: ${address.additionalDirections.trim()} | ` : ''}
             ${address.houseNo?.trim() && address.houseNo?.trim() !== 'undefined' ? `House No: ${address.houseNo.trim()} | ` : ''}
             ${address.floor?.trim() && address.floor?.trim() !== 'undefined' ? `Floor: ${address.floor.trim()} | ` : ''}
             ${address.apartmentNo?.trim() && address.apartmentNo?.trim() !== 'undefined' ? `Apartment No: ${address.apartmentNo.trim()} | ` : ''}
            `
        .trim()
        .slice(0, -2),
    }));

    this.loadingUserAddress = false;
  }

  onAddressSelect(event) {
    const address = this.addressesList.find((address) => address.id === event.id);

    this.longitude = address.longitude;
    this.latitude = address.latitude;
  }

  async submit() {
    this.loader.start();
    this.result = null;

    const userDietaryPreferences = {};
    Object.keys(this.selectedPreferences).forEach((key) => {
      if (this.selectedPreferences[key].length) {
        userDietaryPreferences[key] = this.selectedPreferences[key].map(el => el.id);
      }
    });

    const tagsFilter = {};
    Object.keys(this.selectedTags).forEach((key) => {
      if (this.selectedTags[key].length) {
        tagsFilter[key] = this.selectedTags[key].map(el => el.id);
      }
    });

    const location = {
      longitude: '',
      latitude: '',
    };

    if (
      (this.locationType === 'manual') ||
      (this.locationType === 'userList' && this.selectedUser.length > 0 && this.selectedAddress.length > 0)
    ) {
      location.longitude = this.longitude;
      location.latitude = this.latitude;
    }

    const payload: any = {
      crabsLessOrEqual: Number(this.caloriesLess) || undefined,
      crabsGreaterOrEqual: Number(this.caloriesMore) || undefined,
      proteinLessOrEqual: Number(this.proteinLess) || undefined,
      proteinGreaterOrEqual: Number(this.proteinMore) || undefined,
      fatLessOrEqual: Number(this.fatLess) || undefined,
      fatGreaterOrEqual: Number(this.fatMore) || undefined,
      rangeFilter: {
        minCalories: Number(this.caloriesMore) || undefined,
        maxCalories: Number(this.caloriesLess) || undefined,
      },
      type: this.unit,
      userDietaryPreferences,
      tagsFilter,
      location,
    };

    try {
      const data: any = await this.io.postRequest('pages/portal-count', payload);

      this.result = data.result;
    }
    catch (err) {
      Swal.fire({
        title: "Oops...",
        text: "Something went wrong!",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
    }

    this.loader.stop();
  }
}
