import { Component, Input, OnInit } from "@angular/core";
import { MenuProduct } from '../../../interfaces/menu-product.interface';

@Component({
  selector: "app-menu-food-science-product-list",
  templateUrl: "./menu-food-science-product-list.component.html",
  styleUrls: ["./menu-food-science-product-list.component.css"],
})
export class MenuFoodScienceProductListComponent implements OnInit {
  @Input() menuProducts: MenuProduct[]

  @Input() tags
  @Input() avoidancesId

  showDetails = false

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
