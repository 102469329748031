import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportRoutingModule } from './report-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportComponent } from './report.component';
import { ReportListComponent } from '.';
import { ReportTypesComponent } from './report-types/report-types.component';
import { ResturantReportComponent } from './resturant-report/resturant-report.component'
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    ReportComponent,
    ReportListComponent,
    ReportTypesComponent,
    ResturantReportComponent,

  ],
  imports: [
    CommonModule,
    ReportRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgbModule,

  ]
})
export class ReportModule { }
