import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TabNavigatorComponent } from "./tab-navigator.component";
@NgModule({
  imports: [CommonModule],
  declarations: [
    TabNavigatorComponent
  ],
  exports: [
    TabNavigatorComponent
  ]
})
export class TabNavigatorModule {}
