import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllSignupUserComponent } from './all-signup-user/all-signup-user.component';

import { UserComponent } from './user.component';

const routes: Routes = [
  {
   path: '', 
   component: UserComponent,
   children: [
    {
      path: 'all-signup-user', 
      component: AllSignupUserComponent,
    },
  ]  
 }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
