import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgmCoreModule } from '@agm/core';
import { NgxPaginationModule } from "ngx-pagination";
import { ResturantRoutingModule } from "./resturant-routing.module";
import { ResturantComponent } from "./resturant.component";
import { RestaurantListComponent } from "./restaurant-list/restaurant-list.component";
import { AddRestaurantComponent } from "./add-restaurant/add-restaurant.component";
import { EditRestaurantComponent } from "./edit-restaurant/edit-restaurant.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ImageCropperModule } from "ngx-image-cropper";
import { TimeRangeModule } from "./../time-range/time-range.module";
import { TimeRangeComponent } from "./../time-range/time-range.component";
import { NgbModule, NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { TabNavigatorModule } from "./../tab-navigator/tab-navigator.module";
import { CoverageMapComponent } from './coverage-map/coverage-map.component';
import { LocationSearchComponent } from './location-search/location-search.component';

@NgModule({
  declarations: [
    ResturantComponent,
    RestaurantListComponent,
    AddRestaurantComponent,
    EditRestaurantComponent,
    TimeRangeComponent,
    CoverageMapComponent,
    LocationSearchComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    ResturantRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule.forRoot(),
    TimeRangeModule,
    NgbTimepickerModule,
    TabNavigatorModule,
    AgmCoreModule,
  ],
  providers: [],
})
export class ResturantModule { }
