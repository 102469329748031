import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeroBannersRoutingModule } from './hero-banners-routing.module';
import { HeroBannersComponent } from './hero-banners.component';
import { BannersListComponent } from './banners-list/banners-list.component';
import { BannerSectionComponent } from './banners-list/components/banner-section/banner-section.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageSectionBannersComponent } from './banners-list/components/page-section-banners/page-section-banners.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    HeroBannersComponent,
    BannersListComponent,
    BannerSectionComponent,
    PageSectionBannersComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HeroBannersRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [],
})
export class HeroBannersModule { }
