import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { IoService } from "../../services/http/io.service";
import { AdminSendNotificationPayload } from '../interfaces/admin-send-notification-payload.interface';
import { SendNotificationResult } from '../interfaces/send-notification-result.interface';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.css']
})
export class SendNotificationComponent {
  userIds: string = '';
  userIdsTouched: boolean = false;
  userIdsInvalid: boolean = true;

  title: string = '';
  titleTouched: boolean = false;
  titleInvalid: boolean = true;

  body: string = '';
  bodyTouched: boolean = false;
  bodyInvalid: boolean = true;

  contentType: string = '';

  contentTypeId: string = '';
  contentTypeIdTouched: boolean = false;
  contentTypeIdInvalid: boolean = true;

  pushNotificationChecked: boolean = true;
  appNotificationInboxChecked: boolean = false;

  sendingNotification: boolean = false;

  showResult: boolean = false;
  result: SendNotificationResult = {};

  constructor(
    private io: IoService,
    private toastr: ToastrService,
  ) { }

  userIdsChanged() {
    this.userIdsTouched = true;

    this.userIdsInvalid = !/^\s*[0-9]+(,\s*[0-9]+\s*)*$/.test(this.userIds);
  }

  titleChanged() {
    this.titleTouched = true;

    this.titleInvalid = this.title.trim().length === 0;
  }

  bodyChanged() {
    this.bodyTouched = true;

    this.bodyInvalid = this.body.trim().length === 0;
  }

  contentTypeIdChanged() {
    this.contentTypeIdTouched = true;

    this.contentTypeIdInvalid = !/^\s*[0-9]+\s*$/.test(this.contentTypeId);
  }

  isFormInvalid() {
    return (
      !/^\s*[0-9]+(,\s*[0-9]+\s*)*$/.test(this.userIds) ||
      (this.title.trim().length === 0) ||
      (this.body.trim().length === 0) ||
      ((this.contentType?.length > 0) && !/^\s*[0-9]+\s*$/.test(this.contentTypeId)) ||
      (!this.pushNotificationChecked && !this.appNotificationInboxChecked)
    );
  }

  async sendNotification() {
    if (this.isFormInvalid() || this.sendingNotification) return;

    this.showResult = false;
    this.result = {};
    this.sendingNotification = true;

    const userIdsArray = this.userIds.split(',').map((userId: string) => Number(userId.trim()));

    const payload: AdminSendNotificationPayload = {
      userIds: userIdsArray,
      title: this.title.trim(),
      body: this.body.trim(),
      notificationType: 'Promotional',
      id: '',
      types: {
        push: this.pushNotificationChecked,
        backend: this.appNotificationInboxChecked,
      },
    };

    if (this.contentType?.length > 0) {
      payload.notificationType = this.contentType;
      payload.id = this.contentTypeId;
    }

    try {
      const response = await this.io.postRequest('notification/admin/send', payload) as any;

      response.forEach((entry) => {
        if (entry?.backend) this.handleBackendResult(entry);
        else if (entry?.push) this.handlePushResult(entry);
      });

      this.toastr.success('Notification sent!');
      this.showResult = true;
    }
    catch (err) {
      this.toastr.error('Sorry, something went wrong. Please make sure that the user ids are correct');
    }

    this.sendingNotification = false;
  }

  handleBackendResult(result: any) {
    this.result.appNotificationInboxResult = {
      success: result.backend === 'success',
    };
  }

  handlePushResult(result: any) {
    this.result.pushResult = {
      success: result.push === 'success',
      numberOfUsersWithFcmTokens: result.numberOfUsersWithFcmTokens,
      userIdsWithoutFcmTokens: result.usersWithoutFcmToken.join(', '),
      failureCount: result.failureCount,
      successCount: result.successCount,
      errorCode: result?.errorInfo?.code,
      errorMessage: result?.errorInfo?.message,
    };
  }
}
