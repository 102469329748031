import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RestaurantTransactionRoutingModule } from './restaurant-transaction-routing.module';
import { RestaurantTransactionComponent } from './restaurant-transaction.component';
import { RestaurantTransactionPayoutListComponent } from './restaurant-transaction-payout-list/restaurant-transaction-payout-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { RestaurantTransactionPayoutViewDetailsComponent } from './restaurant-transaction-payout-view-details/restaurant-transaction-payout-view-details.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [RestaurantTransactionComponent, RestaurantTransactionPayoutListComponent, RestaurantTransactionPayoutViewDetailsComponent],
  imports: [
    CommonModule,
    RestaurantTransactionRoutingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot()
  ]
})
export class RestaurantTransactionModule { }
