import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from "../../_constant/constant";

@Injectable({
  providedIn: "root",
})
export class ContentManagementService {
  constructor(private http: HttpClient) {}

  listPages(): Observable<any> {
    return this.http.get(routes.listContentManagementPages);
  }

  listCategories(): Observable<any> {
    return this.http.get(routes.listContentManagementCategories);
  }

  listPageSections(id: string): Observable<any> {
    return this.http.get(routes.listContentManagementPageSections(id));
  }

  createPageSection(
    id: string,
    categoryId: string,
    name: string
  ): Observable<any> {
    return this.http.post(routes.listContentManagementPageSections(id), {
      categoryId,
      name,
    });
  }

  deletePageSection(pageId: string, sectionId: string): Observable<any> {
    return this.http.delete(
      routes.deleteContentManagementPageSection(pageId, sectionId)
    );
  }

  reorderPageSections(
    pageId: string,
    pageSections: Array<string>
  ): Observable<any> {
    return this.http.post(routes.contentManagementSectionsReoder(pageId), {
      pageSections,
    });
  }
}
