import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IoService } from "../../services/http/io.service";
import { DataService } from "../../services/data-management/data.service";
import swal from "sweetalert2";
import moment from "moment";
import { Subscription } from "rxjs";
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: "app-coupon-details",
  templateUrl: "./coupon-details.component.html",
  styleUrls: ["./coupon-details.component.css"],
})
export class CouponDetailsComponent implements OnInit, OnDestroy {
  editCouponFrm: FormGroup;
  startDateModel: any = null;
  startTimeModel: any = null;
  model: any;
  timeModel: any;
  time: any;
  couponid: any;
  couponDetailsResult: any;
  spinners = false;
  paramObserver: Subscription;

  currentRestaurantIdsForProductsSet = new Set<number>();
  currentRestaurantIdsForProducts: number[] = [];
  currentShownProducts: { id: number, name: string }[] = [];
  currentSelectedProducts: { id: number, name: string }[] = [];
  resturantList: { id: number, restaurantName: string }[];
  restaurantsDropdownSelected: { id: number, restaurantName: string }[] = [];
  restaurantDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'restaurantName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  productsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'Select restaurant to show its products',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  FORM_KEYS: {
    COUPON_CODE: string,
    DISCOUNT_AMOUNT: string,
    DISCOUNT_TYPE: string,
    AMOUNT_CAP: string,
    MAX_USE: string,
    MAX_USE_PER_USER: string,
    DATE: string,
    TIME: string,
    START_DATE: string,
    START_TIME: string,
    TOTAL_USE: string,
    STATUS: string,
    RESTAURANT_LIST: string,
    PRODUCT_LIST: string,
  }
  constructor(
    private fb: FormBuilder,
    private io: IoService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.FORM_KEYS = {
      COUPON_CODE: "couponCode",
      DISCOUNT_AMOUNT: "discountAmount",
      DISCOUNT_TYPE: "discountType",
      AMOUNT_CAP: "amountCap",
      MAX_USE: "maxUse",
      MAX_USE_PER_USER: "maxUse",
      DATE: "date",
      TIME: "time",
      START_DATE: "startDate",
      START_TIME: "startTime",
      TOTAL_USE: "totalUse",
      STATUS: "status",
      RESTAURANT_LIST: "restaurantList",
      PRODUCT_LIST: "productList",
    }
    this.editCouponFrm = this.fb.group({
      [this.FORM_KEYS.COUPON_CODE]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.DISCOUNT_AMOUNT]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.DISCOUNT_TYPE]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.AMOUNT_CAP]: [null, ''],
      [this.FORM_KEYS.MAX_USE]: [null, ''],
      [this.FORM_KEYS.MAX_USE_PER_USER]: [null, ''],
      [this.FORM_KEYS.TOTAL_USE]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.DATE]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.TIME]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.START_DATE]: [null, ''],
      [this.FORM_KEYS.START_TIME]: [null, ''],
      [this.FORM_KEYS.STATUS]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.RESTAURANT_LIST]: [null, ''],
      [this.FORM_KEYS.PRODUCT_LIST]: [null, ''],
    });
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  async init() {
    await this.getAllRestaurants();

    this.paramObserver = this.route.params.subscribe(({ id }) => {
      if (id) {
        this.couponid = id;
        this.getCouponViewDetails();
      }
      else {
        this.router.navigate(["/coupon/coupon-list"]);
      }
    })
  }

  async getAllRestaurants() {
    this.resturantList = ((await this.io.apicall({}, 'restaurant/get-restaurant-list-for-dropdown', 'POST')) as any).result;
  }

  // COUPON VIEW DETAILS API //
  getCouponViewDetails() {
    let payload = {
      couponId: this.couponid,
    };
    this.io.apicall(payload, "coupon/coupon-view", "POST").then((res: any) => {
      if (res["serverResponse"].code === 200) {
        this.couponDetailsResult = res["result"];
        let datePicker = res["result"].expDate;
        const [year, month, day] = datePicker.split("-");
        const obj = {
          year: parseInt(year),
          month: parseInt(month),
          day: parseInt(day.split(" ")[0].trim()),
        };
        let date = new Date(datePicker);
        this.model = obj;
        this.timeModel = {
          hour: date.getHours(),
          minute: date.getMinutes(),
          second: 0,
        };

        let startDatePicker = res["result"].startDate;
        if (startDatePicker) {
          const [startYear, startMonth, startDay] = startDatePicker.split("-");
          const startObj = {
            year: parseInt(startYear),
            month: parseInt(startMonth),
            day: parseInt(startDay.split(" ")[0].trim()),
          };

          let startDate = new Date(startDatePicker);
          this.startDateModel = startObj;
          this.startTimeModel = {
            hour: startDate.getHours(),
            minute: startDate.getMinutes(),
            second: 0,
          };
        }

        this.editCouponFrm
          .get(this.FORM_KEYS.COUPON_CODE)
          .setValue(this.couponDetailsResult.couponCode);
        this.editCouponFrm
          .get(this.FORM_KEYS.DISCOUNT_AMOUNT)
          .setValue(this.couponDetailsResult.discountAmount);
        this.editCouponFrm
          .get(this.FORM_KEYS.DISCOUNT_TYPE)
          .setValue(this.couponDetailsResult.discountType);
        this.editCouponFrm
          .get(this.FORM_KEYS.AMOUNT_CAP)
          .setValue(this.couponDetailsResult.amountCap);
        this.editCouponFrm
          .get(this.FORM_KEYS.MAX_USE)
          .setValue(this.couponDetailsResult.maxUsed);
        this.editCouponFrm
          .get(this.FORM_KEYS.MAX_USE_PER_USER)
          .setValue(this.couponDetailsResult.maxUsagesPerUser);
        this.editCouponFrm
          .get(this.FORM_KEYS.TOTAL_USE)
          .setValue(this.couponDetailsResult.totalUsed);
        this.editCouponFrm
          .get(this.FORM_KEYS.STATUS)
          .setValue(this.couponDetailsResult.isActive);
        this.editCouponFrm.get(this.FORM_KEYS.DATE).setValue(this.model);
        this.editCouponFrm.get(this.FORM_KEYS.TIME).setValue(this.timeModel);
        this.editCouponFrm.get(this.FORM_KEYS.START_DATE).setValue(this.startDateModel);
        this.editCouponFrm.get(this.FORM_KEYS.START_TIME).setValue(this.startTimeModel);

        const restaurantIds = res["result"].restaurantIds;
        const productIds = res["result"].productsIds;

        if (restaurantIds?.length) {
          this.restaurantsDropdownSelected = this.resturantList.filter((res) => restaurantIds.includes(res.id));
          this.onRestaurantDropdownClose();
        }

        if (productIds?.length) {
          this.getSelectedProducts(productIds);
        }
      }
    });
  }

  async onRestaurantDropdownClose() {
    const selectedRestaurantIdsSet = new Set<number>(this.restaurantsDropdownSelected.map((res) => res.id));

    if (this.areSetsEqual(this.currentRestaurantIdsForProductsSet, selectedRestaurantIdsSet)) return;

    this.currentRestaurantIdsForProductsSet = selectedRestaurantIdsSet;

    this.currentRestaurantIdsForProducts = Array.from(this.currentRestaurantIdsForProductsSet);

    if (!this.currentRestaurantIdsForProducts.length) {
      this.currentShownProducts = [];
      return;
    }

    this.currentShownProducts = ((await this.io.apicall(null, `menu/portal/products?restaurantIds=${JSON.stringify(this.currentRestaurantIdsForProducts)}`, 'GET')) as any);
  }

  areSetsEqual(a: Set<number>, b: Set<number>) {
    return a.size === b.size && Array.from(a).every(b.has.bind(b));
  }

  onItemSelect(event) { }

  onSelectAll(event) { }

  async getSelectedProducts(productIds: number[]) {
    this.currentSelectedProducts = ((await this.io.apicall(null, `menu/portal/products?productIds=${JSON.stringify(productIds)}`, 'GET')) as any);
  }

  // EDIT COUPON API //
  editCoupon(Obj) {
    if (!this.editCouponFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.editCouponFrm.markAllAsTouched();
      return;
    }

    let startDate = null;

    if (this.startDateModel && this.startTimeModel) {
      startDate = `${this.startDateModel.year}-${this.startDateModel.month}-${this.startDateModel.day} ${this.startTimeModel.hour}:${this.startTimeModel.minute}`;
    }

    let date = `${this.model.year}-${this.model.month}-${this.model.day} ${this.timeModel.hour}:${this.timeModel.minute}`;

    const restaurantIds = this.restaurantsDropdownSelected.length > 0 ? this.restaurantsDropdownSelected.map((res) => res.id) : null;
    const productIds = this.currentSelectedProducts.length > 0 ? this.currentSelectedProducts.map((prod) => prod.id) : null;

    let payLoad = {
      couponId: this.couponid,
      couponCode: Obj[this.FORM_KEYS.COUPON_CODE].toUpperCase(),
      startDate: moment(startDate, 'YYYY-MM-DD HH:mm').utc().toISOString(),
      expDate: moment(date, "YYYY-MM-DD HH:mm").utc().toISOString(),
      discountAmount: Obj[this.FORM_KEYS.DISCOUNT_AMOUNT],
      discountType: Obj[this.FORM_KEYS.DISCOUNT_TYPE],
      amountCap: Obj[this.FORM_KEYS.AMOUNT_CAP],
      maxUsed: Obj[this.FORM_KEYS.MAX_USE],
      maxUsagePerUser: Obj[this.FORM_KEYS.MAX_USE_PER_USER],
      restaurantIds,
      productIds,
      isUserSpecific: false,
    };

    this.io
      .apicall(payLoad, "coupon/edit-coupon", "POST")
      .then((res) => {
        if (this.io.data_.serverResponse.code == 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.router.navigate(["/coupon/coupon-list"]);
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  activeInactiveCoupon(obj) {
    let payLoad;
    payLoad = {
      couponId: obj.id,
    };
    this.io
      .apicall(payLoad, "coupon/coupon-active-inactive", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.getCouponViewDetails();
        }
      })
      .catch((err) => {
        throw err;
      });
  }
}
