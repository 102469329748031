import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralRoutingModule } from './general-routing.module';
import { GeneralComponent } from './general.component';
import { CommingSoonComponent } from './comming-soon/comming-soon.component';


@NgModule({
  declarations: [GeneralComponent, CommingSoonComponent],
  imports: [
    CommonModule,
    GeneralRoutingModule
  ]
})
export class GeneralModule { }
