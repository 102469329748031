import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { IoService } from "../../services/http/io.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { ColorPickerService, Cmyk } from "ngx-color-picker";
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import { TagInputModule } from "ngx-chips";
import { ActivatedRoute, Router } from "@angular/router";
import { base64ToFile } from "ngx-image-cropper";
import swal from "sweetalert2";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { DataService } from "../../services/data-management/data.service";
import { DietaryPreferencesService } from "./../../services/dietary-preferences/dietary-preferences.service";
import { TagManagementService } from "./../../services/tag-management/tag-management.service";
import { forkJoin } from "rxjs";

TagInputModule.withDefaults({
  tagInput: {
    placeholder: "Enter New Tag",
    secondaryPlaceholder: "Enter New Tag",
    // add here other default values for tag-input
  },
});

@Component({
  selector: "app-edit-recipe",
  templateUrl: "./edit-recipe.component.html",
  styleUrls: ["./edit-recipe.component.css"],
})
export class EditRecipeComponent implements OnInit, OnDestroy {
  transform: ImageTransform = {};
  selectedItems2 = [];
  selectedItems3 = [];
  selectedItems4 = [];
  allListedItem = [];
  ingredientList = [];
  public color: string = "#5998be";
  ingreDients: any;
  canvasRotation = 0;
  editRecipeFrm: FormGroup;
  closeResult = "";
  imageChangedEvent: any = "";
  croppedImage: any = "";
  finalFile: any = "";
  file: any = "";
  enableCropper = false;
  recipeId: any = "";
  image: any = "";
  createrId: any = "";
  recipeName: any = "";
  recipeDetails: any = "";
  isNewRecipeCheck: any = "";
  dropdownSettings: IDropdownSettings;
  mealTimeList: any = [];
  mealTimeSelectUnselect = [];
  cuisineList: any = [];
  cuisineSelectUnselect = [];
  foodTypeSelectUnselect = [];
  foodTypeList = [];
  allHeadList: any = [];
  foodTypeHeadId: any;
  mealTimeHeadId: any;
  cuisineHeadId: any;
  finalAddTagArr: any = [];
  isSimpleIngredientCheck: any;
  isNewPostWorkOutCheck: any;
  isNewBudgetFriendlyCheck: any;
  ingredientUnit: any = [];
  ingredientCountObj: any = "";
  ingredientCountUnit: any = "";
  otherIngriDients_: any = "";
  iDontKnowCalories: any = false;
  allColorList: any = [];
  addprocess: any = [];
  prepProcess: any = "";
  ingridientunit: any;
  prepProcessIndex: boolean = false;
  editProcess: FormGroup;
  editIngredientProcess: FormGroup;
  processIndex: any;
  ingredientIndex: any;
  ingridientunit1: any;
  paramObserver: any;
  dietaryPreferences: any = [];
  FORM_KEYS: {
    RECIPE_NAME: string;
    PREPARATION_TIME: string;
    CARBS: string;
    FAT: string;
    PROTEIN: string;
    CALORIES: string;
    SERVING: string;
    COLOR: string;
    ADD_TAG: string;
    PREPARATION_PROCESS: string;
  };
  constructor(
    private cpService: ColorPickerService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private io: IoService,
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private dietaryPreferencesService: DietaryPreferencesService,
    private tagManagementService: TagManagementService
  ) {
    this.FORM_KEYS = {
      RECIPE_NAME: "recipeName",
      PREPARATION_TIME: "preparationTime",
      CARBS: "carbs",
      FAT: "fat",
      PROTEIN: "protein",
      CALORIES: "calories",
      SERVING: "serving",
      COLOR: "color",
      ADD_TAG: "addTag",
      PREPARATION_PROCESS: "preparationProcess",
    };
  }

  ngOnInit(): void {
    this.loadEditFrm();
    this.paramObserver = this.route.queryParams.subscribe(
      ({ recipeId, creatorId }) => {
        if (recipeId && creatorId) {
          this.recipeId = recipeId;
          this.createrId = creatorId;
          this.fetchAllColors();
          this.dropdownSettings = {
            singleSelection: false,
            idField: "tagId",
            textField: "tagName",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            // itemsShowLimit: 2,
            allowSearchFilter: true,
          };
          this.makeReadyUnitAndPiece();
          this.fetchAllHead();
        } else {
          this.router.navigate(["/recipe-provider/recipe-provider-list"]);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  loadEditFrm() {
    this.editRecipeFrm = this.fb.group({
      [this.FORM_KEYS.RECIPE_NAME]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.PREPARATION_TIME]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.CARBS]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.FAT]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.PROTEIN]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.CALORIES]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.SERVING]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.COLOR]: [null, Validators.compose([Validators.required])],
    });

    this.editProcess = this.fb.group({
      editprocess: ["", Validators.compose([Validators.required])],
    });

    this.editIngredientProcess = this.fb.group({
      editIngredientText: ["", Validators.compose([Validators.required])],
      editIngredientQuantity: ["", Validators.compose([Validators.required])],
      editIngredientUnitType: ["", Validators.compose([Validators.required])],
      editIngredientOthers: ["", Validators.compose([Validators.required])],
    });
  }

  fetchAllColors() {
    this.allColorList.push(
      { id: 0, cardColorCode: "#61C5BB" },
      { id: 1, cardColorCode: "#8961C5" },
      { id: 2, cardColorCode: "#EF2160" },
      { id: 3, cardColorCode: "#3161AA" },
      { id: 4, cardColorCode: "#566530" },
      { id: 5, cardColorCode: "#422E6A" },
      { id: 6, cardColorCode: "#AA8862" },
      { id: 7, cardColorCode: "#E29829" },
      { id: 8, cardColorCode: "#7EB923" },
      { id: 9, cardColorCode: "#659765" },
      { id: 10, cardColorCode: "#B70038" },
      { id: 11, cardColorCode: "#00BB95" },
      { id: 12, cardColorCode: "#4034AC" }
    );
  }

  selectColor(event) {
    this.color = event.target.value;
  }

  loadDishDetails() {
    let payload = {
      recipeId: this.recipeId,
    };
    this.io
      .apicall(payload, "recipe/recipe-view", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          let storeResult = res["result"];
          this.recipeDetails = res["result"];
          this.isNewRecipeCheck = res["result"]?.IsNew;
          this.isNewPostWorkOutCheck = res["result"]?.isPostWorkOut;
          this.isNewBudgetFriendlyCheck = res["result"]?.isBudgetFriendly;
          this.isSimpleIngredientCheck = res["result"]?.isSimpleIngredient;
          this.image = res["result"].recipeImage;
          this.editRecipeFrm
            .get(this.FORM_KEYS.RECIPE_NAME)
            .setValue(storeResult.recipeName);
          this.recipeName = storeResult.recipeName;
          this.editRecipeFrm
            .get(this.FORM_KEYS.PREPARATION_TIME)
            .setValue(storeResult.prepTime);
          this.editRecipeFrm
            .get(this.FORM_KEYS.CARBS)
            .setValue(storeResult.carbs);
          this.editRecipeFrm
            .get(this.FORM_KEYS.PROTEIN)
            .setValue(storeResult.protein);
          this.editRecipeFrm.get(this.FORM_KEYS.FAT).setValue(storeResult.fat);
          this.editRecipeFrm
            .get(this.FORM_KEYS.CALORIES)
            .setValue(storeResult.totalCalories);
          this.editRecipeFrm
            .get(this.FORM_KEYS.COLOR)
            .setValue(storeResult.cardColorCode);
          this.editRecipeFrm
            .get(this.FORM_KEYS.SERVING)
            .setValue(storeResult.serving);
          this.color = storeResult.cardColorCode;
          this.selectedItems2 = storeResult.headTagInfo[2].tags;
          this.selectedItems3 = storeResult.headTagInfo[3].tags;
          this.selectedItems4 = storeResult.headTagInfo[4].tags;
          this.toogleSetCalories(this.recipeDetails?.IDontKnowCalories);

          for (const item of this.recipeDetails?.IngredientList) {
            this.ingredientList.push({
              ingredientUnit: item?.ingredientUnit,
              ingredientCount: item?.ingredientCount,
              ingredientText: item?.ingredientText,
              unit: item?.unit,
            });
          }

          for (const tags of storeResult.headTagInfo[2].tags) {
            this.mealTimeSelectUnselect.push({
              tagId: tags.tagId,
              tagName: tags.tagName,
            });
          }
          for (const tags of storeResult.headTagInfo[3].tags) {
            this.cuisineSelectUnselect.push({
              tagId: tags.tagId,
              tagName: tags.tagName,
            });
          }

          for (const tags of storeResult.headTagInfo[4].tags) {
            this.foodTypeSelectUnselect.push({
              tagId: tags.tagId,
              tagName: tags.tagName,
            });
          }

          for (const tags of storeResult.nutritionalInfo) {
            this.allListedItem.push({
              tagId: tags.tagId,
              tagName: tags.tagName,
            });
          }
          for (var i = 0; i < storeResult.prepProcess.length; i++) {
            this.addprocess.push({
              prepprocess: storeResult.prepProcess[i].replace(/[\r\n]+/gm, ""),
            });
          }
          this.dietaryPreferences.forEach((item) => {
            if (storeResult?.dietaryPreferences[item?.id]) {
              let selection = storeResult?.dietaryPreferences[item?.id].map(
                (mapItem) => {
                  let searchRes = item.options?.find(
                    (searchItem) => searchItem.id == mapItem
                  );
                  if (searchRes) {
                    return { id: searchRes?.id, name: searchRes.name };
                  }
                }
              );
              this.editRecipeFrm.patchValue({ [item.name]: selection });
            }
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  ingridientUnit(event) {
    this.ingridientunit = event.target.value;
  }

  ingridientUnit1(event) {
    this.ingridientunit1 = event.target.value;
    if (this.ingridientunit1 == "other") {
      this.editIngredientProcess.patchValue({
        editIngredientOthers: "",
      });
    }
  }

  toggleCheckSimpleIngidients(event) {
    this.isSimpleIngredientCheck = event.target.checked;
  }

  toggleBudgetFriendly(event) {
    this.isNewBudgetFriendlyCheck = event.target.checked;
  }
  togglePostWorkout(event) {
    this.isNewPostWorkOutCheck = event.target.checked;
  }

  numbersCheck(element) {
    element.target.value = element.target.value.replace(/[^(\d|\.|\/|\s)]/, "");
  }

  makeReadyUnitAndPiece() {
    this.ingredientUnit.push(
      { id: 0, ingredientunittype: "tbsp" },
      { id: 1, ingredientunittype: "tsp" },
      { id: 2, ingredientunittype: "ml" },
      { id: 3, ingredientunittype: "g" },
      { id: 4, ingredientunittype: "cup" },
      { id: 5, ingredientunittype: "L" },
      { id: 6, ingredientunittype: "handful" },
      { id: 7, ingredientunittype: "sprinkle" },
      { id: 8, ingredientunittype: "splash" },
      { id: 9, ingredientunittype: "kg" },
      { id: 10, ingredientunittype: "whole" },
      { id: 11, ingredientunittype: "floret" },
      { id: 12, ingredientunittype: "small" },
      { id: 13, ingredientunittype: "medium" },
      { id: 14, ingredientunittype: "large" },
      { id: 15, ingredientunittype: "bulb" },
      { id: 16, ingredientunittype: "clove" },
      { id: 17, ingredientunittype: "bunch" },
      { id: 18, ingredientunittype: "head" },
      { id: 19, ingredientunittype: "other" }
    );
  }

  fetchAllHead() {
    const tag_subscriber = this.tagManagementService.listCategories();
    const diet_subscriber = this.dietaryPreferencesService.listCategories();
    forkJoin({
      tagRes: tag_subscriber,
      dietRes: diet_subscriber,
    }).subscribe(async ({ tagRes, dietRes }) => {
      let categories_subscribers: any = {};
      if (tagRes["serverResponse"].code === 200) {
        this.allHeadList = tagRes["result"];
        for (var i = 0; i < this.allHeadList.length; i++) {
          if (this.allHeadList[i].tagHeadId === "H6") {
            this.headTagMealTime(this.allHeadList[i].id);
          } else if (this.allHeadList[i].tagHeadId === "H4") {
            this.headTagFoodType(this.allHeadList[i].id);
          } else if (this.allHeadList[i].tagHeadId === "H3") {
            this.headTagCusine(this.allHeadList[i].id);
          }
        }
      }
      this.dietaryPreferences = dietRes;
      this.dietaryPreferences.forEach((item) => {
        categories_subscribers[item?.id] =
          this.dietaryPreferencesService.getCategoryOptions(item.id);
        item.dropdownSettings = {
          ...this.dropdownSettings,
          singleSelection: !item.multi && !(item.name=='Macronutrient Ratio'),
          textField: "name",
          idField: "id",
        };
        const diet_control = new FormControl([]);
        this.editRecipeFrm.addControl(item.name, diet_control);
      });
      forkJoin(categories_subscribers).subscribe((data) => {
        this.handleDietaryPreferencesCategories(data);
      });
    });
  }

  handleDietaryPreferencesCategories(data) {
    this.dietaryPreferences.forEach((item, index) => {
      item.options = data[item.id];
      if (item.selectionType == "exclude") {
        this.finalAddTagArr = this.finalAddTagArr.concat(
          item.options.map((mapItem) => {
            return {
              ...mapItem,
              tagName: mapItem.name + " Free",
              tagId: mapItem.id,
            };
          })
        );
        this.handleExcludePreference(item.name, index);
      }
    });
    this.loadDishDetails();
  }

  handleExcludePreference(controlName: string, index: number): void {
    const field_control = this.editRecipeFrm.get(controlName);
    field_control.valueChanges.subscribe((value) => {
      this.allListedItem = this.allListedItem.filter(
        (filteredItem) =>
          !value.find((searchItem) => searchItem?.tagid == filteredItem.tagId)
      );
      let options = [...this.dietaryPreferences[index].options];

      this.finalAddTagArr = options
        .filter(
          (filteredItem) =>
            !value.find((searchItem) => searchItem.id == filteredItem.id)
        )
        .map((item) => {
          return { ...item, tagName: item.name + " Free", tagId: item.id };
        });
    });
  }

  async headTagFoodType(Obj) {
    this.foodTypeHeadId = Obj;
    let value = await this.giveTagList(Obj);
    let myArr1 = [];
    for (let tags of value) {
      myArr1.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.foodTypeList = myArr1;
  }

  async headTagMealTime(Obj) {
    this.mealTimeHeadId = Obj;
    let value = await this.giveTagList(Obj);
    let myArr3 = [];
    for (let tags of value) {
      myArr3.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.mealTimeList = myArr3;
  }

  async headTagCusine(Obj) {
    this.cuisineHeadId = Obj;
    let value = await this.giveTagList(Obj);
    let myArr4 = [];
    for (let tags of value) {
      myArr4.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.cuisineList = myArr4;
  }

  async giveTagList(Obj): Promise<any> {
    return new Promise((resolve, reject) => {
      let payLoad = {
        tagHeadId: Obj,
      };
      this.io
        .apicall(payLoad, "tagcategories/fetch-tag-by-head", "POST")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            resolve(res["result"]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //=============================================' start of meal time '==================================================================//
  onItemSelect2(item: any) {
    //meal time single item Select
    this.mealTimeSelectUnselect.push({
      tagId: item.tagId,
      tagName: item.tagName,
    });
  }

  onSelectAll2(item: any) {
    //meal time all item Select
    this.mealTimeSelectUnselect = [];
    for (let i = 0; i < item.length; i++) {
      this.mealTimeSelectUnselect.push({
        tagId: item[i].tagId,
        tagName: item[i].tagName,
      });
    }
  }

  onItemDeSelect2(event: any) {
    //meal time single item deSelect
    this.mealTimeSelectUnselect.splice(
      this.mealTimeSelectUnselect.findIndex(
        (tier) => tier.tagId === event.tagId
      ),
      1
    );
  }

  onDeSelectAll2(item: any) {
    //meal time all item deSelect

    this.mealTimeSelectUnselect = [];
  }
  //=============================================' end of meal time '====================================================================//

  //=============================================' start of cusine ' =====================================================================//
  onItemSelect3(item: any) {
    //cuisine single item Select
    this.cuisineSelectUnselect.push({
      tagId: item.tagId,
      tagName: item.tagName,
    });
  }

  onSelectAll3(item: any) {
    //cuisine all item Select
    this.cuisineSelectUnselect = [];
    for (let i = 0; i < item.length; i++) {
      this.cuisineSelectUnselect.push({
        tagId: item[i].tagId,
        tagName: item[i].tagName,
      });
    }
  }

  onItemDeSelect3(event: any) {
    //cuisine single item deSelect
    this.cuisineSelectUnselect.splice(
      this.cuisineSelectUnselect.findIndex(
        (tier) => tier.tagId === event.tagId
      ),
      1
    );
  }

  onDeSelectAll3(item: any) {
    //cuisine all item deSelect
    this.cuisineSelectUnselect = [];
  }
  //==============================================' end of cusine '========================================================================//

  //==============================================' start of food type ' ================================================================//
  onItemSelect4(item: any) {
    //food type single item Select
    this.foodTypeSelectUnselect.push({
      tagId: item.tagId,
      tagName: item.tagName,
    });
  }

  onSelectAll4(item: any) {
    //food type all item Select
    this.foodTypeSelectUnselect = [];
    for (let i = 0; i < item.length; i++) {
      this.foodTypeSelectUnselect.push({
        tagId: item[i].tagId,
        tagName: item[i].tagName,
      });
    }
  }

  onItemDeSelect4(event: any) {
    //food type single item deSelect
    this.foodTypeSelectUnselect.splice(
      this.foodTypeSelectUnselect.findIndex(
        (tier) => tier.tagId === event.tagId
      ),
      1
    );
  }

  onDeSelectAll4(item: any) {
    //food type all item deSelect
    this.foodTypeSelectUnselect = [];
  }
  //==============================================' end of food type '===================================================================//

  addIngredients() {
    let validNumber = new RegExp(/^(\d+\.?\/?\d+|\d+(\s\d+\/\d+)?)$/);
    if (!validNumber.test(this.ingredientCountObj)) {
      swal.fire({
        title: "Oops...",
        text: "Quantity is invalid",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return;
    }
    if (
      !this.ingreDients ||
      !this.ingredientCountUnit ||
      !this.ingredientCountObj ||
      (this.ingredientCountUnit == "other" && !this.otherIngriDients_)
    ) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all fields",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return;
    }
    if (this.ingridientunit != "other") {
      this.ingredientList.push({
        ingredientUnit: this.ingredientCountUnit,
        ingredientCount: this.ingredientCountObj,
        ingredientText: this.ingreDients,
        unit: this.ingridientunit,
      });
    } else {
      this.ingredientList.push({
        ingredientUnit: this.otherIngriDients_,
        ingredientCount: this.ingredientCountObj,
        ingredientText: this.ingreDients,
        unit: this.ingridientunit,
      });
    }
    this.ingreDients = "";
    this.ingredientCountUnit = "";
    this.ingredientCountObj = "";
    this.otherIngriDients_ = "";
  }

  removeItem(index: any) {
    this.ingredientList.splice(index, 1);
  }

  addProcess() {
    this.prepProcessIndex = false;
    let mystring = this.prepProcess.split(".");
    this.addprocess.push({
      prepprocess: this.prepProcess.substring(mystring[0].length),
    });
    this.prepProcess = "";
  }

  abc() {
    if (this.prepProcess.length > 0) {
      this.prepProcessIndex = true;
      this.prepProcess = this.addprocess.length + 1 + ". " + this.prepProcess;
    }
  }

  abcd() {
    if (this.prepProcess.length == 0) {
      this.prepProcessIndex = false;
      // this.prepProcess = (this.addprocess.length + 1) +  ' ' + this.prepProcess;
    }
  }

  deleteProcess(index: any) {
    this.addprocess.splice(index, 1);
  }

  open1(content, item, index) {
    this.processIndex = index;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
    this.editProcess.patchValue({
      editprocess: item?.prepprocess.replace(/\./g, "").trim(),
    });
  }

  processEdit() {
    this.addprocess[this.processIndex].prepprocess =
      ". " + this.editProcess.value.editprocess;
    this.modalService.dismissAll();
  }

  open2(content, item, index) {
    this.ingredientIndex = index;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
    if (item.unit != "other") {
      this.editIngredientProcess.patchValue({
        editIngredientText: item?.ingredientText,
        editIngredientQuantity: item?.ingredientCount,
        editIngredientUnitType: item?.ingredientUnit,
        // editIngredientOthers: item?.otherIngriDients_,
      });
    } else {
      this.editIngredientProcess.patchValue({
        editIngredientText: item?.ingredientText,
        editIngredientQuantity: item?.ingredientCount,
        editIngredientUnitType: item?.unit,
        editIngredientOthers: item?.ingredientUnit,
      });
    }
  }

  editTier() {
    let validNumber = new RegExp(/^(\d+\.?\/?\d+|\d+(\s\d+\/\d+)?)$/);
    if (
      !validNumber.test(
        this.editIngredientProcess.get("editIngredientQuantity").value
      )
    ) {
      swal.fire({
        title: "Oops...",
        text: "Quantity is invalid",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return;
    }
    if (
      !this.editIngredientProcess.get("editIngredientText").value ||
      !this.editIngredientProcess.get("editIngredientQuantity").value ||
      !this.editIngredientProcess.get("editIngredientUnitType").value ||
      (this.editIngredientProcess.get("editIngredientUnitType").value ==
        "other" &&
        !this.editIngredientProcess.get("editIngredientOthers").value)
    ) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all fields",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return;
    }
    if (this.editIngredientProcess.value.editIngredientUnitType != "other") {
      this.ingredientList[this.ingredientIndex].ingredientText =
        this.editIngredientProcess.value.editIngredientText;
      this.ingredientList[this.ingredientIndex].ingredientCount =
        this.editIngredientProcess.value.editIngredientQuantity;
      this.ingredientList[this.ingredientIndex].ingredientUnit =
        this.editIngredientProcess.value.editIngredientUnitType;
      this.ingredientList[this.ingredientIndex].unit =
        this.editIngredientProcess.value.editIngredientUnitType;
    } else {
      this.ingredientList[this.ingredientIndex].ingredientText =
        this.editIngredientProcess.value.editIngredientText;
      this.ingredientList[this.ingredientIndex].ingredientCount =
        this.editIngredientProcess.value.editIngredientQuantity;
      this.ingredientList[this.ingredientIndex].ingredientUnit =
        this.editIngredientProcess.value.editIngredientOthers;
      this.ingredientList[this.ingredientIndex].unit =
        this.editIngredientProcess.value.editIngredientUnitType;
    }
    this.editIngredientProcess.value.editIngredientText = "";
    this.editIngredientProcess.value.editIngredientQuantity = "";
    this.editIngredientProcess.value.editIngredientOthers = "";
    this.editIngredientProcess.value.editIngredientUnitType = "";
    this.modalService.dismissAll();
  }

  public onChangeColorHex8(color: string): string {
    const hsva = this.cpService.stringToHsva(color, true);
    if (hsva) {
      return this.cpService.outputFormat(hsva, "rgba", null);
    }
    return "";
  }

  submit(Obj) {
    if (!this.editRecipeFrm.valid || this.ingredientList.length === 0) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.editRecipeFrm.markAllAsTouched();
      return;
    }
    let dietary_preferences = {};
    this.dietaryPreferences.forEach((item) => {
      dietary_preferences[item.id] = this.editRecipeFrm
        .get(item.name)
        .value?.map((preference) => preference.id);
    });
    let payload = {
      recipeId: this.recipeId,
      recipeName: Obj[this.FORM_KEYS.RECIPE_NAME],
      creatorId: this.createrId,
      prepTime: parseInt(Obj[this.FORM_KEYS.PREPARATION_TIME]),
      prepProcess: this.addprocess.map((item) =>
        item.prepprocess.replace(/[\r\n]+/gm, "")
      ),
      recipeImage: this.image,
      IngredientList: this.ingredientList,
      cardColorCode: this.color,
      serving: Obj[this.FORM_KEYS.SERVING],
      carbs: Obj[this.FORM_KEYS.CARBS],
      fat: Obj[this.FORM_KEYS.FAT],
      protein: Obj[this.FORM_KEYS.PROTEIN],
      totalCalories: Obj[this.FORM_KEYS.CALORIES],
      nutritionalInfo: this.allListedItem,
      ingredients: this.ingredientList
        ? this.ingredientList.map(
            (item) =>
              item.ingredientCount +
              " " +
              item.ingredientUnit +
              " " +
              item.ingredientText
          )
        : "",
      IsNew: this.isNewRecipeCheck,
      isSimpleIngredient: this.isSimpleIngredientCheck,
      isPostWorkOut: this.isNewPostWorkOutCheck,
      isBudgetFriendly: this.isNewBudgetFriendlyCheck,
      IDontKnowCalories: this.iDontKnowCalories,
      headTagInfo: [
        {
          headId: this.mealTimeHeadId,
          tags: this.mealTimeSelectUnselect,
        },
        {
          headId: this.cuisineHeadId,
          tags: this.cuisineSelectUnselect,
        },
        {
          headId: this.foodTypeHeadId,
          tags: this.foodTypeSelectUnselect,
        },
      ],
      dietaryPreferences: dietary_preferences,
    };
    this.io
      .apicall(payload, "recipe/edit-recipe", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.recipeDetails.isNewRecipe = res["result"].isNewRecipe;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  publish() {
    let payload = {
      recipeId: this.recipeDetails.id,
    };
    this.io
      .apicall(payload, "recipe/recipe-publish-unpublish", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.recipeDetails.isPublished = true;
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  toggleActive(obj) {
    let payload = {
      recipeId: this.recipeDetails.id,
    };
    this.io
      .apicall(payload, "recipe/recipe-active-inactive", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.recipeDetails.isActive = obj.isActive ? false : true;
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  open(content) {
    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
      this.image = this.imageChangedEvent;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }

  imageLoaded(image: HTMLImageElement) {
    // show cropper
  }
  cropperReady(sourceImageDimensions: Dimensions) {}

  loadImageFailed() {
    // show message
  }

  saveImage() {
    const fd = new FormData();
    fd.append("file", this.finalFile);
    this.io
      .apicall(fd, "uploads/recipe-img-upload", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.image = res["result"][0].fileUrl;
          this.modalService.dismissAll();
        }
      });
  }

  closeModal() {
    this.modalService.dismissAll();
    this.enableCropper = false;
  }

  isNewRecipe(event) {
    this.isNewRecipeCheck = event.target.checked;
  }

  setAdminHelp(event) {
    this.iDontKnowCalories = event.target.checked;
    if (event.target.checked === true) {
      this.editRecipeFrm.get(this.FORM_KEYS.CARBS).setValue(0);
      this.editRecipeFrm.get(this.FORM_KEYS.CARBS).disable();
      this.editRecipeFrm.get(this.FORM_KEYS.PROTEIN).setValue(0);
      this.editRecipeFrm.get(this.FORM_KEYS.PROTEIN).disable();
      this.editRecipeFrm.get(this.FORM_KEYS.FAT).setValue(0);
      this.editRecipeFrm.get(this.FORM_KEYS.FAT).disable();
      this.editRecipeFrm.get(this.FORM_KEYS.CALORIES).setValue(0);
      this.editRecipeFrm.get(this.FORM_KEYS.CALORIES).disable();
    } else {
      this.editRecipeFrm.get(this.FORM_KEYS.CARBS).enable();
      this.editRecipeFrm.get(this.FORM_KEYS.PROTEIN).enable();
      this.editRecipeFrm.get(this.FORM_KEYS.FAT).enable();
      this.editRecipeFrm.get(this.FORM_KEYS.CALORIES).enable();
    }
  }

  toogleSetCalories(Obj) {
    this.iDontKnowCalories = Obj;
    if (Obj === true) {
      this.editRecipeFrm.get(this.FORM_KEYS.CARBS).setValue(0);
      this.editRecipeFrm.get(this.FORM_KEYS.CARBS).disable();
      this.editRecipeFrm.get(this.FORM_KEYS.PROTEIN).setValue(0);
      this.editRecipeFrm.get(this.FORM_KEYS.PROTEIN).disable();
      this.editRecipeFrm.get(this.FORM_KEYS.FAT).setValue(0);
      this.editRecipeFrm.get(this.FORM_KEYS.FAT).disable();
      this.editRecipeFrm.get(this.FORM_KEYS.CALORIES).setValue(0);
      this.editRecipeFrm.get(this.FORM_KEYS.CALORIES).disable();
    } else {
      this.editRecipeFrm.get(this.FORM_KEYS.CARBS).enable();
      this.editRecipeFrm.get(this.FORM_KEYS.PROTEIN).enable();
      this.editRecipeFrm.get(this.FORM_KEYS.FAT).enable();
      this.editRecipeFrm.get(this.FORM_KEYS.CALORIES).enable();
    }
  }
}
