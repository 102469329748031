import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings.component';
import { UpdateSettingsComponent } from './update-settings/update-settings.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SettingRoutingModule } from './setting-routing.module';
import { AppMessagesComponent } from './app-messages/app-messages.component';



@NgModule({
  declarations: [
    SettingsComponent,
    UpdateSettingsComponent,
    AppMessagesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SettingRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule,
  ]
})
export class SettingsModule { }
