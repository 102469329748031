import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { IoService } from "../..//services/http/io.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import swal from "sweetalert2";
import { DataService } from "../../services/data-management/data.service";
import { RecipeService } from "./../../services/recipe/recipe.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
let closeResult = "";

@Component({
  selector: "app-recipe-list",
  templateUrl: "./recipe-list.component.html",
  styleUrls: ["./recipe-list.component.css"],
})
export class RecipeListComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  recipeProviderId: any;
  enqueryList: boolean = false;
  selectedValue: number;
  recipeList: any = [];
  recipeProviderName: any;
  recipeName: any;
  recipeItem: any;
  page: any;
  itemsPerPage: number = 50;
  paramObserver: Subscription;
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private io: IoService,
    private data: DataService,
    private router: Router,
    private RecipeService: RecipeService,
    private loader: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe(({ id, name, page, search }) => {
      if (id) {
        this.recipeProviderId = id;
        this.recipeProviderName = name;
        if (page) {
          this.page = page;
        }
        if (search) {
          this.recipeItem = search;
          this.searchRecipe();
        }
        else {
          this.loadAllRecipes();
        }
      } else {
        this.router.navigate(["/recipe-provider/recipe-provider-action"]);
      }
    });
  }

  handleParams(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page: this.page,
        search: this.recipeItem || null,
      },
      queryParamsHandling: "merge",
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  fetchRecipe(Obj) {
    this.recipeName = Obj;
  }

  countStar(star) {
    this.selectedValue = star;
  }

  loadAllRecipes() {
    this.recipeItem = null;
    this.handleParams();
    let payload = {
      page: this.page,
      limit: this.itemsPerPage,
      creatorId: this.recipeProviderId,
      recipeName: "",
      ingredients: "",
      nutritionalInfo: "",
    };
    this.io
      .apicall(payload, "recipe/recipe-list-by-creator", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];
          this.enqueryList = false;
          this.recipeList = result.recipeList;
          this.recipeItem = "";
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  pageChanged(event) {
    this.handleParams();
    if (event > 1) {
      this.loadAllRecipes();
    }
  }

  deleteRecipe(Obj) {
    this.loader.start();
    this.RecipeService.delete(Obj.id).subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        this.modalService.dismissAll();
        this.page = 1;
        this.recipeList = [];
        this.loadAllRecipes();
        this.loader.stop();
      },
      (err) => {
        this.modalService.dismissAll();
        swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }

  searchRecipe() {
    this.handleParams();
    let payload = {
      page: this.page,
      limit: this.itemsPerPage,
      creatorId: this.recipeProviderId,
      recipeName: this.recipeItem,
      ingredients: "",
      nutritionalInfo: "",
    };
    this.io
      .apicall(payload, "recipe/recipe-list-by-creator", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.enqueryList = true;
          const result = res["result"];
          this.recipeList = result.recipeList;
          if (this.recipeList.length === 0) {
            this.loadAllRecipes();
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }
}
