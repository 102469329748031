import { DataService } from "../../services/data-management/data.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IoService } from "../../services/http/io.service";
import { Router, ActivatedRoute } from "@angular/router";
import swal from "sweetalert2";
import { getSortColumn, getSortType } from "./../../utils/sort";
import { IDropdownSettings } from "ng-multiselect-dropdown";
let closeResult = "";

@Component({
  selector: "app-restaurant-list",
  templateUrl: "./restaurant-list.component.html",
  styleUrls: ["./restaurant-list.component.css"],
})
export class RestaurantListComponent implements OnInit, OnDestroy {
  page: any = 1;
  stars: number[] = [1, 2, 3, 4, 5];
  approvedRestaurantList: any = [];
  rejectedRestaurantList: any = [];
  pageOne: any = 1;
  pageTwo: any = 1;
  total: number;
  totalPages: number;
  totalRejectedPages: number;
  pendingRestaurantList: any = [];
  restaurantProvider: any;
  pendingItemsPerPage: number = 50;
  approvedItemsPerPage: number = 50;
  rejectedItemsPerPage: number = 50;
  rate: any;
  paramObserver: any;
  tabs: any = [];
  activeTab: number = 0;
  sortedColumn: object = {};
  brandCreationModalRestaurant: { id: number, name: string, branch: string; };
  brandCreationModalBrandName: string = '';
  brandCreationDropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name',
    singleSelection: true,
  };
  brandCreationDropdownData: { id: number, name: string; }[] = [];
  constructor(
    private dataService: DataService,
    private modalService: NgbModal,
    private io: IoService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let currentApprovedPage = values.approved;
      let currentRejectedPage = values.rejected;
      let currentPendingPage = values.pending;
      let paramTab = values.tab;
      let sortedField = values.sortBy;
      let sortedDirection = values.sortDirection;
      this.sortedColumn = {};
      this.tabs = [
        { url: "", text: "Pending", status: "pending" },
        { url: "", text: "Approved", status: "approved" },
        { url: "", text: "Rejected", status: "rejected" },
      ]
      if (currentPendingPage) {
        this.page = currentPendingPage;
      } else {
        this.page = 1;
      }
      if (currentApprovedPage) {
        this.pageOne = currentApprovedPage;
      } else {
        this.pageOne = 1;
      }
      if (currentRejectedPage) {
        this.pageTwo = currentRejectedPage;
      } else {
        this.pageTwo = 1;
      }
      if (paramTab) {
        this.activeTab = +paramTab;
      } else {
        this.activeTab = 0;
      }
      this.tabs[this.activeTab] = {
        ...this.tabs[this.activeTab],
        isActive: true,
      };
      if (sortedField) {
        this.sortedColumn = { [sortedField]: sortedDirection };
      }
      if (this.tabs[this.activeTab]?.status == "pending") {
        this.loadPendingRestaurantList();
      } else if (this.tabs[this.activeTab]?.status == "approved") {
        this.loadApprovedRestaurantList();
      } else {
        this.loadRejectedRestaurantList();
      }
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  handleSortClick(field): void {
    if (this.sortedColumn[field]) {
      if (this.sortedColumn[field] == "ascending") {
        this.sortedColumn[field] = "descending";
      } else {
        this.sortedColumn[field] = "ascending";
      }
    } else {
      this.sortedColumn = {};
      this.sortedColumn[field] = "ascending";
    }
    this.handleParams();
  }

  handleTabChange(index): void {
    this.sortedColumn = {};
    delete this.tabs[this.activeTab].isActive;
    this.activeTab = index;
    this.tabs[this.activeTab].isActive = true;
    this.handleParams();
  }

  getRestaurantList(): void {
    if (this.tabs[this.activeTab]?.status == "pending") {
      return this.pendingRestaurantList;
    } else if (this.tabs[this.activeTab]?.status == "approved") {
      return this.approvedRestaurantList;
    } else {
      return this.rejectedRestaurantList;
    }
  }

  handleParams(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        approved: this.pageOne,
        pending: this.page,
        rejected: this.pageTwo,
        tab: this.activeTab,
        sortBy: getSortColumn(this.sortedColumn),
        sortDirection: getSortType(this.sortedColumn)
      },
      queryParamsHandling: "merge",
    });
  }

  open(deleteitem) {
    this.modalService
      .open(deleteitem, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.brandCreationModalBrandName = '';

          return reason;
        }
      );
  }

  loadApprovedRestaurantList() {
    this.approvedRestaurantList = [];
    let payload = {
      page: this.pageOne,
      limit: this.approvedItemsPerPage,
    };
    if(getSortColumn(this.sortedColumn)){
      payload['orderName'] = getSortColumn(this.sortedColumn);
      payload['orderType'] = getSortType(this.sortedColumn, "number", false, 1); 
    }
    this.io
      .apicall(payload, "restaurant/approved-restaurant-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.total = res["result"].totalRecords;
          this.approvedRestaurantList = res["result"].restaurantList;
          this.calculateOpenDaysForApprovedRestaurants();
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  calculateOpenDaysForApprovedRestaurants() {
    const FIVE_HOURS_MS = 5 * 60 * 60 * 1000;

    this.approvedRestaurantList.forEach((restaurant) => {
      const weekdays = {};

      for (let i = 0; i < 7; ++i) {
        weekdays[i] = [];
      }

      const dayEnd = new Date();
      dayEnd.setHours(23);
      dayEnd.setMinutes(59);
      dayEnd.setSeconds(59);
      dayEnd.setMilliseconds(999);

      const dayStart = new Date();
      dayStart.setHours(0);
      dayStart.setMinutes(0);
      dayStart.setSeconds(0);
      dayStart.setMilliseconds(0);

      restaurant.workingHours.forEach((day) => {
        const fromHour = Number(day.from.split(':')[0]);
        const fromMinute = Number(day.from.split(':')[1]);
        const toHour = Number(day.to.split(':')[0]);
        const toMinute = Number(day.to.split(':')[1]);

        const fromDate = new Date();
        fromDate.setHours(fromHour);
        fromDate.setMinutes(fromMinute);
        fromDate.setSeconds(0);
        fromDate.setMilliseconds(0);

        const toDate = new Date();
        toDate.setHours(toHour);
        toDate.setMinutes(toMinute);
        toDate.setSeconds(0);
        toDate.setMilliseconds(0);

        if (toDate.getTime() - fromDate.getTime() < 0) {
          weekdays[day.weekdayNumber].push({ from: fromDate, to: dayEnd });

          weekdays[(day.weekdayNumber + 1) % 7].push({ from: dayStart, to: toDate });
        } else {
          weekdays[day.weekdayNumber].push({ from: fromDate, to: toDate });
        }
      });

      restaurant.countOfOpenDays = 0;

      for (let i = 0; i < 7; ++i) {
        let totalTimeMs = 0;

        weekdays[i].forEach((entry) => {
          totalTimeMs += entry.to.getTime() - entry.from.getTime();
        });

        if (totalTimeMs >= FIVE_HOURS_MS) restaurant.countOfOpenDays++;
      }
    });
  }

  handlePageChange(index): void {
    if (index >= 1) {
      if (this.tabs[this.activeTab]?.status == "pending") {
        this.page = index;
      } else if (this.tabs[this.activeTab]?.status == "approved") {
        this.pageOne = index;
      } else {
        this.pageTwo = index;
      }
    }
    this.handleParams();
  }

  loadRejectedRestaurantList() {
    this.rejectedRestaurantList = [];
    let payload = {
      page: this.pageTwo,
      limit: this.rejectedItemsPerPage,
    };
    if(getSortColumn(this.sortedColumn)){
      payload['orderName'] = getSortColumn(this.sortedColumn);
      payload['orderType'] = getSortType(this.sortedColumn, "number", false, 1); 
    }
    this.io
      .apicall(payload, "restaurant/rejected-restaurant-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.totalRejectedPages = res["result"].totalRecords;
          this.rejectedRestaurantList = res["result"].restaurantList;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  loadPendingRestaurantList() {
    this.pendingRestaurantList = [];
    let payload = {
      page: this.page,
      limit: this.pendingItemsPerPage,
    };
    if(getSortColumn(this.sortedColumn)){
      payload['orderName'] = getSortColumn(this.sortedColumn);
      payload['orderType'] = getSortType(this.sortedColumn, "number", false, 1); 
    }
    this.io
      .apicall(payload, "restaurant/pending-restaurant-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.totalPages = res["result"].totalRecords;
          this.pendingRestaurantList = res["result"].restaurantList;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  catchRecipeProvider(Obj) {
    this.restaurantProvider = Obj;
  }

  approve(Obj: any) {
    let payload = {
      restaurantId: Obj.id,
      commissionRate: this.rate,
      isAccept: true,
    };
    this.io
      .apicall(payload, "restaurant/restaurant-approve-reject", "POST")
      .then((res: any) => {
        if (this.io.data_.serverResponse.code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.modalService.dismissAll();
          this.loadApprovedRestaurantList();
          this.loadPendingRestaurantList();
          this.loadRejectedRestaurantList();
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  reject(obj: any) {
    let payload = {
      restaurantId: obj.id,
      isAccept: false,
    };
    this.io
      .apicall(payload, "restaurant/restaurant-approve-reject", "POST")
      .then((res: any) => {
        if (this.io.data_.serverResponse.code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.modalService.dismissAll();
          this.loadApprovedRestaurantList();
          this.loadPendingRestaurantList();
          this.loadRejectedRestaurantList();
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  openBrandCreationModal(res: any) {
    this.brandCreationModalRestaurant = { id: Number(res.id), name: res.restaurantName, branch: res.branchName };
    this.brandCreationDropdownData = [{
      id: this.brandCreationModalRestaurant.id,
      name: this.brandCreationModalRestaurant.name + (this.brandCreationModalRestaurant.branch?.trim() ? ` - ${this.brandCreationModalRestaurant.branch?.trim()}` : '')
    }];
  }

  async addBrandToRestaurant() {
    this.io
      .apicall({ brandName: this.brandCreationModalBrandName.trim() }, `restaurant/add-brand/${this.brandCreationModalRestaurant.id}`, 'POST')
      .then(() => {
        swal.fire({
          title: 'Success',
          text: 'Brand added to restaurant successfully.',
          icon: 'success',
          confirmButtonColor: '#442DFF;',
          confirmButtonText: 'ok',
        });
      })
      .catch(() => {
        swal.fire({
          title: 'Oops...',
          text: 'Something went wrong! Please try again.',
          icon: 'warning',
          confirmButtonColor: '#442DFF;',
          confirmButtonText: 'ok',
        });
      })
      .finally(() => {
        this.modalService.dismissAll();
      });
  }
}
