import { FormBuilder, Validators, FormArray } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import swal from "sweetalert2";
import { ContentManagementService } from "./../services/content-management/content-management.service";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-content-management",
  templateUrl: "./content-management.component.html",
  styleUrls: ["./content-management.component.css"],
})
export class ContentManagementComponent implements OnInit {
  pages: any = [];
  forms: any = {};
  FORM_KEYS: {
    CATEGORY_TYPE: string;
    TEXT: string;
    SECTIONS: string;
  };
  categories: any = [];
  currentEditPages: any = {};

  constructor(
    private fb: FormBuilder,
    private apiService: ContentManagementService,
    private loader: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadContent();

    /*  this.fetchTagCategory();
    this.loadPageDetails(); */
  }

  initForm(): void {
    this.FORM_KEYS = {
      CATEGORY_TYPE: "categoryType",
      TEXT: "text",
      SECTIONS: "sections",
    };
  }

  loadContent(): void {
    this.loader.start();
    const pages_subscriber = this.apiService.listPages();
    const categories_subscriber = this.apiService.listCategories();
    forkJoin({
      pagesRes: pages_subscriber,
      categoriesRes: categories_subscriber,
    }).subscribe(
      ({ pagesRes, categoriesRes }) => {
        this.loader.stop();
        this.pages = pagesRes;
        pagesRes.map((item) => {
          this.addFormGroup(item?.name);
          this.loadPageSections(item?.id, item?.name);
        });
        this.categories = categoriesRes.results;
      },
      () => {
        swal.fire({
          title: "Oops...",
          text: "Something went wrong while loading pages",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }

  loadPageSections(id: string, page: string): void {
    this.loader.start();
    this.apiService.listPageSections(id).subscribe(
      (res) => {
        this.loader.stop();
        res.map((item) => {
          this.addGroupSection(item?.id, item?.name, item?.categoryId, page);
        });
      },
      () => {
        swal.fire({
          title: "Oops...",
          text: "Something went wrong while loading sections",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }

  addFormGroup(pageName: string): void {
    this.forms[pageName] = this.fb.group({
      [this.FORM_KEYS.CATEGORY_TYPE]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.TEXT]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.SECTIONS]: this.fb.array([]),
    });
  }

  handleAddClick(
    text: string,
    type: string,
    pageName: string,
    pageId: string
  ): void {
    if (!this.forms[pageName].valid) {
      this.forms[pageName].markAllAsTouched();
      return;
    }
    this.loader.start();
    this.apiService.createPageSection(pageId, type, text).subscribe(
      (res) => {
        this.loader.stop();
        this.addGroupSection(res?.id, text, type, pageName);
      },
      () => {
        swal.fire({
          title: "Oops...",
          text: "Something went wrong while adding section",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
    this.forms[pageName].patchValue({
      [this.FORM_KEYS.TEXT]: "",
      [this.FORM_KEYS.CATEGORY_TYPE]: null,
    });
    this.forms[pageName].get(this.FORM_KEYS.TEXT).markAsUntouched();
    this.forms[pageName].get(this.FORM_KEYS.CATEGORY_TYPE).markAsUntouched();
  }

  addGroupSection(
    id: string,
    text: string,
    type: string,
    pageName: string
  ): void {
    const sectionForm = this.fb.group({
      [this.FORM_KEYS.CATEGORY_TYPE]: [
        this.categories?.find((item) => item?.id == type)?.name,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.TEXT]: [text, Validators.compose([Validators.required])],
      id: id,
    });
    this.getGroupSections(pageName).push(sectionForm);
  }

  removeGroupSection(pageName: string, pageId: string, i: number) {
    this.loader.start();
    const section_id = this.getGroupSections(pageName).at(i).get("id").value;
    this.apiService.deletePageSection(pageId, section_id).subscribe(
      () => {
        this.getGroupSections(pageName).removeAt(i);
        this.loader.stop();
      },
      () => {
        this.loader.stop();
        swal.fire({
          title: "Oops...",
          text: "Something went wrong while removing section",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }

  getGroupSections(pageName: string): FormArray {
    return this.forms[pageName].controls[this.FORM_KEYS.SECTIONS] as FormArray;
  }

  handleEditClick(pageId: string): void {
    this.currentEditPages[pageId] = true;
  }

  onDrop(event: CdkDragDrop<any>, pageName: string): void {
    const current_section = this.getGroupSections(pageName).at(
      event.previousIndex
    );
    this.getGroupSections(pageName).removeAt(event.previousIndex);
    this.getGroupSections(pageName).insert(event.currentIndex, current_section);
  }

  handleSaveOrder(pageId: string, pageName: string): void {
    this.loader.start();
    const page_sections = this.getGroupSections(pageName).controls.map(
      (item) => item.get("id").value
    );

    this.apiService.reorderPageSections(pageId, page_sections).subscribe(
      () => {
        delete this.currentEditPages[pageId];
        this.loader.stop();
      },
      () => {
        this.loader.stop();
        swal.fire({
          title: "Oops...",
          text: "Something went wrong while reordering sections",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }
}
