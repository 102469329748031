import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportComponent } from './report.component';
import { ReportListComponent, ReportTypesComponent } from '.';
import { ResturantReportComponent } from './resturant-report/resturant-report.component';

const routes: Routes = [
  {
    path: '',
     component: ReportComponent,children: [
      {
        path: 'types',
        component: ReportTypesComponent,
      },
      {
        path: 'list',
        component: ReportListComponent,
      },
      {
        path: 'restaurant-transactions',
        component: ResturantReportComponent,
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
