import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from "../../_constant/constant";

@Injectable({
  providedIn: "root",
})
export class DietaryPreferencesService {
  constructor(private http: HttpClient) {}

  listCategories(): Observable<any> {
    return this.http.get(routes.getDietaryPreferencesCategories);
  }

  getCategoryOptions(id): Observable<any> {
    return this.http.get(routes.getDietaryPreferencesOptions(id));
  }

  editCategory(id, payload): Observable<any> {
    return this.http.patch(routes.editDietaryPreferenceCategory(id), payload);
  }

  addNewItem(payload): Observable<any> {
    return this.http.post(routes.addDietaryPreferenceItem, payload);
  }

  editItem(categoryId, payload): Observable<any> {
    return this.http.patch(
      routes.editDietaryPreferenceItem(categoryId),
      payload
    );
  }

  deleteItem(id): Observable<any> {
    return this.http.delete(routes.deleteDietaryPreferenceItem(id));
  }
}
