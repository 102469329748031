import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AddDishComponent } from "./add-dish/add-dish.component";
import { DishListComponent } from "./dish-list/dish-list.component";

import { DishComponent } from "./dish.component";
import { EditDishComponent } from "./edit-dish/edit-dish.component";

const routes: Routes = [
  {
    path: "",
    component: DishComponent,
    children: [
      {
        path: "dish-list",
        component: DishListComponent,
      },
      {
        path: "add-dish",
        component: AddDishComponent,
      },
      {
        path: "edit-dish",
        component: EditDishComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DishRoutingModule {}
