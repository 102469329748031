import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CategoryComponent } from './category.component';
import { CategoryManagemntComponent, CategoryListComponent, EditCategoryComponent } from './';

const routes: Routes = [
  { 
    path: '', 
    component: CategoryComponent,
    children: [
      {
        path: 'category-management', 
        component: CategoryManagemntComponent,
      },
      {
        path: 'category-list', 
        component: CategoryListComponent,
      },
      {
        path: 'category-edit', 
        component: EditCategoryComponent,
      }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CategoryRoutingModule { }
