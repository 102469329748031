import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { OrderRoutingModule } from "./order-routing.module";
import { OrderComponent } from "./order.component";
import { OrderListComponent } from "./order-list/order-list.component";
import { OrderDetailsComponent } from "./order-details/order-details.component";
import { ModalModule } from "../modal/modal.module";
import { NgxPaginationModule } from "ngx-pagination";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TabNavigatorModule } from "../tab-navigator/tab-navigator.module";
import { OrderProductComponent } from './order-details/components/order-product/order-product.component';
import { OrderProductDetailsModalComponent } from './order-details/components/order-product-details-modal/order-product-details-modal.component';
import { OrderProductDetailsComponent } from './order-details/components/order-product-details/order-product-details.component';

@NgModule({
  declarations: [
    OrderComponent,
    OrderListComponent,
    OrderDetailsComponent,
    OrderProductComponent,
    OrderProductDetailsModalComponent,
    OrderProductDetailsComponent,
  ],
  imports: [
    CommonModule,
    OrderRoutingModule,
    ModalModule,
    NgxPaginationModule,
    AutocompleteLibModule,
    FormsModule,
    ReactiveFormsModule,
    TabNavigatorModule
  ],
})
export class OrderModule {}
