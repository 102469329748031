import { Injectable } from "@angular/core";
import { IoService } from '../../services/http/io.service';
import { Menu } from '../interfaces/menu.interface';

@Injectable({
  providedIn: "root",
})
export class MenuListService {
  constructor(private io: IoService) { }

  async getMenus(restaurantId: number) {
    const menus = (await this.io.apicall(null, `menu/portal/restaurant/${restaurantId}`, 'GET')) as Menu[];

    return menus;
  }
}
