import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NotificationComponent } from './notification.component';
import { SendNotificationComponent } from './send-notification/send-notification.component';

const routes: Routes = [
  {
    path: '',
    component: NotificationComponent,
    children: [
      {
        path: 'send',
        component: SendNotificationComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotificationRoutingModule { }
