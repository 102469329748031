import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero-banners',
  templateUrl: './hero-banners.component.html',
  styleUrls: ['./hero-banners.component.css']
})
export class HeroBannersComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
