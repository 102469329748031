import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddCouponComponent } from './add-coupon/add-coupon.component';
import { CouponDetailsComponent } from './coupon-details/coupon-details.component';
import { CouponListComponent } from './coupon-list/coupon-list.component';

import { CouponComponent } from './coupon.component';

const routes: Routes = [{
  path: '', 
  component: CouponComponent,
  children: [
    {
      path: 'coupon-list',
      component: CouponListComponent,
    },
    {
      path: 'add-coupon',
      component: AddCouponComponent,
    },
    {
      path: 'coupon-details',
      component: CouponDetailsComponent,
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CouponRoutingModule { }
