import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-meal-plan-creator-step-five',
    templateUrl: './meal-plan-creator-step-five.component.html',
    styleUrls: ['./meal-plan-creator-step-five.component.css']
})
export class MealPlanCreatorStepFiveComponent {
    @Output() savePlanHandler = new EventEmitter();

    createPlan() {
        this.savePlanHandler.emit();
    }
}
