import { Component, Input, OnInit } from "@angular/core";
import { MenuCategory } from '../../../interfaces/menu-category.interface';

@Component({
  selector: "app-menu-food-science-category",
  templateUrl: "./menu-food-science-category.component.html",
  styleUrls: ["./menu-food-science-category.component.css"],
})
export class MenuFoodScienceCategoryComponent implements OnInit {
  @Input() category: MenuCategory

  @Input() tags
  @Input() avoidancesId

  showDetails = false;

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
