import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecipeRoutingModule } from './recipe-routing.module';
import { RecipeComponent } from './recipe.component';
import { RecipeListComponent } from './recipe-list/recipe-list.component';
import { EditRecipeComponent } from './edit-recipe/edit-recipe.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TagInputModule } from 'ngx-chips';
import { IoService } from '../services/http/io.service';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddRecipeComponent } from './add-recipe/add-recipe.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DecimalpointDirective } from '../validators/decimal.directives';
import { TwoDigitDecimaNumberDirective } from '../validators/two-digit-decimal.directives';

@NgModule({
  declarations: [RecipeComponent, RecipeListComponent, EditRecipeComponent, AddRecipeComponent,DecimalpointDirective,TwoDigitDecimaNumberDirective],
  imports: [
    CommonModule,
    NgbModule,
    RecipeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    TagInputModule,
    BrowserAnimationsModule,
    ImageCropperModule,
    HttpClientModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
    ColorPickerModule
  ],
  providers: [
    IoService
  ]
})
export class RecipeModule { }
