import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { rejects } from "assert";
import { couldStartTrivia } from "typescript";
import { transformAll } from "@angular/compiler/src/render3/r3_ast";
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
  providedIn: "root",
})
export class IoService implements OnDestroy {
  private baseUrl = environment.baseUrl;
  data_: any;
  userToken: any = "";
  loader: boolean = false;
  icon: any;
  reason: any;
  subscriptions: Subscription[] = [];
  constructor(private http: HttpClient,
  private ngxService: NgxUiLoaderService) {}

  downloadFile(path: string) {
    let url = this.baseUrl + path;

    return new Promise((resolve, reject) => {
      this.http.get(url, { responseType: 'blob' }).subscribe(
        (data) => resolve(data),
        (err) => reject(err)
      );
    })
  }

  getRequest(path: string) {
    let url = this.baseUrl + path;

    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe(
        (data) => resolve(data),
        (err) => reject(err)
      );
    })
  }

  patchRequest(path: string, body?: any) {
    let url = this.baseUrl + path;

    return new Promise((resolve, reject) => {
      this.http.patch(url, body).subscribe(
        (data) => resolve(data),
        (err) => reject(err)
      );
    })
  }

  postRequest(path: string, body?: any) {
    let url = this.baseUrl + path;

    return new Promise((resolve, reject) => {
      this.http.post(url, body).subscribe(
        (data) => resolve(data),
        (err) => reject(err)
      );
    })
  }

  apicall(body_params: any, request_path: any, action_type: String = "POST"): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.baseUrl + request_path;

      if (action_type == "GET") {
        this.ngxService.start();
        this.http.get(url).subscribe(
          (data) => {
            this.data_ = data;
            this.ngxService.stop();
            resolve(data);
          },
          (err) => {
            this.ngxService.stop();
            this.data_ = err.error;
            reject(err);
          }
        );
      }

      if (action_type == "POST") {
        this.ngxService.start();
        this.http.post(url, body_params).subscribe(
          (data) => {
            this.data_ = data;
            this.ngxService.stop();
            this.subscriptions.push(this.data_);
            resolve(data);
          },
          (err) => {
            this.ngxService.stop();
            this.data_ = err.error;
            reject(err);
          }
        );
      }

      if (action_type == "DELETE") {
        this.ngxService.start();
        this.http.delete(url).subscribe(
          (data) => {
            this.data_ = data;
            this.ngxService.stop();
            this.subscriptions.push(this.data_);
            resolve(data);
          },
          (err) => {
            this.ngxService.stop();
            this.data_ = err.error;
            reject(err);
          }
        );
      }

      if (action_type == "file") {
        this.ngxService.start();
        let headers = new HttpHeaders();

        // headers = headers.set("Content-Type", 'multipart/form-data');
        // headers.append('Accept', 'application/json');
        // headers.append('Access-Control-Allow-Origin ','*');
        // headers.append('Access-Control-Allow-Credentials', 'true');
        // headers.append('Access-Control-Allow-Methods', 'OPTION,POST');
        // let options = new RequestOptions({ headers: headers });

        this.http.post(url, body_params, { headers: headers }).subscribe(
          (data) => {
            this.data_ = data;
            this.ngxService.stop();
            resolve(data);
          },
          (err) => {
            this.ngxService.stop();
            this.data_ = err;
            resolve(err);
          }
        );
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscriptions) => subscriptions.unsubscribe());
  }
}
