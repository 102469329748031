import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { routes } from "./../../_constant/constant";

@Injectable({
  providedIn: "root",
})
export class SupportService {
  constructor(private http: HttpClient) {}

  getCategories(): Observable<any> {
    return this.http.get(routes.getSupportCategories);
  }

  getCategoryItem(id: String): Observable<any> {
    return this.http.get(routes.getSupportItem(id));
  }
  editCategoryItem(id: String, text: String): Observable<any> {
    return this.http.patch(routes.editSupportItem(id), {
      text,
    });
  }
  deleteCategoryItem(id: String): Observable<any> {
    return this.http.delete(routes.deleteSupportItem(id));
  }
  addCategoryItem(text: String): Observable<any> {
    return this.http.post(routes.addSupportItem, {
      text,
    });
  }
}
