import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validator,
  Validators,
} from "@angular/forms";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { IoService } from "../..//services/http/io.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import swal from "sweetalert2";
import { DataService } from "../../services/data-management/data.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import { base64ToFile } from "ngx-image-cropper";
import { HttpClient } from "@angular/common/http";
import { RecipeProviderService } from "./../../services/recipe-provider/recipe-provider.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
let closeResult: any;

@Component({
  selector: "app-recipe-provider-details",
  templateUrl: "./recipe-provider-details.component.html",
  styleUrls: ["./recipe-provider-details.component.css"],
})
export class RecipeProviderDetailsComponent implements OnInit, OnDestroy {
  recipeProviderDetails: any = {};
  subscription: Subscription;
  editFrm: FormGroup;
  paynowform: FormGroup;
  file: any = "";
  recipeId: any = "";
  page: number = 1;
  total: number;
  transactionAllList: any = [];
  croppedImage: any = "";
  finalFile: any = "";
  enableCropper: boolean = false;
  imageChangedEvent: any = "";
  countryList: any = [];
  image: any = "";
  selectedCountry: any = "";
  countryCode: any = "";
  paramObserver: Subscription;
  FORM_KEYS: {
    NAME: string,
    EMAIL: string,
    PHONE: string,
    RATE: string,
    TOTAL_EARNINGS: string,
    STATUS: string
  }

  constructor(
    private route: ActivatedRoute,
    private io: IoService,
    private fb: FormBuilder,
    private data: DataService,
    private modalService: NgbModal,
    private router: Router,
    private http: HttpClient,
    private RecipeProvderService: RecipeProviderService,
    private loader: NgxUiLoaderService
  ) {
    this.FORM_KEYS = {
      NAME:"name",
      EMAIL:"email",
      PHONE:"phone",
      RATE:"rate",
      TOTAL_EARNINGS:"totalEarnings",
      STATUS:"status"
    }
    this.paynowform = this.fb.group({
      transactionAmount_: ["", Validators.compose([Validators.required])],
    });
  }

  deleteRecipeProvider(): void {
    this.loader.start();
    this.RecipeProvderService.delete(this.recipeId).subscribe(
      (res) => {
        swal.fire({
          title: "Success",
          text: "Deleted Successfully",
          icon: "success",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.modalService.dismissAll();
        this.loader.stop();
        this.router.navigate(["/recipe-provider/recipe-provider-action"]);
      },
      (err) => {
        this.modalService.dismissAll();
        swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }

  loadFrm() {
    this.editFrm = this.fb.group({
      [this.FORM_KEYS.NAME]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.EMAIL]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.PHONE]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.RATE]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.TOTAL_EARNINGS]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.STATUS]: ["", Validators.compose([Validators.required])],
    });
  }

  loadContentProviderDetails() {
    let payload = {
      userId: this.recipeId,
    };
    this.io
      .apicall(payload, "creator/creator-profile-view", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.recipeProviderDetails = res["result"];
          this.editFrm.get(this.FORM_KEYS.NAME).setValue(res["result"].name);
          this.editFrm.get(this.FORM_KEYS.EMAIL).setValue(res["result"].email);
          this.editFrm.get(this.FORM_KEYS.PHONE).setValue(res["result"].phone);
          this.editFrm
            .get(this.FORM_KEYS.RATE)
            .setValue(res["result"].recipeCreatorRateAmount);
          this.editFrm
            .get(this.FORM_KEYS.TOTAL_EARNINGS)
            .setValue(res["result"].totalEarnings);
          this.editFrm.get(this.FORM_KEYS.STATUS).setValue(res["result"].isActive ? "Active" : "Inactive");
          this.image = res["result"].profileImage;
          this.selectedCountry = res["result"]?.countryName;
          this.countryCode = res["result"]?.countryCode;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  routerNavigate(obj: any) {
    let payload;
    let abc = obj.referralWebLink;
    if (abc.split("/")[0] == "https:") {
      payload = abc;
    } else {
      payload = `https://${abc}`;
    }
    window.open(payload, "_blank");
  }

  allTransactionList() {
    this.transactionAllList = [];
    let payload = {
      userId: this.recipeId,
      page: this.page,
      limit: 5,
    };
    this.io
      .apicall(payload, "transaction/recipe-creator-transaction-list", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];
          this.total = result.totalRecords;
          if (this.total > this.transactionAllList.length) {
            this.transactionAllList = [
              ...this.transactionAllList,
              ...result.transactionList,
            ];
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  ngOnInit(): void {
    this.loadFrm();
    this.fetchCountries();
    this.paramObserver = this.route.params.subscribe(({ id }) => {
      if (id) {
        this.recipeId = id;
        this.loadContentProviderDetails();
        this.allTransactionList();
      } else {
        this.router.navigate(["/recipe-provider/recipe-provider-action"]);
      }
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  fetchCountries(): void {
    this.http.get<any>("./assets/data/country.json").subscribe((res: any) => {
      this.countryList = res.countries;
    });
  }

  changeCountry(event) {
    this.selectedCountry = event.target.value;
    let index = this.countryList.filter(
      (item) => item.name === this.selectedCountry
    );
    this.countryCode = index[0].code;
  }

  submit(Obj) {
    if(!this.editFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.editFrm.markAllAsTouched();
      return;
    }
    let payload = {
      userId: this.recipeId,
      name: this.editFrm.get(this.FORM_KEYS.NAME).value,
      phone: this.editFrm.get(this.FORM_KEYS.PHONE).value,
      recipeCreatorRateAmount: this.editFrm.get(this.FORM_KEYS.RATE).value,
      countryName: this.selectedCountry,
      countryCode: this.countryCode,
    };
    this.io
      .apicall(payload, "creator/creator-profile-update", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.loadContentProviderDetails();
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  // fileInput(event) {
  //   if (event.target.files && event.target.files[0]) {
  //     var reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       this.imgURL =  reader.result as string;
  //     }
  //     this.file = event.target.files[0];
  //     reader.readAsDataURL(event.target.files[0]);
  //     this.uploadImage();
  //   }
  // }

  makePropular(event) {
    let payLoad = {
      userId: this.recipeId,
      isPopular: event.target.checked,
    };
    this.io
      .apicall(payLoad, "creator/marked-recipe-creator-popular", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.loadContentProviderDetails();
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  activeInactive(active) {
    let payLoad;
    if (active == true) {
      payLoad = {
        userId: this.recipeId,
        isActive: false,
      };
    } else {
      payLoad = {
        userId: this.recipeId,
        isActive: true,
      };
    }

    this.io
      .apicall(payLoad, "creator/recipe-creator-active-inactive", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.loadContentProviderDetails();
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  openModal(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  payNow(Obj) {
    let payload = {
      userId: this.recipeId,
      transactionAmount: Obj.transactionAmount_,
    };
    this.io
      .apicall(payload, "transaction/pay-to-recipe-creator-account", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.paynowform.reset();
          this.modalService.dismissAll();
          this.allTransactionList();
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  pageChanged(event) {
    if (event >= 1) {
      this.allTransactionList();
    }
  }

  //============================================'Handlling Image Cropper'================================================================/
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }

  imageLoaded(image: HTMLImageElement) {
    // show cropper
  }
  cropperReady(sourceImageDimensions: Dimensions) {}

  loadImageFailed() {
    // show message
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
      this.image = this.imageChangedEvent;
    }
  }

  uploadImage() {
    const formData = new FormData();
    formData.append("file", this.finalFile);
    this.io
      .apicall(formData, "uploads/profile-img-upload", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.image = res["result"][0].fileUrl;
          let payload = {
            userId: this.recipeId,
            name: this.editFrm.get(this.FORM_KEYS.NAME).value,
            phone: this.editFrm.get(this.FORM_KEYS.PHONE).value,
            profileImage: res["result"][0].fileUrl,
            recipeCreatorRateAmount: this.editFrm.get(this.FORM_KEYS.RATE).value,
          };
          this.io
            .apicall(payload, "creator/creator-profile-update", "POST")
            .then((res: any) => {
              if (res["serverResponse"].code === 200) {
                swal.fire({
                  title: "Success",
                  text: "Image uploaded.",
                  icon: "success",
                  confirmButtonColor: "#442DFF;",
                  confirmButtonText: "Ok",
                });
              }
              this.croppedImage = "";
            })
            .catch((err) => {
              throw err;
            });
        }
      })
      .catch((err) => {});
    this.modalService.dismissAll();
  }

  closeModal() {
    this.modalService.dismissAll();
    this.enableCropper = false;
  }
}
