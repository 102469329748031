import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ResturantComponent } from './resturant.component';
import { RestaurantListComponent } from './restaurant-list/restaurant-list.component';
import { AddRestaurantComponent } from './add-restaurant/add-restaurant.component';
import { EditRestaurantComponent } from './edit-restaurant/edit-restaurant.component';
import { CoverageMapComponent } from './coverage-map/coverage-map.component';

const routes: Routes = [
  { path: '', 
  component: ResturantComponent,
  children: [
      {
        path: 'restaurant-list', 
        component: RestaurantListComponent,
      },
      {
        path: 'add-restaurant', 
        component: AddRestaurantComponent,
      },
      {
        path: 'edit-restaurant',
        component: EditRestaurantComponent,
      },
      {
        path: 'coverage-map',
        component: CoverageMapComponent,
      }
    ]  
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResturantRoutingModule { }
