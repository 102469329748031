// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  pusher: {
    key: "d773ad39b0fa945f82bf",
    cluster: "ap2",
    channel: "MyEatPal-development",
  },
  baseUrl: "https://api.dev.myeatpal.com/api/v3/",
  pageName: "RECIPE",
  pageName1: "HOME",
  pageName2: "RESTAURANT",
  googleMapKey: "AIzaSyCD5rN5h-9yEFH6nYUJEDEqLyRoZr5eVMM",
  catgory: {
    recipe: "recipe",
    dish: "dish",
    restaurant: "restaurant",
  },
  allTagHeadDetails: {
    Allergies: "H1",
    DietPlan: "H2",
    Cuisine: "H3",
    FoodTypes: "H4",
    MacroRatio: "H5",
    MealTime: "H6",
  },
  httpHeaders:{
    apiVersion: "V4.0",
    channel: "admin_portal",
  },
};
