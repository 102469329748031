import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data-management/data.service';
import { IoService } from '../../services/http/io.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as moment from 'moment';
@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css']
})
export class ReportListComponent implements OnInit {

  resturantRepostList: any = [];
  activePage: number = 1;
  activeTotal: number;
  queryList: boolean = false;
  // today = new Date();
  fromDate = null;
  toDate = null;
  restaurantName: any;
  resturantListForDropdown: any = [];
  excel=[];
  formMaxDate:any;
  toMaxDate:any;

  constructor(
    private modalService: NgbModal,
    private ar: ActivatedRoute,
    private io: IoService,
    private dataservice: DataService,
    private router: Router,
    private formBuilder: FormBuilder,

  ) {

   }




  ngOnInit(): void {
    this.resturantList();
  //   this.getResturantReportList();
  this.formMaxDate ={year:moment().year(), month:moment().month()+1, day: moment().date()-1};
  this.toMaxDate ={year:moment().year(), month:moment().month()+1, day: moment().date()};

  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

    exportAsXLSX():void {
      let fromDateFormat = this.fromDate.year+'-'+this.fromDate.month+'-'+this.fromDate.day+' 01:01:01'
      let toDateFormat = this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.day+' 01:01:01'




      let uri = 'https://dev-admin.myeatpal.com/api/v1/report/trans-restaurant-download?restaurantId=all&fromdate='+fromDateFormat+'&todate='+toDateFormat+'&restaurantName=all';
      window.open( encodeURI(uri));

    }


 getResturantReportList() {
  this.resturantRepostList = [];
  // 2021-10-10 01:01:01
  let fromDateFormat = this.fromDate.year+'-'+this.fromDate.month+'-'+this.fromDate.day+' 01:01:01'
  let toDateFormat = this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.day+' 01:01:01'
  let payload = {
    fromdate: fromDateFormat,
    todate: toDateFormat,
    restaurantName:this.restaurantName?this.restaurantName:'',
    page: this.activePage,
    limit: 5,
  }
  this.io.apicall(payload, '/report/trans-restaurant/all', 'POST').then((res: any) => {
    if (res['serverResponse'].code === 200) {
      this.queryList = false;
     this.activeTotal = res['result'][0]['metadata'][0].total;
      this.resturantRepostList = res['result'][0]['data'];
    }
  })
    .catch(err => {
      throw err;
    })
}


  //PAGE CHANGED FOR NEW Report LIST //
  pageChanged(event) {
    if (event >= 1) {
      this.getResturantReportList();
    }
  }
  viewDetails(obj){
    let Obj = {
      resturantId: obj.restaurantId
    };
    this.dataservice._setObj(Obj);
    this.router.navigate(["/report/restaurant-transactions"]);
  }

  resturantList() {

    this.io
      .apicall({}, "restaurant/get-restaurant-list-for-dropdown", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.resturantListForDropdown = res["result"];
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  fetchReports(){
    if(this.fromDate!=null && this.toDate!=null){
      this.getResturantReportList();
    }
  }

  // SEARCH BY RESTURNT NAME //
  chooseResturant(event) {
    this.restaurantName = event.target.value;
    this.fetchReports();
  }

}
