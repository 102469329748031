import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItemBoxComponent } from "./item-box.component";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

@NgModule({
  declarations: [ItemBoxComponent],
  imports: [CommonModule, NgbTooltipModule, BsDropdownModule],
  exports: [ItemBoxComponent],
})
export class ItemBoxModule {}
