import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

import { SupplyCountRoutingModule } from './supply-count-routing.module';
import { SupplyCountComponent } from './supply-count.component';
import { CountComponent } from './count/count.component';

@NgModule({
  declarations: [
    SupplyCountComponent,
    CountComponent,
  ],
  imports: [
    SupplyCountRoutingModule,
    CommonModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [],
})
export class SupplyCountModule { }
