import {
  Validators,
  FormGroup,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { environment } from "../../../environments/environment";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { IoService } from "../../services/http/io.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import swal from "sweetalert2";
import { CategoryManagementService } from "./../../services/category-management/category-management.service";
import { DietaryPreferencesService } from "./../../services/dietary-preferences/dietary-preferences.service";
import { ValidatorsService } from "./../../services/validators/valdiators.service";

@Component({
  selector: "app-category-managemnt",
  templateUrl: "./category-managemnt.component.html",
  styleUrls: ["./category-managemnt.component.css"],
})
export class CategoryManagemntComponent implements OnInit {
  dropdownSettings: IDropdownSettings;
  form: FormGroup;
  foodTypeList: any = [];
  mealtimeList: any = [];
  cuisineList: any = [];
  categoryArray: any = [];
  dietaryPreferences: any = [];
  FORM_KEYS: {
    CATEGORY_NAME: string;
    PREPARATION_TIME_LTE: string;
    PREPARATION_TIME_GTE: string;
    CATEGORY_TYPE: string;
    CARBS_LTE: string;
    CARBS_GTE: string;
    FAT_LTE: string;
    FAT_GTE: string;
    PROTEIN_LTE: string;
    PROTEIN_GTE: string;
    CALORIES_LTE: string;
    CALORIES_GTE: string;
    PRICE_LTE: string;
    PRICE_GTE: string;
    NEW_RECIPE: string;
    IS_SIMPLE: string;
    BUDGET_FRIENDLY: string;
    POST_WORKOUT: string;
    NEW_DISH: string;
    NEW_RESTAURANT: string;
    ONLY_RESTAURANT: string;
    MEAL_TIME: string;
    CUISINE: string;
    FOOD_TYPE: string;
  };
  constructor(
    private fb: FormBuilder,
    private io: IoService,
    private router: Router,
    private categoryManagementService: CategoryManagementService,
    private dietaryPreferencesService: DietaryPreferencesService,
    private validatorService: ValidatorsService
  ) {
    this.FORM_KEYS = {
      CATEGORY_NAME: "categoryName",
      PREPARATION_TIME_LTE: "preparationTimeLte",
      PREPARATION_TIME_GTE: "preparationTimeGte",
      CATEGORY_TYPE: "categoryType",
      CARBS_LTE: "carbsLte",
      CARBS_GTE: "carbsGte",
      FAT_LTE: "fatLte",
      FAT_GTE: "fatGte",
      PROTEIN_LTE: "proteinLte",
      PROTEIN_GTE: "proteinGte",
      CALORIES_LTE: "caloriesLte",
      CALORIES_GTE: "caloriesGte",
      PRICE_LTE: "priceLte",
      PRICE_GTE: "priceGte",
      NEW_RECIPE: "newRecipe",
      IS_SIMPLE: "isSimple",
      BUDGET_FRIENDLY: "budgetFriendly",
      POST_WORKOUT: "postWorkout",
      NEW_DISH: "newDish",
      NEW_RESTAURANT: "newRestaurant",
      ONLY_RESTAURANT: "onlyRestaurant",
      MEAL_TIME: "mealTime",
      CUISINE: "cuisine",
      FOOD_TYPE: "foodType",
    };
  }

  loadForm() {
    this.form = this.fb.group({
      [this.FORM_KEYS.CATEGORY_NAME]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.PREPARATION_TIME_LTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange(
            "min",
            () => this.form?.get(this.FORM_KEYS.PREPARATION_TIME_GTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.PREPARATION_TIME_GTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange("min", () => 0),
          this.validatorService.validateRange(
            "max",
            () => this.form?.get(this.FORM_KEYS.PREPARATION_TIME_LTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.CATEGORY_TYPE]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.CARBS_LTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange(
            "min",
            () => this.form?.get(this.FORM_KEYS.CARBS_GTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.CARBS_GTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange("min", () => 0),
          this.validatorService.validateRange(
            "max",
            () => this.form?.get(this.FORM_KEYS.CARBS_LTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.FAT_LTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange(
            "min",
            () => this.form?.get(this.FORM_KEYS.FAT_GTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.FAT_GTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange("min", () => 0),
          this.validatorService.validateRange(
            "max",
            () => this.form?.get(this.FORM_KEYS.FAT_LTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.PROTEIN_LTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange(
            "min",
            () => this.form?.get(this.FORM_KEYS.PROTEIN_GTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.PROTEIN_GTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange("min", () => 0),
          this.validatorService.validateRange(
            "max",
            () => this.form?.get(this.FORM_KEYS.PROTEIN_LTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.CALORIES_LTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange(
            "min",
            () => this.form?.get(this.FORM_KEYS.CALORIES_GTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.CALORIES_GTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange("min", () => 0),
          this.validatorService.validateRange(
            "max",
            () => this.form?.get(this.FORM_KEYS.CALORIES_LTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.PRICE_LTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange(
            "min",
            () => this.form?.get(this.FORM_KEYS.PRICE_GTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.PRICE_GTE]: [
        null,
        Validators.compose([
          this.validatorService.validateRange("min", () => 0),
          this.validatorService.validateRange(
            "max",
            () => this.form?.get(this.FORM_KEYS.PRICE_LTE)?.value
          ),
        ]),
      ],
      [this.FORM_KEYS.BUDGET_FRIENDLY]: [null],
      [this.FORM_KEYS.NEW_RECIPE]: [null],
      [this.FORM_KEYS.IS_SIMPLE]: [null],
      [this.FORM_KEYS.POST_WORKOUT]: [null],
      [this.FORM_KEYS.NEW_DISH]: [null],
      [this.FORM_KEYS.NEW_RESTAURANT]: [null],
      [this.FORM_KEYS.ONLY_RESTAURANT]: [null],
      [this.FORM_KEYS.MEAL_TIME]: [[]],
      [this.FORM_KEYS.CUISINE]: [[]],
      [this.FORM_KEYS.FOOD_TYPE]: [[]],
    });
    this.setCustomListeners();
  }

  setCustomListeners(): void {
    const CHANGES_CONTROL_MAPPER = {
      [this.FORM_KEYS.PREPARATION_TIME_LTE]: [
        this.FORM_KEYS.PREPARATION_TIME_GTE,
      ],
      [this.FORM_KEYS.FAT_LTE]: [this.FORM_KEYS.FAT_GTE],
      [this.FORM_KEYS.CARBS_LTE]: [this.FORM_KEYS.CARBS_GTE],
      [this.FORM_KEYS.PRICE_LTE]: [this.FORM_KEYS.PRICE_GTE],
      [this.FORM_KEYS.PROTEIN_LTE]: [this.FORM_KEYS.PROTEIN_GTE],
      [this.FORM_KEYS.CALORIES_LTE]: [this.FORM_KEYS.CALORIES_GTE],
    };
    for (const controlName in CHANGES_CONTROL_MAPPER) {
      this.form.get(controlName).valueChanges.subscribe(() => {
        this.form.get(CHANGES_CONTROL_MAPPER[controlName]).setErrors(null);
      });
      this.form
        .get(CHANGES_CONTROL_MAPPER[controlName])
        .valueChanges.subscribe(() => {
          this.form.get(controlName).setErrors(null);
        });
    }
  }

  ngOnInit(): void {
    this.loadForm();
    this.fetchAllHead();
    this.dropDownIntialized();
    this.loadCategoryType();
  }

  dropDownIntialized() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "tagName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
  }

  loadCategoryType() {
    this.categoryArray.push(
      { id: 0, categoryType: environment.catgory.recipe },
      { id: 1, categoryType: environment.catgory.dish },
      { id: 2, categoryType: environment.catgory.restaurant }
    );
  }

  addCategory() {
    if (!this.form.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.form.markAllAsTouched();
      return;
    }
    const type = this.form.get(this.FORM_KEYS.CATEGORY_TYPE).value;
    let payLoad: any = {
      name: this.form.get(this.FORM_KEYS.CATEGORY_NAME).value,
      type: type,
      params: {
        is_new:
          type == "dish"
            ? this.form.get(this.FORM_KEYS.NEW_DISH).value
            : type == "recipe"
            ? this.form.get(this.FORM_KEYS.NEW_RECIPE).value
            : this.form.get(this.FORM_KEYS.NEW_RESTAURANT).value,
        cuisine: this.form
          .get(this.FORM_KEYS.CUISINE)
          .value.map((item) => item.id),
        food_type: this.form
          .get(this.FORM_KEYS.FOOD_TYPE)
          .value.map((item) => item.id),
      },
    };
    if (type == "recipe" || type == "dish") {
      let dietary_preferences = {};
      this.dietaryPreferences.forEach((item) => {
        dietary_preferences[item.id] = this.form
          .get(item.name)
          .value.map((preference) => preference.id);
      });
      payLoad = {
        ...payLoad,
        params: {
          ...payLoad.params,
          carbs_lte: this.form.get(this.FORM_KEYS.CARBS_LTE).value || null,
          carbs_gte: this.form.get(this.FORM_KEYS.CARBS_GTE).value || null,
          fat_lte: this.form.get(this.FORM_KEYS.FAT_LTE).value || null,
          fat_gte: this.form.get(this.FORM_KEYS.FAT_GTE).value || null,
          protein_lte: this.form.get(this.FORM_KEYS.PROTEIN_LTE).value || null,
          protein_gte: this.form.get(this.FORM_KEYS.PROTEIN_GTE).value || null,
          total_calories_lte:
            this.form.get(this.FORM_KEYS.CALORIES_LTE).value || null,
          total_calories_gte:
            this.form.get(this.FORM_KEYS.CALORIES_GTE).value || null,
          meal_time: this.form
            .get(this.FORM_KEYS.MEAL_TIME)
            .value.map((item) => item.id),
          dietary_preferences,
        },
      };
      if (type == "recipe") {
        payLoad = {
          ...payLoad,
          params: {
            ...payLoad.params,
            preparation_time_lte:
              this.form.get(this.FORM_KEYS.PREPARATION_TIME_LTE).value || null,
            preparation_time_gte:
              this.form.get(this.FORM_KEYS.PREPARATION_TIME_GTE).value || null,
            is_simple_ingredient: this.form.get(this.FORM_KEYS.IS_SIMPLE).value,
            is_budget_friendly: this.form.get(this.FORM_KEYS.BUDGET_FRIENDLY)
              .value,
            is_post_workout: this.form.get(this.FORM_KEYS.POST_WORKOUT).value,
          },
        };
      } else {
        payLoad = {
          ...payLoad,
          params: {
            ...payLoad.params,
            price_lte: this.form.get(this.FORM_KEYS.PRICE_LTE).value,
            price_gte: this.form.get(this.FORM_KEYS.PRICE_GTE).value,
          },
        };
      }
    } else {
      payLoad = {
        ...payLoad,
        params: {
          ...payLoad.params,
          is_only: this.form.get(this.FORM_KEYS.ONLY_RESTAURANT).value,
        },
      };
    }
    this.categoryManagementService.addCategory(payLoad).subscribe(
      (res) => {
        swal.fire({
          title: "Success",
          text: "Category successfully added",
          icon: "success",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "Ok",
        });
        this.router.navigate(["/category/category-list"]);
      },
      (err) => {
        swal.fire({
          title: "Oops...",
          text: "Something went wrong while adding category",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }

  fetchAllHead() {
    this.io
      .apicall({}, "tagcategories/fetch-tag-head", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          let allHeadList = res["result"];
          for (var i = 0; i < allHeadList.length; i++) {
            if (allHeadList[i].tagHeadId === "H6") {
              this.headTagMealTime(allHeadList[i].id);
            } else if (allHeadList[i].tagHeadId === "H4") {
              this.headTagFoodType(allHeadList[i].id);
            } else if (allHeadList[i].tagHeadId === "H3") {
              this.headTagCusine(allHeadList[i].id);
            }
          }
        }
      })
      .catch((err) => {
        throw err;
      });
    this.dietaryPreferencesService.listCategories().subscribe((res) => {
      this.dietaryPreferences = res;
      this.dietaryPreferences.forEach((item, index) => {
        item.dropdownSettings = {
          ...this.dropdownSettings,
          singleSelection: !item.multi,
          textField: "name",
        };
        const diet_control = new FormControl([]);
        this.form.addControl(item.name, diet_control);
        this.fetchDietaryPreferencesForCategory(item.id, index);
      });
    });
  }

  fetchDietaryPreferencesForCategory(id: string, index: number) {
    this.dietaryPreferencesService.getCategoryOptions(id).subscribe((res) => {
      this.dietaryPreferences[index] = {
        ...this.dietaryPreferences[index],
        options: res,
      };
    });
  }

  async headTagFoodType(id) {
    let value = await this.getTagByHead(id);
    this.foodTypeList = value;
  }

  async headTagMealTime(id) {
    let value = await this.getTagByHead(id);
    this.mealtimeList = value;
  }

  async headTagCusine(id) {
    let value = await this.getTagByHead(id);
    this.cuisineList = value;
  }

  async getTagByHead(id): Promise<any> {
    return new Promise((resolve, reject) => {
      let payLoad = {
        tagHeadId: id,
      };
      this.io
        .apicall(payLoad, "tagcategories/fetch-tag-by-head", "POST")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            resolve(res["result"]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
